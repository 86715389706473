import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const HorizontalBarChart = ({ graphData, graphTitle, xAxisTitle }) => {
  const { labels, data } = graphData;
  if (labels.length === 0 || data.length === 0) {
    return null;
  }
  return (
    <HorizontalBar
      data={{
        labels,
        datasets: [
          {
            label: graphTitle,
            data,
            fill: false,
            backgroundColor: "rgba(248, 139, 94, 0.5)",
            borderColor: "rgba(248, 139, 94, 0.75)",
            borderWidth: 1
          }
        ]
      }}
      options={{
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: xAxisTitle,
                fontColor: "#2d3848",
                fontSize: 12,
                fontStyle: "bold"
              },
              ticks: {
                min: 0,
                beginAtZero: true
              }
            }
          ]
        },
        legend: {
          onClick: e => e.preventDefault(),
          align: "end",
          labels: {
            fontColor: "#ff000",
            fontStyle: "bold",
            fontSize: 12
          }
        }
      }}
    />
  );
};

export default HorizontalBarChart;
