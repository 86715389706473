/* eslint-disable import/no-anonymous-default-export */
import {
  APPEND_SR_DOCUMENTS,
  CREATE_SR_DOCUMENT,
  DELETE_SR_DOCUMENT,
  ERROR_SR_DOCUMENT,
  SET_SR_DOCUMENT,
  SET_SR_DOCUMENTS,
  UNSET_SR_DOCUMENTS,
  UNSET_SR_DOCUMENT_ACTION,
  UNSET_ERROR_SR_DOCUMENT,
  UPDATE_SR_DOCUMENT,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  srDocumentsAvailable: {},
  srDocumentErrors: {},
  fetchTime: 0,
}

const srDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENTS:
      if ((action?.fetchTime || 0) > state.fetchTime) {
        return {
          ...state,
          lastAction: 'fetch',
          srDocumentsAvailable: _.mapKeys(action.payload, 'id'),
          fetchTime: action.fetchTime,
          isLoadingMore: action.isLoadingMore,
        }
      } else {
        return state
      }
    case APPEND_SR_DOCUMENTS:
      if (action.fetchTime === state.fetchTime) {
        return {
          ...state,
          lastAction: 'fetch',
          srDocumentsAvailable: {
            ...state.srDocumentsAvailable,
            ..._.mapKeys(action.payload, 'id'),
          },
          isLoadingMore: action.isLoadingMore,
        }
      } else {
        return state
      }
    case SET_SR_DOCUMENT:
      const srDocumentData = Array.isArray(action.payload) && action.payload[0]
      if (srDocumentData) {
        return {
          ...state,
          srDocumentsAvailable: {
            ...state.srDocumentsAvailable,
            [srDocumentData.id]: srDocumentData,
          },
          lastAction: 'fetch',
        }
      } else {
        return state
      }
    case UNSET_SR_DOCUMENTS:
      return INITIAL_STATE
    case CREATE_SR_DOCUMENT:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        srDocumentsAvailable: {
          ...state.srDocumentsAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SR_DOCUMENT:
      const updatedItem = (state.srDocumentsAvailable[
        action.currentSrDocument.id
      ] = action.currentSrDocument)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT:
      return {
        ...state,
        srDocumentErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT:
      return {
        ...state,
        srDocumentErrors: {},
      }
    case UNSET_SR_DOCUMENT_ACTION:
      return { ...state, lastAction: null }
    case DELETE_SR_DOCUMENT:
      let updatedSrDocuments = state.srDocumentsAvailable
      _.unset(updatedSrDocuments, `${action.currentSrDocument.id}`)
      return {
        ...state,
        lastAction: 'delete',
        srDocumentsAvailable: updatedSrDocuments,
      }
    default:
      return state
  }
}

export default srDocumentReducer
