import ContractDocumentForm from '../forms/ContractDocumentForm'
import {
  createContractDocument,
  clearContractDocumentErrors,
  clearContractDocumentAction,
} from 'actions/contracts/contractDocumentActions'
import { fetchContractDocumentTypes } from 'actions/contracts/contractDocumentTypeActions'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ContractDocumentCreate = ({
  formId,
  contractId,
  params,
  setParams,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, documentErrors } = useSelector(
    (state) => state.contractDocuments
  )
  const documentTypeOptions = useSelector(
    (state) => state.contractDocumentTypes.documentTypeOptions
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractDocumentTypes('form'))
    return () => {
      dispatch(clearContractDocumentErrors())
      dispatch(clearContractDocumentAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        contract_id: contractId,
        contract_document_type_id: '',
        comment: '',
        import_file: null,
      })
    }
  }, [contractId, params, setParams])

  // watch for success
  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        const documentParams = new FormData()
        documentParams.append('contract_id', params.contract_id)
        documentParams.append(
          'contract_document_type_id',
          params.contract_document_type_id
        )
        documentParams.append('import_file', params.import_file)
        if (params.comment) documentParams.append('comment', params.comment)

        dispatch(createContractDocument(documentParams))
      }}
      className="form container"
      noValidate
    >
      <ContractDocumentForm
        params={params}
        setParams={setParams}
        documentTypeOptions={documentTypeOptions}
        errors={documentErrors}
      />
    </form>
  )
}

export default ContractDocumentCreate
