import {
  SET_CONTRACT_PROPERTIES,
  SET_CONTRACT_PROPERTY_OPTIONS,
  UNSET_CONTRACT_PROPERTY_OPTIONS,
  CREATE_CONTRACT_PROPERTY,
  DELETE_CONTRACT_PROPERTY,
  UNSET_CONTRACT_PROPERTY_ACTION,
  UPDATE_CONTRACT_PROPERTY,
  ERROR_CONTRACT_PROPERTY,
  UNSET_ERROR_CONTRACT_PROPERTY,
  LOADING_CONTRACT_PROPERTIES,
  TOGGLE_CONTRACT_PROPERTY_GUEST_VISIBILITY,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  contractPropertiesAvailable: {},
  contractPropertyOptions: [],
  contractPropertyErrors: {},
  loading: false,
}

const contractPropertyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_PROPERTIES:
      return {
        ...state,
        lastAction: 'fetch',
        loading: false,
        contractPropertiesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case LOADING_CONTRACT_PROPERTIES:
      return {
        ...state,
        loading: true,
        contractPropertyErrors: {},
        lastAction: null,
      }
    case SET_CONTRACT_PROPERTY_OPTIONS:
      return {
        ...state,
        lastAction: 'fetchOptions',
        loading: false,
        contractPropertyOptions: action.payload,
      }
    case UNSET_CONTRACT_PROPERTY_OPTIONS:
      if (state.contractPropertyOptions) {
        return { ...state, contractPropertyOptions: [] }
      }
      return state
    case UNSET_CONTRACT_PROPERTY_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_PROPERTY:
      const createdItems = _.mapKeys(action.payload.created_items, 'id')
      const newProperties = _.merge(
        state.contractPropertiesAvailable,
        createdItems
      )
      const newState = {
        ...state,
        lastAction: 'create',
        contractPropertiesAvailable: newProperties,
      }
      return newState
    case UPDATE_CONTRACT_PROPERTY:
      const updatedItem = (state.contractPropertiesAvailable[
        action.currentContractProperty.id
      ] = action.currentContractProperty)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_PROPERTY:
      return {
        ...state,
        contractPropertyErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_PROPERTY:
      return {
        ...state,
        contractPropertyErrors: {},
      }
    case TOGGLE_CONTRACT_PROPERTY_GUEST_VISIBILITY:
      const toggledItem = (state.contractPropertiesAvailable[
        action.currentContractProperty.id
      ] = action.currentContractProperty)
      toggledItem.is_visible_to_guests = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_CONTRACT_PROPERTY:
      const updated = state.contractPropertiesAvailable
      _.unset(updated, `${action.currentContractProperty.id}`)
      return {
        ...state,
        lastAction: 'delete',
        contractPropertiesAvailable: updated,
      }
    default:
      return state
  }
}

export default contractPropertyReducer
