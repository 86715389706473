import React from "react";
import { Link } from "react-router-dom";
import { MDBBreadcrumbItem } from "mdbreact";

const BreadcrumbStudyItems = ({ match }) => {
  const { path } = match;
  const renderStudyListItem = () => {
    return (
      <MDBBreadcrumbItem>
        <Link to="/studies">Studies</Link>
      </MDBBreadcrumbItem>
    );
  };
  const renderStudyCreateItem = () => {
    if (path === "/studies/new") {
      return <MDBBreadcrumbItem active>Create</MDBBreadcrumbItem>;
    }
    return "";
  };
  const renderStudyShowItem = () => {
    if (path === "/studies/:id") {
      return <MDBBreadcrumbItem active>#{match.params.id}</MDBBreadcrumbItem>;
    } else if (path === "/studies/:id/edit") {
      return (
        <MDBBreadcrumbItem>
          <Link to={`/studies/${match.params.id}`}>#{match.params.id}</Link>
        </MDBBreadcrumbItem>
      );
    }
    return "";
  };
  const renderStudyEditItem = () => {
    if (path === "/studies/:id/edit") {
      return <MDBBreadcrumbItem active>Edit</MDBBreadcrumbItem>;
    }
    return "";
  };

  return (
    <>
      {renderStudyListItem()}
      {renderStudyCreateItem()}
      {renderStudyShowItem()}
      {renderStudyEditItem()}
    </>
  );
};

export default BreadcrumbStudyItems;
