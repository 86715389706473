import SrDocumentCreate from 'components/srDocuments/SrDocumentCreate'
import SrDocumentList from 'components/srDocuments/SrDocumentList'
import SrDocumentShow from 'components/srDocuments/SrDocumentShow'
import SrDocumentUpdate from 'components/srDocuments/SrDocumentUpdate'
import SrDocumentDocTypeHistory from 'components/srDocuments/docs/SrDocumentDocTypeHistory'
import { useAuth } from 'hooks/useAuth.js'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { isUserGuest, isUserNotReadOnlyForSiteReadiness } from 'utils/authUtils'

const SrDocumentRoutes = () => {
  //// HOOKS.
  const { user } = useAuth() ?? {}
  const { user_level } = user ?? {}
  const hasSiteReadiness = user?.customer?.has_site_readiness

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    !!hasSiteReadiness && (
      <Switch>
        <Route exact path="/sr-documents" component={SrDocumentList} />
        {isUserNotReadOnlyForSiteReadiness(user_level) && (
          <Route path="/sr-documents/new" component={SrDocumentCreate} />
        )}
        <Route exact path="/sr-documents/:id" component={SrDocumentShow} />
        {isUserNotReadOnlyForSiteReadiness(user_level) && (
          <Route path="/sr-documents/:id/edit" component={SrDocumentUpdate} />
        )}
        {!isUserGuest(user_level) && (
          <Route
            exact
            path="/sr-documents/:srDocumentId/doc-types/:docTypeId"
            component={SrDocumentDocTypeHistory}
          />
        )}
      </Switch>
    )
  )
}

export default SrDocumentRoutes
