import {
  deleteContractStatus,
  clearContractStatusAction,
} from 'actions/contracts/contractStatusActions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const ContractStatusDelete = ({ formId, closeModal, selected }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearContractStatusAction())
    }
  }, [dispatch])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(deleteContractStatus(selected))
        closeModal()
      }}
      className="form container"
    >
      <div>
        Are you sure you want to delete this contract status? This can not be
        undone.
      </div>
    </form>
  )
}

export default ContractStatusDelete
