import React from "react";
import { Route, Switch } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import ContactList from "components/contacts/ContactList";
import ContactCreate from "components/contacts/ContactCreate";
import ContactShow from "components/contacts/ContactShow";
import ContactUpdate from "components/contacts/ContactUpdate";

import { isUserNotReadOnly } from "utils/authUtils";

const ContactRoutes = () => {
  const auth = useAuth();
  return (
    <Switch>
      <Route exact path="/contacts" component={ContactList} />
      {isUserNotReadOnly(auth?.user?.user_level) ? (
        <Route path="/contacts/new" component={ContactCreate} />
      ) : (
        ""
      )}
      <Route exact path="/contacts/:id" component={ContactShow} />
      {isUserNotReadOnly(auth?.user?.user_level) ? (
        <Route path="/contacts/:id/edit" component={ContactUpdate} />
      ) : (
        ""
      )}
    </Switch>
  );
};

export default ContactRoutes;
