import BadgeLabel from 'components/common/badges/BadgeLabel'
import Select from 'components/common/forms/Select'
import Panel from 'components/common/panel/Panel'
import { MDBRow, MDBCol, MDBSwitch } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { selectArray, selectObject } from 'utils/formUtils'

const CustomPropertyForm = ({
  create,
  contract,
  customProperties,
  params,
  setParams,
  contractTypes,
  contractTypeDefaultOption,
  errors,
}) => {
  //// HOOKS.

  //// GLOBAL STATE.

  //// LOCAL STATE.
  const [propertyOptions, setPropertyOptions] = useState([])
  const [contractTypeOptions, setContractTypeOptions] = useState(
    create && contract ? [] : undefined
  )

  //// LIFECYCLE HELPERS.

  //// MEMOS.
  const buildOptions = useCallback(setPropertyOptions, [])
  const buildContractTypeOptions = useCallback(setContractTypeOptions, [])

  //// EFFECTS.
  useEffect(() => {
    if (create && !propertyOptions?.length) {
      const options = selectObject(
        customProperties,
        params.property_id ? params.property_id : 0
      )
      buildOptions(options)
    }
  }, [
    create,
    customProperties,
    propertyOptions,
    buildOptions,
    params.property_id,
  ])

  useEffect(() => {
    if (create && contract && contractTypeOptions?.length === 0) {
      const options = contractTypeDefaultOption.length
        ? selectArray(
            contractTypes,
            params.contract_type_id ? params.contract_type_id : 0,
            true,
            contractTypeDefaultOption
          )
        : selectArray(
            contractTypes,
            params.contract_type_id ? params.contract_type_id : 0
          )
      buildContractTypeOptions(options)
    }
  }, [
    create,
    contract,
    contractTypes,
    contractTypeOptions,
    buildContractTypeOptions,
    params.contract_type_id,
    contractTypeDefaultOption,
  ])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <>
      <MDBRow>
        {!propertyOptions?.length ? (
          ''
        ) : (
          <MDBCol md="12">
            <Select
              id="select__property"
              options={propertyOptions}
              getTextContent={(v) => {
                if (v !== 'Choose A Custom Property') {
                  const propertySelection = propertyOptions.filter(
                    (property) => property.value === v
                  )
                  setParams({
                    ...params,
                    property_id: propertySelection[0].id,
                  })
                }
              }}
              label="Property"
              defaultOption="Choose A Custom Property"
              required
            />
          </MDBCol>
        )}
        <MDBCol md="5">
          <BadgeLabel spacingClass="mb-3">Is this field required?</BadgeLabel>
          <MDBSwitch
            className="availability-switch"
            labelLeft="False"
            labelRight="True"
            checked={params.required}
            getValue={(toggle) => setParams({ ...params, required: toggle })}
          />
        </MDBCol>
        {!contractTypes?.length ? (
          ''
        ) : (
          <MDBCol md="7">
            <BadgeLabel spacingClass="mb-3">
              Limit this to specific contract types?
            </BadgeLabel>
            <Select
              id="select__type"
              options={contractTypeOptions}
              getTextContent={(v) => {
                if (v !== 'Choose A Contract Type') {
                  const splitValue = v.split(', ')
                  let typeSelections = []
                  splitValue.forEach((value) => {
                    const contractTypeSelection = contractTypeOptions.filter(
                      (type) => type.value === value
                    )
                    if (contractTypeSelection?.[0]?.hasOwnProperty('id')) {
                      typeSelections.push(contractTypeSelection[0].id)
                    }
                  })
                  setParams({
                    ...params,
                    contract_type_id: typeSelections,
                  })
                }
              }}
              label="Contract Type"
              defaultOption="Choose Contract Types"
              multiple
            />
          </MDBCol>
        )}
      </MDBRow>
      <Panel messages={errors.error} backgroundColor="red" textColor="white" />
    </>
  )
}

export default CustomPropertyForm
