import history from '../../history'
import {
  APPEND_SR_DOCUMENTS,
  CREATE_SR_DOCUMENT,
  DELETE_SR_DOCUMENT,
  ERROR_SR_DOCUMENT,
  SET_SR_DOCUMENT,
  SET_SR_DOCUMENTS,
  UNSET_SR_DOCUMENTS,
  UNSET_SR_DOCUMENT_ACTION,
  UNSET_ERROR_SR_DOCUMENT,
  UPDATE_SR_DOCUMENT,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentEndPoint = '/sr-documents/sr-document'

// Passing data back to reducers
const setSrDocument = (obj, type, srdoc, options) => {
  const action = { type: type, payload: obj }
  if (srdoc) action.currentSrDocument = srdoc
  // Shimin: add fetch time for loading srdocs to only keep last fetch data
  if (type === SET_SR_DOCUMENTS || type === APPEND_SR_DOCUMENTS) {
    action.fetchTime = options?.fetchTime || 0
    action.isLoadingMore =
      // check to see if we got less than the page size we asked for
      (options?.pageSize && obj?.length && options.pageSize === obj.length) ||
      // check to see if we got less than the initial page size we asked for
      (options?.pageNumber === 0 &&
        options?.initialPageSize &&
        options?.pageSize &&
        obj?.length &&
        options.initialPageSize === obj.length) ||
      // check to see if we got less than the page size we asked for with an offset for the initial page size
      (options?.pageNumber === 1 &&
        options?.initialPageSize &&
        options?.pageSize &&
        obj?.length &&
        options.pageSize - options.initialPageSize === obj.length)
  }
  return action
}

// Non-API calls
export const useClearSrDocuments = (dispatch) => async () => {
  dispatch(setSrDocument({}, UNSET_SR_DOCUMENTS))
}
export const clearSrDocumentErrors = () => async (dispatch) => {
  dispatch(setSrDocument({}, UNSET_ERROR_SR_DOCUMENT))
}
export const clearSrDocumentAction = () => async (dispatch) => {
  dispatch(setSrDocument({}, UNSET_SR_DOCUMENT_ACTION))
}

// API calls
// pass an array of query parameters for filtering
window.currentSrDocumentFetchTime = 0
window.currentSrDocumentFetchParameters = null
export const fetchSrDocuments = (queryParameters) => async (dispatch) => {
  if (
    fetchToken() &&
    window.currentSrDocumentFetchParameters !== queryParameters
  ) {
    const fetchPath = window.location.pathname
    const fetchTime = Date.now()
    window.currentSrDocumentFetchTime = fetchTime
    window.currentSrDocumentFetchParameters = queryParameters
    const initialPageSize = 50
    const pageSize = 250
    const pageNumber = 0
    const endPoint = `${srDocumentEndPoint}?${[
      `initial_page_size=${initialPageSize}`,
      `page_size=${pageSize}`,
      `page_number=${pageNumber}`,
      queryParameters,
    ].join('&')}`

    const res = await restApi.get(endPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    const action = setSrDocument(res.data, SET_SR_DOCUMENTS, false, {
      fetchTime,
      initialPageSize,
      pageSize,
      pageNumber,
    })
    dispatch(action)

    if (action.isLoadingMore) {
      fetchAdditionalSrDocuments(
        fetchTime,
        fetchPath,
        queryParameters,
        initialPageSize,
        pageSize,
        pageNumber + 1
      )(dispatch)
    }

    window.currentSrDocumentFetchParameters = null
  }
}

const fetchAdditionalSrDocuments =
  (
    fetchTime,
    fetchPath,
    queryParameters,
    initialPageSize,
    pageSize,
    pageNumber
  ) =>
  async (dispatch) => {
    if (
      fetchToken() &&
      window.location.pathname === fetchPath &&
      window.currentSrDocumentFetchTime === fetchTime
    ) {
      const endPoint = `${srDocumentEndPoint}?${[
        `initial_page_size=${initialPageSize}`,
        `page_size=${pageSize}`,
        `page_number=${pageNumber}`,
        queryParameters,
      ].join('&')}`
      const res = await restApi.get(endPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })

      if (
        window.location.pathname === fetchPath &&
        window.currentSrDocumentFetchTime === fetchTime
      ) {
        const action = setSrDocument(res.data, APPEND_SR_DOCUMENTS, false, {
          fetchTime,
          initialPageSize,
          pageSize,
          pageNumber,
        })
        dispatch(action)

        if (action.isLoadingMore) {
          fetchAdditionalSrDocuments(
            fetchTime,
            fetchPath,
            queryParameters,
            initialPageSize,
            pageSize,
            pageNumber + 1
          )(dispatch)
        }
      }
    }
  }

export const fetchSrDocument = (id) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${srDocumentEndPoint}/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocument(res.data, SET_SR_DOCUMENT, res.data))
  }
}

export const createSrDocument = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocument(res.data, CREATE_SR_DOCUMENT))
      const url = `/sr-documents${
        res?.data?.created_items?.[0]?.id
          ? `/${res.data.created_items[0].id}`
          : ''
      }`
      history.push(url)
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocument(error, ERROR_SR_DOCUMENT))
    }
  }
}

export const deleteSrDocument = (srdoc) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentEndPoint, {
      data: { id: srdoc.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocument(res.data, DELETE_SR_DOCUMENT, srdoc))
  }
}

export const updateSrDocument = (params, srdoc) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(srDocumentEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocument(res.data, UPDATE_SR_DOCUMENT, srdoc))
      history.push(`/sr-documents/${srdoc.id}`)
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setSrDocument(error, ERROR_SR_DOCUMENT))
    }
  }
}
