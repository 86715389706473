import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";
import {
  updateContact,
  clearContactErrors
} from "actions/contacts/contactActions";
import { fetchCountries, clearCountries } from "actions/countryActions";
import { fetchSites, clearSiteOptions } from "actions/sites/siteActions";

import Section from "components/common/Section";
import Modal from "components/common/Modal";
import ContactForm from "./ContactForm";
import ContactDelete from "./ContactDelete";

import { findSelected, selectValueSplit } from "utils/formUtils";

const ContactUpdate = ({ match }) => {
  const contactId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();
  const { contactsAvailable, contactErrors } = useSelector(
    state => state.contacts
  );
  const { countryOptions } = useSelector(state => state.countries);
  const { siteOptions, lastAction: lastSiteAction } = useSelector(
    state => state.sites
  );
  const [currentContact, setCurrentContact] = useState(
    contactsAvailable[contactId]
  );
  const [customProperties, setCustomProperties] = useState({});
  const auth = useAuth();
  const dispatch = useDispatch();

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Contact");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  useEffect(() => {
    if (Object.keys(contactsAvailable).length) {
      setCurrentContact(contactsAvailable[contactId]);
      setCustomProperties(contactsAvailable[contactId].custom_properties);
    }
  }, [contactsAvailable, contactId, setCurrentContact]);

  useEffect(() => {
    if (_.size(currentContact)) {
      dispatch(fetchCountries(currentContact.country));
      dispatch(fetchSites("form", currentContact.org));
    }
    return () => {
      dispatch(clearCountries());
      dispatch(clearSiteOptions());
      dispatch(clearContactErrors());
    };
  }, [dispatch, currentContact]);

  const handleSubmission = (e, params, countries, sites) => {
    e.preventDefault();
    const countrySelected = findSelected(countries);
    const siteSelected = findSelected(sites);

    const updatedParams = {};
    Object.keys(params).forEach(paramKey => {
      // find where there was a change
      // and if the field previously filled in and now removed
      if (
        currentContact[paramKey] !== params[paramKey] &&
        (params[paramKey] !== "" || currentContact[paramKey])
      ) {
        updatedParams[paramKey] = params[paramKey];
      }
    });

    updatedParams.id = currentContact.id;

    const updatedContact = _.mergeWith(currentContact, updatedParams);

    if (countrySelected.length > 0) {
      updatedParams.country_id = countrySelected[0].id;
      if (countrySelected[0].id !== currentContact.country.id) {
        const splitCountryValue = selectValueSplit(countrySelected[0].value);
        updatedContact.country = {
          id: countrySelected[0].id,
          name: splitCountryValue[0],
          code: splitCountryValue[1]
        };
      }
    }

    if (siteSelected.length > 0) {
      updatedParams.org_id = siteSelected[0].id;
      if (siteSelected[0].id !== currentContact.org.id) {
        updatedContact.org = {
          id: siteSelected[0].id,
          name: siteSelected[0].value
        };
      }
    }

    Object.assign(updatedContact.custom_properties, customProperties);

    dispatch(updateContact(updatedParams, updatedContact));
  };

  if (currentContact) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: [
        {
          name: "View",
          url: `/contacts/${currentContact.id}`,
          exact: true
        },
        {
          name: "Edit",
          url: `/contacts/${currentContact.id}/edit`,
          exact: true
        }
      ]
    };

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: "Delete",
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: e => toggleModal(e, "delete", currentContact),
          className: "text-danger"
        }
      });
    }

    return (
      <Section
        header={`Contact #${currentContact.id}`}
        description="Change inputs to edit contact"
        breadcrumbs
        menu={sectionHeaderMenu}
        footer={[
          {
            text: "Update",
            color: "primary",
            additionalProps: {
              form: "form_contact",
              type: "submit"
            }
          }
        ]}
      >
        <ContactForm
          submit={handleSubmission}
          contact={currentContact}
          countries={countryOptions}
          sites={siteOptions}
          lastSiteAction={lastSiteAction}
          errors={contactErrors}
          customProperties={customProperties}
          setCustomProperties={setCustomProperties}
        />
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <ContactDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      </Section>
    );
  }

  return "";
};

export default ContactUpdate;
