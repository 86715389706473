/** @jsx jsx */
import { Link } from "react-router-dom";
import { MDBIcon, MDBBtn, MDBTooltip } from "mdbreact";
import { css, jsx } from "@emotion/core";
import { darken } from "polished";
import { useTheme } from "emotion-theming";

const ButtonIcon = ({ id, label, to, icon, onClick, color, card }) => {
  const theme = useTheme();
  const btnColor = theme?.colors[color] ?? theme?.colors?.primary ?? "#f88b5e";

  const btnIconStyle = css`
    &.btn-icon.btn-sm {
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 0 !important;
      margin-left: 0.75rem;
      font-size: 0.75rem;
      display: inline-block;
      color: ${btnColor};

      &:hover {
        color: ${darken(0.2, btnColor)};
      }
    }
  `;

  const renderBtn = () => {
    if (to) {
      return (
        <Link
          to={to}
          className={`btn Ripple-parent btn-flat btn-sm btn-icon${
            card ? " mb-0 mt-0" : ""
          }`}
          css={btnIconStyle}
        >
          <MDBIcon icon={icon} />
        </Link>
      );
    }

    return (
      <MDBBtn
        onClick={onClick}
        flat
        className={`btn-icon${card ? " mb-0 mt-0" : ""}`}
        css={btnIconStyle}
        size="sm"
      >
        <MDBIcon icon={icon} />
      </MDBBtn>
    );
  };

  if (label) {
    return (
      <MDBTooltip id={`tooltip-${id}`} placement="top" material>
        {renderBtn()}
        <div>{label}</div>
      </MDBTooltip>
    );
  }

  return renderBtn();
};

export default ButtonIcon;
