import React from "react";
import { Route, Switch } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import StudyList from "components/studies/StudyList";
import StudyCreate from "components/studies/StudyCreate";
import StudyShow from "components/studies/StudyShow";
import StudyUpdate from "components/studies/StudyUpdate";

import { isUserNotReadOnly } from "utils/authUtils";

const StudyRoutes = () => {
  const auth = useAuth();
  return (
    <Switch>
      <Route exact path="/studies" component={StudyList} />
      {isUserNotReadOnly(auth?.user?.user_level) ? (
        <Route path="/studies/new" component={StudyCreate} />
      ) : (
        ""
      )}
      <Route exact path="/studies/:id" component={StudyShow} />
      {isUserNotReadOnly(auth?.user?.user_level) ? (
        <Route path="/studies/:id/edit" component={StudyUpdate} />
      ) : (
        ""
      )}
    </Switch>
  );
};

export default StudyRoutes;
