import {
  createContractProperty,
  clearContractPropertyErrors,
  clearContractPropertyAction,
  fetchContractPropertyOptions,
} from 'actions/contracts/contractPropertyActions'
import { fetchCustomProperties } from 'actions/properties/customPropertyActions'
import CustomPropertyForm from 'components/common/properties/CustomPropertyForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ContractPropertyCreate = ({ formId, params, setParams, closeModal }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, contractPropertyErrors, contractPropertyOptions } =
    useSelector((state) => state.contractProperties)
  const customProperties = useSelector(
    (state) => state.customProperties.propertiesAvailable
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (_.isEmpty(customProperties)) {
      dispatch(fetchCustomProperties())
    }
    if (_.isEmpty(contractPropertyOptions?.contract_type_id)) {
      dispatch(fetchContractPropertyOptions())
    }
  }, [dispatch, customProperties, contractPropertyOptions])

  useEffect(() => {
    return () => {
      dispatch(clearContractPropertyErrors())
      dispatch(clearContractPropertyAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        property_id: null,
        required: false,
        contract_type_id: 0,
      })
    }
  }, [params, setParams])

  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(createContractProperty(params))
      }}
      className="form container"
      noValidate
    >
      {_.size(customProperties) && _.size(contractPropertyOptions) ? (
        <CustomPropertyForm
          create={true}
          contract={true}
          customProperties={customProperties}
          params={params}
          setParams={setParams}
          contractTypes={contractPropertyOptions.contract_type_id}
          contractTypeDefaultOption="All Contact Types"
          errors={contractPropertyErrors}
        />
      ) : (
        ''
      )}
    </form>
  )
}

export default ContractPropertyCreate
