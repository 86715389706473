import {
  clearSrDocumentActivityByLocation,
  clearSrDocumentActivityChartAction,
  displaySrDocumentActivityByLocation,
} from 'actions/charts/srDocumentActivityChartActions'
import React, { useEffect, useState } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'

const SrDocumentActivityChart = ({ srDocumentId }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { charts, lastAction } = useSelector(
    (state) => state.srDocumentActivityCharts
  )
  const lastActivityAction = useSelector(
    (state) => state.srDocumentActivities.lastAction
  )

  //// LOCAL STATE.
  const [byLocation, setByLocation] = useState({})

  //// LIFECYCLE HELPERS.
  const stringByLocation = charts.byLocation
    ? JSON.stringify(charts.byLocation.data)
    : null

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (
      srDocumentId > 0 &&
      (!stringByLocation || lastActivityAction !== null)
    ) {
      dispatch(displaySrDocumentActivityByLocation(srDocumentId))
    }
  }, [dispatch, srDocumentId, stringByLocation, lastActivityAction])

  useEffect(() => {
    return () => {
      dispatch(clearSrDocumentActivityByLocation())
    }
  }, [dispatch])

  useEffect(() => {
    if (
      lastAction === 'locationChart' &&
      charts.byLocation &&
      charts.byLocation.data.length
    ) {
      setByLocation({
        labels: charts.byLocation.labels,
        datasets: [
          {
            label: 'Site Readiness Document Activity By Location',
            data: charts.byLocation.data,
            fill: false,
            backgroundColor: 'rgba(248, 139, 94, 0.5)',
            borderColor: 'rgba(248, 139, 94, 0.75)',
            borderWidth: 1,
          },
        ],
      })
      dispatch(clearSrDocumentActivityChartAction())
    }
  }, [lastAction, charts, setByLocation, dispatch])

  //// RENDER VARS & HELPERS.

  //// RENDER.

  return !byLocation || !byLocation.datasets ? (
    ''
  ) : (
    <HorizontalBar
      data={byLocation}
      options={{
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Days',
                fontColor: '#2d3848',
                fontSize: 12,
                fontStyle: 'bold',
              },
              ticks: {
                min: 0,
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          onClick: (e) => e.preventDefault(),
          align: 'end',
          labels: {
            fontColor: '#ff000',
            fontStyle: 'bold',
            fontSize: 12,
          },
        },
      }}
    />
  )
}

export default SrDocumentActivityChart
