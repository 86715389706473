import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { deleteUser, clearUserAction } from "actions/users/userActions";

const UserDelete = ({ formId, closeModal, selected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearUserAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteUser(selected));
    closeModal();
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this user? This can not be undone.
      </div>
    </form>
  );
};

export default UserDelete;
