import ContractDelete from './ContractDelete'
import ContractTable, {
  contractsColumnsAtom,
  useResetContracts,
} from './ContractTable'
import ContractView from './ContractView'
import { fetchContractColumns } from 'actions/contracts/contractColumnActions'
import { fetchContractPropertyOptions } from 'actions/contracts/contractPropertyActions'
import { fetchContractViews } from 'actions/contracts/contractViewActions'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import { useSetAtom } from 'jotai'
import { MDBIcon } from 'mdbreact'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  isUserGuest,
  isUserNotReadOnly,
  isUserNotReadOnlyForContracts,
} from 'utils/authUtils'
import { defaultContractColumns } from 'utils/tableUtils'

const fetchTableLink = (type, item) => {
  switch (type) {
    case 'show':
      return `/contracts/${item.id}`
    case 'edit':
      return `/contracts/${item.id}/edit`
    default:
      return null
  }
}

const ContractList = () => {
  //// HOOKS.
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}
  const dispatch = useDispatch()
  const resetContracts = useResetContracts(dispatch)
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const [setContractsColumns] = [useSetAtom(contractsColumnsAtom)]

  const {
    contractProperties: { contractPropertyOptions },
    contractColumns: { contractColumns: contractColumnsFromRedux },
    contracts: {
      contractsAvailable,
      lastAction,
      isLoadingMore: isLoadingMoreContracts,
    },
  } = useSelector(({ contractProperties, contractColumns, contracts }) => ({
    contractProperties,
    contractColumns,
    contracts,
  }))

  //// LOCAL STATE.
  const [isInit, setIsInit] = useState(true)

  //// EFFECTS.
  useEffect(
    () => {
      setIsInit(true)
      resetContracts({
        contractsTableData: {
          columns: [
            ...defaultContractColumns,
            {
              label: '',
              field: 'buttons',
              sort: 'disabled',
              minimal: 'lg',
            },
          ],
          rows: [],
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    setContractsColumns(contractColumnsFromRedux)
  }, [contractColumnsFromRedux, setContractsColumns])

  useEffect(
    () => {
      // load init data
      if (user) {
        Promise.all(
          [
            fetchContractPropertyOptions(),
            fetchContractViews(),
            fetchContractColumns(user),
          ].map((action) => dispatch(action))
        ).finally(() => {
          setIsInit(false)
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )

  //// FUNCTIONS.
  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Contract')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
      return true
    } else {
      setModalState(false)
      return false
    }
  }

  //// RENDER.
  return (
    <Section
      title={'Contract List'}
      menu={
        isUserNotReadOnly(user_level) || isUserGuest(user_level)
          ? {
              order: 2,
              show: true,
              linkItems: !isUserNotReadOnlyForContracts(user_level)
                ? []
                : [
                    {
                      name: (
                        <>
                          <MDBIcon icon="plus" /> Add Contract
                        </>
                      ),
                      url: '/contracts/new',
                      additionalProps: {
                        active: true,
                      },
                    },
                  ],
              leftSide: <ContractView editable />,
            }
          : void 0
      }
      loading={isInit}
    >
      {!isInit && (
        <ContractTable
          contractsAvailable={contractsAvailable}
          isLoadingMore={isLoadingMoreContracts}
          lastAction={lastAction}
          contractPropertyOptions={contractPropertyOptions}
          fetchTableLink={fetchTableLink}
          toggleModal={toggleModal}
          willSaveColumns
        />
      )}
      {is_admin ? (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: 'Delete',
              color: 'danger',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ]}
        >
          <ContractDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      ) : (
        void 0
      )}
    </Section>
  )
}

export default ContractList
