import restApi from 'apis/restApi'

export const fetchToken = () => {
  return localStorage.getItem('token')
}

export const fetchOTPReq = () => {
  return localStorage.getItem('is_OTP_required')
}

export const setOTPReq = (req) => {
  return localStorage.setItem('is_OTP_required', req)
}

export const setMFAQR = (QR) => {
  return localStorage.setItem('mfa', QR)
}

export const getMFAQR = () => {
  return localStorage.getItem('mfa')
}

export const clearMFAQR = () => {
  return localStorage.removeItem('mfa')
}
const passwordResetEndPoint = '/user/password'

export const updateUserPassword = async (params, auth) => {
  const res = await restApi.post(passwordResetEndPoint, params)
  auth.setUserToken(res?.data?.token)
  return res
}

export const isUserNotReadOnly = (role) =>
  role === 'admin' || role === 'normal' || role === 'site_readiness'

export const isUserNotReadOnlyForContracts = (role) =>
  role === 'admin' || role === 'normal'

export const isUserNotReadOnlyForSiteReadiness = (role) =>
  role === 'admin' || role === 'site_readiness'

export const isUserGuest = (role) => role === 'guest'

export const isUserAdmin = (role) => role === 'admin'

export const isUserNormal = (role) => role === 'normal'

export const isUserSiteReadiness = (role) => role === 'site_readiness'
