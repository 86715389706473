import SrDocumentActivityForm from '../forms/SrDocumentActivityForm'
import {
  clearSrDocumentActivityAction,
  clearSrDocumentActivityErrors,
  createSrDocumentActivity,
} from 'actions/srDocuments/srDocumentActivityActions'
import { fetchSrDocumentLocations } from 'actions/srDocuments/srDocumentLocationActions'
import { fetchSrDocumentStatuses } from 'actions/srDocuments/srDocumentStatusActions'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const SrDocumentActivityCreate = ({
  formId,
  srDocumentId,
  params,
  setParams,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, activityErrors } = useSelector(
    (state) => state.srDocumentActivities
  )
  const statusOptions = useSelector(
    (state) => state.srDocumentStatuses.statusOptions
  )
  const locationOptions = useSelector(
    (state) => state.srDocumentLocations.locationOptions
  )

  //// we are not currently using srdoc documents
  // const documentTypeOptions = useSelector(
  //   (state) => state.srDocumentDocumentTypes.documentTypeOptions
  // )

  //// LOCAL STATE.
  const [occurDate, setOccurDate] = useState(moment())
  const [occurTime, setOccurTime] = useState(moment())

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchSrDocumentStatuses('form', null, true))
    dispatch(fetchSrDocumentLocations('form', null, true))
    // dispatch(fetchSrDocumentDocumentTypes('form')) ////we are not currently using srdoc documents
    return () => {
      dispatch(clearSrDocumentActivityErrors())
      dispatch(clearSrDocumentActivityAction())
      // dispatch(fetchSrDocumentDocuments({ sr_document_id: srDocumentId })) ////we are not currently using srdoc documents
    }
  }, [dispatch, srDocumentId])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        sr_document_id: srDocumentId,
        sr_document_status_id: '',
        sr_document_location_id: '',
        // sr_document_document_type_id: '', //// we are not currently using srdoc documents
        comment: '',
        // import_file: null, //// we are not currently using srdoc documents
        activity_occurred_at: moment().toISOString().split('.')[0],
      })
    }
  }, [srDocumentId, params, setParams])

  // watch for success
  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.

  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        const activityParams = new FormData()
        activityParams.append('sr_document_id', params.sr_document_id)
        activityParams.append(
          'sr_document_status_id',
          params.sr_document_status_id
        )
        activityParams.append(
          'sr_document_location_id',
          params.sr_document_location_id
        )
        activityParams.append(
          'activity_occurred_at',
          params.activity_occurred_at
        )
        activityParams.append('comment', params.comment)

        //// we are not currently using srdoc documents
        // if (params.import_file) {
        //   activityParams.append('import_file', params.import_file)
        //   activityParams.append(
        //     'sr_document_document_type_id',
        //     params.sr_document_document_type_id
        //   )
        // }

        dispatch(createSrDocumentActivity(activityParams))
      }}
      className="form container"
      noValidate
    >
      <SrDocumentActivityForm
        params={params}
        setParams={setParams}
        statusOptions={statusOptions}
        locationOptions={locationOptions}
        // documentTypeOptions={documentTypeOptions} //// we are not currently using srdoc documents
        occurDate={occurDate}
        setOccurDate={setOccurDate}
        occurTime={occurTime}
        setOccurTime={setOccurTime}
        errors={activityErrors}
      />
    </form>
  )
}

export default SrDocumentActivityCreate
