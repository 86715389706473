import restApi from 'apis/restApi'
import { saveAs } from 'file-saver'
import { fetchToken } from 'utils/authUtils'

export const downloadFile = (documentData, documentFilename) => {
  saveAs(documentData, documentFilename)
}

export const requestContractDocument = async (
  documentmentId,
  documentFilename
) => {
  if (fetchToken()) {
    const res = await restApi.get(
      `/documents/contract/download/${documentmentId}`,
      {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${fetchToken()}` },
      }
    )
    downloadFile(res.data, documentFilename)
  }
}

export const requestSrDocumentDoc = async (docId, docFilename) => {
  if (fetchToken()) {
    const res = await restApi.get(`/documents/sr-document/download/${docId}`, {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    downloadFile(res.data, docFilename)
  }
}

export const fetchDocumentAndDownload = async (docParams) => {
  if (fetchToken()) {
    const res = await restApi.get('/documents/contract', {
      params: docParams,
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })

    requestContractDocument(res.data[0].id, res.data[0].filename)
  }
}
