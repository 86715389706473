import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";

import { fetchSitePropertyOptions } from "actions/sites/sitePropertyActions";

import ErrorPanel from "components/common/panel/ErrorPanel";
import Select from "components/common/forms/Select";
import CustomPropertyManagement from "components/common/properties/CustomPropertyManagement";

import { inputErrorClass } from "utils/formUtils";

const SiteForm = ({
  submit,
  site,
  countries,
  errors,
  customProperties,
  setCustomProperties
}) => {
  const dispatch = useDispatch();

  const { sitePropertyOptions } = useSelector(state => state.siteProperties);

  const [siteParams, setSiteParams] = useState({
    name: site ? site.name : "",
    website: site && site.website ? site.website : "",
    email: site && site.email ? site.email : "",
    phone: site && site.phone ? site.phone : "",
    address_1: site && site.address_1 ? site.address_1 : "",
    address_2: site && site.address_2 ? site.address_2 : "",
    city: site && site.city ? site.city : "",
    state: site && site.state ? site.state : "",
    zip: site && site.zip ? site.zip : ""
  });

  useEffect(() => {
    dispatch(fetchSitePropertyOptions());
  }, [dispatch]);

  const handleInputChange = (value, key) => {
    setSiteParams({ ...siteParams, [key]: value });
  };

  return (
    <form
      id="form_site"
      className="form row"
      onSubmit={e => submit(e, siteParams, countries)}
      noValidate
    >
      <MDBCol md="6">
        <MDBInput
          value={siteParams.name}
          getValue={value => handleInputChange(value, "name")}
          id="site_name-input"
          label="Name"
          outline
          required
          autoFocus
          containerClass="required-label"
          className={`input-select${inputErrorClass(errors, !siteParams.name)}`}
        />
        <MDBInput
          value={siteParams.website}
          getValue={value => handleInputChange(value, "website")}
          id="site_last-name-input"
          label="Website"
          outline
        />
        <MDBInput
          value={siteParams.email}
          getValue={value => handleInputChange(value, "email")}
          id="site_email-input"
          label="Email"
          outline
        />
        <MDBInput
          value={siteParams.phone}
          getValue={value => handleInputChange(value, "phone")}
          id="site_phone-input"
          label="Phone"
          outline
        />
      </MDBCol>
      <MDBCol md="6">
        <MDBInput
          value={siteParams.address_1}
          getValue={value => handleInputChange(value, "address_1")}
          id="site_address-1-input"
          label="Street Address"
          outline
        />
        <MDBInput
          value={siteParams.address_2}
          getValue={value => handleInputChange(value, "address_2")}
          id="site_address-2-input"
          label="Apartment, suite, building, etc"
          outline
        />
        <MDBRow>
          <MDBCol md="5">
            <MDBInput
              value={siteParams.city}
              getValue={value => handleInputChange(value, "city")}
              id="site_city-input"
              label="City"
              outline
            />
          </MDBCol>
          <MDBCol md="7">
            <MDBInput
              value={siteParams.state}
              getValue={value => handleInputChange(value, "state")}
              id="site_state-input"
              label="State / Province / Region"
              outline
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="4">
            <MDBInput
              value={siteParams.zip}
              getValue={value => handleInputChange(value, "zip")}
              id="site_zip-input"
              label="Postal Code"
              outline
            />
          </MDBCol>
          <MDBCol md="8">
            <Select
              id="select__country"
              options={countries}
              label="Country"
              defaultOption="Choose A Country"
              search
              required
              errors={errors}
            />
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <CustomPropertyManagement
        params={siteParams}
        setParams={setSiteParams}
        current={site ? site : false}
        propertyOptions={sitePropertyOptions?.custom_properties}
        customProperties={customProperties || false}
        setCustomProperties={setCustomProperties || undefined}
      />
      <ErrorPanel results={errors} />
    </form>
  );
};

export default SiteForm;
