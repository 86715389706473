import { clearContractErrors } from 'actions/contracts/contractActions'
import {
  fetchContractPropertyOptions,
  fetchContractProperties,
} from 'actions/contracts/contractPropertyActions'
import { clearContractStates } from 'actions/contracts/contractStateActions'
import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import CustomPropertyManagement from 'components/common/properties/CustomPropertyManagement'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBInput } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showAvailable, showAvailableByType } from 'utils/commonUtils'
import { findSelected, selectArray } from 'utils/formUtils'

const ContractForm = ({
  contract,
  submit,
  errors,
  states,
  customProperties,
  setCustomProperties,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { contractPropertyOptions } = useSelector(
    (state) => state.contractProperties
  )

  //// LOCAL STATE.
  const [contractStandardOptions, setContractStandardOptions] = useState({})
  const [currentType, setCurrentType] = useState(
    contract?.contract_type ? contract.contract_type.id : 0
  )
  const [contractParams, setContractParams] = useState({
    study_id: contract?.study ? contract.study.id : null,
    contract_type_id: contract?.contract_type
      ? contract.contract_type.id
      : null,
    contract_subtype_id: contract?.contract_subtype
      ? contract.contract_subtype.id
      : null,
    is_budget: contract ? contract.is_budget : false,
    currency_id: contract?.currency ? contract.currency.id : null,
    current_state: contract ? contract.current_state : undefined,
    primary_investigator_id: contract?.primary_investigator
      ? contract.primary_investigator.id
      : null,
    primary_site_id: contract?.primary_site ? contract.primary_site.id : null,
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractPropertyOptions())
    return () => {
      dispatch(clearContractStates())
      dispatch(clearContractErrors())
      dispatch(fetchContractProperties())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(contractStandardOptions) && _.size(contractPropertyOptions)) {
      setContractStandardOptions({
        study: selectArray(
          showAvailable(contractPropertyOptions.study_id),
          contract?.study?.id || 0
        ),
        contractType: selectArray(
          showAvailable(contractPropertyOptions.contract_type_id),
          contract?.contract_type?.id || 0
        ),
        contractSubtype: selectArray(
          showAvailableByType(
            contractPropertyOptions.contract_subtype_id,
            contract?.contract_type?.id
          ),
          contract?.contract_subtype?.id,
          true
        ),
        currency: selectArray(
          showAvailable(contractPropertyOptions.currency_id),
          contract?.currency?.id || 0
        ),
        primaryInvestigator: selectArray(
          showAvailable(contractPropertyOptions.primary_investigator_id),
          contract?.primary_investigator?.id || 0
        ),
        primarySite: selectArray(
          showAvailable(contractPropertyOptions.primary_site_id),
          contract?.primary_site?.id || 0
        ),
      })
    }
  }, [
    contractPropertyOptions,
    contractStandardOptions,
    setContractStandardOptions,
    contractParams,
    contract,
  ])

  //// RENDER VARS & HELPERS.
  const selectedContractType = findSelected(
    contractStandardOptions.contractType
  )

  //// RENDER.

  const renderStateSelection = () => {
    if (contract && states.length) {
      return (
        <Select
          id="select__state"
          options={states}
          label="Contract State"
          defaultOption="Choose Contract State"
          required
          errors={errors}
        />
      )
    }
    return ''
  }

  return _.isEmpty(contractStandardOptions) ? (
    ''
  ) : (
    <form
      id="form_contract"
      className="form row"
      noValidate
      onSubmit={(e) =>
        submit(e, contractParams, contractStandardOptions, states)
      }
    >
      <MDBCol md="6">
        {renderStateSelection()}
        <Select
          id="select__studies"
          options={contractStandardOptions?.study}
          label="Sponsor Study Name"
          defaultOption="Choose A Sponsor Study Name"
          search
          required
          disabled={contract?.study?.id ? true : false}
          errors={errors}
        />
        <Select
          id="select__contract-types"
          options={contractStandardOptions?.contractType}
          label="Contract Type"
          defaultOption="Choose A Type"
          getTextContent={(value) => {
            if (value !== 'Choose A Type') {
              const selectedContractType = findSelected(
                contractStandardOptions.contractType
              )
              if (selectedContractType[0].id) {
                setCurrentType(selectedContractType[0].id)
                setContractStandardOptions({
                  ...contractStandardOptions,
                  contractSubtype: selectArray(
                    showAvailableByType(
                      contractPropertyOptions.contract_subtype_id,
                      selectedContractType[0].id
                    ),
                    0,
                    true
                  ),
                })
              }
            }
          }}
          search
          required
          errors={errors}
        />
        {contractStandardOptions.contractSubtype <= 0 ||
        (contract?.contract_subtype?.id &&
          contract.contract_subtype.id === 0) ? (
          <>
            <Select
              options={[]}
              label="Contract Subtype"
              defaultOption="Choose A Subtype"
              disabled
            />
            {!(selectedContractType[0] && selectedContractType[0].id) ? (
              ''
            ) : (
              <p className="input-warning text-muted">
                No subtypes associated with this type.
              </p>
            )}
          </>
        ) : (
          <Select
            id="select__contact-subtypes"
            options={contractStandardOptions.contractSubtype}
            label="Contract Subtype"
            defaultOption="Choose A Subtype"
            search
          />
        )}
      </MDBCol>
      <MDBCol md="6">
        <MDBRow className="align-items-center mb-4">
          <MDBCol md="4">
            <MDBInput
              label="Set Budget"
              type="checkbox"
              id="is-budget__checkbox"
              checked={contractParams.is_budget}
              value="Contract Budget"
              onChange={(event) => {
                const { checked } = event.target
                if (checked !== contractParams.is_budget) {
                  setContractParams({
                    ...contractParams,
                    is_budget: checked,
                  })
                }
              }}
            />
          </MDBCol>
          <MDBCol md="8" className="currency-selection">
            <Select
              id="select__currency"
              options={contractStandardOptions?.currency}
              label="Currency"
              defaultOption="Choose Currency"
              required
              disabled={contractParams.is_budget ? false : true}
              errors={errors}
              search
            />
          </MDBCol>
        </MDBRow>
        <Select
          id="select__contact"
          options={contractStandardOptions?.primaryInvestigator}
          label="Primary Investigator"
          defaultOption="Choose A Primary Investigator"
          search
        />
        <Select
          id="select__site"
          options={contractStandardOptions?.primarySite}
          label="Primary Site"
          defaultOption="Choose A Primary Site"
          search
        />
      </MDBCol>
      <CustomPropertyManagement
        params={contractParams}
        setParams={setContractParams}
        current={contract ? contract : false}
        propertyOptions={contractPropertyOptions?.custom_properties}
        contractTypeSelection={currentType}
        customProperties={customProperties || false}
        setCustomProperties={setCustomProperties || undefined}
      />
      <ErrorPanel results={errors} />
    </form>
  )
}

export default ContractForm
