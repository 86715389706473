import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { MDBIcon, MDBCardBody } from "mdbreact";

import useModal from "hooks/useModal";
import { useAuth } from "hooks/useAuth";
import { fetchContactProperties } from "actions/contacts/contactPropertyActions";
import { fetchCustomProperties } from "actions/properties/customPropertyActions";

import Table from "components/common/tables/Table";
import Modal from "components/common/Modal";
import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import EmptyList from "components/common/EmptyList";
import ContactPropertyCreate from "./ContactPropertyCreate";
import ContactPropertyUpdate from "./ContactPropertyUpdate";
import ContactPropertyDelete from "./ContactPropertyDelete";

const ContactPropertyList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState
  } = useModal();
  const dispatch = useDispatch();
  const auth = useAuth();

  // redux hooks
  const {
    propertiesAvailable: contactPropertiesAvailable,
    lastAction
  } = useSelector(state => state.contactProperties);

  const { propertiesAvailable } = useSelector(state => state.customProperties);

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "#",
        field: "id",
        sort: "desc"
      },
      {
        label: "Name",
        field: "property.name",
        sort: "desc",
        minimal: "lg"
      },
      {
        label: "Type",
        field: "property.property_type.name",
        sort: "desc"
      },
      {
        label: "Required",
        field: "required",
        sort: "desc"
      },
      {
        label: "",
        field: "buttons",
        sort: "disabled",
        minimal: "lg"
      }
    ]
  });

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault();

      if (type === "new") {
        setHeaderText("New Contact Property");
        setFooter(true);
        setActions([
          {
            text: "Submit",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
      } else if (type === "edit") {
        setHeaderText("Edit Contact Property");
        setFooter(true);
        setActions([
          {
            text: "Update",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
        setSelected(item);
      } else if (type === "delete") {
        setHeaderText("Delete Contact Property");
        setFooter(true);
        setActions([
          {
            text: "Delete",
            color: "danger",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
        setSelected(item);
      }
      if (type) {
        setModalState(true);
      } else {
        setModalState(false);
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  );

  // use effect setups
  useEffect(() => {
    dispatch(fetchContactProperties());
    dispatch(fetchCustomProperties());
  }, [dispatch]);

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: (
          <>
            <MDBIcon icon="fas fa-plus" /> Add Contact Property
          </>
        ),
        url: `/contacts/properties`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: e => toggleModal(e, "new"),
          className: "btn-sm"
        }
      }
    ]
  };

  const renderModalBody = () => {
    if (headerText === "New Contact Property") {
      return (
        <ContactPropertyCreate
          formId={formId}
          params={params}
          setParams={setParams}
          closeModal={() => setModalState(false)}
        />
      );
    } else if (headerText === "Edit Contact Property") {
      return (
        <ContactPropertyUpdate
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      );
    } else if (headerText === "Delete Contact Property") {
      return (
        <ContactPropertyDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      );
    }

    return "";
  };

  const renderTable = () => {
    if (_.size(contactPropertiesAvailable)) {
      return (
        <Table
          section="Property"
          tableData={tableData}
          setTableData={setTableData}
          rowData={contactPropertiesAvailable}
          sort={["id", "desc"]}
          pageLengthOpts={[50, 100, 150]}
          toggleModal={toggleModal}
          updatedState={lastAction}
          downloadableCSV
          editButton={auth?.user?.is_admin}
          deleteButton={auth?.user?.is_admin}
        />
      );
    } else if (_.isEmpty(propertiesAvailable)) {
      return (
        <EmptyList
          name="custom property"
          namePlural="custom properties"
          customText="At least one custom property is required before making contact properties. "
          userRole={auth?.user?.user_level}
          createElement={<Link to="/admin/properties">Create</Link>}
        />
      );
    } else if (_.isEmpty(contactPropertiesAvailable)) {
      return (
        <EmptyList
          name="contract property"
          namePlural="contract properties"
          userRole={auth?.user?.user_level}
          createElement={
            <span className="link" onClick={e => toggleModal(e, "new")}>
              Create
            </span>
          }
        />
      );
    }

    return "";
  };

  return (
    <>
      <SectionHeaderMenu
        menuData={_.isEmpty(propertiesAvailable) || sectionHeaderMenu}
      />
      <MDBCardBody>{renderTable()}</MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  );
};

export default ContactPropertyList;
