import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAuth } from "hooks/useAuth.js";
import { fetchSites } from "actions/sites/siteActions";

import SiteRoutes from "components/routes/SiteRoutes";

const SiteHome = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.user) {
      dispatch(fetchSites("list"));
    }
  }, [dispatch, auth]);

  return <SiteRoutes />;
};

export default SiteHome;
