import _ from "lodash";
import moment from "moment";

import {
  SET_SITE_PROPERTIES,
  SET_SITE_PROPERTY_OPTIONS,
  UNSET_SITE_PROPERTY_OPTIONS,
  CREATE_SITE_PROPERTY,
  DELETE_SITE_PROPERTY,
  UNSET_SITE_PROPERTY_ACTION,
  UPDATE_SITE_PROPERTY,
  ERROR_SITE_PROPERTY,
  UNSET_ERROR_SITE_PROPERTY
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  propertiesAvailable: {},
  sitePropertyOptions: [],
  propertyErrors: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SITE_PROPERTIES:
      return {
        ...state,
        lastAction: "fetch",
        propertiesAvailable: _.mapKeys(action.payload, "id")
      };
    case SET_SITE_PROPERTY_OPTIONS:
      return { ...state, sitePropertyOptions: action.payload };
    case UNSET_SITE_PROPERTY_OPTIONS:
      if (state.sitePropertyOptions) {
        return { ...state, sitePropertyOptions: [] };
      }
      return state;
    case UNSET_SITE_PROPERTY_ACTION:
      return { ...state, lastAction: null };
    case CREATE_SITE_PROPERTY:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        propertiesAvailable: {
          ...state.propertiesAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_SITE_PROPERTY:
      const updatedItem = (state.propertiesAvailable[
        action.currentSiteProperty.id
      ] = action.currentSiteProperty);
      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        updatedItem
      };
    case ERROR_SITE_PROPERTY:
      return {
        ...state,
        propertyErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_SITE_PROPERTY:
      return {
        ...state,
        propertyErrors: {}
      };
    case DELETE_SITE_PROPERTY:
      const updated = state.propertiesAvailable;
      _.unset(updated, `${action.currentSiteProperty.id}`);
      return { ...state, lastAction: "delete", propertiesAvailable: updated };
    default:
      return state;
  }
};
