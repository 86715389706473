import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";

const Modal = ({
  headerText,
  includeFooter,
  isOpen,
  toggle,
  actionButtons,
  children
}) => {
  if (isOpen) {
    return (
      <MDBModal autoFocus={false} isOpen={isOpen} toggle={toggle} centered>
        <MDBModalHeader toggle={toggle}>{headerText}</MDBModalHeader>
        <MDBModalBody>{children}</MDBModalBody>
        {includeFooter ? (
          <MDBModalFooter>
            <MDBBtn outline color="primary" onClick={toggle}>
              Cancel
            </MDBBtn>
            {actionButtons.map(button => (
              <MDBBtn
                key={button.text}
                color={button.color}
                {...button.additionalProps}
              >
                {button.text}
              </MDBBtn>
            ))}
          </MDBModalFooter>
        ) : (
          ""
        )}
      </MDBModal>
    );
  }
  return "";
};

export default Modal;
