import ContractDocumentForm from '../forms/ContractDocumentForm'
import {
  updateContractDocument,
  clearContractDocumentErrors,
  clearContractDocumentAction,
} from 'actions/contracts/contractDocumentActions'
import { fetchContractDocumentTypes } from 'actions/contracts/contractDocumentTypeActions'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ContractDocumentUpdate = ({
  formId,
  params,
  setParams,
  selected,
  setSelected,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, documentErrors } = useSelector(
    (state) => state.contractDocuments
  )
  const { documentTypeOptions } = useSelector(
    (state) => state.contractDocumentTypes
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (_.size(selected)) {
      dispatch(
        fetchContractDocumentTypes('form', selected.contract_document_type)
      )
    }
    return () => {
      dispatch(clearContractDocumentAction())
      dispatch(clearContractDocumentErrors())
    }
  }, [dispatch, selected])

  useEffect(() => {
    if (_.isEmpty(params) && documentTypeOptions.length) {
      setParams({
        id: selected.id,
        comment: selected.comment ? selected.comment : '',
      })
      setSelected({
        ...selected,
        id: selected.id,
      })
    }
  }, [selected, setSelected, params, setParams, documentTypeOptions])

  // watch for success
  useEffect(() => {
    if (lastAction === 'edit') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        dispatch(updateContractDocument(params, selected))
      }}
      className="form container"
      noValidate
    >
      <ContractDocumentForm
        params={params}
        setParams={setParams}
        selected={selected}
        setSelected={setSelected}
        documentTypeOptions={documentTypeOptions}
        errors={documentErrors}
      />
    </form>
  )
}

export default ContractDocumentUpdate
