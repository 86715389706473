import axios from "axios";
import store from "../store";
import { setAuth, resetAuth } from "actions/users/authActions";

const restApi = axios.create({
  baseURL: process.env.REACT_APP_CLINISTART_API
});

restApi.interceptors.response.use(
  function (response) {
    const { authorization } = store.getState();
    if (!authorization?.status) store.dispatch(setAuth());

    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(resetAuth());
    }
    return Promise.reject(error);
  }
);

export default restApi;
