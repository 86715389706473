import _ from "lodash";
import moment from "moment";
import {
  LOADING_CONTRACT_ACTIVITY_ALERTS,
  SET_CONTRACT_ACTIVITY_ALERTS,
  UNSET_CONTRACT_ACTIVITY_ALERTS,
  CREATE_CONTRACT_ACTIVITY_ALERT,
  DELETE_CONTRACT_ACTIVITY_ALERT,
  UPDATE_CONTRACT_ACTIVITY_ALERT,
  ERROR_CONTRACT_ACTIVITY_ALERT,
  UNSET_CONTRACT_ACTIVITY_ALERT_ACTION,
  UNSET_ERROR_CONTRACT_ACTIVITY_ALERT
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  activityAlertsAvailable: {},
  activityAlertErrors: {},
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_CONTRACT_ACTIVITY_ALERTS:
      return {
        ...state,
        lastAction: null,
        loading: true
      };
    case SET_CONTRACT_ACTIVITY_ALERTS:
      return {
        ...state,
        lastAction: "fetch",
        activityAlertsAvailable: _.mapKeys(action.payload, "id"),
        loading: false
      };
    case UNSET_CONTRACT_ACTIVITY_ALERTS:
      return {
        ...state,
        activityAlertsAvailable: {},
        loading: false
      };
    case CREATE_CONTRACT_ACTIVITY_ALERT:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        activityAlertsAvailable: {
          ...state.activityAlertsAvailable,
          [id]: createdItem
        },
        loading: false
      };
      return newState;
    case UPDATE_CONTRACT_ACTIVITY_ALERT:
      const updatedItem = (state.activityAlertsAvailable[
        action.currentActivityAlert.id
      ] = action.currentActivityAlert);

      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return { ...state, lastAction: "edit", updatedItem, loading: false };
    case ERROR_CONTRACT_ACTIVITY_ALERT:
      return {
        ...state,
        activityAlertErrors: action.payload,
        lastAction: "error",
        loading: false
      };
    case UNSET_ERROR_CONTRACT_ACTIVITY_ALERT:
      return {
        ...state,
        activityAlertErrors: {},
        loading: false
      };
    case UNSET_CONTRACT_ACTIVITY_ALERT_ACTION:
      return { ...state, lastAction: null, loading: false };
    case DELETE_CONTRACT_ACTIVITY_ALERT:
      let updatedActivityAlerts = state.activityAlertsAvailable;
      _.unset(updatedActivityAlerts, `${action.currentActivityAlert.id}`);
      return {
        ...state,
        lastAction: "delete",
        activityAlertsAvailable: updatedActivityAlerts,
        loading: false
      };
    default:
      return state;
  }
};
