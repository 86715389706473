import _ from "lodash";
import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { MDBIcon } from "mdbreact";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";
import { toggleSite } from "actions/sites/siteActions";

import Section from "components/common/Section";
import Table from "components/common/tables/Table";
import Modal from "components/common/Modal";
import EmptyList from "components/common/EmptyList";
import SiteDelete from "./SiteDelete";

import { isUserNotReadOnly } from "utils/authUtils";
import { filterTableColumns } from "utils/tableUtils";

const SiteList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();
  const auth = useAuth();
  const dispatch = useDispatch();

  // redux hooks
  const { sitesAvailable, lastAction } = useSelector(state => state.sites);
  const authorizationStatus = useSelector(state => state.authorization.status);

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "#",
        field: "id",
        sort: "desc"
      },
      {
        label: "Name",
        field: "name",
        sort: "asc"
      },
      {
        label: "Address #1",
        field: "address_1",
        sort: "asc"
      },
      {
        label: "City",
        field: "city",
        sort: "desc"
      },
      {
        label: "State",
        field: "state",
        sort: "desc"
      },
      {
        label: "Zip",
        field: "zip",
        sort: "desc"
      },
      {
        label: "Country",
        field: "country.name",
        sort: "desc"
      },
      {
        label: "Available",
        field: "is_available",
        toggleControl: true,
        sort: "disabled"
      },
      {
        label: "",
        field: "buttons",
        sort: "disabled",
        minimal: "lg"
      }
    ]
  });

  // remove available column once we know the user context is loaded
  // and it is not an admin
  if (auth.hasUserInfo && !auth?.user?.is_admin) {
    filterTableColumns(tableData, setTableData, ["is_available"]);
  }

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Site");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  const fetchTableLink = useCallback((type, item) => {
    switch (type) {
      case "show":
        return `/sites/${item.id}`;
      case "edit":
        return `/sites/${item.id}/edit`;
      default:
        return null;
    }
  }, []);

  const handleAvailabilityChange = (item, toggle) => {
    dispatch(toggleSite(item, toggle));
  };

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: (
          <>
            <MDBIcon icon="fas fa-plus" /> New Site
          </>
        ),
        url: "/sites/new",
        additionalProps: {
          active: true
        }
      }
    ]
  };

  const renderTable = () => {
    if (_.size(sitesAvailable)) {
      return (
        <Table
          section="Site"
          tableData={tableData}
          setTableData={setTableData}
          rowData={sitesAvailable}
          sort={["id", "desc"]}
          fetchTableLink={fetchTableLink}
          toggleAvailability={handleAvailabilityChange}
          toggleModal={toggleModal}
          updatedState={lastAction}
          clickableRow
          downloadableCSV
          linkToPage
          showButton
          editButton={isUserNotReadOnly(auth?.user?.user_level)}
          deleteButton={auth?.user?.is_admin ?? false}
        />
      );
    } else if (_.isEmpty(sitesAvailable)) {
      return (
        <EmptyList
          name="site"
          namePlural="sites"
          userRole={auth?.user?.user_level}
          createElement={<Link to="/sites/new">Create</Link>}
        />
      );
    }
    return "";
  };

  return (
    <Section
      header="Site List"
      menu={
        isUserNotReadOnly(auth?.user?.user_level) ? sectionHeaderMenu : null
      }
    >
      {renderTable()}
      {auth?.user?.is_admin ? (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <SiteDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      ) : (
        ""
      )}
    </Section>
  );
};

export default SiteList;
