import React from "react";
import { Route, Switch } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import SiteList from "components/sites/SiteList";
import SiteCreate from "components/sites/SiteCreate";
import SiteShow from "components/sites/SiteShow";
import SiteUpdate from "components/sites/SiteUpdate";

import { isUserNotReadOnly } from "utils/authUtils";

const SiteRoutes = () => {
  const auth = useAuth();
  return (
    <Switch>
      <Route exact path="/sites" component={SiteList} />
      {isUserNotReadOnly(auth?.user?.user_level) ? (
        <Route path="/sites/new" component={SiteCreate} />
      ) : (
        ""
      )}
      <Route exact path="/sites/:id" component={SiteShow} />
      {isUserNotReadOnly(auth?.user?.user_level) ? (
        <Route path="/sites/:id/edit" component={SiteUpdate} />
      ) : (
        ""
      )}
    </Switch>
  );
};

export default SiteRoutes;
