// import { useAuth } from 'hooks/useAuth'
import HomeContractViews, {
  isInitializingContractViewsAtom,
} from './HomeContractViews'
import HomeSrDocumentViews, {
  isInitializingSrDocumentViewsAtom,
} from './HomeSrDocumentViews'
import { useAuth } from 'hooks/useAuth'
import { useAtomValue } from 'jotai'
import { MDBSpinner } from 'mdbreact'
import React from 'react'

const Home = () => {
  //// HOOKS.
  const { user } = useAuth()
  const { user_level, customer } = user ?? {}
  const hasSiteReadiness = customer?.has_site_readiness

  //// GLOBAL STATE.
  const isContractViewsInitializing = useAtomValue(
    isInitializingContractViewsAtom
  )
  const isSrDocumentViewsInitializing =
    useAtomValue(isInitializingSrDocumentViewsAtom) && hasSiteReadiness

  const isInitializing =
    isContractViewsInitializing || isSrDocumentViewsInitializing

  //// RENDER.
  return (
    <>
      {isInitializing && <MDBSpinner />}
      {((sections) =>
        user_level === 'site_readiness' ? sections.reverse() : sections)([
        <HomeContractViews key="HomeContractViews" />,
        hasSiteReadiness ? (
          <HomeSrDocumentViews key="HomeSrDocumentViews" />
        ) : null,
      ])}
    </>
  )
}

export default Home
