import {
  SET_CONTRACT_STATUSES,
  SET_CONTRACT_STATUS_OPTIONS,
  UNSET_CONTRACT_STATUS_OPTIONS,
  CREATE_CONTRACT_STATUS,
  DELETE_CONTRACT_STATUS,
  UPDATE_CONTRACT_STATUS,
  TOGGLE_CONTRACT_STATUS,
  ERROR_CONTRACT_STATUS,
  UNSET_CONTRACT_STATUS_ACTION,
  UNSET_ERROR_CONTRACT_STATUS,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractStatusEndPoint = '/contracts/status'

// Passing data back to reducers
const setContractStatus = (
  obj,
  type,
  contractStatus,
  noDefaultOptions = false
) => {
  const action = { type: type, payload: obj }
  if (contractStatus) action.currentContractStatus = contractStatus
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions
  return action
}

// Non-API calls
export const clearContractStatusOptions = () => async (dispatch) => {
  dispatch(setContractStatus({}, UNSET_CONTRACT_STATUS_OPTIONS))
}
export const clearContractStatusAction = () => async (dispatch) => {
  dispatch(setContractStatus({}, UNSET_CONTRACT_STATUS_ACTION))
}
export const clearContractStatusErrors = () => async (dispatch) => {
  dispatch(setContractStatus({}, UNSET_ERROR_CONTRACT_STATUS))
}

// API calls
export const fetchContractStatuses =
  (results, contractStatus, noDefaultOptions = false) =>
  async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(contractStatusEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setContractStatus(res.data, SET_CONTRACT_STATUSES))
      } else if (results === 'form') {
        dispatch(
          setContractStatus(
            res.data,
            SET_CONTRACT_STATUS_OPTIONS,
            contractStatus,
            noDefaultOptions
          )
        )
      }
    }
  }

export const createContractStatus = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractStatusEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractStatus(res.data, CREATE_CONTRACT_STATUS))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractStatus(error, ERROR_CONTRACT_STATUS))
    }
  }
}

export const deleteContractStatus = (status) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractStatusEndPoint, {
      data: { id: status.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractStatus(res.data, DELETE_CONTRACT_STATUS, status))
  }
}

export const updateContractStatus = (params, status) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contractStatusEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractStatus(res.data, UPDATE_CONTRACT_STATUS, status))
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setContractStatus(error, ERROR_CONTRACT_STATUS))
    }
  }
}

export const toggleContractStatus = (status, toggle) => async (dispatch) => {
  const toggleEndPoint = `${contractStatusEndPoint}/${
    toggle ? 'enable' : 'disable'
  }`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: status.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setContractStatus({ res, toggle: toggle }, TOGGLE_CONTRACT_STATUS, status)
    )
  }
}
