import _ from "lodash";
import {
  SET_STUDY_BUDGET_VISITS,
  UNSET_STUDY_BUDGET_VISITS,
  ERROR_STUDY_BUDGET_VISIT,
  UNSET_ERROR_STUDY_BUDGET_VISIT,
  CREATE_STUDY_BUDGET_VISIT,
  DELETE_STUDY_BUDGET_VISIT,
  UNSET_STUDY_BUDGET_VISIT_ACTION
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  studyBudgetVisitsAvailable: {},
  studyBudgetVisitErrors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STUDY_BUDGET_VISITS:
      return {
        ...state,
        lastAction: "fetch",
        studyBudgetVisitsAvailable: _.mapKeys(action.payload, "id")
      };
    case UNSET_STUDY_BUDGET_VISITS:
      return {
        ...state,
        studyBudgetVisitsAvailable: {},
        studyBudgetVisitErrors: {},
        lastAction: null
      };
    case UNSET_STUDY_BUDGET_VISIT_ACTION:
      return { ...state, lastAction: null };
    case CREATE_STUDY_BUDGET_VISIT:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        studyBudgetVisitsAvailable: {
          ...state.studyBudgetVisitsAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case ERROR_STUDY_BUDGET_VISIT:
      return {
        ...state,
        lastAction: "error",
        studyBudgetVisitErrors: action.payload
      };
    case UNSET_ERROR_STUDY_BUDGET_VISIT:
      return {
        ...state,
        studyBudgetVisitErrors: {}
      };
    case DELETE_STUDY_BUDGET_VISIT:
      let updatedStudyBudgetVisits = state.studyBudgetVisitsAvailable;
      _.unset(updatedStudyBudgetVisits, `${action.currentStudyBudgetVisit.id}`);
      return {
        ...state,
        lastAction: "delete",
        studyBudgetVisitsAvailable: updatedStudyBudgetVisits
      };
    default:
      return state;
  }
};
