import restApi from "apis/restApi";
import {
  SET_INVOICE_MILESTONES,
  UNSET_INVOICE_MILESTONES,
  CREATE_INVOICE_MILESTONE,
  DELETE_INVOICE_MILESTONE,
  UPDATE_INVOICE_MILESTONE,
  UNSET_INVOICE_MILESTONE_ACTION,
  ERROR_INVOICE_MILESTONE,
  UNSET_ERROR_INVOICE_MILESTONE
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const invoiceMilestoneEndPoint = "/invoiceMilestone";

// Passing data back to reducers
const setInvoiceMilestone = (
  obj,
  type,
  invoiceMilestone,
  noDefaultOptions = true
) => {
  const action = { type: type, payload: obj };
  // optional obj passing for cases like delete (where we dont get id back)
  if (invoiceMilestone) action.currentInvoiceMilestone = invoiceMilestone;
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions;
  return action;
};

// Non-API calls
export const clearInvoiceMilestones = () => async dispatch => {
  dispatch(setInvoiceMilestone({}, UNSET_INVOICE_MILESTONES));
};

export const clearInvoiceMilestoneErrors = () => async dispatch => {
  dispatch(setInvoiceMilestone({}, UNSET_ERROR_INVOICE_MILESTONE));
};

export const clearInvoiceMilestoneAction = () => async dispatch => {
  dispatch(setInvoiceMilestone({}, UNSET_INVOICE_MILESTONE_ACTION));
};

// When there is no type, we set them as all types/status/locations
const setMilestoneNull = milestone => {
  if (!milestone.contract_type?.id) {
    milestone.contract_type = {
      id: 0,
      name: "All Types"
    };
  }
  if (!milestone.contract_status?.id) {
    milestone.contract_status = {
      id: 0,
      name: "All Statuses"
    };
  }
  if (!milestone.contract_location?.id) {
    milestone.contract_location = {
      id: 0,
      name: "All Locations"
    };
  }
};

// API calls
export const fetchInvoiceMilestones =
  (queryStudyId = "") =>
  async dispatch => {
    if (fetchToken()) {
      const endPoint =
        queryStudyId?.length > 0
          ? `${invoiceMilestoneEndPoint}?study_id=${queryStudyId}`
          : invoiceMilestoneEndPoint;
      const res = await restApi.get(endPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      res.data.forEach(setMilestoneNull);
      dispatch(setInvoiceMilestone(res.data, SET_INVOICE_MILESTONES));
    }
  };

export const createInvoiceMilestone = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(invoiceMilestoneEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      res.data.created_items.forEach(setMilestoneNull);
      dispatch(setInvoiceMilestone(res.data, CREATE_INVOICE_MILESTONE));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setInvoiceMilestone(error, ERROR_INVOICE_MILESTONE));
    }
  }
};

export const deleteInvoiceMilestone = invoiceMilestone => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(invoiceMilestoneEndPoint, {
      data: { id: invoiceMilestone.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setInvoiceMilestone(res.data, DELETE_INVOICE_MILESTONE, invoiceMilestone)
    );
  }
};

export const updateInvoiceMilestone =
  (params, currentInvoiceMilestone) => async dispatch => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(invoiceMilestoneEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` }
        });
        dispatch(
          setInvoiceMilestone(
            res.data,
            UPDATE_INVOICE_MILESTONE,
            currentInvoiceMilestone
          )
        );
      } catch (err) {
        const error = { ...err.response.data, type: "edit" };
        dispatch(setInvoiceMilestone(error, ERROR_INVOICE_MILESTONE));
      }
    }
  };
