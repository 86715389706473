import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearInvoiceMilestoneErrors,
  updateInvoiceMilestone,
  clearInvoiceMilestoneAction
} from "actions/invoiceMilestones/invoiceMilestoneActions";
import InvoiceMilestoneForm from "components/admin/invoiceMilestones/InvoiceMilestoneForm";

const InvoiceMilestoneUpdate = ({
  formId,
  params,
  setParams,
  selected,
  setSelected,
  closeModal
}) => {
  const dispatch = useDispatch();

  const { lastAction, milestoneErrors } = useSelector(
    state => state.invoiceMilestones
  );

  useEffect(() => {
    return () => {
      // Cleanup error, actions, get updated milestone
      dispatch(clearInvoiceMilestoneErrors());
      dispatch(clearInvoiceMilestoneAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  // Set modal with param values
  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        name: selected.name || "",
        percent_complete: selected.percent_complete || 0
      });
      setSelected({
        ...selected,
        id: selected.id,
        customer_id: selected.customer_id
      });
    }
  }, [selected, setSelected, params, setParams]);

  const handleUpdate = (e, milestoneParams, currentMilestone) => {
    e.preventDefault();

    const updatedMilestone = _.merge(currentMilestone, milestoneParams);
    dispatch(updateInvoiceMilestone(milestoneParams, updatedMilestone));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleUpdate(e, params, selected)}
        className="form container"
        noValidate
      >
        <InvoiceMilestoneForm
          params={params}
          setParams={setParams}
          errors={milestoneErrors}
        />
      </form>
    );
  }
  return "";
};

export default InvoiceMilestoneUpdate;
