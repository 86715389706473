import { isInitializingSrDocumentViewsAtom } from './HomeSrDocumentViews'
import { fetchContractPropertyOptions } from 'actions/contracts/contractPropertyActions'
import {
  fetchContractViews,
  clearContractViews,
} from 'actions/contracts/contractViewActions'
import EmptyList from 'components/common/EmptyList'
import Section from 'components/common/Section'
import ContractTable, {
  contractsColumnsAtom,
  contractsFilterParamsAtom,
  contractsQueryParamsAtom,
  contractsTableDataAtom,
  contractsViewParamsAtom,
  useResetContracts,
} from 'components/contracts/ContractTable'
import ContractView from 'components/contracts/ContractView'
import { useAuth } from 'hooks/useAuth'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setContractColumns } from 'utils/tableUtils'

const fetchTableLink = (type, item) => {
  switch (type) {
    case 'show':
      return `/contracts/${item.id}`
    default:
      return null
  }
}

export const isInitializingContractViewsAtom = atom(true)

const HomeContractViews = () => {
  //// HOOKS.
  const auth = useAuth()
  const dispatch = useDispatch()
  const resetContracts = useResetContracts(dispatch)

  //// GLOBAL STATE.
  const [contractsTableData, setContractsTableData] = useAtom(
    contractsTableDataAtom
  )
  const [
    setContractsFilterParams,
    setContractsViewParams,
    setContractsQueryParams,
    setContractsColumns,
  ] = [
    useSetAtom(contractsFilterParamsAtom),
    useSetAtom(contractsViewParamsAtom),
    useSetAtom(contractsQueryParamsAtom),
    useSetAtom(contractsColumnsAtom),
  ]

  const {
    contractViews: {
      contractViewsAvailable,
      lastAction: lastContractViewAction,
    },
    contracts: { contractsAvailable, lastAction, isLoadingMore },
    contractProperties: {
      contractPropertyOptions,
      lastAction: lastActionContractProperties,
    },
  } = useSelector(({ contractViews, contracts, contractProperties }) => ({
    contractViews,
    contracts,
    contractProperties,
  }))

  const [[isInitializing, setIsInitializing], isSrDocumentViewsInitializing] = [
    useAtom(isInitializingContractViewsAtom),
    useAtomValue(isInitializingSrDocumentViewsAtom) &&
      auth?.user?.customer?.has_site_readiness !== false,
  ]

  //// LOCAL STATE.
  const [defaultView, setDefaultView] = useState({})

  //// EFFECTS.
  useEffect(
    () => {
      setIsInitializing(true)
      resetContracts()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    // loading init data.
    Promise.all(
      [fetchContractPropertyOptions(), fetchContractViews()].map((action) =>
        dispatch(action)
      )
    ).finally(() => {
      setIsInitializing(false)
    })

    return () => {
      dispatch(clearContractViews())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // build home page on first load
  useEffect(() => {
    if (
      !isInitializing &&
      lastContractViewAction === 'fetch' &&
      lastActionContractProperties === 'fetchOptions'
    ) {
      if (_.size(contractViewsAvailable)) {
        const selectedView =
          _.find(contractViewsAvailable, 'is_primary') ||
          _.find(contractViewsAvailable, 'id')
        setDefaultView(selectedView)
        setContractsViewParams(selectedView.selection_params)
        setContractsFilterParams(selectedView.selection_params)
        setContractsQueryParams(selectedView.url_params)
        const contractColumns = setContractColumns(
          selectedView.column_params,
          contractPropertyOptions
        )
        const contractColumnIds = contractColumns.map((col) => col.field)
        setContractsColumns(contractColumnIds)
        setContractsTableData({
          ...contractsTableData,
          columns: contractColumns,
        })
      }
    }
    // eslint-disable-next-line
  }, [
    isInitializing,
    lastContractViewAction,
    contractViewsAvailable,
    lastActionContractProperties,
    contractPropertyOptions,
  ])

  //// RENDER.
  return (
    !isInitializing &&
    !isSrDocumentViewsInitializing && (
      <Section
        header="Saved Contract Views"
        menu={{
          order: 2,
          show: true,
          linkItems: [],
          leftSide: <ContractView defaultView={defaultView} />,
        }}
      >
        {_.isEmpty(contractViewsAvailable) ? (
          <EmptyList
            name="contract view"
            namePlural="contract views"
            createElement={<Link to="/contracts">Create</Link>}
            userRole={auth?.user?.user_level}
          />
        ) : (
          <ContractTable
            contractsAvailable={contractsAvailable}
            isLoadingMore={isLoadingMore}
            lastAction={lastAction}
            contractPropertyOptions={contractPropertyOptions}
            fetchTableLink={fetchTableLink}
            homePageView
            section="Home"
            willSaveColumns={false}
            shouldRequireQueryParams
          />
        )}
      </Section>
    )
  )
}

export default HomeContractViews
