import { useEffect } from "react";
import { useAuth } from "./useAuth";
import useRouter from "./useRouter";

import { fetchToken } from "utils/authUtils";

export function useRequireAuth(
  redirectUrl = "/login",
  pwResetUrl = "/change-password"
) {
  const auth = useAuth();
  const router = useRouter();

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    const currentToken = fetchToken();
    if (auth?.user && auth?.token !== currentToken) {
      auth.setUserToken(null);
    }
    // if user data exists and  needs a password reset, check for reroute to
    // passwod change url before  back to login page
    if (
      auth?.user !== false &&
      auth?.user?.force_new_password === true &&
      router.pathname !== pwResetUrl
    ) {
      router.push(pwResetUrl);
    } else if (auth?.user === false && router.pathname !== redirectUrl) {
      router.push(redirectUrl);
    }
  }, [auth]);

  return auth;
}
