import {
  CREATE_SR_DOCUMENT_COLUMNS,
  ERROR_SR_DOCUMENT_COLUMNS,
  SET_SR_DOCUMENT_COLUMNS,
  UNSET_SR_DOCUMENT_COLUMNS,
  UNSET_SR_DOCUMENT_COLUMN_ACTION,
  UNSET_ERROR_SR_DOCUMENT_COLUMN,
} from 'actions/types'

const INITIAL_STATE = {
  lastAction: null,
  srDocumentColumns: [],
  srDocumentColumnErrors: {},
}

const srDocumentColumnReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_COLUMNS:
      return {
        srDocumentColumns: action.payload,
        lastAction: 'fetch',
      }
    case UNSET_SR_DOCUMENT_COLUMNS:
      return INITIAL_STATE
    case CREATE_SR_DOCUMENT_COLUMNS:
      const columns = action?.payload?.sr_document_columns.split(',') ?? []
      const newState = {
        lastAction: 'create',
        srDocumentColumns: columns,
      }
      return newState
    case ERROR_SR_DOCUMENT_COLUMNS:
      return {
        ...state,
        srDocumentColumnErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_COLUMN:
      return {
        ...state,
        srDocumentColumnErrors: {},
      }
    case UNSET_SR_DOCUMENT_COLUMN_ACTION:
      return { ...state, lastAction: null }
    default:
      return state
  }
}

export default srDocumentColumnReducer
