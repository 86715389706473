import { MDBBreadcrumbItem } from 'mdbreact'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbContractItems = ({
  match: {
    path,
    params: { id, contractId },
  },
}) => {
  const [isNew, isView, isEdit, isViewDocumentType] = [
    path === '/contracts/new',
    path === '/contracts/:id',
    path === '/contracts/:id/edit',
    path === '/contracts/:contractId/document-types/:documentTypeId',
  ]

  return (
    <>
      <MDBBreadcrumbItem>
        <Link to="/contracts">Contracts</Link>
      </MDBBreadcrumbItem>

      {!(isNew || isView || isEdit || isViewDocumentType) ? (
        ''
      ) : (
        <MDBBreadcrumbItem active={isNew || isView}>
          {isNew ? (
            'Create'
          ) : isView ? (
            `#${id}`
          ) : isEdit ? (
            <Link to={`/contracts/${id}`}>#{id}</Link>
          ) : isViewDocumentType ? (
            <Link to={`/contracts/${contractId}`}>#{contractId}</Link>
          ) : (
            ''
          )}
        </MDBBreadcrumbItem>
      )}

      {!(isEdit || isViewDocumentType) ? (
        ''
      ) : (
        <MDBBreadcrumbItem active>
          {isEdit ? 'Edit' : isViewDocumentType ? 'Document Type' : ''}
        </MDBBreadcrumbItem>
      )}
    </>
  )
}

export default BreadcrumbContractItems
