import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { MDBCol, MDBInput } from "mdbreact";

import {
  clearContractActivityAlertErrors,
  clearContractActivityAlertAction
} from "actions/contracts/contractActivityAlertActions";
import { clearContractTypeOptions } from "actions/contracts/contractTypeActions";
import { clearContractLocationOptions } from "actions/contracts/contractLocationActions";
import { clearContractStatusOptions } from "actions/contracts/contractStatusActions";

import ErrorPanel from "components/common/panel/ErrorPanel";
import Select from "components/common/forms/Select";

import { inputErrorClass } from "utils/formUtils";

const ContractActivityAlertForm = ({
  activityAlert,
  typeOptions,
  locationOptions,
  statusOptions,
  contractProperties,
  submit,
  errors,
  isEdit
}) => {
  const dispatch = useDispatch();

  const [activityAlertParams, setActivityAlertParams] = useState({
    email: activityAlert ? activityAlert.email : ""
  });

  const handleInputChange = (value, key) => {
    setActivityAlertParams({ ...activityAlertParams, [key]: value });
  };

  useEffect(() => {
    return () => {
      dispatch(clearContractActivityAlertErrors());
      dispatch(clearContractActivityAlertAction());
      dispatch(clearContractTypeOptions());
      dispatch(clearContractLocationOptions());
      dispatch(clearContractStatusOptions());
    };
  }, [dispatch]);

  return (
    <form
      id="form_contract_activity_alert"
      className="form row"
      onSubmit={e =>
        submit(
          e,
          activityAlertParams,
          typeOptions,
          locationOptions,
          statusOptions,
          contractProperties
        )
      }
      noValidate
    >
      <MDBCol md="6">
        <MDBInput
          value={activityAlertParams.email}
          getValue={value => handleInputChange(value, "email")}
          id="activity_alert-email-input"
          label="Emails"
          outline
          autoFocus
          required
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !activityAlertParams.email
          )}`}
        />
      </MDBCol>
      <MDBCol md="6">
        {typeOptions.length ? (
          <Select
            id="select__type"
            options={typeOptions}
            label="Contract Type"
            required
            search
            errors={errors}
            disabled={isEdit ?? false}
          />
        ) : (
          ""
        )}
      </MDBCol>
      <MDBCol md="6">
        {locationOptions.length ? (
          <Select
            id="select__location"
            options={locationOptions}
            label="Contract Location"
            required
            search
            errors={errors}
            disabled={isEdit ?? false}
          />
        ) : (
          ""
        )}
      </MDBCol>
      <MDBCol md="6">
        {statusOptions.length ? (
          <Select
            id="select__status"
            options={statusOptions}
            label="Contract Status"
            required
            search
            errors={errors}
            disabled={isEdit ?? false}
          />
        ) : (
          ""
        )}
      </MDBCol>
      <MDBCol md="6">
        {contractProperties.length ? (
          <Select
            id="select__property-option"
            options={contractProperties}
            label="User List (Name - Contract Type)"
            required
            search
            errors={errors}
            disabled={isEdit ?? false}
          />
        ) : (
          ""
        )}
      </MDBCol>
      <ErrorPanel results={errors} />
    </form>
  );
};

export default ContractActivityAlertForm;
