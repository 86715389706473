import {
  clearStudyErrors,
  clearStudyAction,
} from 'actions/studies/studyActions'
import {
  fetchStudyPropertyOptions,
  fetchStudyProperties,
} from 'actions/studies/studyPropertyActions'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import CustomPropertyManagement from 'components/common/properties/CustomPropertyManagement'
import { MDBCol, MDBInput } from 'mdbreact'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { inputErrorClass } from 'utils/formUtils'

const StudyForm = ({
  submit,
  study,
  errors,
  customProperties,
  setCustomProperties,
}) => {
  const dispatch = useDispatch()

  const { propertyOptions } = useSelector((state) => state.studyProperties)

  const [studyParams, setStudyParams] = useState({
    name: study?.name || '',
    protocol: study?.protocol || '',
  })

  useEffect(() => {
    dispatch(fetchStudyPropertyOptions())
    return () => {
      dispatch(clearStudyErrors())
      dispatch(clearStudyAction())
      dispatch(fetchStudyProperties())
    }
  }, [dispatch])

  const handleInputChange = (value, key) => {
    setStudyParams({ ...studyParams, [key]: value })
  }

  return (
    <form
      id="form_study"
      className="form row"
      noValidate
      onSubmit={(e) => submit(e, studyParams)}
    >
      <MDBCol md="6">
        <MDBInput
          value={studyParams.name}
          getValue={(value) => handleInputChange(value, 'name')}
          id="study-name-input"
          label="Sponsor Study Name"
          outline
          required
          autoFocus
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !studyParams?.name
          )}`}
        />
      </MDBCol>
      <MDBCol md="6">
        <MDBInput
          value={studyParams.protocol}
          getValue={(value) => handleInputChange(value, 'protocol')}
          id="study-protocol-input"
          label="Protocol"
          outline
          required
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !studyParams.protocol
          )}`}
        />
      </MDBCol>
      <CustomPropertyManagement
        params={studyParams}
        setParams={setStudyParams}
        current={study ? study : false}
        propertyOptions={propertyOptions}
        customProperties={customProperties || false}
        setCustomProperties={setCustomProperties || undefined}
      />
      <ErrorPanel results={errors} />
    </form>
  )
}

export default StudyForm
