import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBSpinner
} from "mdbreact";

import Breadcrumbs from "../nav/breadcrumbs/BreadcrumbNav";
import SectionHeaderMenu from "../nav/SectionHeaderMenu";
import SectionHeader from "./SectionHeader";

const SectionContainer = ({
  children,
  description,
  header,
  title,
  menu,
  footer,
  breadcrumbs,
  loading
}) => {
  title = title ? <h2 className="mb-2">{title}</h2> : "";
  header = header ? <h4 className="mb-2">{header}</h4> : "";
  breadcrumbs = breadcrumbs ? <Breadcrumbs /> : "";
  menu = menu ? (
    <SectionHeaderMenu menuData={menu} description={description} />
  ) : (
    ""
  );
  description = description ? <SectionHeader description={description} /> : "";

  return (
    <>
      {loading ? (
        <MDBSpinner />
      ) : (
        <>
          <header className="d-flex justify-content-between align-items-start">
            <div>
              {title}
              {header}
            </div>
            {breadcrumbs}
          </header>
          <MDBContainer fluid className="mb-5">
            <MDBRow>
              <MDBCard className="w-100">
                {menu || description}
                <MDBCardBody style={{ minHeight: "100px" }}>
                  {children}
                </MDBCardBody>
                {footer ? (
                  <MDBCardFooter className="text-right">
                    {footer.map(button => (
                      <MDBBtn
                        key={button.text}
                        color={button.color}
                        {...button.additionalProps}
                      >
                        {button.text}
                      </MDBBtn>
                    ))}
                  </MDBCardFooter>
                ) : (
                  ""
                )}
              </MDBCard>
            </MDBRow>
          </MDBContainer>
        </>
      )}
    </>
  );
};

export default SectionContainer;
