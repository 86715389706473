import BadgeLabel from 'components/common/badges/BadgeLabel'
import Select from 'components/common/forms/Select'
import Panel from 'components/common/panel/Panel'
import { MDBRow, MDBCol, MDBSwitch } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { selectArray, selectObject } from 'utils/formUtils'

const SrCustomPropertyForm = ({
  create,
  isSiteReadiness,
  customProperties,
  params,
  setParams,
  srDocumentTypes,
  srDocumentTypeDefaultOption,
  errors,
}) => {
  //// HOOKS.

  //// GLOBAL STATE.

  //// LOCAL STATE.
  const [propertyOptions, setPropertyOptions] = useState([])
  const [srDocumentTypeOptions, setSrDocumentTypeOptions] = useState(
    create && isSiteReadiness ? [] : undefined
  )

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (create && !propertyOptions?.length) {
      const options = selectObject(
        customProperties,
        params.property_id ? params.property_id : 0
      )
      setPropertyOptions(options)
    }
  }, [
    create,
    customProperties,
    propertyOptions,
    setPropertyOptions,
    params.property_id,
  ])

  useEffect(() => {
    if (create && isSiteReadiness && srDocumentTypeOptions?.length === 0) {
      const options = srDocumentTypeDefaultOption.length
        ? selectArray(
            srDocumentTypes,
            params.sr_document_type_id ? params.sr_document_type_id : 0,
            true,
            srDocumentTypeDefaultOption
          )
        : selectArray(
            srDocumentTypes,
            params.sr_document_type_id ? params.sr_document_type_id : 0
          )
      setSrDocumentTypeOptions(options)
    }
  }, [
    create,
    isSiteReadiness,
    srDocumentTypes,
    srDocumentTypeOptions,
    setSrDocumentTypeOptions,
    params.sr_document_type_id,
    srDocumentTypeDefaultOption,
  ])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <>
      <MDBRow>
        {!propertyOptions?.length ? (
          ''
        ) : (
          <MDBCol md="12">
            <Select
              id="select__property"
              options={propertyOptions}
              getTextContent={(v) => {
                if (v !== 'Choose A Custom Property') {
                  const propertySelection = propertyOptions.filter(
                    (property) => property.value === v
                  )
                  setParams({
                    ...params,
                    property_id: propertySelection[0].id,
                  })
                }
              }}
              label="Property"
              defaultOption="Choose A Custom Property"
              required
            />
          </MDBCol>
        )}
        <MDBCol md="5">
          <BadgeLabel spacingClass="mb-3">Is this field required?</BadgeLabel>
          <MDBSwitch
            className="availability-switch"
            labelLeft="False"
            labelRight="True"
            checked={params.required}
            getValue={(toggle) => setParams({ ...params, required: toggle })}
          />
        </MDBCol>
        {!srDocumentTypes?.length ? (
          ''
        ) : (
          <MDBCol md="7">
            <BadgeLabel spacingClass="mb-3">
              Limit this to specific site readiness types?
            </BadgeLabel>
            <Select
              id="select__type"
              options={srDocumentTypeOptions}
              getTextContent={(v) => {
                if (v !== 'Choose A Site Readiness Type') {
                  const splitValue = v.split(', ')
                  let typeSelections = []
                  splitValue.forEach((value) => {
                    const srDocumentTypeSelection =
                      srDocumentTypeOptions.filter(
                        (type) => type.value === value
                      )
                    if (srDocumentTypeSelection?.[0]?.hasOwnProperty('id')) {
                      typeSelections.push(srDocumentTypeSelection[0].id)
                    }
                  })
                  setParams({
                    ...params,
                    sr_document_type_id: typeSelections,
                  })
                }
              }}
              label="Site Readiness Type"
              defaultOption="Choose Site Readiness Types"
              multiple
            />
          </MDBCol>
        )}
      </MDBRow>
      <Panel messages={errors.error} backgroundColor="red" textColor="white" />
    </>
  )
}

export default SrCustomPropertyForm
