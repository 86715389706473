/** @jsx jsx */
import _ from "lodash";
import { MDBBadge } from "mdbreact";
import { lighten } from "polished";
import { css, jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";

const BadgePill = ({ themeColor, lightenColor, additionalProps, children }) => {
  const theme = useTheme();

  const bigBadge = css`
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    text-transform: uppercase;
    background-color: ${theme.colors[themeColor] ||
    theme.colors.primary} !important;
  `;

  const bigBadgeLighten = lightenColor
    ? css`
        background-color: ${lighten(
          lightenColor,
          theme.colors[themeColor] || theme.colors.primary
        )} !important;
      `
    : "";

  const handleBadgeLinkHover = () => {
    if (lightenColor) {
      return `&:hover {
        background-color: ${lighten(
          lightenColor + 0.1,
          theme.colors[themeColor]
        )} !important;
      }`;
    }
    return "";
  };

  const bigBadgeLink =
    additionalProps && additionalProps.onClick
      ? css`
          cursor: pointer;
          ${handleBadgeLinkHover()};
        `
      : "";

  return (
    <MDBBadge
      pill
      className="mr-2"
      css={_.concat(bigBadge, bigBadgeLighten, bigBadgeLink)}
      {...additionalProps}
    >
      {children}
    </MDBBadge>
  );
};

export default BadgePill;
