import React, { useState } from "react";
import { MDBCol, MDBInput, MDBBtn } from "mdbreact";

import Panel from "components/common/panel/Panel";

const ProfileForm = ({ btnText, submit, errors, success, userCanEdit }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleOldPassword = e => {
    setOldPassword(e);
  };

  const handleNewPassword = e => {
    setNewPassword(e);
  };

  const handleConfirmNewPassword = e => {
    setConfirmNewPassword(e);
  };

  return (
    <form
      className="form"
      noValidate
      onSubmit={e => submit(e, oldPassword, newPassword, confirmNewPassword)}
    >
      <MDBCol md="6">
        <MDBInput
          value={oldPassword}
          getValue={handleOldPassword}
          id="old-password"
          label="Old Password"
          outline
          required
          containerClass="required-label"
          className="input-select"
          disabled={!userCanEdit}
          type="password"
        />
      </MDBCol>
      <MDBCol md="6">
        <MDBInput
          value={newPassword}
          getValue={handleNewPassword}
          id="new-password"
          label="New Password"
          outline
          required
          containerClass="required-label"
          className="input-select"
          disabled={!userCanEdit}
          type="password"
        />
      </MDBCol>
      <MDBCol md="6">
        <MDBInput
          value={confirmNewPassword}
          getValue={handleConfirmNewPassword}
          id="confirm-new-password"
          label="Confirm New Password"
          outline
          required
          containerClass="required-label"
          className="input-select"
          disabled={!userCanEdit}
          type="password"
        />
      </MDBCol>
      <MDBCol className="d-flex justify-content-right">
        <MDBBtn type="submit" color="primary">
          {btnText}
        </MDBBtn>
      </MDBCol>
      <Panel messages={errors} backgroundColor="red" textColor="white" />
      <Panel messages={success} backgroundColor="green" textColor="white" />
      {!userCanEdit && (
        <Panel
          messages={["Users can only reset their own password."]}
          backgroundColor="red"
          textColor="white"
        />
      )}
    </form>
  );
};

export default ProfileForm;
