import { useWindowWidth } from '@react-hook/window-size'
import { fetchContractLocations } from 'actions/contracts/contractLocationActions'
import {
  requestReport,
  clearReport,
  fetchAndWaitForReport,
} from 'actions/reportActions'
import { fetchSitesForSelect } from 'actions/sites/siteActions'
import { fetchStudies } from 'actions/studies/studyActions'
import Section from 'components/common/Section'
import Select from 'components/common/forms/Select'
import { saveAs } from 'file-saver'
import { useAuth } from 'hooks/useAuth.js'
import { useGetReportsData } from 'hooks/useGetReportsData'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBInputGroup, MDBBtn, MDBSpinner } from 'mdbreact'
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isUserGuest, isUserAdmin } from 'utils/authUtils'

const ALL_ID = 0

const Report = () => {
  //// HOOKS.
  const auth = useAuth()
  const dispatch = useDispatch()
  const windowWidth = useWindowWidth()
  const {
    availableStudyReports,
    availableAllStudyReports,
    availableStudyReportsAdmin,
    availableContractLocationReports,
  } = useGetReportsData()

  //// GLOBAL STATE.
  const { reportRequestId, initiatedReport, reportResult } = useSelector(
    (state) => state.reports
  )

  //// LOCAL STATE.
  const [reportsOptions, setReportsOptions] = React.useState({})

  //// HELPERS.

  //// MEMOS.
  const [isAdmin, isGuest] = useMemo(
    () => [
      isUserAdmin(auth?.user?.user_level),
      isUserGuest(auth?.user?.user_level),
    ],
    [auth?.user?.user_level]
  )

  //// EFFECTS.
  // watch for report complete
  useEffect(() => {
    if (reportRequestId) dispatch(fetchAndWaitForReport(reportRequestId))
  }, [reportRequestId, dispatch])

  // populates the list of studies and contract locations
  useEffect(() => {
    if (Object.keys(reportResult).length === 0) {
      dispatch(fetchStudies('form'))
      dispatch(fetchSitesForSelect())
      dispatch(fetchContractLocations('form', null, true))
    }
  }, [reportResult, dispatch])

  // check on the status of the requested report until it's ready to download, then save it
  useEffect(() => {
    if (reportResult && reportResult.status === 'success') {
      const reportToDownload = new Blob([reportResult.data], {
        type: reportResult.data.type,
      })
      saveAs(
        reportToDownload,
        reportResult.headers['content-disposition']
          ? reportResult.headers['content-disposition'].split('"')[1]
          : 'report.xlsx'
      )
      dispatch(clearReport())
    }
  }, [dispatch, reportResult])

  // not sure if this part is needed?
  useEffect(() => {
    return () => {
      dispatch(clearReport())
    }
  }, [dispatch])

  //// RENDER VARS.

  //// RENDER.
  const renderSelectReportForm = (report, subject) => {
    return (
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault()
          const studySelected =
            reportsOptions[report.reportId]?.sponsor_study_name ||
            reportsOptions[report.reportId]?.contract_location

          const siteSelected = reportsOptions[report.reportId]?.site

          const requestParams = {
            study_ids: !studySelected?.length
              ? undefined
              : // if empty or any id is for "all" send id for "all".
                studySelected.some(({ id }) => id === ALL_ID)
                ? [ALL_ID]
                : // if there are multiple send the ids as arrays
                  studySelected.map(({ id }) => id),
            site_ids: !siteSelected?.length
              ? undefined
              : // if there are multiple send the ids as arrays
                siteSelected.map(({ id }) => id),
          }
          // if only one id send study_id.
          dispatch(requestReport(report.reportId, requestParams))
        }}
      >
        <MDBRow>
          <MDBCol>
            {!initiatedReport || reportResult.status === 'success' ? (
              <MDBInputGroup
                style={{
                  display: 'grid',
                  gridAutoFlow: windowWidth >= 768 ? 'column' : 'row',
                  gridRowGap: '1em',
                  gridColumnGap: '2em',
                }}
                inputs={
                  <>
                    {report.selects.map(({ label, options, isMultiSelect }) => {
                      const rid = report.reportId
                      const stateKey = label.replaceAll(' ', '_').toLowerCase()
                      // checking to see if the other selects for this report have any values
                      const isDisabled = Object.values(
                        _.omit(reportsOptions?.[rid], stateKey)
                      ).some((x) => x?.length)
                      return (
                        <Select
                          id={`select__${label}--${rid}`}
                          key={`select__${label}--${rid}`}
                          options={options}
                          label={label}
                          defaultOption={`Choose A ${label}`}
                          required
                          search
                          disabled={isDisabled}
                          multiple={isMultiSelect}
                          getValue={(newValues) =>
                            setReportsOptions((x) => ({
                              ...x,
                              [rid]: {
                                ...(x?.[rid] || {}),
                                [stateKey]: options.filter(({ value }) =>
                                  newValues.includes(value)
                                ),
                              },
                            }))
                          }
                        />
                      )
                    })}
                    <MDBBtn
                      style={{
                        justifySelf: 'end',
                        margin: '0px',
                        borderRadius: '0px',
                      }}
                      className="offset-md-3"
                      size="sm"
                      type="submit"
                      color="primary"
                      disabled={Object.values(
                        reportsOptions[[report.reportId]] ?? {}
                      ).every((x) => !x?.length)}
                    >
                      Generate report
                    </MDBBtn>
                  </>
                }
              />
            ) : (
              <MDBSpinner style={{ margin: 'auto' }} />
            )}
          </MDBCol>
        </MDBRow>
      </form>
    )
  }

  // all studies reports
  const handleAllStudyReportRequest = (e, reportId) => {
    e.preventDefault()
    dispatch(requestReport(reportId, {}))
  }
  const renderAllStudyReportForm = (report) => {
    return (
      <form
        className="form"
        onSubmit={(e) => handleAllStudyReportRequest(e, report.reportId)}
      >
        <MDBRow>
          <MDBCol>
            {!initiatedReport || reportResult.status === 'success' ? (
              <MDBBtn
                className="offset-md-3"
                size="sm"
                type="submit"
                color="primary"
                disabled={false}
                style={{ height: '40px', float: 'right' }}
              >
                Generate report
              </MDBBtn>
            ) : (
              <MDBSpinner style={{ margin: 'auto' }} />
            )}
          </MDBCol>
        </MDBRow>
      </form>
    )
  }

  return auth?.user ? (
    <>
      {availableStudyReports
        .filter((report) => !report?.isAdminOnly || isAdmin)
        .map((report) => {
          return (
            <Section
              key={report.reportId}
              header={report.name}
              description={report.description}
            >
              {renderSelectReportForm(report, 'Study')}
            </Section>
          )
        })}
      {!isGuest
        ? availableAllStudyReports.map((report) => {
            return (
              <Section
                key={report.reportId}
                header={report.name}
                description={report.description}
              >
                {renderAllStudyReportForm(report)}
              </Section>
            )
          })
        : ''}
      {isAdmin
        ? availableStudyReportsAdmin.map((report) => {
            return (
              <Section
                key={report.reportId}
                header={report.name}
                description={report.description}
              >
                {renderSelectReportForm(report, 'Study')}
              </Section>
            )
          })
        : ''}
      {!isGuest
        ? availableContractLocationReports.map((report) => {
            return (
              <Section
                key={report.reportId}
                header={report.name}
                description={report.description}
              >
                {renderSelectReportForm(report, 'Contract Location')}
              </Section>
            )
          })
        : ''}
    </>
  ) : (
    <MDBSpinner />
  )
}

export default Report
