import {
  srDocumentsColumnsAtom,
  srDocumentsFilterParamsAtom,
  srDocumentsQueryParamsAtom,
  srDocumentsReloadAtom,
  srDocumentsTableDataAtom,
  srDocumentsViewParamsAtom,
} from './SrDocumentTable'
import {
  createSrDocumentView,
  deleteSrDocumentView,
  fetchSrDocumentViews,
  updateSrDocumentView,
} from 'actions/srDocuments/srDocumentViewActions'
import Modal from 'components/common/Modal'
import Select from 'components/common/forms/Select'
import useModal from 'hooks/useModal'
import { useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import { MDBCol, MDBRow, MDBInput, MDBBtn } from 'mdbreact'
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectObject,
  propertiesToMultiSelectOptions,
  findSelected,
} from 'utils/formUtils'

const CREATE_HEADER = 'Create Site Readiness Document View'
const DELETE_HEADER = 'Delete Site Readiness Document View'
const CREATE_FLAG = 'create'
const DELETE_FLAG = 'delete'

const SrDocumentView = ({ editable, defaultView }) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    actions,
    setActions,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const [
    setSrDocumentsReload,
    setSrDocumentsViewParams,
    setSrDocumentsColumns,
    srDocumentsFilterParams,
    srDocumentsQueryParams,
    srDocumentsTableData,
  ] = [
    useSetAtom(srDocumentsReloadAtom),
    useSetAtom(srDocumentsViewParamsAtom),
    useSetAtom(srDocumentsColumnsAtom),
    useAtomValue(srDocumentsFilterParamsAtom),
    useAtomValue(srDocumentsQueryParamsAtom),
    useAtomValue(srDocumentsTableDataAtom),
  ]

  const { srDocumentViewsAvailable } = useSelector((state) => {
    return state.srDocumentViews
  })

  //// LOCAL STATE.
  const [newViewName, setNewViewName] = useState('')
  const [viewOptions, setViewOptions] = useState([])
  const [dropDownSelection, setDropDownSelection] = useState(defaultView || {})

  //// MEMOS.
  const hasQueryParams = useMemo(
    () => Object.values(srDocumentsFilterParams).flat().length === 0,
    [srDocumentsFilterParams]
  )

  //// EFFECTS.
  useEffect(() => {
    const dropDownOptions = propertiesToMultiSelectOptions(
      selectObject(srDocumentViewsAvailable)
    )

    if (dropDownSelection?.id || defaultView?.id) {
      const updatedDrownDownOptions = dropDownOptions.map((option) => {
        if (dropDownSelection?.id?.toString() === option.value) {
          return {
            ...option,
            checked: true,
          }
        } else if (defaultView?.id.toString() === option.value) {
          return {
            ...option,
            checked: true,
          }
        }
        return {
          ...option,
          checked: false,
        }
      })
      setViewOptions(updatedDrownDownOptions)
    } else {
      setViewOptions(dropDownOptions)
    }
    // eslint-disable-next-line
  }, [srDocumentViewsAvailable, defaultView])

  //// FUNCTIONS.
  const toggleModal = (e, type) => {
    if (e) e.preventDefault()

    if (type === CREATE_FLAG) {
      setHeaderText(CREATE_HEADER)
      setFooter(true)
      setActions([
        {
          text: 'Create',
          color: 'primary',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
    }

    if (type === DELETE_FLAG) {
      setHeaderText(DELETE_HEADER)
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  const defaultActiveClass = () => {
    const selectedView = findSelected(viewOptions)
    if (selectedView[0].value === dropDownSelection.id) return 'active'

    return ''
  }

  //// RENDER.
  return (
    <MDBRow
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: editable ? '980px' : '2000px',
      }}
    >
      {/* SELECT-SRDOC-VIEW */}
      <MDBCol lg="4" md="6" sm="12">
        <Select
          id="select__views"
          options={viewOptions}
          label="Select a View"
          defaultOption="Select A View"
          disabled={_.isEmpty(viewOptions)}
          search
          getValue={(e) => {
            // views without all options set, set missing options to []
            const completeSelectionParams = {}
            Object.keys(srDocumentsFilterParams).forEach((param) => {
              if (srDocumentViewsAvailable[e]?.selection_params[param]) {
                completeSelectionParams[param] =
                  srDocumentViewsAvailable[e].selection_params[param]
              } else {
                completeSelectionParams[param] = []
              }
            })
            if (e.length) {
              // if no column set in view use columns already set on table
              const columnSelection =
                srDocumentViewsAvailable?.[e]?.column_params ??
                srDocumentsTableData.columns.map((col) => col.field)

              if (setSrDocumentsColumns) setSrDocumentsColumns(columnSelection)
              setDropDownSelection(srDocumentViewsAvailable[e])
              setSrDocumentsViewParams(completeSelectionParams)
            } else {
              setDropDownSelection({})
              setSrDocumentsViewParams({})
            }
          }}
        />
      </MDBCol>

      {/* SRDOC-VIEW-CONTROLS */}
      <MDBCol lg="8" md="6" sm="12">
        {editable ? (
          <>
            {/* UPDATE-VIEW */}
            <MDBBtn
              disabled={_.isEmpty(dropDownSelection)}
              size="sm"
              color="secondary"
              onClick={async (e) => {
                e.preventDefault()

                const tableColumnNames = srDocumentsTableData.columns
                  .map((column) => column.field)
                  .join(',')

                const srDocumentParams = {
                  id: dropDownSelection.id,
                  name: dropDownSelection.name,
                  url_params: srDocumentsQueryParams,
                  selection_params: JSON.stringify(srDocumentsFilterParams),
                  column_params: tableColumnNames,
                }
                await dispatch(updateSrDocumentView(srDocumentParams, {}))
                await dispatch(fetchSrDocumentViews())
              }}
            >
              Update View
            </MDBBtn>

            {/* CREATE-VIEW */}
            <MDBBtn
              size="sm"
              color="secondary"
              disabled={hasQueryParams}
              onClick={(e) => toggleModal(e, CREATE_FLAG)}
            >
              Create View
            </MDBBtn>

            {/* MAKE-DEFAULT-VIEW */}
            <MDBBtn
              className={defaultActiveClass}
              size="sm"
              color="secondary"
              disabled={_.isEmpty(dropDownSelection)}
              onClick={async (e) => {
                e.preventDefault()

                setDropDownSelection({
                  ...dropDownSelection,
                  is_primary: true,
                })
                const params = {
                  id: dropDownSelection.id,
                  is_primary: true,
                }

                await dispatch(updateSrDocumentView(params, dropDownSelection))
              }}
            >
              Make Default
            </MDBBtn>

            {/* DELETE-VIEW */}
            <MDBBtn
              disabled={_.isEmpty(dropDownSelection)}
              size="sm"
              color="secondary"
              onClick={(e) => toggleModal(e, DELETE_FLAG)}
            >
              Delete View
            </MDBBtn>
          </>
        ) : (
          void 0
        )}

        {/* REFRESH-VIEW */}
        <MDBBtn
          className={defaultActiveClass}
          size="sm"
          color="secondary"
          onClick={() => {
            setSrDocumentsReload(true)
          }}
        >
          Refresh
        </MDBBtn>
      </MDBCol>

      {/* SRDOC-VIEW-CONTROLS-MODAL */}
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={true}
        actionButtons={actions}
      >
        {headerText === CREATE_HEADER ? (
          <form
            id={formId}
            className="form"
            noValidate
            onSubmit={async (e) => {
              e.preventDefault()

              const tableColumnNames = srDocumentsTableData.columns
                .map((column) => column.field)
                .join(',')

              const srDocumentViewParams = {
                name: newViewName,
                url_params: srDocumentsQueryParams,
                selection_params: JSON.stringify(srDocumentsFilterParams),
                column_params: tableColumnNames,
              }
              await dispatch(createSrDocumentView(srDocumentViewParams))
              await dispatch(fetchSrDocumentViews())
              setNewViewName('')
              toggleModal()
            }}
          >
            <MDBInput
              value={newViewName}
              getValue={(e) => {
                setNewViewName(e)
              }}
              id="name"
              label="Name"
              outline
              containerClass="required-label"
              required
              className="input-select"
            />
          </form>
        ) : headerText === DELETE_HEADER ? (
          <form
            id={formId}
            className="form"
            noValidate
            onSubmit={async (e) => {
              e.preventDefault()

              const srDocumentViewParams = {
                id: dropDownSelection.id,
              }

              await dispatch(deleteSrDocumentView(srDocumentViewParams))
              await dispatch(fetchSrDocumentViews())
              setNewViewName('')
              toggleModal()
            }}
          >
            <span>
              Are you sure you want to delete the view{' '}
              {
                srDocumentViewsAvailable?.[dropDownSelection?.id?.toString()]
                  ?.name
              }
              ?
            </span>
          </form>
        ) : (
          void 0
        )}
      </Modal>
    </MDBRow>
  )
}

export default SrDocumentView
