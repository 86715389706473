import restApi from "apis/restApi";
import {
  SET_CONTACTS,
  SET_CONTACT_OPTIONS,
  UNSET_CONTACT_OPTIONS,
  CREATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  TOGGLE_CONTACT,
  ERROR_CONTACT,
  UNSET_CONTACT_ACTION,
  UNSET_ERROR_CONTACT
} from "actions/types";
import history from "../../history";
import { fetchToken } from "utils/authUtils";

const contactEndPoint = "/contact";

// Pass data to reducers
export const setContact = (obj, type, contact) => {
  const action = { type: type, payload: obj };
  if (contact) action.currentContact = contact;
  return action;
};

// Non-API Calls
export const clearContactOptions = () => async dispatch => {
  dispatch(setContact([], UNSET_CONTACT_OPTIONS));
};
export const fetchExistingContacts = contact => async dispatch => {
  dispatch(setContact([], SET_CONTACT_OPTIONS, contact));
};
export const clearContactAction = () => async dispatch => {
  dispatch(setContact({}, UNSET_CONTACT_ACTION));
};
export const clearContactErrors = () => async dispatch => {
  dispatch(setContact({}, UNSET_ERROR_CONTACT));
};

// API Calls
export const fetchContacts = (results, contact) => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(contactEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    if (results === "list") {
      dispatch(setContact(res.data, SET_CONTACTS));
    } else if (results === "form") {
      dispatch(setContact(res.data, SET_CONTACT_OPTIONS, contact));
    }
  }
};

export const createContact = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contactEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContact(res.data, CREATE_CONTACT));
      history.push("/contacts");
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setContact(error, ERROR_CONTACT));
    }
  }
};

export const deleteContact = contact => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(contactEndPoint, {
      data: { id: contact.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContact(res.data, DELETE_CONTACT, contact));
  }
};

export const updateContact = (params, contact) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contactEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContact(res.data, UPDATE_CONTACT, contact));
      history.push(`/contacts/${params.id}`);
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setContact(error, ERROR_CONTACT));
    }
  }
};

export const toggleContact = (contact, toggle) => async dispatch => {
  const toggleEndPoint = `${contactEndPoint}/${toggle ? "enable" : "disable"}`;
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: contact.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContact({ res, toggle: toggle }, TOGGLE_CONTACT, contact));
  }
};
