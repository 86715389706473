import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createSiteProperty,
  clearSitePropertyErrors,
  clearSitePropertyAction
} from "actions/sites/sitePropertyActions";
import { fetchCustomProperties } from "actions/properties/customPropertyActions";

import CustomPropertyForm from "components/common/properties/CustomPropertyForm";

const SitePropertyCreate = ({ formId, params, setParams, closeModal }) => {
  const dispatch = useDispatch();

  const { lastAction, propertyErrors } = useSelector(
    state => state.siteProperties
  );
  const customProperties = useSelector(
    state => state.customProperties.propertiesAvailable
  );

  useEffect(() => {
    if (_.isEmpty(customProperties)) {
      dispatch(fetchCustomProperties());
    }
  }, [dispatch, customProperties]);

  useEffect(() => {
    return () => {
      dispatch(clearSitePropertyErrors());
      dispatch(clearSitePropertyAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        property_id: null,
        required: false
      });
    }
  }, [params, setParams]);

  // watch for success
  useEffect(() => {
    if (lastAction === "create") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();

    dispatch(createSiteProperty(createParams));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        {_.size(customProperties) ? (
          <CustomPropertyForm
            create={true}
            customProperties={customProperties}
            params={params}
            setParams={setParams}
            errors={propertyErrors}
          />
        ) : (
          ""
        )}
      </form>
    );
  }

  return "";
};

export default SitePropertyCreate;
