import _ from "lodash";
import moment from "moment";

import { selectObject } from "utils/formUtils";
import {
  UNSET_STUDY_CONTRACT_VALUE_ACTION,
  SET_STUDY_CONTRACT_VALUES,
  UNSET_STUDY_CONTRACT_VALUES,
  UPDATE_STUDY_CONTRACT_VALUE,
  CREATE_STUDY_CONTRACT_VALUE,
  ERROR_STUDY_CONTRACT_VALUE,
  UNSET_ERROR_STUDY_CONTRACT_VALUE,
  UPDATE_STUDY_CONTRACT_VALUE_COUNTRY_OPTIONS
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  contractValuesAvailable: null,
  contractValueErrors: [],
  countryOptions: []
};

// mapper to make contract values easier to work with
const contractValueMapper = payload => {
  const result = _.mapKeys(payload, "id");
  Object.keys(result).forEach(k => {
    result[k].values = _.mapKeys(result[k].values, "contract_type.id");
  });
  return result;
};

// Maps new contract value object
const newContractValuesMapper = created_items => {
  const {
    id,
    country,
    study,
    customer_id,
    created_at,
    updated_at
  } = created_items[0];
  const result = {
    id,
    customer_id,
    country,
    study,
    created_at,
    updated_at,
    values: {}
  };
  created_items.forEach(item => {
    const { contract_type, amount } = item;
    result.values[contract_type.id] = {
      contract_type,
      amount
    };
  });
  return result;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STUDY_CONTRACT_VALUES:
      return {
        ...state,
        lastAction: "fetch",
        contractValuesAvailable: contractValueMapper(action.payload)
      };
    case UNSET_STUDY_CONTRACT_VALUES:
      return {
        ...state,
        contractValuesAvailable: {}
      };
    case UNSET_STUDY_CONTRACT_VALUE_ACTION:
      return {
        ...state,
        lastAction: null
      };
    case UNSET_ERROR_STUDY_CONTRACT_VALUE:
      return {
        ...state,
        contractValueErrors: []
      };
    case CREATE_STUDY_CONTRACT_VALUE:
      const createdContractValues = newContractValuesMapper(
        action.payload.created_items
      );
      const { id } = createdContractValues;
      const newState = {
        ...state,
        lastAction: "create",
        contractValuesAvailable: {
          ...state.contractValuesAvailable,
          [id]: createdContractValues
        }
      };
      return newState;
    case UPDATE_STUDY_CONTRACT_VALUE_COUNTRY_OPTIONS:
      const contractValues = state.contractValuesAvailable;
      // country id will not be null;
      const selectedCountryId =
        action.currentContractValue?.country?.id || null;
      const existingCountryIds = [];
      Object.keys(contractValues).forEach(v => {
        const contractCountryId = contractValues[v].country.id;
        if (contractCountryId !== selectedCountryId) {
          existingCountryIds.push(contractCountryId);
        }
      });
      const filtered_countries = action.payload.filter(country => {
        return !existingCountryIds.includes(country.id);
      });

      const options = selectObject(
        filtered_countries,
        selectedCountryId ? selectedCountryId : 0
      );
      return {
        ...state,
        countryOptions: options
      };
    case UPDATE_STUDY_CONTRACT_VALUE:
      const updatedItem = (state.contractValuesAvailable[
        action.currentContractValue.id
      ] = action.currentContractValue);

      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        contractValuesAvailable: {
          ...state.contractValuesAvailable,
          [action.currentContractValue.id]: updatedItem
        }
      };
    case ERROR_STUDY_CONTRACT_VALUE:
      return {
        ...state,
        contractValueErrors: action.payload,
        lastAction: "error"
      };
    default:
      return state;
  }
};
