import {
  clearSrDocumentTypeAction,
  clearSrDocumentTypeErrors,
  createSrDocumentType,
} from 'actions/srDocuments/srDocumentTypeActions'
import SrAdminForm from 'components/admin/SrAdminForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const SrDocumentTypeCreate = ({ formId, params, setParams, closeModal }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, typeErrors } = useSelector(
    (state) => state.srDocumentTypes
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearSrDocumentTypeErrors())
      dispatch(clearSrDocumentTypeAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        name: '',
      })
    }
  }, [params, setParams])

  // watch for success
  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        dispatch(createSrDocumentType(params))
      }}
      className="form container"
    >
      <SrAdminForm params={params} setParams={setParams} errors={typeErrors} />
    </form>
  )
}

export default SrDocumentTypeCreate
