import React from "react";

import restApi from "apis/restApi";

import { fetchToken } from "utils/authUtils";

const resetPassword = async id =>
  await restApi.post(
    "users/resetPassword",
    { id },
    {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    }
  );

const resetMFA = async id =>
  await restApi.post(
    "users/resetMFA",
    { id },
    {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    }
  );

const UserReset = ({
  formId,
  closeModal,
  selected,
  setPassResetMessage,
  shouldResetPassword = false,
  shouldResetMFA = false
}) => {
  const handleReset = async e => {
    e.preventDefault();

    if (shouldResetPassword) resetPassword(selected.id);

    if (shouldResetMFA) resetMFA(selected.id);

    setPassResetMessage(true);
    closeModal();
  };

  const subjects = [];

  if (shouldResetPassword) subjects.push("password");

  if (shouldResetMFA) subjects.push("MFA");

  return (
    <form id={formId} onSubmit={e => handleReset(e)} className="form container">
      <div>
        Are you sure you want to reset this user's {subjects.join(" and ")}?
        This can not be undone.
      </div>
    </form>
  );
};

export default UserReset;
