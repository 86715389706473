import restApi from "apis/restApi";
import {
  SET_CUSTOM_PROPERTIES,
  CREATE_CUSTOM_PROPERTY,
  DELETE_CUSTOM_PROPERTY,
  UPDATE_CUSTOM_PROPERTY,
  ERROR_CUSTOM_PROPERTY,
  UNSET_CUSTOM_PROPERTY_ACTION,
  UNSET_ERROR_CUSTOM_PROPERTY
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const customPropertyEndPoint = "/property";

// Passing data back to reducers
const setCustomProperty = (obj, type, customProperty) => {
  const action = { type: type, payload: obj };
  if (customProperty) action.currentCustomProperty = customProperty;
  return action;
};

// Non-API calls
export const clearCustomPropertyAction = () => async dispatch => {
  dispatch(setCustomProperty({}, UNSET_CUSTOM_PROPERTY_ACTION));
};
export const clearCustomPropertyErrors = () => async dispatch => {
  dispatch(setCustomProperty({}, UNSET_ERROR_CUSTOM_PROPERTY));
};

// API calls
export const fetchCustomProperties = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(customPropertyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setCustomProperty(res.data, SET_CUSTOM_PROPERTIES));
  }
};

export const createCustomProperty = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(customPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setCustomProperty(res.data, CREATE_CUSTOM_PROPERTY));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setCustomProperty(error, ERROR_CUSTOM_PROPERTY));
    }
  }
};

export const deleteCustomProperty = property => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(customPropertyEndPoint, {
      data: { id: property.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setCustomProperty(res.data, DELETE_CUSTOM_PROPERTY, property));
  }
};

export const updateCustomProperty = (params, property) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(customPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setCustomProperty(res.data, UPDATE_CUSTOM_PROPERTY, property));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setCustomProperty(error, ERROR_CUSTOM_PROPERTY));
    }
  }
};
