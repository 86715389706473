import CustomPropertyDateOnly from './inputs/CustomPropertyDateOnly'
import CustomPropertyDateTime from './inputs/CustomPropertyDateTime'
import CustomPropertyInput from './inputs/CustomPropertyInput'
import CustomPropertySelect from './inputs/CustomPropertySelect'
import useRouter from 'hooks/useRouter'
import _ from 'lodash'
import { MDBRow, MDBCol } from 'mdbreact'
import React, { useEffect, useState, useRef, useMemo } from 'react'

const CustomPropertyManagement = ({
  params,
  setParams,
  current,
  propertyOptions,
  customProperties,
  setCustomProperties,
  contractTypeSelection,
  srDocumentTypeSelection,
}) => {
  //// HOOKS.
  const { pathname } = useRouter()
  const paramsRef = useRef(params)

  //// GLOBAL STATE.

  //// LOCAL STATE.
  const [loading, setLoading] = useState(true)

  //// LIFECYCLE HELPERS.
  const getPropertyItemType = (propertyType) => {
    if (propertyType.is_list) {
      return 'list'
    }
    return propertyType.code
  }

  //// MEMOS.
  const siteSection = useMemo(
    () =>
      pathname.includes('contracts')
        ? 'contract'
        : pathname.includes('sr-documents')
          ? 'sr_document'
          : pathname.includes('contacts')
            ? 'contact'
            : pathname.includes('sites')
              ? 'site'
              : pathname.includes('studies')
                ? 'study'
                : '',
    [pathname]
  )

  //// EFFECTS.
  useEffect(() => {
    setLoading(true)
    if (_.size(propertyOptions)) {
      const newParams = paramsRef.current
      for (const item of propertyOptions) {
        const id = item?.id || item?.[`${siteSection}_property_id`]
        if (id) {
          switch (getPropertyItemType(item.property.property_type)) {
            case 'list':
              newParams[`id_${id}`] = current
                ? current.custom_properties?.[item.property.name]?.list_id
                : null
              break
            default:
              newParams[`id_${id}`] = current
                ? current.custom_properties?.[item.property.name]?.value
                : null
          }
        }
      }
      setParams(newParams)
      setLoading(false)
    }
  }, [propertyOptions, current, setParams, setLoading, siteSection])

  //// RENDER VARS & HELPERS.

  //// RENDER.

  if (_.isEmpty(propertyOptions) || loading) return ''
  return (
    <MDBCol md="12">
      <hr className="mt-3 mb-3" />
      <h3 className="h6 text-muted mb-3">Custom Properties</h3>

      <MDBRow>
        {propertyOptions.map((option) => {
          const id = option?.id || option?.[`${siteSection}_property_id`]
          if (
            params[`id_${id}`] === undefined ||
            (contractTypeSelection !== undefined &&
              option.contract_type_id !== 0 &&
              option.contract_type_id !== contractTypeSelection) ||
            (srDocumentTypeSelection !== undefined &&
              option.sr_document_type_id !== 0 &&
              option.sr_document_type_id !== srDocumentTypeSelection)
          ) {
            return false
          }

          const propertyType = getPropertyItemType(
            option.property.property_type
          )

          return propertyType === 'list' ? (
            <MDBCol md="6" key={`property-${id}-select`}>
              <CustomPropertySelect
                item={option}
                handleSelectChange={(value, item) => {
                  if (
                    value !== `Choose From ${item.property.property_type.name}`
                  ) {
                    let selection = item.property.options.filter(
                      (option) => option.value === value
                    )
                    const id = item?.id || item?.[`${siteSection}_property_id`]
                    setParams({ ...params, [`id_${id}`]: selection[0].id })
                    const selectedValue =
                      item.property.property_type.code === 'boolean'
                        ? selection[0].value === 'True'
                        : selection[0].value
                    if (customProperties && current) {
                      setCustomProperties((prev) => ({
                        ...prev,
                        [item.property.name]: {
                          list_id: selection[0].id,
                          [`${siteSection}_property_id`]: id,
                          value: selectedValue,
                        },
                      }))
                    }
                  }
                }}
                current={current}
              />
            </MDBCol>
          ) : propertyType === 'date' || propertyType === 'datetime' ? (
            <MDBCol md="6" key={`property-${id}-date`}>
              {option.property.property_type.code === 'datetime' ? (
                <CustomPropertyDateTime
                  currentValue={params[`id_${id}`] || ''}
                  item={option}
                  params={params}
                  setParams={setParams}
                  current={current ? current : false}
                  customProperties={customProperties}
                  setCustomProperties={setCustomProperties}
                  siteSection={siteSection}
                />
              ) : (
                <CustomPropertyDateOnly
                  currentValue={params[`id_${id}`] || ''}
                  item={option}
                  params={params}
                  setParams={setParams}
                  current={current ? current : false}
                  customProperties={customProperties}
                  setCustomProperties={setCustomProperties}
                  siteSection={siteSection}
                />
              )}
            </MDBCol>
          ) : (
            <MDBCol md="6" key={`property-${id}-input`}>
              <CustomPropertyInput
                currentValue={params[`id_${id}`] || ''}
                item={option}
                handleInputChange={(value, item) => {
                  const id = item?.id || item?.[`${siteSection}_property_id`]
                  setParams({ ...params, [`id_${id}`]: value })
                  if (current && customProperties) {
                    setCustomProperties((prev) => ({
                      ...prev,
                      [item.property.name]: {
                        [`${siteSection}_property_id`]: id,
                        value: value,
                      },
                    }))
                  }
                }}
              />
            </MDBCol>
          )
        })}
      </MDBRow>
    </MDBCol>
  )
}

export default CustomPropertyManagement
