import {
  SET_SITES,
  SET_SITE_OPTIONS,
  UNSET_SITE_OPTIONS,
  CREATE_SITE,
  DELETE_SITE,
  UPDATE_SITE,
  TOGGLE_SITE,
  ERROR_SITE,
  UNSET_ERROR_SITE,
  UNSET_SITE_ACTION,
  SET_SITES_SELECTABLES,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  sitesAvailable: {},
  siteOptions: [],
  siteErrors: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SITES:
      return {
        ...state,
        lastAction: 'fetch',
        sitesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_SITE_OPTIONS:
      const siteObject = _.isEmpty(state.sitesAvailable)
        ? showAvailable(action.payload)
        : state.sitesAvailable
      const options = selectObject(
        siteObject,
        action.currentSite ? action.currentSite.id : 0,
        true
      )
      return { ...state, lastAction: 'fetch', siteOptions: options }
    case SET_SITES_SELECTABLES:
      return {
        ...state,
        lastAction: 'fetch',
        sitesSelectables: action.payload,
      }
    case UNSET_SITE_OPTIONS:
      if (state.siteOptions.length) {
        return { ...state, siteOptions: [] }
      }
      return state
    case CREATE_SITE:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        sitesAvailable: {
          ...state.sitesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SITE:
      const updatedItem = (state.sitesAvailable[action.currentSite.id] =
        action.currentSite)

      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return { ...state, lastAction: 'edit', updatedItem }
    case ERROR_SITE:
      return {
        ...state,
        siteErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SITE:
      return {
        ...state,
        siteErrors: {},
      }
    case UNSET_SITE_ACTION:
      return { ...state, lastAction: null }
    case TOGGLE_SITE:
      const toggledItem = (state.sitesAvailable[action.currentSite.id] =
        action.currentSite)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_SITE:
      let updatedSites = state.sitesAvailable
      _.unset(updatedSites, `${action.currentSite.id}`)
      return {
        ...state,
        lastAction: 'delete',
        sitesAvailable: updatedSites,
      }
    default:
      return state
  }
}
