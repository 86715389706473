import { clearSrDocumentErrors } from 'actions/srDocuments/srDocumentActions'
import {
  fetchSrDocumentProperties,
  fetchSrDocumentPropertyOptions,
} from 'actions/srDocuments/srDocumentPropertyActions'
import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import CustomPropertyManagement from 'components/common/properties/CustomPropertyManagement'
import _ from 'lodash'
import { MDBRow, MDBCol } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showAvailable, showAvailableByType } from 'utils/commonUtils'
import { findSelected, selectArray } from 'utils/formUtils'

const SrDocumentForm = ({
  srDocument,
  submit,
  errors,
  states,
  customProperties,
  setCustomProperties,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { srDocumentPropertyOptions } = useSelector(
    (state) => state.srDocumentProperties
  )

  //// LOCAL STATE.
  const [srDocumentStandardOptions, setSrDocumentStandardOptions] = useState({})
  const [currentType, setCurrentType] = useState(
    srDocument?.sr_document_type ? srDocument.sr_document_type.id : 0
  )
  const [srDocumentParams, setSrDocumentParams] = useState({
    study_id: srDocument?.study ? srDocument.study.id : null,
    sr_document_type_id: srDocument?.sr_document_type
      ? srDocument.sr_document_type.id
      : null,
    sr_document_subtype_id: srDocument?.sr_document_subtype
      ? srDocument.sr_document_subtype.id
      : null,
    // is_budget: srDocument ? srDocument.is_budget : false,
    currency_id: srDocument?.currency ? srDocument.currency.id : null,
    current_state: srDocument ? srDocument.current_state : undefined,
    primary_investigator_id: srDocument?.primary_investigator
      ? srDocument.primary_investigator.id
      : null,
    primary_site_id: srDocument?.primary_site
      ? srDocument.primary_site.id
      : null,
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchSrDocumentPropertyOptions())
    return () => {
      // dispatch(clearSrDocumentStates())
      dispatch(clearSrDocumentErrors())
      dispatch(fetchSrDocumentProperties())
    }
  }, [dispatch])

  useEffect(() => {
    if (
      _.isEmpty(srDocumentStandardOptions) &&
      _.size(srDocumentPropertyOptions)
    ) {
      setSrDocumentStandardOptions({
        study: selectArray(
          showAvailable(srDocumentPropertyOptions.study_id),
          srDocument?.study?.id || 0
        ),
        srDocumentType: selectArray(
          showAvailable(srDocumentPropertyOptions.sr_document_type_id),
          srDocument?.sr_document_type?.id || 0
        ),
        srDocumentSubtype: selectArray(
          showAvailableByType(
            srDocumentPropertyOptions.sr_document_subtype_id,
            srDocument?.sr_document_type?.id
          ),
          srDocument?.sr_document_subtype?.id
        ),
        currency: selectArray(
          showAvailable(srDocumentPropertyOptions.currency_id),
          srDocument?.currency?.id || 0
        ),
        primaryInvestigator: selectArray(
          showAvailable(srDocumentPropertyOptions.primary_investigator_id),
          srDocument?.primary_investigator?.id || 0
        ),
        primarySite: selectArray(
          showAvailable(srDocumentPropertyOptions.primary_site_id),
          srDocument?.primary_site?.id || 0
        ),
      })
    }
  }, [
    srDocumentPropertyOptions,
    srDocumentStandardOptions,
    setSrDocumentStandardOptions,
    srDocumentParams,
    srDocument,
  ])

  //// RENDER VARS & HELPERS.
  const selectedSrDocumentType = findSelected(
    srDocumentStandardOptions.srDocumentType
  )

  //// RENDER.

  return _.isEmpty(srDocumentStandardOptions) ? (
    ''
  ) : (
    <form
      id="form_sr_document"
      className="form row"
      noValidate
      onSubmit={(e) =>
        submit(e, srDocumentParams, srDocumentStandardOptions, states)
      }
    >
      <MDBCol md="6">
        {/* Site Readiness Document State is being hidden for now as it isn't likely to be used. */}
        {/* {!srDocument || !states.length ? (
          ''
        ) : (
          <Select
            id="select__state"
            options={states}
            label="Site Readiness Document State"
            defaultOption="Choose Site Readiness Document State"
            required
            errors={errors}
          />
        )} */}
        <Select
          id="select__studies"
          options={srDocumentStandardOptions?.study}
          label="Sponsor Study Name"
          defaultOption="Choose A Sponsor Study Name"
          search
          required
          disabled={srDocument?.study?.id ? true : false}
          errors={errors}
        />
        <Select
          id="select__sr-document-types"
          options={srDocumentStandardOptions?.srDocumentType}
          label="Site Readiness Document Type"
          defaultOption="Choose A Type"
          getTextContent={(value) => {
            if (value !== 'Choose A Type') {
              const selectedSrDocumentType = findSelected(
                srDocumentStandardOptions.srDocumentType
              )
              if (selectedSrDocumentType[0].id) {
                setCurrentType(selectedSrDocumentType[0].id)
                setSrDocumentStandardOptions({
                  ...srDocumentStandardOptions,
                  srDocumentSubtype: selectArray(
                    showAvailableByType(
                      srDocumentPropertyOptions.sr_document_subtype_id,
                      selectedSrDocumentType[0].id
                    )
                  ),
                })
              }
            }
          }}
          search
          required
          errors={errors}
        />
        {srDocumentStandardOptions.srDocumentSubtype <= 0 ||
        (srDocument?.sr_document_subtype?.id &&
          srDocument.sr_document_subtype.id === 0) ? (
          <>
            <Select
              options={[]}
              label="Site Readiness Document Subtype"
              defaultOption="Choose A Subtype"
              disabled
            />
            {!(selectedSrDocumentType[0] && selectedSrDocumentType[0].id) ? (
              ''
            ) : (
              <p className="input-warning text-muted">
                No subtypes associated with this type.
              </p>
            )}
          </>
        ) : (
          <Select
            id="select__contact-subtypes"
            options={srDocumentStandardOptions.srDocumentSubtype}
            label="Site Readiness Document Subtype"
            defaultOption="Choose A Subtype"
            search
            required
            errors={errors}
          />
        )}
      </MDBCol>
      <MDBCol md="6">
        <MDBRow className="align-items-center my-4 py-2">
          {/* <MDBCol md="4">
            <MDBInput
              label="Set Budget"
              type="checkbox"
              id="is-budget__checkbox"
              checked={srDocumentParams.is_budget}
              value="Site Readiness Document Budget"
              onChange={(event) => {
                const { checked } = event.target
                if (checked !== srDocumentParams.is_budget) {
                  setSrDocumentParams({
                    ...srDocumentParams,
                    is_budget: checked,
                  })
                }
              }}
            />
          </MDBCol>
          <MDBCol md="8" className="currency-selection">
            <Select
              id="select__currency"
              options={srDocumentStandardOptions?.currency}
              label="Currency"
              defaultOption="Choose Currency"
              required
              disabled={srDocumentParams.is_budget ? false : true}
              errors={errors}
              search
            />
          </MDBCol> */}
        </MDBRow>
        <Select
          id="select__contact"
          options={srDocumentStandardOptions?.primaryInvestigator}
          label="Primary Investigator"
          defaultOption="Choose A Primary Investigator"
          search
        />
        <Select
          id="select__site"
          options={srDocumentStandardOptions?.primarySite}
          label="Primary Site"
          defaultOption="Choose A Primary Site"
          search
        />
      </MDBCol>
      <CustomPropertyManagement
        params={srDocumentParams}
        setParams={setSrDocumentParams}
        current={srDocument ? srDocument : false}
        propertyOptions={srDocumentPropertyOptions?.custom_properties}
        srDocumentTypeSelection={currentType}
        customProperties={customProperties || false}
        setCustomProperties={setCustomProperties || undefined}
      />
      <ErrorPanel results={errors} />
    </form>
  )
}

export default SrDocumentForm
