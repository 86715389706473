import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createCustomProperty,
  clearCustomPropertyErrors,
  clearCustomPropertyAction
} from "actions/properties/customPropertyActions";
import { fetchCustomPropertyType } from "actions/properties/customPropertyTypeActions";

import CustomPropertyForm from "./CustomPropertyForm";

const CustomPropertyCreate = ({ formId, params, setParams, closeModal }) => {
  const { lastAction, propertyErrors } = useSelector(
    state => state.customProperties
  );
  const { customPropertyTypes } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomPropertyType());
    return () => {
      dispatch(clearCustomPropertyErrors());
      dispatch(clearCustomPropertyAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        name: "",
        property_type_id: null
      });
    }
  }, [params, setParams]);

  // watch for success
  useEffect(() => {
    if (lastAction === "create") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();
    dispatch(createCustomProperty(createParams));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        <CustomPropertyForm
          params={params}
          setParams={setParams}
          propertyTypes={customPropertyTypes}
          errors={propertyErrors}
        />
      </form>
    );
  }

  return "";
};

export default CustomPropertyCreate;
