import React from "react";
import { MDBInput } from "mdbreact";

const ComponentPropertyInput = ({ currentValue, item, handleInputChange }) => {
  return (
    <MDBInput
      value={currentValue}
      getValue={value => handleInputChange(value, item)}
      id={`property-${item.id}-input`}
      label={item.property.name}
      type={item.property.property_type.code || "text"}
      outline
      required={item.required}
      containerClass={item.required ? "required-label" : ""}
      className="input-select"
    />
  );
};

export default ComponentPropertyInput;
