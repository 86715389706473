import {
  clearSrDocumentDocAction,
  deleteSrDocumentDoc,
} from 'actions/srDocuments/srDocumentDocActions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const SrDocumentDocDelete = ({ formId, closeModal, selected }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearSrDocumentDocAction())
    }
  }, [dispatch])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(deleteSrDocumentDoc(selected))
        closeModal()
      }}
      className="form container"
    >
      <div>
        Are you sure you want to delete this site readiness document file? This
        can not be undone.
      </div>
    </form>
  )
}

export default SrDocumentDocDelete
