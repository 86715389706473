import _ from "lodash";
import moment from "moment";
import { showAvailable } from "utils/commonUtils";
import { selectObject } from "utils/formUtils";
import {
  SET_CONTACTS,
  SET_CONTACT_OPTIONS,
  UNSET_CONTACT_OPTIONS,
  CREATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  TOGGLE_CONTACT,
  ERROR_CONTACT,
  UNSET_ERROR_CONTACT,
  UNSET_CONTACT_ACTION
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  contactsAvailable: {},
  contactOptions: [],
  contactErrors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      return {
        ...state,
        lastAction: "fetch",
        contactsAvailable: _.mapKeys(action.payload, "id")
      };
    case SET_CONTACT_OPTIONS:
      const contactObject = _.isEmpty(state.contactsAvailable)
        ? showAvailable(action.payload)
        : state.contactsAvailable;
      const options = selectObject(
        contactObject,
        action.currentContact ? action.currentContact.id : 0,
        true
      );
      return { ...state, contactOptions: options };
    case UNSET_CONTACT_OPTIONS:
      if (state.contactOptions.length) {
        return { ...state, contactOptions: [] };
      }
      return state;
    case CREATE_CONTACT:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        contactsAvailable: {
          ...state.contactsAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_CONTACT:
      const updatedItem = (state.contactsAvailable[action.currentContact.id] =
        action.currentContact);

      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return { ...state, lastAction: "edit", updatedItem };
    case ERROR_CONTACT:
      return {
        ...state,
        contactErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_CONTACT:
      return {
        ...state,
        contactErrors: {}
      };
    case UNSET_CONTACT_ACTION:
      return { ...state, lastAction: null };
    case TOGGLE_CONTACT:
      const toggledItem = (state.contactsAvailable[action.currentContact.id] =
        action.currentContact);
      toggledItem.is_available = action.payload.toggle;
      return {
        ...state,
        lastAction: action.payload.toggle ? "enable" : "disable",
        toggledItem
      };
    case DELETE_CONTACT:
      let updatedContacts = state.contactsAvailable;
      _.unset(updatedContacts, `${action.currentContact.id}`);
      return {
        ...state,
        lastAction: "delete",
        contactsAvailable: updatedContacts
      };
    default:
      return state;
  }
};
