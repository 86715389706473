import ContractCreate from 'components/contracts/ContractCreate'
import ContractList from 'components/contracts/ContractList'
import ContractShow from 'components/contracts/ContractShow'
import ContractUpdate from 'components/contracts/ContractUpdate'
import ContractDocumentTypeHistory from 'components/contracts/documents/ContractDocumentTypeHistory'
import { useAuth } from 'hooks/useAuth.js'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { isUserGuest, isUserNotReadOnlyForContracts } from 'utils/authUtils'

const ContractRoutes = () => {
  //// HOOKS.
  const { user } = useAuth() ?? {}
  const { user_level } = user ?? {}

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <Switch>
      <Route exact path="/contracts" component={ContractList} />
      {isUserNotReadOnlyForContracts(user_level) && (
        <Route path="/contracts/new" component={ContractCreate} />
      )}
      <Route exact path="/contracts/:id" component={ContractShow} />
      {isUserNotReadOnlyForContracts(user_level) && (
        <Route path="/contracts/:id/edit" component={ContractUpdate} />
      )}
      {!isUserGuest(user_level) && (
        <Route
          exact
          path="/contracts/:contractId/document-types/:documentTypeId"
          component={ContractDocumentTypeHistory}
        />
      )}
    </Switch>
  )
}

export default ContractRoutes
