import _ from "lodash";
import moment from "moment";

import {
  SET_CUSTOM_PROPERTY_LIST_ITEMS,
  CREATE_CUSTOM_PROPERTY_LIST_ITEM,
  DELETE_CUSTOM_PROPERTY_LIST_ITEM,
  UNSET_CUSTOM_PROPERTY_LIST_ITEM_ACTION,
  UPDATE_CUSTOM_PROPERTY_LIST_ITEM,
  TOGGLE_CUSTOM_PROPERTY_LIST_ITEM,
  ERROR_CUSTOM_PROPERTY_LIST_ITEM,
  UNSET_ERROR_CUSTOM_PROPERTY_LIST_ITEM
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  listItemsAvailable: {},
  listItemErrors: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CUSTOM_PROPERTY_LIST_ITEMS:
      return {
        ...state,
        lastAction: "fetch",
        listItemsAvailable: _.mapKeys(action.payload, "id")
      };
    case UNSET_CUSTOM_PROPERTY_LIST_ITEM_ACTION:
      return { ...state, lastAction: null };
    case CREATE_CUSTOM_PROPERTY_LIST_ITEM:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        listItemsAvailable: {
          ...state.listItemsAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_CUSTOM_PROPERTY_LIST_ITEM:
      const updatedItem = (state.listItemsAvailable[
        action.currentCustomPropertyListItem.id
      ] = action.currentCustomPropertyListItem);
      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        updatedItem
      };
    case ERROR_CUSTOM_PROPERTY_LIST_ITEM:
      return {
        ...state,
        listItemErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_CUSTOM_PROPERTY_LIST_ITEM:
      return {
        ...state,
        listItemErrors: {}
      };
    case TOGGLE_CUSTOM_PROPERTY_LIST_ITEM:
      const toggledItem = (state.listItemsAvailable[
        action.currentCustomPropertyListItem.id
      ] = action.currentCustomPropertyListItem);
      toggledItem.is_available = action.payload.toggle;
      return {
        ...state,
        lastAction: action.payload.toggle ? "enable" : "disable",
        toggledItem
      };
    case DELETE_CUSTOM_PROPERTY_LIST_ITEM:
      const updated = state.listItemsAvailable;
      _.unset(updated, `${action.currentCustomPropertyListItem.id}`);
      return { ...state, lastAction: "delete", listItemsAvailable: updated };
    default:
      return state;
  }
};
