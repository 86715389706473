import {
  SET_CONTRACT_VIEWS,
  UNSET_CONTRACT_VIEWS,
  CREATE_CONTRACT_VIEW,
  DELETE_CONTRACT_VIEW,
  UPDATE_CONTRACT_VIEW,
  ERROR_CONTRACT_VIEW,
  UNSET_CONTRACT_VIEW_ACTION,
  UNSET_ERROR_CONTRACT_VIEW,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  contractViewsAvailable: {},
  contractViewErrors: {},
}

const contractViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_VIEWS:
      return {
        ...state,
        lastAction: 'fetch',
        contractViewsAvailable: _.mapKeys(action.payload, 'id'),
      }
    case UNSET_CONTRACT_VIEWS:
      return INITIAL_STATE
    case CREATE_CONTRACT_VIEW:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        contractViewsAvailable: {
          ...state.contractViewsAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_VIEW:
      const updatedItem = (state.contractViewsAvailable[
        action.currentContractView.id
      ] = action.currentContractView)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_VIEW:
      return {
        ...state,
        contractViewErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_VIEW:
      return {
        ...state,
        contractViewErrors: {},
      }
    case UNSET_CONTRACT_VIEW_ACTION:
      return { ...state, lastAction: null }
    case DELETE_CONTRACT_VIEW:
      let updatedContractViews = state.contractViewsAvailable
      _.unset(updatedContractViews, `${action.currentContractView.id}`)
      return {
        ...state,
        lastAction: 'delete',
        contractViewsAvailable: updatedContractViews,
      }
    default:
      return state
  }
}

export default contractViewReducer
