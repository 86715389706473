import _ from "lodash";
import moment from "moment";
import { showAvailable } from "utils/commonUtils";
import { selectObject } from "utils/formUtils";
import {
  SET_STUDIES,
  SET_STUDY_OPTIONS,
  UNSET_STUDY_OPTIONS,
  CREATE_STUDY,
  DELETE_STUDY,
  UPDATE_STUDY,
  TOGGLE_STUDY,
  ERROR_STUDY,
  UNSET_ERROR_STUDY,
  UNSET_STUDY_ACTION
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  studiesAvailable: {},
  studyOptions: [],
  studyErrors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STUDIES:
      return {
        ...state,
        lastAction: "fetch",
        studiesAvailable: _.mapKeys(action.payload, "id")
      };
    case SET_STUDY_OPTIONS:
      const studyObject = _.isEmpty(state.studiesAvailable)
        ? showAvailable(action.payload)
        : state.studiesAvailable;
      const options = selectObject(
        studyObject,
        action.currentStudy ? action.currentStudy.id : 0
      );
      return { ...state, studyOptions: options };
    case UNSET_STUDY_OPTIONS:
      if (state.studyOptions.length) {
        return { ...state, studyOptions: [] };
      }
      return state;
    case CREATE_STUDY:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        studiesAvailable: {
          ...state.studiesAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_STUDY:
      const updatedItem = (state.studiesAvailable[action.currentStudy.id] =
        action.currentStudy);

      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return { ...state, lastAction: "edit", updatedItem };
    case ERROR_STUDY:
      return {
        ...state,
        studyErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_STUDY:
      return {
        ...state,
        studyErrors: {}
      };
    case UNSET_STUDY_ACTION:
      return { ...state, lastAction: null };
    case TOGGLE_STUDY:
      const toggledItem = (state.studiesAvailable[action.currentStudy.id] =
        action.currentStudy);
      toggledItem.is_available = action.payload.toggle;
      return {
        ...state,
        lastAction: action.payload.toggle ? "enable" : "disable",
        toggledItem
      };
    case DELETE_STUDY:
      let updatedStudies = state.studiesAvailable;
      _.unset(updatedStudies, `${action.currentStudy.id}`);
      return {
        ...state,
        lastAction: "delete",
        studiesAvailable: updatedStudies
      };
    default:
      return state;
  }
};
