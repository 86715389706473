import restApi from "apis/restApi";
import {
  SET_STUDY_PROPERTIES,
  SET_STUDY_PROPERTY_OPTIONS,
  UNSET_STUDY_PROPERTY_OPTIONS,
  CREATE_STUDY_PROPERTY,
  DELETE_STUDY_PROPERTY,
  UPDATE_STUDY_PROPERTY,
  ERROR_STUDY_PROPERTY,
  UNSET_STUDY_PROPERTY_ACTION,
  UNSET_ERROR_STUDY_PROPERTY
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const studyPropertyEndPoint = "/property/custom/study";

// Passing data back to reducers
const setStudyProperty = (obj, type, studyProperty) => {
  const action = { type: type, payload: obj };
  if (studyProperty) action.currentStudyProperty = studyProperty;
  return action;
};

// Non-API calls
export const clearStudyPropertyOptions = () => async dispatch => {
  dispatch(setStudyProperty({}, UNSET_STUDY_PROPERTY_OPTIONS));
};
export const clearStudyPropertyAction = () => async dispatch => {
  dispatch(setStudyProperty({}, UNSET_STUDY_PROPERTY_ACTION));
};
export const clearStudyPropertyErrors = () => async dispatch => {
  dispatch(setStudyProperty({}, UNSET_ERROR_STUDY_PROPERTY));
};

// API calls
export const fetchStudyProperties = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(studyPropertyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setStudyProperty(res.data, SET_STUDY_PROPERTIES));
  }
};

export const fetchStudyPropertyOptions = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${studyPropertyEndPoint}/options`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setStudyProperty(res.data, SET_STUDY_PROPERTY_OPTIONS));
  }
};

export const createStudyProperty = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(studyPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setStudyProperty(res.data, CREATE_STUDY_PROPERTY));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setStudyProperty(error, ERROR_STUDY_PROPERTY));
    }
  }
};

export const deleteStudyProperty = property => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(studyPropertyEndPoint, {
      data: { id: property.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setStudyProperty(res.data, DELETE_STUDY_PROPERTY, property));
  }
};

export const updateStudyProperty = (params, property) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(studyPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setStudyProperty(res.data, UPDATE_STUDY_PROPERTY, property));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setStudyProperty(error, ERROR_STUDY_PROPERTY));
    }
  }
};
