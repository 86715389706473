import React from "react";
import { MDBSelect } from "mdbreact";

import { findSelected, inputErrorClass } from "utils/formUtils";

const Select = ({
  options,
  id,
  multiple,
  search,
  required,
  disabled,
  label,
  defaultOption,
  errors,
  getValue,
  getTextContent,
  additionalClasses,
  style
}) => {
  const setFocus = e => {
    if (search && e.target.classList.contains("select-dropdown")) {
      const searchInput = e.target.parentElement.querySelector(
        ".md-form .form-control"
      );

      setTimeout(() => {
        searchInput.focus();
      }, 100);
    }
  };

  const handleDisableClass = () => {
    if (disabled) return "disabled";

    return "";
  };
  const handleRequireClass = () => {
    if (required && !disabled) return "required-label";

    return "";
  };
  const handleErrorClass = () => {
    if (required && errors && !disabled) {
      return inputErrorClass(
        errors,
        !disabled && findSelected(options).length === 0
      );
    }
    return "";
  };

  if (!options) return "";

  return (
    <MDBSelect
      multiple={multiple ?? false}
      search={search ?? false}
      id={id ?? "select-field"}
      style={style ?? undefined}
      onClick={setFocus}
      getTextContent={getTextContent ?? undefined}
      getValue={getValue ?? undefined}
      outline
      color="primary"
      options={options}
      label={label}
      selected={defaultOption}
      className={`input-select ${handleDisableClass()} ${handleRequireClass()} ${handleErrorClass()} ${
        additionalClasses ?? ""
      }`}
      required={required ?? false}
      disabled={disabled ?? false}
    />
  );
};

export default Select;
