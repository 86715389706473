import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createBudgetVisit,
  clearBudgetVisitAction
} from "actions/budgetVisitActions";

import AdminForm from "components/admin/AdminForm";

const BudgetVisitCreate = ({ formId, params, setParams, closeModal }) => {
  const { lastAction, budgetVisitErrors } = useSelector(
    state => state.budgetVisits
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        name: ""
      });
    }
  }, [params, setParams]);

  // watch for success
  useEffect(() => {
    if (lastAction === "create") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  useEffect(() => {
    return () => {
      dispatch(clearBudgetVisitAction());
    };
  }, [dispatch]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();

    dispatch(createBudgetVisit(createParams));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
      >
        <AdminForm
          params={params}
          setParams={setParams}
          errors={budgetVisitErrors}
        />
      </form>
    );
  }

  return "";
};

export default BudgetVisitCreate;
