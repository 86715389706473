import history from '../../history'
import {
  clearSrDocumentAction,
  deleteSrDocument,
} from 'actions/srDocuments/srDocumentActions'
import useRouter from 'hooks/useRouter'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const SrDocumentDelete = ({ formId, closeModal, selected }) => {
  //// HOOKS.
  const routingParams = useRouter()
  const dispatch = useDispatch()

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearSrDocumentAction())
    }
  }, [dispatch])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(deleteSrDocument(selected))
        if (
          routingParams.match.path === '/sr-documents/:id' ||
          routingParams.match.path === '/sr-documents/:id/edit'
        ) {
          history.push('/sr-documents')
        } else {
          closeModal()
        }
      }}
      className="form container"
    >
      <div>
        Are you sure you want to delete this Site Readiness Document? This can
        not be undone.
      </div>
    </form>
  )
}

export default SrDocumentDelete
