import {
  SET_CONTRACT_LOCATIONS,
  SET_CONTRACT_LOCATION_OPTIONS,
  UNSET_CONTRACT_LOCATION_OPTIONS,
  CREATE_CONTRACT_LOCATION,
  DELETE_CONTRACT_LOCATION,
  UNSET_CONTRACT_LOCATION_ACTION,
  UPDATE_CONTRACT_LOCATION,
  TOGGLE_CONTRACT_LOCATION,
  ERROR_CONTRACT_LOCATION,
  UNSET_ERROR_CONTRACT_LOCATION,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  locationsAvailable: {},
  locationOptions: [],
  locationErrors: [],
}

const contractLocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_LOCATIONS:
      return {
        ...state,
        lastAction: 'fetch',
        locationsAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_CONTRACT_LOCATION_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentContractLocation ? action.currentContractLocation.id : 0,
        action.noDefaultOptions ? false : true,
        'All Locations'
      )
      return { ...state, locationOptions: options }
    case UNSET_CONTRACT_LOCATION_OPTIONS:
      if (state.locationOptions) {
        return { ...state, locationOptions: [] }
      }
      return state
    case UNSET_CONTRACT_LOCATION_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_LOCATION:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        locationsAvailable: {
          ...state.locationsAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_LOCATION:
      const updatedItem = (state.locationsAvailable[
        action.currentContractLocation.id
      ] = action.currentContractLocation)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_LOCATION:
      return {
        ...state,
        locationErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_LOCATION:
      return {
        ...state,
        locationErrors: {},
      }
    case TOGGLE_CONTRACT_LOCATION:
      const toggledItem = (state.locationsAvailable[
        action.currentContractLocation.id
      ] = action.currentContractLocation)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_CONTRACT_LOCATION:
      const updated = state.locationsAvailable
      _.unset(updated, `${action.currentContractLocation.id}`)
      return { ...state, lastAction: 'delete', locationsAvailable: updated }
    default:
      return state
  }
}

export default contractLocationReducer
