import React, { useState } from "react";
import { useTheme } from "emotion-theming";

import { useAuth } from "hooks/useAuth";
import useWindowSize from "hooks/useWindowSize";

import SideNav from "./SideNav/SideNav";
import TopNav from "./TopNav";

import Logo from "assets/images/logo_combomark.png";
import LogoLight from "assets/images/logo_combomark_white_orange.png";

const SiteNav = () => {
  const auth = useAuth();
  const size = useWindowSize();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    const toggle = !isOpen;
    setIsOpen(toggle);
  };

  return (
    <>
      <header>
        <TopNav
          auth={auth}
          size={size}
          screens={theme.screens}
          openSideBar={handleToggle}
          logo={Logo}
        />
      </header>
      <SideNav
        auth={auth}
        theme={theme}
        size={size}
        isOpen={isOpen}
        logo={LogoLight}
      />
    </>
  );
};

export default SiteNav;
