import { useState } from "react";

const useModal = (
  initialMode = false,
  initialHeaderText = "",
  initialFormId = "modal-form",
  initialFooter = false,
  initialSelected = null,
  initialParams = {},
  initialActions = []
) => {
  const [modalOpen, setModalOpen] = useState(initialMode);
  const [headerText, setHeaderText] = useState(initialHeaderText);
  const [formId, setFormId] = useState(initialFormId);
  const [footer, setFooter] = useState(initialFooter);
  const [params, setParams] = useState(initialParams);
  const [selected, setSelected] = useState(initialSelected);
  const [actions, setActions] = useState(initialActions);
  const setModalState = state => {
    setModalOpen(state);
    if (state === false) {
      setSelected(initialSelected);
      setParams(initialParams);
      setHeaderText(initialHeaderText);
      setFooter(initialFooter);
      setActions(initialActions);
    }
  };
  return {
    modalOpen,
    setModalOpen,
    headerText,
    setHeaderText,
    formId,
    setFormId,
    footer,
    setFooter,
    params,
    setParams,
    selected,
    setSelected,
    actions,
    setActions,
    setModalState
  };
};

export default useModal;
