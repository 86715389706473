import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBCardBody } from "mdbreact";

import useModal from "hooks/useModal";
import {
  fetchUsers,
  toggleUser,
  clearUserAction
} from "actions/users/userActions";

import Table from "components/common/tables/Table";
import Modal from "components/common/Modal";
import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import UserCreate from "./UserCreate";
import UserUpdate from "./UserUpdate";
import UserDelete from "./UserDelete";

const UserList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState
  } = useModal();
  const dispatch = useDispatch();

  // redux hooks
  const { usersAvailable, lastAction } = useSelector(state => state.users);

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "#",
        field: "id",
        sort: "desc"
      },
      {
        label: "Name",
        field: "last_name",
        appendField: "first_name",
        sort: "desc"
      },
      {
        label: "Email",
        field: "email",
        sort: "desc"
      },
      {
        label: "Date Joined (UTC)",
        field: "created_at",
        date: true,
        sort: "desc"
      },
      {
        label: "Role",
        field: "user_level",
        snakeCase: true,
        sort: "desc"
      },
      {
        label: "Active",
        field: "is_active",
        toggleControl: true,
        sort: "disabled"
      },
      {
        label: "",
        field: "buttons",
        sort: "disabled",
        minimal: "lg"
      }
    ]
  });

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault();
      if (type === "new") {
        setHeaderText("New User");
        setFooter(true);
        setActions([
          {
            text: "Submit",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
      } else if (type === "edit") {
        setHeaderText("Edit User");
        setFooter(true);
        setActions([
          {
            text: "Update",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
        setSelected(item);
      } else if (type === "delete") {
        setHeaderText("Delete User");
        setFooter(true);
        setActions([
          {
            text: "Delete",
            color: "danger",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
        setSelected(item);
      }
      if (type) {
        setModalState(true);
      } else {
        setModalState(false);
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  );

  const fetchTableLink = useCallback((type, item) => {
    switch (type) {
      case "show":
        return `/admin/users/${item.id}`;
      case "list":
        return `/admin/users/${item.id}/studies`;
      default:
        return null;
    }
  }, []);

  const handleAvailabilityChange = async (item, toggle) => {
    await dispatch(toggleUser(item, toggle));
    dispatch(clearUserAction());
  };

  // use effect setups
  useEffect(() => {
    dispatch(fetchUsers("list"));
  }, [dispatch]);

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: "Create",
        url: `/admin/users`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: e => toggleModal(e, "new"),
          className: "btn-sm"
        }
      }
    ]
  };

  const renderModalBody = () => {
    if (headerText === "New User") {
      return (
        <UserCreate
          formId={formId}
          params={params}
          setParams={setParams}
          closeModal={() => setModalState(false)}
        />
      );
    } else if (headerText === "Edit User") {
      return (
        <UserUpdate
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => setModalState(false)}
        />
      );
    } else if (headerText === "Delete User") {
      return (
        <UserDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      );
    }

    return "";
  };

  const renderTable = () => {
    if (_.size(usersAvailable)) {
      return (
        <Table
          section="User"
          listTitle="Manange Available Studies"
          tableData={tableData}
          setTableData={setTableData}
          rowData={usersAvailable}
          sort={["id", "desc"]}
          fetchTableLink={fetchTableLink}
          toggleAvailability={handleAvailabilityChange}
          toggleModal={toggleModal}
          updatedState={lastAction}
          clickableRow
          downloadableCSV
          showButton
          editableListButton
          editButton
          deleteButton
        />
      );
    } else if (lastAction === "fetch" && _.isEmpty(usersAvailable)) {
      return <p>No users available.</p>;
    }

    return "";
  };

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>{renderTable()}</MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  );
};

export default UserList;
