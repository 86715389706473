import {
  CHART_SR_DOCUMENT_ACTIVITY_BY_LOCATION,
  CHART_SR_DOCUMENT_ACTIVITY_CLEAR_ACTION,
  CHART_SR_DOCUMENT_ACTIVITY_CLEAR_BY_LOCATION,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentTimeByLocation = '/stats/sr-document/location'

// Passing data back to reducers
export const setSrDocumentActivityChart = (obj, type) => {
  const action = { type: type, payload: obj }
  return action
}

// clear state
export const clearSrDocumentActivityChartAction = () => async (dispatch) => {
  dispatch(
    setSrDocumentActivityChart({}, CHART_SR_DOCUMENT_ACTIVITY_CLEAR_ACTION)
  )
}

export const clearSrDocumentActivityByLocation = () => async (dispatch) => {
  dispatch(
    setSrDocumentActivityChart({}, CHART_SR_DOCUMENT_ACTIVITY_CLEAR_BY_LOCATION)
  )
}

// API calls
export const displaySrDocumentActivityByLocation = (id) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${srDocumentTimeByLocation}/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentActivityChart(
        res.data,
        CHART_SR_DOCUMENT_ACTIVITY_BY_LOCATION
      )
    )
  }
}
