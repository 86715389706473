import _ from "lodash";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { createStudy } from "actions/studies/studyActions";

import Section from "components/common/Section";
import StudyForm from "./StudyForm";

const StudyCreate = () => {
  const dispatch = useDispatch();

  const { studyErrors } = useSelector(state => state.studies);

  const handleSubmission = (e, params) => {
    e.preventDefault();
    const studyParams = _.pickBy(params, _.identity);

    dispatch(createStudy(studyParams));
  };

  return (
    <Section
      header="New Study"
      description="Change inputs to create a new study"
      breadcrumbs
      footer={[
        {
          text: "Submit",
          color: "primary",
          additionalProps: {
            form: "form_study",
            type: "submit"
          }
        }
      ]}
    >
      <StudyForm submit={handleSubmission} errors={studyErrors} />
    </Section>
  );
};

export default StudyCreate;
