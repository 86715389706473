import history from '../../history'
import {
  deleteContract,
  clearContractAction,
} from 'actions/contracts/contractActions'
import useRouter from 'hooks/useRouter'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const ContractDelete = ({ formId, closeModal, selected }) => {
  //// HOOKS.
  const routingParams = useRouter()
  const dispatch = useDispatch()

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearContractAction())
    }
  }, [dispatch])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(deleteContract(selected))
        if (
          routingParams.match.path === '/contracts/:id' ||
          routingParams.match.path === '/contracts/:id/edit'
        ) {
          history.push('/contracts')
        } else {
          closeModal()
        }
      }}
      className="form container"
    >
      <div>
        Are you sure you want to delete this contract? This can not be undone.
      </div>
    </form>
  )
}

export default ContractDelete
