import {
  SET_CONTRACT_DOCUMENT_TYPES,
  SET_CONTRACT_DOCUMENT_TYPE_OPTIONS,
  UNSET_CONTRACT_DOCUMENT_TYPE_OPTIONS,
  CREATE_CONTRACT_DOCUMENT_TYPE,
  DELETE_CONTRACT_DOCUMENT_TYPE,
  UNSET_CONTRACT_DOCUMENT_TYPE_ACTION,
  UPDATE_CONTRACT_DOCUMENT_TYPE,
  TOGGLE_CONTRACT_DOCUMENT_TYPE,
  ERROR_CONTRACT_DOCUMENT_TYPE,
  UNSET_ERROR_CONTRACT_DOCUMENT_TYPE,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  documentTypesAvailable: {},
  documentTypeOptions: [],
  documentTypeErrors: [],
}

const contractDocumentTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_DOCUMENT_TYPES:
      return {
        ...state,
        lastAction: 'fetch',
        documentTypesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_CONTRACT_DOCUMENT_TYPE_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentContractDocumentType
          ? action.currentContractDocumentType.id
          : 0
      )
      return { ...state, documentTypeOptions: options }
    case UNSET_CONTRACT_DOCUMENT_TYPE_OPTIONS:
      if (state.documentTypeOptions) {
        return { ...state, documentTypeOptions: [] }
      }
      return state
    case UNSET_CONTRACT_DOCUMENT_TYPE_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_DOCUMENT_TYPE:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        documentTypesAvailable: {
          ...state.documentTypesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_DOCUMENT_TYPE:
      const updatedItem = (state.documentTypesAvailable[
        action.currentContractDocumentType.id
      ] = action.currentContractDocumentType)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypeErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypeErrors: {},
      }
    case TOGGLE_CONTRACT_DOCUMENT_TYPE:
      const toggledItem = (state.documentTypesAvailable[
        action.currentContractDocumentType.id
      ] = action.currentContractDocumentType)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_CONTRACT_DOCUMENT_TYPE:
      const updated = state.documentTypesAvailable
      _.unset(updated, `${action.currentContractDocumentType.id}`)
      return {
        ...state,
        lastAction: 'delete',
        documentTypesAvailable: updated,
      }
    default:
      return state
  }
}

export default contractDocumentTypeReducer
