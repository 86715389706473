import {
  CREATE_CONTRACT_VIEW,
  DELETE_CONTRACT_VIEW,
  ERROR_CONTRACT_VIEW,
  SET_CONTRACT_VIEWS,
  UNSET_CONTRACT_VIEWS,
  UNSET_CONTRACT_VIEW_ACTION,
  UNSET_ERROR_CONTRACT_VIEW,
  UPDATE_CONTRACT_VIEW,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractViewEndPoint = '/user/view/contract'

// Passing data back to reducers
const setContractView = (obj, type, contractView) => {
  const action = { type: type, payload: obj }
  if (contractView) action.currentContractView = contractView
  return action
}

// Non-API calls
export const clearContractViews = () => async (dispatch) => {
  dispatch(setContractView({}, UNSET_CONTRACT_VIEWS))
}
export const clearContractViewErrors = () => async (dispatch) => {
  dispatch(setContractView({}, UNSET_ERROR_CONTRACT_VIEW))
}
export const clearContractViewAction = () => async (dispatch) => {
  dispatch(setContractView({}, UNSET_CONTRACT_VIEW_ACTION))
}

// API calls
// pass an array of query parameters for filtering
export const fetchContractViews = () => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(contractViewEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractView(res.data, SET_CONTRACT_VIEWS))
  }
}

export const createContractView = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractViewEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractView(res.data, CREATE_CONTRACT_VIEW))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractView(error, ERROR_CONTRACT_VIEW))
    }
  }
}

export const deleteContractView = (contractView) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractViewEndPoint, {
      data: { id: contractView.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractView(res.data, DELETE_CONTRACT_VIEW, contractView))
  }
}

export const updateContractView =
  (params, contractView) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractViewEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(setContractView(res.data, UPDATE_CONTRACT_VIEW, contractView))
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractView(error, ERROR_CONTRACT_VIEW))
      }
    }
  }
