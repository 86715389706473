import moment from "moment";
import {
  REQUEST_REPORT,
  SET_REPORT,
  INITIATE_REPORT,
  CLEAR_REPORT
} from "actions/types";

const INITIAL_STATE = {
  reportRequestId: null,
  reportResult: {},
  initiatedReport: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INITIATE_REPORT:
      return { ...state, initiatedReport: true };
    case REQUEST_REPORT:
      return { ...state, reportRequestId: action.payload.report_request_id };
    case SET_REPORT:
      if (
        action.payload &&
        action.payload.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const timeStamp = moment().format("YYYY-MM-DDTHH:mm:ss");

        return {
          ...state,
          reportResult: {
            status: "success",
            timeStamp: timeStamp,
            data: action.payload,
            headers: action.headers
          }
        };
      }
      return { ...state, reportResult: action.payload };
    case CLEAR_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
