import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAuth } from "hooks/useAuth.js";
import { fetchContacts } from "actions/contacts/contactActions";

import ContactRoutes from "components/routes/ContactRoutes";

const ContactHome = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.user) {
      dispatch(fetchContacts("list"));
    }
  }, [dispatch, auth]);

  return <ContactRoutes />;
};

export default ContactHome;
