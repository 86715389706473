export default {
  colors: {
    primary: "#f88b5e",
    secondary: "#2d3848",
    gray: "#999",
    lightGray: "#f7f7f7",
    lightest: "#fff",
    red: "#ff3547"
  },
  screens: {
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1200
  }
};
