import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createContact } from "actions/contacts/contactActions";
import { fetchCountries } from "actions/countryActions";
import { fetchSites } from "actions/sites/siteActions";

import Section from "components/common/Section";
import ContactForm from "./ContactForm";

import { findSelected } from "utils/formUtils";

const ContactCreate = () => {
  const { contactErrors } = useSelector(state => state.contacts);
  const { countryOptions } = useSelector(state => state.countries);
  const { siteOptions, lastAction: lastSiteAction } = useSelector(
    state => state.sites
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchSites("form"));
  }, [dispatch]);

  const handleSubmission = (e, params, countries, sites) => {
    e.preventDefault();
    const countrySelected = findSelected(countries);
    const siteSelected = findSelected(sites);
    const contactParams = _.pickBy(params, _.identity);

    if (countrySelected.length > 0) {
      contactParams.country_id = countrySelected[0].id;
    }

    if (siteSelected.length > 0) {
      contactParams.org_id = siteSelected[0].id;
    }

    dispatch(createContact(contactParams));
  };

  return (
    <Section
      header="New Contact"
      description="Fill in form to create a new contact"
      breadcrumbs
      footer={[
        {
          text: "Submit",
          color: "primary",
          additionalProps: {
            form: "form_contact",
            type: "submit"
          }
        }
      ]}
    >
      <ContactForm
        countries={countryOptions}
        sites={siteOptions}
        lastSiteAction={lastSiteAction}
        submit={handleSubmission}
        errors={contactErrors}
      />
    </Section>
  );
};

export default ContactCreate;
