import restApi from "apis/restApi";
import {
  REQUEST_DATA_IMPORTS,
  UPLOAD_REQUEST_DATA_IMPORTS,
  SET_DATA_IMPORTS,
  SET_SINGLE_DATA_IMPORT,
  UNSET_DATA_IMPORTS,
  UNSET_DATA_IMPORT_STATUS,
  CREATE_DATA_IMPORT,
  ERROR_DATA_IMPORT,
  UNSET_ERROR_DATA_IMPORT
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const dataImportEndPoint = "/import";

// Passing data back to reducers
export const setDataImport = (obj, type, ...args) => ({
  type: type,
  payload: obj,
  ...args
});

// Non-API calls
export const requestDataImports = () => dispatch => {
  dispatch(setDataImport(null, REQUEST_DATA_IMPORTS));
};
export const clearDataImports = () => dispatch => {
  dispatch(setDataImport(null, UNSET_DATA_IMPORTS));
};
export const clearDataImportStatus = () => dispatch => {
  dispatch(setDataImport(null, UNSET_DATA_IMPORT_STATUS));
};
export const clearDataImportErrors = () => dispatch => {
  dispatch(setDataImport(null, UNSET_ERROR_DATA_IMPORT));
};

// API calls
export const fetchDataImports = id => async dispatch => {
  dispatch(setDataImport(null, REQUEST_DATA_IMPORTS));
  const idCheck = id ? `/${id}` : "";
  const res = await restApi.get(dataImportEndPoint + idCheck, {
    headers: { Authorization: `Bearer ${fetchToken()}` }
  });

  dispatch(
    setDataImport(res.data, id ? SET_SINGLE_DATA_IMPORT : SET_DATA_IMPORTS)
  );
};

export const createDataImport = params => async dispatch => {
  dispatch(setDataImport(null, UPLOAD_REQUEST_DATA_IMPORTS));
  try {
    const res = await restApi.post(dataImportEndPoint, params, {
      headers: {
        Authorization: `Bearer ${fetchToken()}`,
        "Content-Type": `multipart/form-data; boundary=${params._boundary}`
      }
    });
    dispatch(setDataImport(null, CREATE_DATA_IMPORT));
    dispatch(fetchDataImports(res.data.data_import_id));
  } catch (err) {
    const error = { ...err.response.data, type: "create" };
    dispatch(setDataImport(error, ERROR_DATA_IMPORT));
  }
};
