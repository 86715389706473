import restApi from "apis/restApi";
import {
  LOADING_CONTRACT_ACTIVITY_ALERTS,
  SET_CONTRACT_ACTIVITY_ALERTS,
  UNSET_CONTRACT_ACTIVITY_ALERTS,
  CREATE_CONTRACT_ACTIVITY_ALERT,
  DELETE_CONTRACT_ACTIVITY_ALERT,
  UPDATE_CONTRACT_ACTIVITY_ALERT,
  ERROR_CONTRACT_ACTIVITY_ALERT,
  UNSET_CONTRACT_ACTIVITY_ALERT_ACTION,
  UNSET_ERROR_CONTRACT_ACTIVITY_ALERT
} from "actions/types";
import history from "../../history";
import { fetchToken } from "utils/authUtils";

const contractActivityAlertEndPoint = "/contracts/alert";

// Pass data to reducers
export const setContractActivityAlert = (obj, type, activityAlert) => {
  const action = { type: type, payload: obj };
  if (activityAlert) action.currentActivityAlert = activityAlert;
  return action;
};

// Non-API Calls
export const clearContractActivityAlertOptions = () => async dispatch => {
  dispatch(setContractActivityAlert([], UNSET_CONTRACT_ACTIVITY_ALERTS));
};
export const clearContractActivityAlertAction = () => async dispatch => {
  dispatch(setContractActivityAlert({}, UNSET_CONTRACT_ACTIVITY_ALERT_ACTION));
};
export const clearContractActivityAlertErrors = () => async dispatch => {
  dispatch(setContractActivityAlert({}, UNSET_ERROR_CONTRACT_ACTIVITY_ALERT));
};

// API Calls
export const fetchContractActivityAlert = () => async dispatch => {
  dispatch(setContractActivityAlert([], LOADING_CONTRACT_ACTIVITY_ALERTS));
  if (fetchToken()) {
    const res = await restApi.get(contractActivityAlertEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContractActivityAlert(res.data, SET_CONTRACT_ACTIVITY_ALERTS));
  }
};

export const createContractActivityAlert = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractActivityAlertEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(
        setContractActivityAlert(res.data, CREATE_CONTRACT_ACTIVITY_ALERT)
      );
      history.push("/admin/contracts/activity-alerts/");
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setContractActivityAlert(error, ERROR_CONTRACT_ACTIVITY_ALERT));
    }
  }
};

export const deleteContractActivityAlert = activityAlert => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(contractActivityAlertEndPoint, {
      data: { id: activityAlert.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setContractActivityAlert(
        res.data,
        DELETE_CONTRACT_ACTIVITY_ALERT,
        activityAlert
      )
    );
  }
};

export const updateContractActivityAlert = (
  params,
  activityAlert
) => async dispatch => {
  dispatch(setContractActivityAlert([], LOADING_CONTRACT_ACTIVITY_ALERTS));
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contractActivityAlertEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(
        setContractActivityAlert(
          res.data,
          UPDATE_CONTRACT_ACTIVITY_ALERT,
          activityAlert
        )
      );
      history.push(`/admin/contracts/activity-alerts/${params.id}`);
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setContractActivityAlert(error, ERROR_CONTRACT_ACTIVITY_ALERT));
    }
  }
};
