import React from "react";

import ButtonIcon from "components/common/ButtonIcon";

const TableButton = ({
  type,
  item,
  color,
  icon,
  modal,
  toggleModal,
  link,
  download,
  label
}) => {
  const returnClickFunction = e => {
    if (download && !modal) {
      return download(e, item);
    }

    return toggleModal(e, type, item);
  };

  return (
    <ButtonIcon
      id={`${type}-${item.id}`}
      label={label ? label : false}
      to={link ? link : false}
      icon={icon}
      color={color}
      onClick={modal || download ? returnClickFunction : undefined}
    />
  );
};

export default TableButton;
