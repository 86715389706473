import _ from 'lodash'

export const selectObject = (obj, curId, notRequired, value) => {
  const options = _.toArray(
    _.map(obj, (item) => {
      const name = item.last_name
        ? `${item.last_name}, ${item.first_name}`
        : item.name
      const code = item.code ? ` (${item.code})` : ''
      return {
        id: item.id,
        value: name + code,
        checked: item.id === curId ? true : false,
      }
    })
  )

  const defaultValue = value || 'None'

  if (notRequired && options.length) {
    options.unshift({
      id: 0,
      value: defaultValue,
      checked: curId ? false : true,
    })
  }

  return options
}

// pass an array of available options, select the correct value based on id passed
export const selectArray = (array, curId, optional, value) => {
  const options = array.map((item) => {
    if (item.id === curId) {
      item.checked = true
    } else {
      item.checked = false
    }
    return item
  })

  const defaultValue = value || 'None'

  if (optional && options?.length && options[0].value !== defaultValue) {
    options.unshift({
      id: 0,
      value: defaultValue,
      checked: curId ? false : true,
    })
  }

  return options
}

// returns an array
export const findSelected = (arr) => arr?.filter && arr.filter((a) => a.checked)

export const selectValueSplit = (value) => {
  return value.split(/[()]+/).filter(function (e) {
    return e
  })
}

export const inputErrorClass = (results, inputError) => {
  if (results.error && results.error.length && inputError) {
    return ' input-error'
  }
  return ''
}

export const propertiesToMultiSelectOptions = (items, encodeIntValues) => {
  if (items) {
    return items.map((item) => {
      const x = Number(item.id)
      const shouldEncode = encodeIntValues && Number.isInteger(x)

      return {
        text: item.value,
        value: !shouldEncode
          ? item.id.toString()
          : // encode the id so that if it is 0, the select will still work. (the multi-select treats 0 as select all)
            x
              .toString()
              .split('')
              .map((c) => `%3${c}`)
              .join(''),
      }
    })
  }
  return []
}

export const validatePositiveValue = (value) => {
  let isValid = true
  let v = value
  if (typeof v === 'string') {
    v = v.replace(/,/g, '')
    v = v.replace(/\$/g, '')
  }
  if (isNaN(Number(v))) {
    isValid = false
  } else if (Number(v) < 0) {
    isValid = false
  }
  return isValid
}
