import moment from 'moment-timezone'

export const showAvailable = (arr) => {
  return (
    arr?.filter((a) => {
      return a.is_available
    }) ?? []
  )
}

export const showAvailableByType = (arr, typeId) => {
  return (
    arr?.filter((a) => {
      return (
        (a.is_available &&
          typeId &&
          (a.contract_type_id === typeId ||
            a.sr_document_type_id === typeId)) ||
        a.value === 'None'
      )
    }) ?? []
  )
}

export const filterByType = (arr, typeId) => {
  return (
    arr?.filter((a) => {
      return (
        a.is_available &&
        typeId &&
        (a.contract_type?.id === typeId || a.sr_document_type?.id === typeId)
      )
    }) ?? []
  )
}

export const formatDateToLocal = (date, format) => {
  const timezone = moment.tz.guess(true)
  const utcTime = moment.utc(date).format('YYYY-MM-DD HH:mm:ssZ')
  const localTime = moment(utcTime, 'YYYY-MM-DD HH:mm:ssZ').local()
  return localTime.tz(timezone).format(format)
}

export const formatDateStandard = (date, format) => {
  return moment.utc(date).format(format)
}

export const preventRowClick = (e) => {
  const fa = e.target.classList.contains('fa')
  const btn =
    e.target.classList.contains('btn') || e.target.tagName === 'BUTTON'
  const lever = e.target.classList.contains('lever')
  const label = e.target.tagName === 'LABEL'
  const input = e.target.tagName === 'INPUT'

  if (fa || btn || lever || label || input) {
    return true
  }
  return false
}

export const formatSnakeCase = (
  [first, ...rest],
  locale = navigator.language
) => {
  const formatString = [first.toLocaleUpperCase(locale), ...rest].join('')
  return formatString.replace(/_/g, ' ')
}
