import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBContainer } from "mdbreact";

import { useAuth } from "hooks/useAuth.js";
import { fetchStudies } from "actions/studies/studyActions";

import SiteNav from "./nav/SiteNav";
import ParentRoutes from "./routes/ParentRoutes";

import { fetchToken } from "utils/authUtils";

const App = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const authorization = useSelector(state => state.authorization);

  useEffect(() => {
    if (auth.user) {
      dispatch(fetchStudies("list"));
    }
  }, [dispatch, auth]);

  const resetUserAuth = useCallback(auth.setUserToken, []);
  useEffect(() => {
    if (authorization.status === false && (auth.token || fetchToken())) {
      resetUserAuth(null);
    }
  }, [authorization.status, resetUserAuth, auth.token]);

  return (
    <div
      className={`flexible-content position-relative${
        auth.user ? " flexible-content-full" : ""
      }`}
    >
      <SiteNav />
      <MDBContainer fluid tag="main" className="dashboard-content mt-5">
        <ParentRoutes />
      </MDBContainer>
    </div>
  );
};

export default App;
