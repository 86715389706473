import ContractForm from './forms/ContractForm'
import { createContract } from 'actions/contracts/contractActions'
import Section from 'components/common/Section'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findSelected } from 'utils/formUtils'

const ContractCreate = () => {
  const { contractErrors } = useSelector((state) => state.contracts)
  const dispatch = useDispatch()

  const handleSubmission = (e, params, options) => {
    e.preventDefault()

    const studySelected = findSelected(options.study)
    const typeSelected = findSelected(options.contractType)
    const subtypeSelected = findSelected(options.contractSubtype)
    const currencySelected = findSelected(options.currency)
    const contactSelected = findSelected(options.primaryInvestigator)
    const siteSelected = findSelected(options.primarySite)
    const contractParams = _.pickBy(params, _.identity)

    if (studySelected.length > 0) {
      contractParams.study_id = studySelected[0].id
    }
    if (typeSelected.length > 0) {
      contractParams.contract_type_id = typeSelected[0].id
    }
    if (subtypeSelected.length > 0 && subtypeSelected[0].id !== 0) {
      contractParams.contract_subtype_id = subtypeSelected[0].id
    }
    if (
      params.is_budget &&
      currencySelected.length > 0 &&
      currencySelected[0].id !== 0
    ) {
      contractParams.currency_id = currencySelected[0].id
    }
    if (contactSelected.length > 0 && contactSelected[0].id !== 0) {
      contractParams.primary_investigator_id = contactSelected[0].id
    }
    if (siteSelected.length > 0 && siteSelected[0].id !== 0) {
      contractParams.primary_site_id = siteSelected[0].id
    }

    dispatch(createContract(contractParams))
  }

  return (
    <Section
      title="Create Contract"
      description="Select options to create a new contract"
      breadcrumbs
      footer={[
        {
          text: 'Submit',
          color: 'primary',
          additionalProps: {
            form: 'form_contract',
            type: 'submit',
          },
        },
      ]}
    >
      <ContractForm submit={handleSubmission} errors={contractErrors} />
    </Section>
  )
}

export default ContractCreate
