import {
  SET_CONTRACT_ACTIVITIES,
  UNSET_CONTRACT_ACTIVITIES,
  CREATE_CONTRACT_ACTIVITY,
  DELETE_CONTRACT_ACTIVITY,
  UNSET_CONTRACT_ACTIVITY_ACTION,
  UPDATE_CONTRACT_ACTIVITY,
  ERROR_CONTRACT_ACTIVITY,
  UNSET_ERROR_CONTRACT_ACTIVITY,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  activitiesAvailable: {},
  activityErrors: {},
}

const contractActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_ACTIVITIES:
      return {
        ...state,
        lastAction: 'fetch',
        activitiesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case UNSET_CONTRACT_ACTIVITIES:
      return INITIAL_STATE
    case UNSET_CONTRACT_ACTIVITY_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_ACTIVITY:
      const createdItem = action.payload.created_items[0]
      if (createdItem?.contract_document?.id) {
        createdItem.contract_document_id = createdItem.contract_document.id
      }
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        activitiesAvailable: {
          ...state.activitiesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_ACTIVITY:
      const updatedItem = (state.activitiesAvailable[
        action.currentContractActivity.id
      ] = action.currentContractActivity)
      updatedItem.created_at =
        state.activitiesAvailable[action.currentContractActivity.id].created_at
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_ACTIVITY:
      return {
        ...state,
        activityErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_ACTIVITY:
      return {
        ...state,
        activityErrors: {},
      }
    case DELETE_CONTRACT_ACTIVITY:
      const updated = state.activitiesAvailable
      _.unset(updated, `${action.currentContractActivity.id}`)
      return { ...state, lastAction: 'delete', activitiesAvailable: updated }
    default:
      return state
  }
}

export default contractActivityReducer
