import history from '../../history'
import {
  SET_SITES,
  SET_SITE_OPTIONS,
  UNSET_SITE_OPTIONS,
  CREATE_SITE,
  DELETE_SITE,
  UPDATE_SITE,
  TOGGLE_SITE,
  ERROR_SITE,
  UNSET_SITE_ACTION,
  UNSET_ERROR_SITE,
  SET_SITES_SELECTABLES,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const siteEndPoint = '/org'

// Pass data to reducers
export const setSite = (obj, type, site) => {
  const action = { type: type, payload: obj }
  if (site) action.currentSite = site
  return action
}

// Non-API Calls
export const clearSiteOptions = () => async (dispatch) => {
  dispatch(setSite([], UNSET_SITE_OPTIONS))
}
export const fetchExistingSites = (site) => async (dispatch) => {
  dispatch(setSite([], SET_SITE_OPTIONS, site))
}
export const clearSiteAction = () => async (dispatch) => {
  dispatch(setSite({}, UNSET_SITE_ACTION))
}
export const clearSiteErrors = () => async (dispatch) => {
  dispatch(setSite({}, UNSET_ERROR_SITE))
}

// API Calls
export const fetchSites = (results, site) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(siteEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    if (results === 'list') {
      dispatch(setSite(res.data, SET_SITES))
    } else if (results === 'form') {
      dispatch(setSite(res.data, SET_SITE_OPTIONS, site))
    }
  }
}

export const fetchSitesForSelect = () => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(siteEndPoint + '/selectables', {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSite(res.data, SET_SITES_SELECTABLES))
  }
}

export const createSite = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(siteEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSite(res.data, CREATE_SITE))
      history.push('/sites')
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSite(error, ERROR_SITE))
    }
  }
}

export const deleteSite = (site) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(siteEndPoint, {
      data: { id: site.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSite(res.data, DELETE_SITE, site))
  }
}

export const updateSite = (params, site) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(siteEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSite(res.data, UPDATE_SITE, site))
      history.push(`/sites/${params.id}`)
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setSite(error, ERROR_SITE))
    }
  }
}

export const toggleSite = (site, toggle) => async (dispatch) => {
  const toggleEndPoint = `${siteEndPoint}/${toggle ? 'enable' : 'disable'}`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: site.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSite({ res, toggle: toggle }, TOGGLE_SITE, site))
  }
}
