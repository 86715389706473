import {
  SET_CONTRACT_STATUSES,
  SET_CONTRACT_STATUS_OPTIONS,
  UNSET_CONTRACT_STATUS_OPTIONS,
  CREATE_CONTRACT_STATUS,
  DELETE_CONTRACT_STATUS,
  UNSET_CONTRACT_STATUS_ACTION,
  UPDATE_CONTRACT_STATUS,
  TOGGLE_CONTRACT_STATUS,
  ERROR_CONTRACT_STATUS,
  UNSET_ERROR_CONTRACT_STATUS,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  statusesAvailable: {},
  statusOptions: [],
  statusErrors: {},
}

const contractStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_STATUSES:
      return {
        ...state,
        lastAction: 'fetch',
        statusesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_CONTRACT_STATUS_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentContractStatus ? action.currentContractStatus.id : 0,
        action.noDefaultOptions ? false : true,
        'All Statuses'
      )
      return { ...state, statusOptions: options }
    case UNSET_CONTRACT_STATUS_OPTIONS:
      if (state.statusOptions) {
        return { ...state, statusOptions: [] }
      }
      return state
    case UNSET_CONTRACT_STATUS_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_STATUS:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        statusesAvailable: {
          ...state.statusesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_STATUS:
      const updatedItem = (state.statusesAvailable[
        action.currentContractStatus.id
      ] = action.currentContractStatus)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_STATUS:
      return {
        ...state,
        statusErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_STATUS:
      return {
        ...state,
        statusErrors: {},
      }
    case TOGGLE_CONTRACT_STATUS:
      const toggledItem = (state.statusesAvailable[
        action.currentContractStatus.id
      ] = action.currentContractStatus)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_CONTRACT_STATUS:
      const updated = state.statusesAvailable
      _.unset(updated, `${action.currentContractStatus.id}`)
      return { ...state, lastAction: 'delete', statusesAvailable: updated }
    default:
      return state
  }
}

export default contractStatusReducer
