import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import _ from 'lodash'
import { MDBRow, MDBCol, MDBInput, MDBFileInput } from 'mdbreact'
import React from 'react'

const SrDocumentDocForm = ({
  params,
  setParams,
  selected,
  setSelected,
  docTypeOptions,
  errors,
}) => {
  //// HOOKS.

  //// GLOBAL STATE.

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <MDBRow>
      {selected ? (
        ''
      ) : (
        <>
          <MDBCol md="12">
            <Select
              id="select__doc-types"
              options={docTypeOptions}
              getTextContent={(v) => {
                if (v !== 'Choose A File Type') {
                  const currentDocType = docTypeOptions.filter(
                    (docType) => docType.value === v
                  )
                  setParams({
                    ...params,
                    sr_document_doc_type_id: `${currentDocType[0].id}`,
                  })
                  if (
                    selected &&
                    selected.sr_document_doc_type.id !== currentDocType[0].id
                  ) {
                    setSelected({
                      ...selected,
                      sr_document_doc_type: {
                        id: currentDocType[0].id,
                        name: currentDocType[0].value,
                      },
                    })
                  }
                }
              }}
              label="File Type"
              defaultOption="Choose A File Type"
              additionalClasses="mb-0"
              search
              required
              errors={errors}
            />
          </MDBCol>

          <MDBCol md="12">
            <MDBFileInput
              textFieldTitle="Upload a file"
              btnColor="primary"
              getValue={(v) => {
                setParams({ ...params, import_file: v[0] })
                if (selected && !_.isEqual(selected.import_file, v[0])) {
                  setSelected({ ...selected, import_file: v[0] })
                }
              }}
            />
          </MDBCol>
        </>
      )}

      <MDBCol md="12">
        <MDBInput
          value={params.comment}
          getValue={(v) => {
            setParams({ ...params, comment: v })
            if (selected && selected.comment !== v) {
              setSelected({ ...selected, comment: v })
            }
          }}
          id="comment-input"
          label="Comment"
          type="textarea"
          rows="3"
          outline
          className="md-textarea mb-0 input-select"
        />
      </MDBCol>

      <ErrorPanel results={errors} />
    </MDBRow>
  )
}

export default SrDocumentDocForm
