import SrDocumentFilter from './SrDocumentFilter'
import {
  fetchSrDocuments,
  useClearSrDocuments,
} from 'actions/srDocuments/srDocumentActions'
import { createSrDocumentColumns } from 'actions/srDocuments/srDocumentColumnActions'
import EmptyList from 'components/common/EmptyList'
import Table from 'components/common/tables/Table'
import { useAuth } from 'hooks/useAuth.js'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomWithReset } from 'jotai/utils'
import _ from 'lodash'
import { MDBSpinner } from 'mdbreact'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { isUserNotReadOnlyForSiteReadiness } from 'utils/authUtils'
import { defaultSrDocumentColumns } from 'utils/tableUtils'

const defaultButtons = [
  {
    label: '',
    field: 'buttons',
    sort: 'disabled',
    minimal: 'lg',
  },
]

export const srDocumentsReloadAtom = atom(false)
export const isLoadingSrDocumentsAtom = atomWithReset(true)
export const prevSrDocumentsLoadQueryParamsAtom = atomWithReset(null)

export const srDocumentsFilterParamsAtom = atomWithReset([])
export const srDocumentsViewParamsAtom = atomWithReset([])
export const srDocumentsQueryParamsAtom = atomWithReset('')

export const srDocumentsColumnsAtom = atomWithReset([])
export const srDocumentsTableDataAtom = atomWithReset({})

const SrDocumentTable = ({
  srDocumentsAvailable,
  isLoadingMore,
  lastAction,
  srDocumentPropertyOptions,
  homePageView,
  fetchTableLink,
  toggleModal,
  section,
  willSaveColumns,
  shouldRequireQueryParams,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}

  //// GLOBAL STATE.
  const [
    [reloadSrDocuments, setReloadSrDocuments],
    [isLoadingSrDocuments, setIsLoadingSrDocuments],
    [prevSrDocumentsLoadQueryParams, setPrevSrDocumentsLoadQueryParams],
    srDocumentsViewParams,
    srDocumentsFilterParams,
    [srDocumentsQueryParams, setSrDocumentsQueryParams],
    srDocumentsColumns,
    [srDocumentsTableData, setSrDocumentsTableData],
  ] = [
    useAtom(srDocumentsReloadAtom),
    useAtom(isLoadingSrDocumentsAtom),
    useAtom(prevSrDocumentsLoadQueryParamsAtom),
    useAtomValue(srDocumentsViewParamsAtom),
    useAtomValue(srDocumentsFilterParamsAtom),
    useAtom(srDocumentsQueryParamsAtom),
    useAtomValue(srDocumentsColumnsAtom),
    useAtom(srDocumentsTableDataAtom),
  ]

  //// LOCAL STATE.

  //// EFFECT HELPERS.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUpdatedData = useCallback(
    _.debounce(async (qp, rc) => {
      setIsLoadingSrDocuments(true)
      setPrevSrDocumentsLoadQueryParams(qp)
      await dispatch(fetchSrDocuments(qp))
      setIsLoadingSrDocuments(false)
      if (rc) setReloadSrDocuments(false)
    }, 500),
    [
      dispatch,
      setReloadSrDocuments,
      setIsLoadingSrDocuments,
      setPrevSrDocumentsLoadQueryParams,
    ]
  )

  //// EFFECTS.
  // handle view param change
  useEffect(() => {
    if (!_.isEmpty(srDocumentsViewParams)) {
      const queryParamsObjects = {}
      Object.keys(srDocumentsViewParams).forEach((filterValue) => {
        if (srDocumentsViewParams[filterValue].length === 0) {
          queryParamsObjects[filterValue] = []
        } else {
          const queryParam = `${filterValue}[]=${srDocumentsViewParams[
            filterValue
          ].join(`&${filterValue}[]=`)}`
          queryParamsObjects[filterValue] = queryParam
        }
      })
      setSrDocumentsQueryParams(
        Object.values(queryParamsObjects).flat().join('&')
      )
    }
  }, [srDocumentsViewParams, setSrDocumentsQueryParams])

  // handle filter param change
  useEffect(() => {
    if (!_.isEmpty(srDocumentsFilterParams)) {
      const queryParamsObjects = {}
      Object.keys(srDocumentsFilterParams).forEach((filterValue) => {
        if (srDocumentsFilterParams[filterValue].length === 0) {
          queryParamsObjects[filterValue] = []
        } else {
          const queryParam = `${filterValue}[]=${srDocumentsFilterParams[
            filterValue
          ].join(`&${filterValue}[]=`)}`
          queryParamsObjects[filterValue] = queryParam
        }
      })
      setSrDocumentsQueryParams(
        Object.values(queryParamsObjects).flat().join('&')
      )
    }
  }, [srDocumentsFilterParams, setSrDocumentsQueryParams])

  // get new data as needed
  useEffect(() => {
    // only fetch data if we have already loaded the initial srdoc data
    if (
      (!shouldRequireQueryParams || srDocumentsQueryParams?.length) &&
      (reloadSrDocuments ||
        srDocumentsQueryParams !== prevSrDocumentsLoadQueryParams)
    ) {
      // update reload data in case of manual reload
      fetchUpdatedData(srDocumentsQueryParams, reloadSrDocuments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srDocumentsQueryParams, reloadSrDocuments, fetchUpdatedData])

  //// RENDER.
  return (
    <>
      <SrDocumentFilter
        srDocumentPropertyOptions={srDocumentPropertyOptions}
        hasQueryParams={
          Object.values(srDocumentsFilterParams).flat().length === 0
        }
        viewParams={srDocumentsViewParams}
        homePageView={homePageView}
      />
      <div
        className="pr-4 d-flex align-items-center"
        style={{
          position: 'absolute',
          right: 0,
          opacity: isLoadingSrDocuments || isLoadingMore ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <span
          className="pr-3"
          style={{
            opacity: isLoadingMore ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          Loading additional documents ...
        </span>
        <MDBSpinner small className=" border-primary" />
      </div>

      {_.isEmpty(srDocumentsAvailable) && !isLoadingSrDocuments ? (
        <EmptyList
          name="site readiness document"
          namePlural="site readiness documents"
          userRole={user_level}
          createElement={<Link to="/sr-document/new">Create</Link>}
        />
      ) : (
        <div
          style={{
            opacity: isLoadingSrDocuments ? 0.5 : 1,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          <Table
            section={section || 'Site Readiness Document'}
            tableData={srDocumentsTableData}
            setTableData={setSrDocumentsTableData}
            rowData={srDocumentsAvailable}
            sort={['id', 'desc']}
            fetchTableLink={fetchTableLink}
            toggleModal={toggleModal}
            updatedState={lastAction}
            clickableRow
            downloadableCSV={!homePageView && !isLoadingMore}
            infoLabel={['', '-', 'of', isLoadingMore ? 'and counting ...' : '']}
            linkToPage
            showButton
            editButton={
              !homePageView && isUserNotReadOnlyForSiteReadiness(user_level)
            }
            deleteButton={is_admin && !homePageView}
            defaultColumns={defaultSrDocumentColumns}
            defaultButtons={defaultButtons}
            customProperties={
              srDocumentPropertyOptions
                ? srDocumentPropertyOptions?.custom_properties
                : undefined
            }
            willSaveColumns={willSaveColumns}
            columnsAvailable={srDocumentsColumns}
            onSubmitColumns={
              willSaveColumns
                ? (values) => {
                    dispatch(
                      createSrDocumentColumns({
                        sr_document_columns: values.join(','),
                      })
                    )
                  }
                : undefined
            }
            toggleAvailability={undefined}
          />
        </div>
      )}
    </>
  )
}

export default SrDocumentTable

export const useResetSrDocuments = ({
  isLoadingSrDocuments: isLoadingSrDocumentsAtomExt,
  prevSrDocumentsLoadQueryParams: prevSrDocumentsLoadQueryParamsAtomExt,
  srDocumentsFilterParams: srDocumentsFilterParamsAtomExt,
  srDocumentsViewParams: srDocumentsViewParamsAtomExt,
  srDocumentsQueryParams: srDocumentsQueryParamsAtomExt,
  srDocumentsColumns: srDocumentsColumnsAtomExt,
  srDocumentsTableData: srDocumentsTableDataAtomExt,
}) => {
  const dispatch = useDispatch()
  const clearSrDocuments = useClearSrDocuments(dispatch)
  const [
    setIsLoadingSrDocuments,
    setPrevSrDocumentsLoadQueryParams,
    setSrDocumentsFilterParams,
    setSrDocumentsViewParams,
    setSrDocumentsQueryParams,
    setSrDocumentsColumns,
    setSrDocumentsTableData,
  ] = [
    useSetAtom(isLoadingSrDocumentsAtom),
    useSetAtom(prevSrDocumentsLoadQueryParamsAtom),
    useSetAtom(srDocumentsFilterParamsAtom),
    useSetAtom(srDocumentsViewParamsAtom),
    useSetAtom(srDocumentsQueryParamsAtom),
    useSetAtom(srDocumentsColumnsAtom),
    useSetAtom(srDocumentsTableDataAtom),
  ]

  return () => {
    clearSrDocuments()
    setIsLoadingSrDocuments(isLoadingSrDocumentsAtomExt ?? RESET)
    setPrevSrDocumentsLoadQueryParams(
      prevSrDocumentsLoadQueryParamsAtomExt ?? RESET
    )
    setSrDocumentsFilterParams(srDocumentsFilterParamsAtomExt ?? RESET)
    setSrDocumentsViewParams(srDocumentsViewParamsAtomExt ?? RESET)
    setSrDocumentsQueryParams(srDocumentsQueryParamsAtomExt ?? RESET)
    setSrDocumentsColumns(srDocumentsColumnsAtomExt ?? RESET)
    setSrDocumentsTableData(srDocumentsTableDataAtomExt ?? RESET)
  }
}
