import SrDocumentDocForm from '../forms/SrDocumentDocForm'
import {
  clearSrDocumentDocAction,
  clearSrDocumentDocErrors,
  updateSrDocumentDoc,
} from 'actions/srDocuments/srDocumentDocActions'
import { fetchSrDocumentDocTypes } from 'actions/srDocuments/srDocumentDocTypeActions'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const SrDocumentDocUpdate = ({
  formId,
  params,
  setParams,
  selected,
  setSelected,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, docErrors } = useSelector((state) => state.srDocumentDocs)
  const { docTypeOptions } = useSelector((state) => state.srDocumentDocTypes)

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (_.size(selected)) {
      dispatch(fetchSrDocumentDocTypes('form', selected.sr_document_doc_type))
    }
    return () => {
      dispatch(clearSrDocumentDocAction())
      dispatch(clearSrDocumentDocErrors())
    }
  }, [dispatch, selected])

  useEffect(() => {
    if (_.isEmpty(params) && docTypeOptions.length) {
      setParams({
        id: selected.id,
        comment: selected.comment ? selected.comment : '',
      })
      setSelected({
        ...selected,
        id: selected.id,
      })
    }
  }, [selected, setSelected, params, setParams, docTypeOptions])

  // watch for success
  useEffect(() => {
    if (lastAction === 'edit') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        dispatch(updateSrDocumentDoc(params, selected))
      }}
      className="form container"
      noValidate
    >
      <SrDocumentDocForm
        params={params}
        setParams={setParams}
        selected={selected}
        setSelected={setSelected}
        docTypeOptions={docTypeOptions}
        errors={docErrors}
      />
    </form>
  )
}

export default SrDocumentDocUpdate
