import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import useRouter from "hooks/useRouter";
import {
  deleteContractActivityAlert,
  clearContractActivityAlertAction
} from "actions/contracts/contractActivityAlertActions";

import history from "../../../../history";

const ContractActivityAlertDelete = ({ formId, closeModal, selected }) => {
  const routingParams = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearContractActivityAlertAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteContractActivityAlert(selected));
    if (
      routingParams.match.path === "/admin/contracts/activity-alerts/:id" ||
      routingParams.match.path === "/admin/contracts/activity-alerts/:id/edit"
    ) {
      history.push("/admin/contracts/activity-alerts");
    } else {
      closeModal();
    }
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this activity alerts? This can not be
        undone.
      </div>
    </form>
  );
};

export default ContractActivityAlertDelete;
