import {
  CREATE_SR_DOCUMENT_VIEW,
  DELETE_SR_DOCUMENT_VIEW,
  ERROR_SR_DOCUMENT_VIEW,
  SET_SR_DOCUMENT_VIEWS,
  UNSET_SR_DOCUMENT_VIEWS,
  UNSET_SR_DOCUMENT_VIEW_ACTION,
  UNSET_ERROR_SR_DOCUMENT_VIEW,
  UPDATE_SR_DOCUMENT_VIEW,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentViewEndPoint = '/user/view/sr-document'

// Passing data back to reducers
const setSrDocumentView = (obj, type, srDocumentView) => {
  const action = { type: type, payload: obj }
  if (srDocumentView) action.currentSrDocumentView = srDocumentView
  return action
}

// Non-API calls
export const clearSrDocumentViews = () => async (dispatch) => {
  dispatch(setSrDocumentView({}, UNSET_SR_DOCUMENT_VIEWS))
}
export const clearSrDocumentViewErrors = () => async (dispatch) => {
  dispatch(setSrDocumentView({}, UNSET_ERROR_SR_DOCUMENT_VIEW))
}
export const clearSrDocumentViewAction = () => async (dispatch) => {
  dispatch(setSrDocumentView({}, UNSET_SR_DOCUMENT_VIEW_ACTION))
}

// API calls
// pass an array of query parameters for filtering
export const fetchSrDocumentViews = () => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(srDocumentViewEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentView(res.data, SET_SR_DOCUMENT_VIEWS))
  }
}

export const createSrDocumentView = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentViewEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentView(res.data, CREATE_SR_DOCUMENT_VIEW))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentView(error, ERROR_SR_DOCUMENT_VIEW))
    }
  }
}

export const deleteSrDocumentView = (srDocumentView) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentViewEndPoint, {
      data: { id: srDocumentView.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentView(res.data, DELETE_SR_DOCUMENT_VIEW, srDocumentView)
    )
  }
}

export const updateSrDocumentView =
  (params, srDocumentView) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(srDocumentViewEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setSrDocumentView(res.data, UPDATE_SR_DOCUMENT_VIEW, srDocumentView)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setSrDocumentView(error, ERROR_SR_DOCUMENT_VIEW))
      }
    }
  }
