import {
  fetchContractTypes,
  clearContractTypeOptions,
} from 'actions/contracts/contractTypeActions'
import {
  fetchContractValues,
  clearContractValues,
  clearContractValueErrors,
  clearContractValueAction,
} from 'actions/studies/studyContractValueActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import Table from 'components/common/tables/Table'
import ContractValueCreate from 'components/studies/contractValues/ContractValueCreate'
import ContractValueUpdate from 'components/studies/contractValues/ContractValueUpdate'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ContractValuesList = ({ studyId }) => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    footer,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()
  const auth = useAuth()
  const dispatch = useDispatch()

  // redux hooks
  const { contractValuesAvailable, lastAction } = useSelector(
    (state) => state.studyContractValues
  )
  const typeOptions = useSelector((state) => state.contractTypes.typeOptions)

  useEffect(() => {
    dispatch(fetchContractValues(studyId))
    dispatch(fetchContractTypes('form', null, true))
    return () => {
      dispatch(clearContractValues())
      dispatch(clearContractValueErrors())
      dispatch(clearContractValueAction())
      dispatch(clearContractTypeOptions())
    }
  }, [dispatch, studyId])

  const closeModal = () => {
    setModalState(false)
  }

  // local state
  const [tableData, setTableData] = useState({
    columns: [],
  })

  useEffect(() => {
    // Rercalculat table column data once types are fetched
    if (_.isEmpty(tableData.columns) && !_.isEmpty(typeOptions)) {
      const columns = [
        {
          label: '#',
          field: 'id',
          sort: 'desc',
        },
        {
          label: 'Country Name',
          field: 'country.name',
          sort: 'desc',
        },
      ]

      typeOptions.forEach((t) => {
        const typeId = t.id
        columns.push({
          label: t.value,
          field: `values.${typeId}.amount`,
          sort: 'desc',
          formatter: 'formatUSD',
        })
      })

      columns.push({
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      })

      setTableData({
        columns,
      })
    }
  }, [typeOptions, tableData.columns])

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'new') {
        setHeaderText('New Contract Value')
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      }
      if (type === 'edit') {
        setHeaderText('Edit Contract Values')
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      }
      if (type) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  const renderModalBody = () => {
    if (headerText === 'New Contract Value') {
      return (
        <ContractValueCreate
          studyId={studyId}
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => closeModal()}
        />
      )
    }
    if (headerText === 'Edit Contract Values') {
      return (
        <ContractValueUpdate
          studyId={studyId}
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => closeModal()}
        />
      )
    }
    return ''
  }

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: 'Create',
        url: `/admin/studies`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: (e) => toggleModal(e, 'new'),
          className: 'btn-sm',
        },
      },
    ],
  }

  const renderTable = () => {
    if (_.size(contractValuesAvailable)) {
      return (
        <Table
          section="Contract Values"
          tableData={tableData}
          setTableData={setTableData}
          rowData={contractValuesAvailable}
          sort={['id', 'desc']}
          toggleModal={toggleModal}
          updatedState={lastAction}
          clickableRow
          downloadableCSV
          editButton
        />
      )
    } else if (_.isEmpty(contractValuesAvailable)) {
      return (
        <EmptyList
          name="Contract Value"
          namePlural="Contract Values"
          userRole={auth?.user?.user_level}
          createElement={
            <span className="link" onClick={(e) => toggleModal(e, 'new')}>
              Create
            </span>
          }
        />
      )
    }
    return ''
  }

  return (
    <Section
      header="Study Contract Values"
      menu={auth?.user?.is_admin ? sectionHeaderMenu : ''}
    >
      {renderTable()}
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </Section>
  )
}

export default ContractValuesList
