import restApi from "apis/restApi";
import {
  SET_CONTACT_PROPERTIES,
  SET_CONTACT_PROPERTY_OPTIONS,
  UNSET_CONTACT_PROPERTY_OPTIONS,
  CREATE_CONTACT_PROPERTY,
  DELETE_CONTACT_PROPERTY,
  UPDATE_CONTACT_PROPERTY,
  ERROR_CONTACT_PROPERTY,
  UNSET_CONTACT_PROPERTY_ACTION,
  UNSET_ERROR_CONTACT_PROPERTY
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const contactPropertyEndPoint = "/property/custom/contact";

// Passing data back to reducers
const setContactProperty = (obj, type, contactProperty) => {
  const action = { type: type, payload: obj };
  if (contactProperty) action.currentContactProperty = contactProperty;
  return action;
};

// Non-API calls
export const clearContactPropertyOptions = () => async dispatch => {
  dispatch(setContactProperty({}, UNSET_CONTACT_PROPERTY_OPTIONS));
};
export const clearContactPropertyAction = () => async dispatch => {
  dispatch(setContactProperty({}, UNSET_CONTACT_PROPERTY_ACTION));
};
export const clearContactPropertyErrors = () => async dispatch => {
  dispatch(setContactProperty({}, UNSET_ERROR_CONTACT_PROPERTY));
};

// API calls
export const fetchContactProperties = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(contactPropertyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContactProperty(res.data, SET_CONTACT_PROPERTIES));
  }
};

export const fetchContactPropertyOptions = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${contactPropertyEndPoint}/options`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContactProperty(res.data, SET_CONTACT_PROPERTY_OPTIONS));
  }
};

export const createContactProperty = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contactPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContactProperty(res.data, CREATE_CONTACT_PROPERTY));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setContactProperty(error, ERROR_CONTACT_PROPERTY));
    }
  }
};

export const deleteContactProperty = property => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(contactPropertyEndPoint, {
      data: { id: property.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContactProperty(res.data, DELETE_CONTACT_PROPERTY, property));
  }
};

export const updateContactProperty = (params, property) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contactPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContactProperty(res.data, UPDATE_CONTACT_PROPERTY, property));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setContactProperty(error, ERROR_CONTACT_PROPERTY));
    }
  }
};
