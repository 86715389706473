import _ from "lodash";
import {
  REQUEST_DATA_IMPORTS,
  UPLOAD_REQUEST_DATA_IMPORTS,
  SET_DATA_IMPORTS,
  SET_SINGLE_DATA_IMPORT,
  UNSET_DATA_IMPORTS,
  CREATE_DATA_IMPORT,
  UNSET_DATA_IMPORT_STATUS,
  ERROR_DATA_IMPORT,
  UNSET_ERROR_DATA_IMPORT
} from "actions/types";
import { setErrorStatusState, resetErrorStatusState } from "utils/errorUtils";

const INITIAL_STATUS = {
  fetching: false,
  uploading: false,
  fetchSuccess: null,
  uploadSuccess: null
};

const INITIAL_STATE = {
  status: INITIAL_STATUS,
  data: {},
  errors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_DATA_IMPORTS:
      return {
        ...state,
        status: {
          ...state.status,
          fetching: true
        }
      };
    case SET_DATA_IMPORTS:
      return {
        ...state,
        data: _.mapKeys(action.payload, "id"),
        status: {
          ...state.status,
          fetching: false,
          fetchSuccess: true
        }
      };
    case SET_SINGLE_DATA_IMPORT:
      const { id: singleId } = action.payload[0];
      const newUpload = { [singleId]: action.payload[0] };
      newUpload[singleId].import_errors =
        newUpload[singleId].data_import_errors;
      newUpload[singleId].data_import_errors =
        newUpload[singleId].data_import_errors.length;
      return {
        ...state,
        data: {
          ...state.data,
          ...newUpload
        },
        status: {
          ...state.status,
          fetching: false,
          fetchSuccess: true
        }
      };
    case UNSET_DATA_IMPORTS:
      return INITIAL_STATE;
    case UNSET_DATA_IMPORT_STATUS:
      return { ...state, status: INITIAL_STATUS, errors: {} };
    case UPLOAD_REQUEST_DATA_IMPORTS:
      return {
        ...state,
        status: {
          ...state.status,
          uploading: true
        }
      };
    case CREATE_DATA_IMPORT:
      return {
        ...state,
        status: {
          ...state.status,
          uploading: false,
          uploadSuccess: true
        }
      };
    case ERROR_DATA_IMPORT:
      return {
        ...state,
        errors: action.payload,
        status: {
          ...state.status,
          uploading: state.status.uploading ? false : state.status.uploading,
          fetching: state.status.fetching ? false : state.status.fetching,
          fetchSuccess: setErrorStatusState(state.status.fetchSuccess),
          uploadSuccess: setErrorStatusState(state.status.uploadSuccess)
        }
      };
    case UNSET_ERROR_DATA_IMPORT:
      return {
        ...state,
        status: {
          ...state.status,
          fetchSuccess: resetErrorStatusState(state.status.fetchSuccess),
          uploadSuccess: resetErrorStatusState(state.status.uploadSuccess)
        },
        errors: {}
      };
    default:
      return state;
  }
};
