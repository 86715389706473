import { useAuth } from './useAuth'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isUserGuest } from 'utils/authUtils'
import { selectObject } from 'utils/formUtils'

export const useGetReportsData = () => {
  const auth = useAuth()
  const allStudiesAvailable = !isUserGuest(auth?.user?.user_level)

  const { studiesAvailable } = useSelector((state) => state.studies)
  const { sitesSelectables } = useSelector((state) => state.sites)
  const { locationOptions } = useSelector((state) => state.contractLocations)

  return useMemo(() => {
    const studyOptions = () => selectObject(studiesAvailable)
    const studyOptionsPlusAll = () =>
      selectObject(
        studiesAvailable,
        -1,
        allStudiesAvailable,
        'All Sponsor Study Names'
      )

    const siteOptions = () => selectObject(sitesSelectables)

    return {
      // reports that show only the selected study
      availableStudyReports: [
        {
          reportId: 1,
          name: 'Study Contracts Summary',
          description:
            'See an overview of all contracts for a study and their full activity history',
          selects: [
            {
              label: 'Site',
              options: siteOptions(),
              isMultiSelect: true,
            },
            {
              label: 'Sponsor Study Name',
              options: studyOptionsPlusAll(),
              isMultiSelect: true,
            },
          ],
        },
        ...(auth?.user?.customer?.has_site_readiness
          ? [
              {
                reportId: 8,
                name: 'Study Site Readiness Documents Summary',
                description:
                  'See an overview of all site readiness documents for a study and their full activity history',
                selects: [
                  {
                    label: 'Site',
                    options: siteOptions(),
                    isMultiSelect: true,
                  },
                  {
                    label: 'Sponsor Study Name',
                    options: studyOptionsPlusAll(),
                    isMultiSelect: true,
                  },
                ],
              },
            ]
          : []),
        {
          reportId: 7,
          name: 'Metrics',
          description: 'See metrics for individual or multiple studies',
          selects: [
            {
              label: 'Site',
              options: siteOptions(),
              isMultiSelect: true,
            },
            {
              label: 'Sponsor Study Name',
              options: studyOptionsPlusAll(),
              isMultiSelect: true,
            },
          ],
          isAdminOnly: true,
        },
        {
          reportId: 2,
          name: 'Study Budgets Summary',
          description:
            'See the visit costs for all contracts marked as a budget for a study',
          selects: [{ label: 'Sponsor Study Name', options: studyOptions() }],
        },
        {
          reportId: 6,
          name: 'Contacts and Sites by Study',
          description:
            'Show all properties of the primary site and contact for each contract in the selected study.',
          selects: [{ label: 'Sponsor Study Name', options: studyOptions() }],
        },
      ],

      // reports that show data from all studies
      availableAllStudyReports: [
        {
          reportId: 3,
          name: 'Resource Summary',
          description:
            'All open contracts with their total time spent by location (measured in days)',
        },
      ],

      // reports that show only the selected study and are admin only
      availableStudyReportsAdmin: [
        {
          reportId: 4,
          name: 'WIP Contract Value',
          description:
            'The current value of contracts for a study based on milestones.',
          selects: [
            {
              label: 'Site',
              options: siteOptions(),
              isMultiSelect: true,
            },
            {
              label: 'Sponsor Study Name',
              options: studyOptionsPlusAll(),
              isMultiSelect: true,
            },
          ],
        },
      ],

      // reports that show only the selected contract location
      availableContractLocationReports: [
        {
          reportId: 5,
          name: 'Contracts By Location',
          description:
            'Generate all contracts that match the selected contract location.',
          selects: [{ label: 'Contract Location', options: locationOptions }],
        },
      ],
    }
  }, [allStudiesAvailable, studiesAvailable, locationOptions, sitesSelectables])
}
