import React, { useEffect, useState, useCallback } from "react";

import Select from "components/common/forms/Select";

const ComponentPropertySelect = ({ item, current, handleSelectChange }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const buildOptions = useCallback(() => {
    return item.property.options
      .filter(option => option.is_available !== false)
      .map(option => {
        return {
          id: option.id,
          value: option.value.toString(),
          checked:
            current?.custom_properties?.[item.property.name]?.list_id ===
            option.id
        };
      });
  }, [item, current]);
  const buildOptionList = useCallback(setOptions, []);
  const adjustLoading = useCallback(setLoading, []);
  useEffect(() => {
    if (loading) {
      buildOptionList(buildOptions());
      adjustLoading(false);
    }
  }, [buildOptionList, buildOptions, adjustLoading, loading]);

  if (options.length === 0) return "";

  return (
    <Select
      id={`select__custom-${item.id}`}
      options={options}
      getTextContent={value => handleSelectChange(value, item)}
      label={item.property.name}
      defaultOption={`Choose From ${item.property.property_type.name}`}
      required={item.required}
      search
    />
  );
};

export default ComponentPropertySelect;
