import restApi from "apis/restApi";
import { SET_COUNTRIES, UNSET_COUNTRIES } from "actions/types";
import { fetchToken } from "utils/authUtils";

const countryEndPoint = "/country";

// Pass data to reducers
export const setCountry = (obj, type, country) => {
  const action = { type: type, payload: obj };
  if (country) action.currentCountry = country;
  return action;
};

// API Calls
export const fetchCountries = country => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(countryEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setCountry(res.data, SET_COUNTRIES, country));
  }
};

export const clearCountries = () => async dispatch => {
  dispatch(setCountry([], UNSET_COUNTRIES));
};
