import {
  fetchContractDocuments,
  clearContractDocuments,
} from 'actions/contracts/contractDocumentActions'
import Section from 'components/common/Section'
import Table from 'components/common/tables/Table'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestContractDocument } from 'utils/downloadUtils'

const downloadFile = (e, item) => {
  e.preventDefault()
  requestContractDocument(item.id, item.filename)
}

const ContractDocumentHistory = ({ match }) => {
  const { contractId, documentTypeId } = match.params

  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { documentsAvailable, lastAction } = useSelector(
    (state) => state.contractDocuments
  )

  //// LOCAL STATE.
  const [documentTypeName, setDocumentTypeName] = useState('')
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: 'Date Stored (UTC)',
        field: 'created_at',
        sort: 'desc',
        date: true,
      },
      {
        label: 'Document',
        field: 'filename',
        sort: 'desc',
      },
      {
        label: 'Comment',
        field: 'comment',
        sort: 'asc',
        minimal: 'lg',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(
      fetchContractDocuments({
        contract_id: contractId,
        contract_document_type_id: documentTypeId,
      })
    )
    return () => {
      dispatch(clearContractDocuments())
    }
  }, [dispatch, contractId, documentTypeId])

  useEffect(() => {
    if (_.size(documentsAvailable)) {
      const obj = _.find(documentsAvailable, (o) => {
        return o.contract_document_type.id
      })
      setDocumentTypeName(obj.contract_document_type.name)
    }
  }, [documentsAvailable, documentTypeName, setDocumentTypeName])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <Section
      header={`${documentTypeName} Documents`}
      description={`View ${documentTypeName} document type history for contract #${contractId}`}
      breadcrumbs
    >
      {_.size(documentsAvailable) ? (
        <Table
          section="Contract Document"
          tableData={tableData}
          setTableData={setTableData}
          rowData={documentsAvailable}
          sort={['created_at', 'desc']}
          downloadFile={downloadFile}
          updatedState={lastAction}
          downloadButton
        />
      ) : lastAction === 'fetch' && _.isEmpty(documentsAvailable) ? (
        <p>
          It appears this document type doesn't have any documents attached to
          it.
        </p>
      ) : (
        ''
      )}
    </Section>
  )
}

export default ContractDocumentHistory
