import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateUser,
  clearUserErrors,
  clearUserAction
} from "actions/users/userActions";

import UserForm from "./UserForm";

const UserUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected,
  setSelected
}) => {
  const { lastAction, userErrors } = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearUserAction());
      dispatch(clearUserErrors());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        first_name: selected.first_name,
        last_name: selected.last_name,
        email: selected.email,
        is_available: selected.is_available,
        user_level: selected.user_level
      });
    }
  }, [params, setParams, selected]);

  // watch for success
  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = (e, updateParams, updateItem) => {
    e.preventDefault();
    const updatedParams = _.mapValues(updateParams, (prop, key) => {
      if (updateItem[key] !== prop && prop.length) return ([key] = prop);
    });
    const userParams = _.pickBy(updatedParams, _.identity);
    if (!userParams.id) userParams.id = updateItem.id;
    const updatedUser = _.mergeWith(updateItem, userParams);

    dispatch(updateUser(userParams, updatedUser));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params, selected)}
        className="form container"
        noValidate
      >
        <UserForm
          params={params}
          setParams={setParams}
          availableToggle
          errors={userErrors}
        />
      </form>
    );
  }

  return "";
};

export default UserUpdate;
