import {
  SET_SR_DOCUMENT_TYPES,
  SET_SR_DOCUMENT_TYPE_OPTIONS,
  UNSET_SR_DOCUMENT_TYPE_OPTIONS,
  CREATE_SR_DOCUMENT_TYPE,
  DELETE_SR_DOCUMENT_TYPE,
  UNSET_SR_DOCUMENT_TYPE_ACTION,
  UPDATE_SR_DOCUMENT_TYPE,
  TOGGLE_SR_DOCUMENT_TYPE,
  ERROR_SR_DOCUMENT_TYPE,
  UNSET_ERROR_SR_DOCUMENT_TYPE,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  typesAvailable: {},
  typeOptions: [],
  typeErrors: {},
}

const srDocumentTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_TYPES:
      return {
        ...state,
        lastAction: 'fetch',
        typesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_SR_DOCUMENT_TYPE_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentSrDocumentType ? action.currentSrDocumentType.id : 0,
        action.noDefaultOptions ? false : true,
        'All Types'
      )
      return { ...state, typeOptions: options }
    case UNSET_SR_DOCUMENT_TYPE_OPTIONS:
      if (state.typeOptions) {
        return { ...state, typeOptions: [] }
      }
      return state
    case UNSET_SR_DOCUMENT_TYPE_ACTION:
      return { ...state, lastAction: null }
    case CREATE_SR_DOCUMENT_TYPE:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        typesAvailable: {
          ...state.typesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SR_DOCUMENT_TYPE:
      const updatedItem = (state.typesAvailable[
        action.currentSrDocumentType.id
      ] = action.currentSrDocumentType)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_TYPE:
      return {
        ...state,
        typeErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_TYPE:
      return {
        ...state,
        typeErrors: {},
      }
    case TOGGLE_SR_DOCUMENT_TYPE:
      const toggledItem = (state.typesAvailable[
        action.currentSrDocumentType.id
      ] = action.currentSrDocumentType)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_SR_DOCUMENT_TYPE:
      const updated = state.typesAvailable
      _.unset(updated, `${action.currentSrDocumentType.id}`)
      return { ...state, lastAction: 'delete', typesAvailable: updated }
    default:
      return state
  }
}

export default srDocumentTypeReducer
