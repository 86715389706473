import React from "react";
import { Link } from "react-router-dom";
import { MDBCol, MDBRow } from "mdbreact";

import TableSimple from "components/common/tables/TableSimple";
import BadgeLabel from "components/common/badges/BadgeLabel";

const UserGuestStudiesDisplay = ({ user, loading, tableData }) => {
  if (user.user_level !== "guest") return "";

  return (
    <MDBCol size="12">
      <hr className="mt-5 mb-3" />
      <MDBRow className="align-items-center">
        <MDBCol size="9">
          <BadgeLabel>Studies user has access to</BadgeLabel>
        </MDBCol>
        <MDBCol size="3" className="text-right">
          <Link
            className="btn btn-primary btn-sm"
            to={`/admin/users/${user.id}/studies`}
          >
            Manage User's Studies
          </Link>
        </MDBCol>
      </MDBRow>
      {!loading && tableData.rows.length === 0 ? (
        <p>
          {user.first_name} {user.last_name} has no studies assigned.
        </p>
      ) : (
        <TableSimple tableData={tableData} authWidth />
      )}
    </MDBCol>
  );
};

export default UserGuestStudiesDisplay;
