import _ from "lodash";
import moment from "moment";
import { showAvailable } from "utils/commonUtils";
import { selectObject } from "utils/formUtils";
import {
  SET_BUDGET_VISITS,
  SET_BUDGET_VISIT_OPTIONS,
  UNSET_BUDGET_VISIT_OPTIONS,
  CREATE_BUDGET_VISIT,
  DELETE_BUDGET_VISIT,
  UPDATE_BUDGET_VISIT,
  ERROR_BUDGET_VISIT,
  UNSET_ERROR_BUDGET_VISIT,
  TOGGLE_BUDGET_VISIT,
  UNSET_BUDGET_VISIT_ACTION
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  budgetVisitsAvailable: {},
  budgetVisitOptions: [],
  budgetVisitErrors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BUDGET_VISITS:
      return {
        ...state,
        lastAction: "fetch",
        budgetVisitsAvailable: _.mapKeys(action.payload, "id")
      };
    case SET_BUDGET_VISIT_OPTIONS:
      const budgetVisitObject = _.isEmpty(state.budgetVisitsAvailable)
        ? showAvailable(action.payload)
        : state.budgetVisitsAvailable;
      const options = selectObject(
        budgetVisitObject,
        action.currentBudgetVisits ? action.currentBudgetVisits.id : 0
      );
      return { ...state, budgetVisitOptions: options };
    case UNSET_BUDGET_VISIT_OPTIONS:
      if (state.budgetVisitOptions.length) {
        return { ...state, budgetVisitOptions: [] };
      }
      return state;
    case UNSET_BUDGET_VISIT_ACTION:
      return { ...state, lastAction: null };
    case CREATE_BUDGET_VISIT:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        budgetVisitsAvailable: {
          ...state.budgetVisitsAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_BUDGET_VISIT:
      const updatedItem = (state.budgetVisitsAvailable[
        action.currentBudgetVisit.id
      ] = action.currentBudgetVisit);
      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        updatedItem
      };
    case ERROR_BUDGET_VISIT:
      return {
        ...state,
        budgetVisitErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_BUDGET_VISIT:
      return {
        ...state,
        budgetVisitErrors: {}
      };
    case TOGGLE_BUDGET_VISIT:
      const toggledItem = (state.budgetVisitsAvailable[
        action.currentBudgetVisit.id
      ] = action.currentBudgetVisit);
      toggledItem.is_available = action.payload.toggle;
      return {
        ...state,
        lastAction: action.payload.toggle ? "enable" : "disable",
        toggledItem
      };
    case DELETE_BUDGET_VISIT:
      let updatedBudgetVisits = state.budgetVisitsAvailable;
      _.unset(updatedBudgetVisits, `${action.currentBudgetVisit.id}`);
      return {
        ...state,
        lastAction: "delete",
        budgetVisitsAvailable: updatedBudgetVisits
      };
    default:
      return state;
  }
};
