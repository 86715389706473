import {
  SET_CONTRACT_SUBTYPES,
  SET_CONTRACT_SUBTYPE_OPTIONS,
  UNSET_CONTRACT_SUBTYPE_OPTIONS,
  CREATE_CONTRACT_SUBTYPE,
  DELETE_CONTRACT_SUBTYPE,
  UPDATE_CONTRACT_SUBTYPE,
  TOGGLE_CONTRACT_SUBTYPE,
  ERROR_CONTRACT_SUBTYPE,
  UNSET_CONTRACT_SUBTYPE_ACTION,
  UNSET_ERROR_CONTRACT_SUBTYPE,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractSubtypeEndPoint = '/contracts/subtype'

// Passing data back to reducers
const setContractSubtype = (obj, type, contractTypeId, contractSubtype) => {
  const action = { type: type, payload: obj }
  if (contractTypeId) action.currentContractTypeId = contractTypeId
  if (contractSubtype) action.currentContractSubtype = contractSubtype
  return action
}

// Non-API calls
export const clearContractSubtypeOptions = () => async (dispatch) => {
  dispatch(setContractSubtype({}, UNSET_CONTRACT_SUBTYPE_OPTIONS))
}
export const clearContractSubtypeAction = () => async (dispatch) => {
  dispatch(setContractSubtype({}, UNSET_CONTRACT_SUBTYPE_ACTION))
}
export const clearContractSubtypeErrors = () => async (dispatch) => {
  dispatch(setContractSubtype({}, UNSET_ERROR_CONTRACT_SUBTYPE))
}

// API calls
export const fetchContractSubtypes =
  (results, contractTypeId, contractSubtype) => async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(contractSubtypeEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setContractSubtype(res.data, SET_CONTRACT_SUBTYPES))
      } else if (results === 'form') {
        dispatch(
          setContractSubtype(
            res.data,
            SET_CONTRACT_SUBTYPE_OPTIONS,
            contractTypeId,
            contractSubtype
          )
        )
      }
    }
  }

export const createContractSubtype = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractSubtypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractSubtype(res.data, CREATE_CONTRACT_SUBTYPE))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractSubtype(error, ERROR_CONTRACT_SUBTYPE))
    }
  }
}

export const deleteContractSubtype = (subtype) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractSubtypeEndPoint, {
      data: { id: subtype.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setContractSubtype(res.data, DELETE_CONTRACT_SUBTYPE, null, subtype)
    )
  }
}

export const updateContractSubtype = (params, subtype) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contractSubtypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setContractSubtype(res.data, UPDATE_CONTRACT_SUBTYPE, null, subtype)
      )
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setContractSubtype(error, ERROR_CONTRACT_SUBTYPE))
    }
  }
}

export const toggleContractSubtype = (subtype, toggle) => async (dispatch) => {
  const toggleEndPoint = `${contractSubtypeEndPoint}/${
    toggle ? 'enable' : 'disable'
  }`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: subtype.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setContractSubtype(
        { res, toggle: toggle },
        TOGGLE_CONTRACT_SUBTYPE,
        null,
        subtype
      )
    )
  }
}
