import history from '../../history'
import {
  SET_CONTRACTS,
  SET_CONTRACT,
  CREATE_CONTRACT,
  DELETE_CONTRACT,
  UPDATE_CONTRACT,
  ERROR_CONTRACT,
  UNSET_CONTRACTS,
  UNSET_CONTRACT_ACTION,
  UNSET_ERROR_CONTRACT,
  APPEND_CONTRACTS,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractEndPoint = '/contracts/contract'

// Passing data back to reducers
const setContract = (obj, type, contract, options) => {
  const action = { type: type, payload: obj }
  if (contract) action.currentContract = contract
  // Shimin: add fetch time for loading contracts to only keep last fetch data
  if (type === SET_CONTRACTS || type === APPEND_CONTRACTS) {
    action.fetchTime = options?.fetchTime || 0
    action.isLoadingMore =
      // check to see if we got less than the page size we asked for
      (options?.pageSize && obj?.length && options.pageSize === obj.length) ||
      // check to see if we got less than the initial page size we asked for
      (options?.pageNumber === 0 &&
        options?.initialPageSize &&
        options?.pageSize &&
        obj?.length &&
        options.initialPageSize === obj.length) ||
      // check to see if we got less than the page size we asked for with an offset for the initial page size
      (options?.pageNumber === 1 &&
        options?.initialPageSize &&
        options?.pageSize &&
        obj?.length &&
        options.pageSize - options.initialPageSize === obj.length)
  }
  return action
}

// Non-API calls
export const useClearContracts = (dispatch) => async () => {
  dispatch(setContract({}, UNSET_CONTRACTS))
}
export const clearContractErrors = () => async (dispatch) => {
  dispatch(setContract({}, UNSET_ERROR_CONTRACT))
}
export const clearContractAction = () => async (dispatch) => {
  dispatch(setContract({}, UNSET_CONTRACT_ACTION))
}

// API calls
// pass an array of query parameters for filtering
window.currentContractFetchTime = 0
window.currentContractFetchParameters = null
export const fetchContracts = (queryParameters) => async (dispatch) => {
  if (
    fetchToken() &&
    window.currentContractFetchParameters !== queryParameters
  ) {
    const fetchPath = window.location.pathname
    const fetchTime = Date.now()
    window.currentContractFetchTime = fetchTime
    window.currentContractFetchParameters = queryParameters
    const initialPageSize = 50
    const pageSize = 250
    const pageNumber = 0
    const endPoint = `${contractEndPoint}?${[
      `initial_page_size=${initialPageSize}`,
      `page_size=${pageSize}`,
      `page_number=${pageNumber}`,
      queryParameters,
    ].join('&')}`

    const res = await restApi.get(endPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    const action = setContract(res.data, SET_CONTRACTS, false, {
      fetchTime,
      initialPageSize,
      pageSize,
      pageNumber,
    })
    dispatch(action)

    if (action.isLoadingMore) {
      fetchAdditionalContracts(
        fetchTime,
        fetchPath,
        queryParameters,
        initialPageSize,
        pageSize,
        pageNumber + 1
      )(dispatch)
    }

    window.currentContractFetchParameters = null
  }
}

const fetchAdditionalContracts =
  (
    fetchTime,
    fetchPath,
    queryParameters,
    initialPageSize,
    pageSize,
    pageNumber
  ) =>
  async (dispatch) => {
    if (
      fetchToken() &&
      window.location.pathname === fetchPath &&
      window.currentContractFetchTime === fetchTime
    ) {
      const endPoint = `${contractEndPoint}?${[
        `initial_page_size=${initialPageSize}`,
        `page_size=${pageSize}`,
        `page_number=${pageNumber}`,
        queryParameters,
      ].join('&')}`
      const res = await restApi.get(endPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })

      if (
        window.location.pathname === fetchPath &&
        window.currentContractFetchTime === fetchTime
      ) {
        const action = setContract(res.data, APPEND_CONTRACTS, false, {
          fetchTime,
          initialPageSize,
          pageSize,
          pageNumber,
        })
        dispatch(action)

        if (action.isLoadingMore) {
          fetchAdditionalContracts(
            fetchTime,
            fetchPath,
            queryParameters,
            initialPageSize,
            pageSize,
            pageNumber + 1
          )(dispatch)
        }
      }
    }
  }

export const fetchContract = (id) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${contractEndPoint}/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContract(res.data, SET_CONTRACT, res.data))
  }
}

export const createContract = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContract(res.data, CREATE_CONTRACT))
      const url = `/contracts${
        res?.data?.created_items?.[0]?.id
          ? `/${res.data.created_items[0].id}`
          : ''
      }`
      history.push(url)
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContract(error, ERROR_CONTRACT))
    }
  }
}

export const deleteContract = (contract) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractEndPoint, {
      data: { id: contract.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContract(res.data, DELETE_CONTRACT, contract))
  }
}

export const updateContract = (params, contract) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contractEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContract(res.data, UPDATE_CONTRACT, contract))
      history.push(`/contracts/${contract.id}`)
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setContract(error, ERROR_CONTRACT))
    }
  }
}
