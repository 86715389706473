import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBRow, MDBCol } from "mdbreact";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";
import { fetchSitePropertyOptions } from "actions/sites/sitePropertyActions";

import Section from "components/common/Section";
import Modal from "components/common/Modal";
import BadgeLabel from "components/common/badges/BadgeLabel";
import CardPanel from "components/common/panel/CardPanel";
import CustomPropertyDisplay from "components/common/properties/CustomPropertyDisplay";
import SiteDelete from "./SiteDelete";

import { formatDateToLocal } from "utils/commonUtils";
import { isUserNotReadOnly } from "utils/authUtils";

const SiteShow = ({ match }) => {
  const siteId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();
  const { sitesAvailable } = useSelector(state => state.sites);
  const { sitePropertyOptions } = useSelector(state => state.siteProperties);
  const [currentSite, setCurrentSite] = useState(sitesAvailable[siteId]);
  const auth = useAuth();
  const dispatch = useDispatch();

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Site");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  useEffect(() => {
    dispatch(fetchSitePropertyOptions());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(sitesAvailable).length) {
      setCurrentSite(sitesAvailable[siteId]);
    }
  }, [sitesAvailable, siteId, setCurrentSite]);

  if (currentSite) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: isUserNotReadOnly(auth?.user?.user_level)
        ? [
            {
              name: "View",
              url: `/sites/${currentSite.id}`,
              exact: true
            },
            {
              name: "Edit",
              url: `/sites/${currentSite.id}/edit`,
              exact: true
            }
          ]
        : []
    };

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: "Delete",
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: e => toggleModal(e, "delete", currentSite),
          className: "text-danger"
        }
      });
    }

    return (
      <Section
        header={`${currentSite.name}${
          currentSite.website ? ` (${currentSite.website})` : ""
        }`}
        breadcrumbs
        menu={sectionHeaderMenu}
      >
        <MDBRow>
          <MDBCol md="6">
            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Name</BadgeLabel>
              {currentSite.name}
            </div>
            <hr className="mt-3 mb-3" />
            {currentSite.website ? (
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Website</BadgeLabel>
                {currentSite.website}
              </div>
            ) : (
              ""
            )}
            {currentSite.email ? (
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Email</BadgeLabel>
                {currentSite.email}
              </div>
            ) : (
              ""
            )}
            {currentSite.phone ? (
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Phone</BadgeLabel>
                {currentSite.phone}
              </div>
            ) : (
              ""
            )}
            <hr className="mt-3 mb-3" />
            <div>
              <strong>Created on: </strong>
              {formatDateToLocal(currentSite.created_at, "LLLL")}
            </div>
          </MDBCol>
          <MDBCol md="6">
            <CardPanel title="Location" className="mb-3">
              <MDBRow className="mb-3">
                <MDBCol md="6">
                  {currentSite.address_1 ? (
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Street Address</BadgeLabel>
                      {currentSite.address_1}
                    </div>
                  ) : (
                    ""
                  )}
                </MDBCol>
                <MDBCol md="6">
                  {currentSite.address_2 ? (
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Apt, Unit, Suite, etc</BadgeLabel>
                      {currentSite.address_2}
                    </div>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                {currentSite.city ? (
                  <MDBCol md="6" lg="3">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>City</BadgeLabel>
                      {currentSite.city}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
                {currentSite.state ? (
                  <MDBCol md="6" lg="4">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>State / Province / Region</BadgeLabel>
                      {currentSite.state}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
                {currentSite.country.name ? (
                  <MDBCol md="6" lg="2">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Country</BadgeLabel>
                      {currentSite.country.name}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
                {currentSite.zip ? (
                  <MDBCol md="6" lg="3">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Zip</BadgeLabel>
                      {currentSite.zip}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
              </MDBRow>
            </CardPanel>
            {sitePropertyOptions?.custom_properties?.length ? (
              <CustomPropertyDisplay
                propertyOptions={sitePropertyOptions.custom_properties}
                customProperties={currentSite.custom_properties}
              />
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <SiteDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      </Section>
    );
  }

  return "";
};

export default SiteShow;
