import React from "react";
import { Link } from "react-router-dom";
import { MDBBreadcrumbItem } from "mdbreact";

const BreadcrumbSiteItems = ({ match }) => {
  const { path } = match;
  const renderSiteListItem = () => {
    return (
      <MDBBreadcrumbItem>
        <Link to="/sites">Sites</Link>
      </MDBBreadcrumbItem>
    );
  };
  const renderSiteCreateItem = () => {
    if (path === "/sites/new") {
      return <MDBBreadcrumbItem active>Create</MDBBreadcrumbItem>;
    }
    return "";
  };
  const renderSiteShowItem = () => {
    if (path === "/sites/:id") {
      return <MDBBreadcrumbItem active>#{match.params.id}</MDBBreadcrumbItem>;
    } else if (path === "/sites/:id/edit") {
      return (
        <MDBBreadcrumbItem>
          <Link to={`/sites/${match.params.id}`}>#{match.params.id}</Link>
        </MDBBreadcrumbItem>
      );
    }
    return "";
  };
  const renderSiteEditItem = () => {
    if (path === "/sites/:id/edit") {
      return <MDBBreadcrumbItem active>Edit</MDBBreadcrumbItem>;
    }
    return "";
  };

  return (
    <>
      {renderSiteListItem()}
      {renderSiteCreateItem()}
      {renderSiteShowItem()}
      {renderSiteEditItem()}
    </>
  );
};

export default BreadcrumbSiteItems;
