import React from 'react'
import { isUserNotReadOnly } from 'utils/authUtils'

const EmptyList = ({
  name,
  namePlural,
  createElement,
  customText,
  userRole,
}) => {
  return (
    <p>
      {customText || `It appears there aren't any ${namePlural} created. `}
      {isUserNotReadOnly(userRole) ? (
        <>
          {createElement} the first {name} now!
        </>
      ) : (
        ''
      )}
    </p>
  )
}

export default EmptyList
