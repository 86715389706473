import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBRow, MDBCol } from "mdbreact";

import {
  fetchContractBudgets,
  updateContractBudget,
  clearContractBudgetErrors
} from "actions/contracts/contractBudgetActions";

import CurrencyInput from "components/CurrencyInput";
import ErrorPanel from "components/common/panel/ErrorPanel";

const ContractBudgetUpdate = ({
  contract,
  formId,
  params,
  setParams,
  selected,
  setSelected,
  closeModal
}) => {
  const { contractBudgetErrors, lastAction } = useSelector(
    state => state.contractBudgets
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (lastAction === "edit") {
      dispatch(fetchContractBudgets(contract.id));
      closeModal();
    }
  }, [lastAction, closeModal, contract.id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearContractBudgetErrors());
    };
  }, [dispatch]);

  const handleUpdate = (e, updateParams, updateItem) => {
    e.preventDefault();

    dispatch(updateContractBudget(contract.id, updateParams, updateItem));
  };
  const handleAmountChange = event => {
    const newAmount = event.target.value.replace(/[^0-9.-]+/g, "");
    setParams({
      ...params,
      amount: newAmount
    });
    setSelected({
      ...selected,
      amount: newAmount
    });
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleUpdate(e, params, selected)}
      className="form container"
      noValidate
    >
      <MDBRow>
        <MDBCol md="12">
          <div className="md-form md-outline">
            <CurrencyInput
              id="amount-input"
              placeholder="$0.00"
              value={params.amount === 0 ? "" : params.amount}
              onChange={handleAmountChange}
              className={["form-control", "required-label"].join(" ")}
              type="text"
              required
            />
            <label className="active" htmlFor="amount-input">
              Amount
            </label>
          </div>
        </MDBCol>
        <ErrorPanel results={contractBudgetErrors} />
      </MDBRow>
    </form>
  );
};

export default ContractBudgetUpdate;
