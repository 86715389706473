import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBCardBody, MDBIcon } from "mdbreact";

import { useAuth } from "hooks/useAuth";
import useModal from "hooks/useModal";
import {
  fetchDataImports,
  clearDataImportStatus
} from "actions/dataImportActions";

import Table from "components/common/tables/Table";
import Modal from "components/common/Modal";
import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import EmptyList from "components/common/EmptyList";
import DataImportCreate from "./DataImportCreate";

const DataImportList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    params,
    setParams,
    setModalState
  } = useModal();
  const auth = useAuth();
  const dispatch = useDispatch();

  // redux hooks
  const { data, status } = useSelector(state => state.dataImports);

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "#",
        field: "id",
        sort: "desc"
      },
      {
        label: "Type",
        field: "type",
        sort: "desc"
      },
      {
        label: "File",
        field: "filename",
        sort: "desc"
      },
      {
        label: "Status",
        field: "status",
        sort: "desc"
      },
      {
        label: "Date Created (UTC)",
        field: "created_at",
        date: true,
        sort: "desc"
      },
      {
        label: "Errors",
        field: "data_import_errors",
        sort: "desc"
      },
      {
        label: "",
        field: "buttons",
        sort: "disabled",
        minimal: "lg"
      }
    ]
  });

  const toggleModal = useCallback(
    (e, type) => {
      if (e) e.preventDefault();

      if (type === "new") {
        setHeaderText("New Data Import");
        setFooter(true);
        setActions([
          {
            text: "Submit",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
      }
      if (type) {
        setModalState(true);
      } else {
        setModalState(false);
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState]
  );

  const fetchTableLink = useCallback((type, item) => {
    switch (type) {
      case "show":
        return `/admin/imports/${item.id}`;
      default:
        return null;
    }
  }, []);

  // use effect setups
  useEffect(() => {
    dispatch(fetchDataImports());
    return () => {
      dispatch(clearDataImportStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (status.fetchSuccess) {
      const itemProcessing = _.find(data, item => {
        return item.status === "Processing";
      });
      if (itemProcessing?.id) {
        const interval = setInterval(() => {
          dispatch(fetchDataImports());
        }, 5000);
        return () => clearInterval(interval);
      }
    }
  }, [status.fetchSuccess, data, dispatch]);

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: (
          <>
            <MDBIcon icon="upload" /> Import File
          </>
        ),
        url: `/admin/imports`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: e => toggleModal(e, "new"),
          className: "btn-sm"
        }
      }
    ]
  };

  const renderModalBody = () => {
    if (headerText === "New Data Import") {
      return (
        <DataImportCreate
          formId={formId}
          params={params}
          setParams={setParams}
          closeModal={() => setModalState(false)}
        />
      );
    }

    return "";
  };

  const renderTable = () => {
    if (_.size(data) && status.fetchSuccess) {
      return (
        <Table
          section="Import Errors"
          tableData={tableData}
          setTableData={setTableData}
          fetchTableLink={fetchTableLink}
          showButton
          rowData={data}
          sort={["id", "desc"]}
          toggleModal={toggleModal}
          updatedState={status.fetchSuccess}
          downloadableCSV
        />
      );
    } else if (_.isEmpty(data) && status.fetchSuccess) {
      return (
        <EmptyList
          name="data import"
          namePlural="data imports"
          userRole={auth?.user?.user_level}
          createElement={
            <span className="link" onClick={e => toggleModal(e, "new")}>
              Create
            </span>
          }
        />
      );
    }

    return "";
  };

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>{renderTable()}</MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  );
};

export default DataImportList;
