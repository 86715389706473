import {
  createContractSubtype,
  clearContractSubtypeAction,
  clearContractSubtypeErrors,
} from 'actions/contracts/contractSubtypeActions'
import {
  fetchContractTypes,
  clearContractTypeOptions,
} from 'actions/contracts/contractTypeActions'
import AdminForm from 'components/admin/AdminForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ContractSubtypeCreate = ({ formId, params, setParams, closeModal }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { typeOptions } = useSelector((state) => state.contractTypes)
  const { lastAction, subtypeErrors } = useSelector(
    (state) => state.contractSubtypes
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractTypes('form', null, true))
    return () => {
      dispatch(clearContractTypeOptions())
      dispatch(clearContractSubtypeErrors())
      dispatch(clearContractSubtypeAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        name: '',
        contract_type_id: 0,
      })
    }
  }, [params, setParams])

  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) || !typeOptions.length ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        const newParams = { name: params.name }
        if (params.contract_type_id > 0)
          newParams.contract_type_id = params.contract_type_id

        dispatch(createContractSubtype(newParams))
      }}
      className="form container"
      noValidate
    >
      <AdminForm
        params={params}
        setParams={setParams}
        contractTypes={typeOptions}
        errors={subtypeErrors}
      />
    </form>
  )
}

export default ContractSubtypeCreate
