import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  deleteBudgetVisit,
  clearBudgetVisitAction
} from "actions/budgetVisitActions";

const BudgetVisitDelete = ({ formId, closeModal, selected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearBudgetVisitAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteBudgetVisit(selected));
    closeModal();
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this budget visit? This can not be
        undone.
      </div>
    </form>
  );
};

export default BudgetVisitDelete;
