import { selectObject } from "utils/formUtils";
import { SET_CURRENCIES, UNSET_CURRENCIES } from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  currencyOptions: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENCIES:
      const options = selectObject(
        action.payload,
        action.currentCurrency ? action.currentCurrency.id : 0
      );
      return { ...state, currencyOptions: options, lastAction: "fetch" };
    case UNSET_CURRENCIES:
      if (state.currencyOptions.length) {
        return { ...state, currencyOptions: [], lastAction: null };
      }
      return state;
    default:
      return state;
  }
};
