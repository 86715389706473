import { DEFAULT_STUDY_VAULE } from './InvoiceMilestonesList'
import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import { MDBRow, MDBCol, MDBInput } from 'mdbreact'
import React, { useEffect } from 'react'
import { inputErrorClass } from 'utils/formUtils'

const InvoiceMilestoneForm = ({
  params,
  setParams,
  selected,
  setSelected,
  typeOptions,
  statusOptions,
  locationOptions,
  studyOptions,
  setQueryParams,
  errors,
}) => {
  const handleType = (e) => {
    if (e !== 'Choose A Type') {
      const currentType = typeOptions.filter((type) => type.value === e)
      setParams({ ...params, contract_type_id: `${currentType[0].id}` })
      if (selected && selected.contract_type.id !== currentType[0].id) {
        setSelected({
          ...selected,
          contract_type: {
            id: currentType[0].id,
            name: currentType[0].value,
          },
        })
      }
    }
  }

  const handleStatus = (e) => {
    if (e !== 'Choose A Status') {
      const currentStatus = statusOptions.filter((status) => status.value === e)
      setParams({ ...params, contract_status_id: `${currentStatus[0].id}` })
      if (selected && selected.contract_status.id !== currentStatus[0].id) {
        setSelected({
          ...selected,
          contract_status: {
            id: currentStatus[0].id,
            name: currentStatus[0].value,
          },
        })
      }
    }
  }
  const handleLocation = (e) => {
    if (e !== 'Choose A Location') {
      const currentLocation = locationOptions.filter(
        (location) => location.value === e
      )
      setParams({
        ...params,
        contract_location_id: `${currentLocation[0].id}`,
      })
      if (selected && selected.contract_location.id !== currentLocation[0].id) {
        setSelected({
          ...selected,
          contract_location: {
            id: currentLocation[0].id,
            name: currentLocation[0].value,
          },
        })
      }
    }
  }

  const handleStudy = (e) => {
    if (e === 'Choose a Sponsor Study Name' || e === DEFAULT_STUDY_VAULE) {
      setParams({ ...params, study_id: '0' })
      setQueryParams('0')
    } else {
      const currentStudy = studyOptions.filter((study) => study.value === e)
      setQueryParams(currentStudy[0].id)
      setParams({ ...params, study_id: `${currentStudy[0].id}` })
      if (selected && selected.currentStudy.id !== currentStudy[0].id) {
        setSelected({
          ...selected,
          study_id: {
            id: currentStudy[0].id,
            name: currentStudy[0].value,
          },
        })
      }
    }
  }

  // on first load set the corrrect selected study value for the form
  useEffect(() => {
    if (studyOptions) {
      const currentStudy = studyOptions.filter((study) => study.checked)
      setParams({ ...params, study_id: `${currentStudy[0].id}` })
    }
  }, [])

  const handleName = (value) => {
    setParams({ ...params, name: value })
    if (selected) setSelected({ ...selected, name: value })
  }

  const handlePercent = (value) => {
    setParams({ ...params, percent_complete: value })

    if (selected) setSelected({ ...selected, percent_complete: value })
  }

  let typeSelect
  if (typeOptions) {
    typeSelect = (
      <MDBCol md="12">
        <Select
          id="select__types"
          options={typeOptions}
          getTextContent={handleType}
          label="Type"
          defaultOption="Choose A Type"
          additionalClasses="mb-0"
          search
          required
          errors={errors}
        />
      </MDBCol>
    )
  }

  let statusSelect
  if (statusOptions) {
    statusSelect = (
      <MDBCol md="12">
        <Select
          id="select__statuses"
          options={statusOptions}
          getTextContent={handleStatus}
          label="Status"
          defaultOption="Choose A Status"
          additionalClasses="mb-0"
          search
          required
          errors={errors}
        />
      </MDBCol>
    )
  }

  let locationSelect
  if (locationOptions) {
    locationSelect = (
      <MDBCol md="12">
        <Select
          id="select__locations"
          options={locationOptions}
          getTextContent={handleLocation}
          label="Location"
          defaultOption="Choose A Location"
          additionalClasses="mb-0"
          search
          required
          errors={errors}
        />
      </MDBCol>
    )
  }

  let studySelect
  if (studyOptions) {
    studySelect = (
      <MDBCol md="12">
        <Select
          id="select__study_id"
          options={studyOptions}
          getTextContent={handleStudy}
          label="Sponsor Study Name"
          defaultOption="Choose a Sponsor Study Name"
          additionalClasses="mb-0"
          search
          errors={errors}
        />
      </MDBCol>
    )
  }

  return (
    <MDBRow>
      <MDBCol md="12">
        <MDBInput
          autoFocus
          value={params.name}
          getValue={handleName}
          id="name-input"
          label="Name"
          type="text"
          outline
          required
          containerClass="required-label"
          className={`input-select${inputErrorClass(errors, !params.name)}`}
        />
      </MDBCol>
      {typeSelect}
      {statusSelect}
      {locationSelect}
      {studySelect}
      <MDBCol md="12">
        <MDBInput
          value={params.percent_complete}
          getValue={handlePercent}
          id="percent-complete-input"
          label="Percent Complete"
          type="number"
          min="1"
          max="100"
          step="1"
          outline
          required
          validate
          containerClass="required-label"
          className={`input-select${inputErrorClass(errors, !params.percent)}`}
        />
      </MDBCol>
      <ErrorPanel results={errors} />
    </MDBRow>
  )
}

export default InvoiceMilestoneForm
