import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import useRouter from "hooks/useRouter";
import { deleteSite, clearSiteAction } from "actions/sites/siteActions";

import history from "../../history";

const SiteDelete = ({ formId, closeModal, selected }) => {
  const routingParams = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearSiteAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteSite(selected));
    if (
      routingParams.match.path === "/sites/:id" ||
      routingParams.match.path === "/sites/:id/edit"
    ) {
      history.push("/sites");
    } else {
      closeModal();
    }
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this site? This can not be undone.
      </div>
    </form>
  );
};

export default SiteDelete;
