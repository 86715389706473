import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchContractTypes } from "actions/contracts/contractTypeActions";

import {
  clearContractValueErrors,
  clearContractValueAction,
  createContractValue,
  fetchAvailableCountries,
  setContractValueErrors
} from "actions/studies/studyContractValueActions";
import {
  contractFormParamValidator,
  ContractValueForm
} from "components/studies/contractValues/ContractValueForm";

const ContractValueCreate = ({
  studyId,
  formId,
  params,
  setParams,
  closeModal
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch Contract Types, Status, Locations
    dispatch(fetchContractTypes("form", null, true));
    dispatch(fetchAvailableCountries());
    return () => {
      // Cleanup error, actions, get updated milestone
      dispatch(clearContractValueErrors());
      dispatch(clearContractValueAction());
    };
  }, [dispatch]);

  const { lastAction, contractValueErrors, countryOptions } = useSelector(
    state => state.studyContractValues
  );

  const typeOptions = useSelector(state => state.contractTypes.typeOptions);

  // watch for create success
  useEffect(() => {
    if (lastAction === "create") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  // set initial form params
  useEffect(() => {
    if (_.isEmpty(params)) {
      const initialParams = {
        study_id: studyId,
        country_id: ""
      };
      typeOptions.forEach(v => {
        const contractParamName = `contract_type_${v.id}`;
        initialParams[contractParamName] = "0";
      });
      setParams(initialParams);
    }
  }, [params, setParams, studyId, typeOptions]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();

    const formErrors = contractFormParamValidator(createParams);
    if (formErrors.length) {
      dispatch(setContractValueErrors(formErrors));
    } else {
      const contractValueParams = new FormData();
      Object.keys(createParams).forEach(contractValueKey => {
        contractValueParams.append(
          contractValueKey,
          createParams[contractValueKey]
        );
      });
      dispatch(createContractValue(contractValueParams));
    }
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        <ContractValueForm
          params={params}
          setParams={setParams}
          countryOptions={countryOptions}
          typeOptions={typeOptions}
          errors={contractValueErrors}
        />
      </form>
    );
  }
  return "";
};

export default ContractValueCreate;
