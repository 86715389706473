import {
  clearSrDocumentSubtypeAction,
  clearSrDocumentSubtypeErrors,
  createSrDocumentSubtype,
} from 'actions/srDocuments/srDocumentSubtypeActions'
import {
  clearSrDocumentTypeOptions,
  fetchSrDocumentTypes,
} from 'actions/srDocuments/srDocumentTypeActions'
import SrAdminForm from 'components/admin/SrAdminForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const SrDocumentSubtypeCreate = ({ formId, params, setParams, closeModal }) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { typeOptions } = useSelector((state) => state.srDocumentTypes)
  const { lastAction, subtypeErrors } = useSelector(
    (state) => state.srDocumentSubtypes
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchSrDocumentTypes('form', null, true))
    return () => {
      dispatch(clearSrDocumentTypeOptions())
      dispatch(clearSrDocumentSubtypeErrors())
      dispatch(clearSrDocumentSubtypeAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        name: '',
        sr_document_type_id: 0,
      })
    }
  }, [params, setParams])

  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) || !typeOptions.length ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        const newParams = { name: params.name }
        if (params.sr_document_type_id > 0)
          newParams.sr_document_type_id = params.sr_document_type_id

        dispatch(createSrDocumentSubtype(newParams))
      }}
      className="form container"
      noValidate
    >
      <SrAdminForm
        params={params}
        setParams={setParams}
        srDocumentTypes={typeOptions}
        errors={subtypeErrors}
      />
    </form>
  )
}

export default SrDocumentSubtypeCreate
