import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  deleteContactProperty,
  clearContactPropertyAction
} from "actions/contacts/contactPropertyActions";

const ContactPropertyDelete = ({ formId, closeModal, selected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearContactPropertyAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteContactProperty(selected));
    closeModal();
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this contact property? This can not be
        undone.
      </div>
    </form>
  );
};

export default ContactPropertyDelete;
