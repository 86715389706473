import React from "react";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";

import ErrorPanel from "components/common/panel/ErrorPanel";
import Select from "components/common/forms/Select";

import { inputErrorClass, validatePositiveValue } from "utils/formUtils";

export const ContractValueForm = ({
  params,
  setParams,
  selected,
  setSelected,
  typeOptions,
  countryOptions,
  actionType,
  errors
}) => {
  const handleCountry = e => {
    if (e !== "Choose A Country") {
      const currentCountry = countryOptions.filter(
        country => country.value === e
      );
      setParams({
        ...params,
        country_id: `${currentCountry[0].id}`
      });

      if (selected) {
        setSelected({
          ...selected,
          country: {
            id: currentCountry[0].id,
            name: currentCountry[0].name
          }
        });
      }
    }
  };

  let contractTypeInputs = [];
  typeOptions.forEach(contractType => {
    const contractParamName = `contract_type_${contractType.id}`;

    const handleTypeN = value => {
      setParams({ ...params, [contractParamName]: value });
      // In the Update Case
      if (selected) setSelected({ ...selected, name: value });
    };

    let contractTypeInput = (
      <MDBCol md="12" key={contractType.id}>
        <MDBInput
          value={params[contractParamName]}
          getValue={handleTypeN}
          id={`${contractType.value}-input`}
          label={`${contractType.value} Amount`}
          type="number"
          min="0"
          step="0.01"
          outline
          className={`input-select${inputErrorClass(
            errors,
            !validatePositiveValue(params[contractParamName])
          )}`}
        />
      </MDBCol>
    );
    contractTypeInputs.push(contractTypeInput);
  });

  const contractTypeSelects = <>{contractTypeInputs}</>;

  return (
    <>
      <MDBCol md="12">
        <Select
          id="select__country"
          options={countryOptions}
          getTextContent={handleCountry}
          value={params.country_id}
          label="Country"
          defaultOption="Choose A Country"
          additionalClasses="mb-0"
          search
          required
          disabled={actionType === "update"}
          errors={errors}
        />
      </MDBCol>
      {contractTypeSelects}
      <MDBRow>
        <ErrorPanel results={errors} />
      </MDBRow>
    </>
  );
};

export const contractFormParamValidator = params => {
  let negativeContractValues = false;
  const formErrors = [];
  const negativeValueError = "Contract value needs to be a positive number.";
  const missingCountryIdError = "The country id field is required.";
  Object.keys(params).forEach(contractValueKey => {
    const val = params[contractValueKey];
    if (contractValueKey.startsWith("contract_type_")) {
      if (!validatePositiveValue(val) && !negativeContractValues) {
        formErrors.push(negativeValueError);
        negativeContractValues = true;
      }
    }
    if (contractValueKey.startsWith("country_id")) {
      if (!val) {
        formErrors.push(missingCountryIdError);
      }
    }
  });
  return formErrors;
};
