import _ from "lodash";
import moment from "moment";

import { showAvailable } from "utils/commonUtils";
import { selectObject } from "utils/formUtils";
import {
  SET_CONTRACT_TYPES,
  SET_CONTRACT_TYPE_OPTIONS,
  UNSET_CONTRACT_TYPE_OPTIONS,
  CREATE_CONTRACT_TYPE,
  DELETE_CONTRACT_TYPE,
  UNSET_CONTRACT_TYPE_ACTION,
  UPDATE_CONTRACT_TYPE,
  TOGGLE_CONTRACT_TYPE,
  ERROR_CONTRACT_TYPE,
  UNSET_ERROR_CONTRACT_TYPE
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  typesAvailable: {},
  typeOptions: [],
  typeErrors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_TYPES:
      return {
        ...state,
        lastAction: "fetch",
        typesAvailable: _.mapKeys(action.payload, "id")
      };
    case SET_CONTRACT_TYPE_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentContractType ? action.currentContractType.id : 0,
        action.noDefaultOptions ? false : true,
        "All Types"
      );
      return { ...state, typeOptions: options };
    case UNSET_CONTRACT_TYPE_OPTIONS:
      if (state.typeOptions) {
        return { ...state, typeOptions: [] };
      }
      return state;
    case UNSET_CONTRACT_TYPE_ACTION:
      return { ...state, lastAction: null };
    case CREATE_CONTRACT_TYPE:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        typesAvailable: {
          ...state.typesAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_CONTRACT_TYPE:
      const updatedItem = (state.typesAvailable[action.currentContractType.id] =
        action.currentContractType);
      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        updatedItem
      };
    case ERROR_CONTRACT_TYPE:
      return {
        ...state,
        typeErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_CONTRACT_TYPE:
      return {
        ...state,
        typeErrors: {}
      };
    case TOGGLE_CONTRACT_TYPE:
      const toggledItem = (state.typesAvailable[action.currentContractType.id] =
        action.currentContractType);
      toggledItem.is_available = action.payload.toggle;
      return {
        ...state,
        lastAction: action.payload.toggle ? "enable" : "disable",
        toggledItem
      };
    case DELETE_CONTRACT_TYPE:
      const updated = state.typesAvailable;
      _.unset(updated, `${action.currentContractType.id}`);
      return { ...state, lastAction: "delete", typesAvailable: updated };
    default:
      return state;
  }
};
