import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBRow, MDBCol } from "mdbreact";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";
import { fetchContactPropertyOptions } from "actions/contacts/contactPropertyActions";

import Section from "components/common/Section";
import Modal from "components/common/Modal";
import BadgeLabel from "components/common/badges/BadgeLabel";
import CardPanel from "components/common/panel/CardPanel";
import CustomPropertyDisplay from "components/common/properties/CustomPropertyDisplay";
import ContactDelete from "./ContactDelete";

import { formatDateToLocal } from "utils/commonUtils";
import { isUserNotReadOnly } from "utils/authUtils";

const ContactShow = ({ match }) => {
  const contactId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();
  const { contactsAvailable } = useSelector(state => state.contacts);
  const { propertyOptions } = useSelector(state => state.contactProperties);
  const [currentContact, setCurrentContact] = useState(
    contactsAvailable[contactId]
  );
  const dispatch = useDispatch();
  const auth = useAuth();

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Contact");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  useEffect(() => {
    dispatch(fetchContactPropertyOptions());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(contactsAvailable).length) {
      setCurrentContact(contactsAvailable[contactId]);
    }
  }, [contactsAvailable, contactId, setCurrentContact]);

  if (currentContact) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: isUserNotReadOnly(auth?.user?.user_level)
        ? [
            {
              name: "View",
              url: `/contacts/${currentContact.id}`,
              exact: true
            },
            {
              name: "Edit",
              url: `/contacts/${currentContact.id}/edit`,
              exact: true
            }
          ]
        : []
    };

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: "Delete",
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: e => toggleModal(e, "delete", currentContact),
          className: "text-danger"
        }
      });
    }

    return (
      <Section
        header={`Contact #${currentContact.id}`}
        breadcrumbs
        menu={sectionHeaderMenu}
      >
        <MDBRow>
          <MDBCol
            md="6"
            className="d-flex flex-column align-items-start justify-content-start"
          >
            <MDBRow>
              <MDBCol md="6">
                <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
                  <BadgeLabel>First Name</BadgeLabel>
                  {currentContact.first_name}
                </div>
              </MDBCol>
              <MDBCol md="6">
                <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
                  <BadgeLabel>Last Name</BadgeLabel>
                  {currentContact.last_name}
                </div>
              </MDBCol>
            </MDBRow>
            <hr className="mt-3 mb-3 w-100" />
            {currentContact.email ? (
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Email</BadgeLabel>
                {currentContact?.email || "No email selected"}
              </div>
            ) : (
              ""
            )}
            {currentContact.phone ? (
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Phone</BadgeLabel>
                {currentContact.phone}
              </div>
            ) : (
              ""
            )}
            {currentContact.org.name ? (
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Site</BadgeLabel>
                {currentContact.org.name}
              </div>
            ) : (
              ""
            )}
            <hr className="mt-3 mb-3 w-100" />
            <div className="flex-grow-1 d-flex align-items-end">
              <strong>Created on: </strong>
              {formatDateToLocal(currentContact.created_at, "LLLL")}
            </div>
          </MDBCol>
          <MDBCol md="6">
            <CardPanel title="Location" className="mb-3">
              <MDBRow className="mb-3">
                <MDBCol md="6">
                  {currentContact.address_1 ? (
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Street Address</BadgeLabel>
                      {currentContact.address_1}
                    </div>
                  ) : (
                    ""
                  )}
                </MDBCol>
                <MDBCol md="6">
                  {currentContact.address_2 ? (
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Apt, Unit, Suite, etc</BadgeLabel>
                      {currentContact.address_2}
                    </div>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-3">
                {currentContact.city ? (
                  <MDBCol md="6" lg="3">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>City</BadgeLabel>
                      {currentContact.city}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
                {currentContact.state ? (
                  <MDBCol md="6" lg="4">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>State / Province / Region</BadgeLabel>
                      {currentContact.state}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
                {currentContact.country.name ? (
                  <MDBCol md="6" lg="2">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Country</BadgeLabel>
                      {currentContact.country.name}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
                {currentContact.zip ? (
                  <MDBCol md="6" lg="3">
                    <div className="d-flex flex-column align-items-start">
                      <BadgeLabel>Zip</BadgeLabel>
                      {currentContact.zip}
                    </div>
                  </MDBCol>
                ) : (
                  ""
                )}
              </MDBRow>
            </CardPanel>
            {propertyOptions?.custom_properties?.length ? (
              <CustomPropertyDisplay
                propertyOptions={propertyOptions.custom_properties}
                customProperties={currentContact.custom_properties}
              />
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <ContactDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      </Section>
    );
  }

  return "";
};

export default ContactShow;
