import restApi from "apis/restApi";
import {
  INITIATE_REPORT,
  REQUEST_REPORT,
  CLEAR_REPORT,
  SET_REPORT
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const reportEndPoint = "/report";

// Pass data to reducers
export const setReport = (obj, type, headers, report) => {
  const action = { type: type, payload: obj, headers: headers };
  if (report) action.currentReport = report;
  return action;
};

export const fetchAndWaitForReport = report_request_id => async dispatch => {
  const getReport = async () =>
    await restApi.get(`${reportEndPoint}/status/${report_request_id}`, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });

  const waitForReport = async () => {
    await new Promise(res => setTimeout(res, 1500));
    const res = await getReport();
    if (res.status !== 200 || res.data?.type === "application/json") {
      return waitForReport();
    } else {
      return res;
    }
  };

  if (fetchToken()) {
    const res = await waitForReport();
    dispatch(setReport(res.data, SET_REPORT, res.headers));
  }
};

export const clearReport = () => async dispatch => {
  dispatch(setReport("", CLEAR_REPORT));
};

export const requestReport = (report_id, params) => async dispatch => {
  if (fetchToken()) {
    dispatch(setReport(null, INITIATE_REPORT));
    const res = await restApi.post(`${reportEndPoint}/${report_id}`, params, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setReport(res.data, REQUEST_REPORT));
  }
};
