import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  deleteCustomPropertyListItem,
  clearCustomPropertyListItemAction
} from "actions/properties/customPropertyListItemActions";

const CustomPropertyListItemDelete = ({
  propertyId,
  formId,
  closeModal,
  selected
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearCustomPropertyListItemAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteCustomPropertyListItem(propertyId, selected));
    closeModal();
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this custom property list item? This can
        not be undone.
      </div>
    </form>
  );
};

export default CustomPropertyListItemDelete;
