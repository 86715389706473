import {
  CREATE_SR_DOCUMENT_PROPERTY,
  DELETE_SR_DOCUMENT_PROPERTY,
  ERROR_SR_DOCUMENT_PROPERTY,
  LOADING_SR_DOCUMENT_PROPERTIES,
  SET_SR_DOCUMENT_PROPERTIES,
  SET_SR_DOCUMENT_PROPERTY_OPTIONS,
  TOGGLE_SR_DOCUMENT_PROPERTY_GUEST_VISIBILITY,
  UNSET_ERROR_SR_DOCUMENT_PROPERTY,
  UNSET_SR_DOCUMENT_PROPERTY_ACTION,
  UNSET_SR_DOCUMENT_PROPERTY_OPTIONS,
  UPDATE_SR_DOCUMENT_PROPERTY,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentPropertyEndPoint = '/property/custom/sr-document'

// Passing data back to reducers
const setSrDocumentProperty = (obj, type, srDocumentProperty) => {
  const action = { type: type, payload: obj }
  if (srDocumentProperty) action.currentSrDocumentProperty = srDocumentProperty
  return action
}

// Non-API calls
export const clearSrDocumentPropertyOptions = () => async (dispatch) => {
  dispatch(setSrDocumentProperty({}, UNSET_SR_DOCUMENT_PROPERTY_OPTIONS))
}
export const clearSrDocumentPropertyAction = () => async (dispatch) => {
  dispatch(setSrDocumentProperty({}, UNSET_SR_DOCUMENT_PROPERTY_ACTION))
}
export const clearSrDocumentPropertyErrors = () => async (dispatch) => {
  dispatch(setSrDocumentProperty({}, UNSET_ERROR_SR_DOCUMENT_PROPERTY))
}

// API calls
export const fetchSrDocumentProperties = () => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(srDocumentPropertyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentProperty(res.data, SET_SR_DOCUMENT_PROPERTIES))
  }
}

export const fetchSrDocumentPropertyOptions = () => async (dispatch) => {
  dispatch(setSrDocumentProperty([], LOADING_SR_DOCUMENT_PROPERTIES))

  if (fetchToken()) {
    const res = await restApi.get(`${srDocumentPropertyEndPoint}/options`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentProperty(res.data, SET_SR_DOCUMENT_PROPERTY_OPTIONS))
  }
}

export const createSrDocumentProperty = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentProperty(res.data, CREATE_SR_DOCUMENT_PROPERTY))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentProperty(error, ERROR_SR_DOCUMENT_PROPERTY))
    }
  }
}

export const deleteSrDocumentProperty = (property) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentPropertyEndPoint, {
      data: { id: property.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentProperty(res.data, DELETE_SR_DOCUMENT_PROPERTY, property)
    )
  }
}

export const updateSrDocumentProperty =
  (params, property) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(srDocumentPropertyEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setSrDocumentProperty(res.data, UPDATE_SR_DOCUMENT_PROPERTY, property)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setSrDocumentProperty(error, ERROR_SR_DOCUMENT_PROPERTY))
      }
    }
  }

export const toggleSrDocumentPropertyGuestVisibility =
  (type, toggle) => async (dispatch) => {
    const toggleEndPoint = `${srDocumentPropertyEndPoint}/${
      toggle ? 'guestVisibilityEnable' : 'guestVisibilityDisable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: type.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setSrDocumentProperty(
          { res, toggle: toggle },
          TOGGLE_SR_DOCUMENT_PROPERTY_GUEST_VISIBILITY,
          type
        )
      )
    }
  }
