/** @jsx jsx */
import _ from "lodash";
import { MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import { css, jsx } from "@emotion/core";

const errorPanel = css`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .modal-body & {
    margin-top: 1.5rem;
  }
`;

const inlineError = css`
  display: inline-block;
  margin-left: 0.25rem;
  &:first-of-type {
    margin-left: 0;
  }
`;

const ErrorPanel = ({ results }) => {
  const hasErrors = _.size(results);
  if (hasErrors) {
    return (
      <MDBCol className="error" md="12">
        <MDBCard
          color="red lighten-3"
          text="white"
          className="text-center"
          css={errorPanel}
        >
          <MDBCardBody>
            {results.error.map(error => {
              return (
                <span key={error} css={inlineError}>
                  {error}
                </span>
              );
            })}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    );
  }
  return "";
};

export default ErrorPanel;
