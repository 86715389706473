/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBBtn,
} from 'mdbreact'

const Navbar = ({ auth, openSideBar, logo, size, screens }) => {
  const logoCss = css`
    height: 25px;
    @media (min-width: ${screens.small}px) {
      height: 30px;
    }
    @media (min-width: ${screens.medium}px) {
      height: 40px;
    }
  `

  const accountBtn = css`
    width: 40px;
    height: 40px;
    text-align: center;
    box-shadow: none;
    &.btn {
      padding: 0;
      border-radius: 40px;
    }
    &:hover {
      box-shadow:
        0 2px 4px 0 rgba(0, 0, 0, 0.18),
        0 1px 8px 0 rgba(0, 0, 0, 0.15);
    }
  `

  return auth.user ? (
    <MDBNavbar color="white" light expand="md">
      <MDBNavbarNav className="flex-row align-items-center" left>
        {size.width < screens.xlarge
          ? [
              <MDBNavItem className="mr-3" key="menu">
                <MDBBtn
                  flat
                  size="lg"
                  style={{ padding: '0' }}
                  onClick={() => openSideBar('header')}
                >
                  <MDBIcon size="lg" icon="bars" />
                </MDBBtn>
              </MDBNavItem>,
              <MDBNavbarBrand href="/" key="logo">
                <img
                  src={logo}
                  alt="epicenter portal"
                  height="40"
                  css={logoCss}
                />
              </MDBNavbarBrand>,
            ]
          : ''}
      </MDBNavbarNav>
      <MDBNavbarNav right>
        <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle color="white" css={accountBtn}>
              <MDBIcon icon="ellipsis-v" />
            </MDBDropdownToggle>
            <MDBDropdownMenu color="secondary" right>
              <MDBDropdownItem href={`/change-password`}>
                Change Password
              </MDBDropdownItem>
              <MDBDropdownItem onClick={() => auth.signout()}>
                Log Out
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
      </MDBNavbarNav>
    </MDBNavbar>
  ) : (
    ''
  )
}

export default Navbar
