import UserDelete from './UserDelete'
import UserReset from './UserReset'
import UserUpdate from './UserUpdate'
import { fetchUsers, toggleUser } from 'actions/users/userActions'
import UserGuestStudiesDisplay from 'components/admin/users/UserGuestStudiesDisplay'
import Modal from 'components/common/Modal'
import BadgeLabel from 'components/common/badges/BadgeLabel'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import {
  MDBCard,
  MDBIcon,
  MDBBtn,
  MDBCardTitle,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBSwitch,
} from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatSnakeCase } from 'utils/commonUtils'

const MODAL_TYPES = {
  NONE: 0,
  EDIT: 1,
  DELETE: 2,
  RESET_PASSWORD: 3,
  RESET_MFA: 4,
}

const UserShow = ({ match }) => {
  const { id } = match.params
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()
  const dispatch = useDispatch()

  // redux hooks
  const { usersAvailable } = useSelector((state) => state.users)
  const { studiesAvailable } = useSelector((state) => state.studies)

  // local state
  const [currentUser, setCurrentUser] = useState(usersAvailable[id])
  const [passChangedMessage, setPassResetMessage] = useState(false)
  const [mfaResetMessage, setMfaResetMessage] = useState(false)
  const [tableLoading, setTableLoading] = useState(true)
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        minimal: 'id',
      },
      {
        label: 'Sponsor Study Name',
        field: 'name',
        minimal: 'lg',
      },
      {
        label: 'Protocol',
        field: 'protocol',
      },
    ],
  })

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      const open = (headerText, buttonText, buttonColor) => {
        setModalState(true)
        setFooter(true)
        setSelected(item)
        setHeaderText(headerText)
        setActions([
          {
            text: buttonText,
            color: buttonColor,
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      }

      switch (type) {
        case MODAL_TYPES.EDIT:
          open('Edit User', 'Update', 'primary')
          break
        case MODAL_TYPES.DELETE:
          open('Delete User', 'Delete', 'danger')
          break
        case MODAL_TYPES.RESET_PASSWORD:
          open("Reset User's Password", 'Reset', 'danger')
          break
        case MODAL_TYPES.RESET_MFA:
          open("Reset User's MFA", 'Reset', 'danger')
          break
        default:
          setModalState(false)
          break
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  useEffect(() => {
    if (_.size(usersAvailable)) {
      setCurrentUser(usersAvailable[id])
    }
  }, [usersAvailable, id, setCurrentUser])

  const buildTableData = useCallback(setTableData, [])
  const buildTableLoading = useCallback(setTableLoading, [])
  useEffect(() => {
    if (
      currentUser?.user_level === 'guest' &&
      !tableData.rows &&
      _.size(studiesAvailable)
    ) {
      const rowArray = () => {
        if (currentUser.studies.length) {
          return _.filter(studiesAvailable, (study) =>
            currentUser.studies.includes(study.id)
          ).map((study) => ({
            id: study.id,
            name: study.name,
            protocol: study.protocol,
          }))
        } else {
          return []
        }
      }

      buildTableData((prev) => ({
        ...prev,
        rows: rowArray(),
      }))
      buildTableLoading(false)
    }
  }, [
    currentUser,
    studiesAvailable,
    tableData,
    buildTableData,
    buildTableLoading,
  ])

  const callPassResetSet = useCallback(setPassResetMessage, [
    setPassResetMessage,
  ])
  useEffect(() => {
    const timer = setTimeout(() => {
      if (passChangedMessage) callPassResetSet(false)
    }, 6000)
    return () => clearTimeout(timer)
  }, [passChangedMessage, callPassResetSet])

  const handleAvailabilityChange = (item, toggle) => {
    dispatch(toggleUser(item, toggle))
  }

  // use effect setups
  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    leftSide: (
      <Link to="/admin/users">
        <MDBIcon icon="fas fa-chevron-left" /> Users
      </Link>
    ),
    linkItems: [
      {
        name: 'Edit',
        url: `/#!`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: (e) => toggleModal(e, MODAL_TYPES.EDIT, currentUser),
          className: 'btn-sm',
        },
      },
      {
        name: 'Delete',
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: (e) => toggleModal(e, MODAL_TYPES.DELETE, currentUser),
          className: 'text-danger',
        },
      },
    ],
  }

  const renderModalBody = () => {
    if (headerText === 'Edit User') {
      return (
        <UserUpdate
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => setModalState(false)}
        />
      )
    } else if (headerText === 'Delete User') {
      return (
        <UserDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      )
    } else if (headerText === "Reset User's Password") {
      return (
        <UserReset
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
          setPassResetMessage={setPassResetMessage}
          shouldResetPassword
        />
      )
    } else if (headerText === "Reset User's MFA") {
      return (
        <UserReset
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
          setPassResetMessage={setMfaResetMessage}
          shouldResetMFA
        />
      )
    }

    return ''
  }

  if (_.isEmpty(currentUser)) {
    return false
  }

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>
        <MDBRow>
          <MDBCol md="6">
            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>First Name</BadgeLabel>
              {currentUser.first_name}
            </div>
            <hr className="mt-3 mb-3" />
            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Last Name</BadgeLabel>
              {currentUser.last_name}
            </div>
            <hr className="mt-3 mb-3" />
            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Email</BadgeLabel>
              {currentUser.email}
            </div>
          </MDBCol>
          <MDBCol md="6">
            <MDBCard className="card-panel">
              <MDBCardBody className="rgba-grey-slight dark-grey-text">
                <MDBCardTitle>Options</MDBCardTitle>
                <div className="mb-3">
                  <BadgeLabel>Role</BadgeLabel>
                  <div>{formatSnakeCase(currentUser.user_level)}</div>
                </div>
                <div>
                  <BadgeLabel>Active</BadgeLabel>
                  <MDBSwitch
                    className="availability-switch"
                    checked={currentUser.is_active}
                    getValue={(toggle) =>
                      handleAvailabilityChange(currentUser, toggle)
                    }
                  />
                </div>
                <div>
                  <MDBBtn
                    onClick={(e) =>
                      toggleModal(e, MODAL_TYPES.RESET_PASSWORD, currentUser)
                    }
                    flat
                    className="m-0 my-3 px-0 py-1 primary-color-text"
                    size="sm"
                  >
                    Change Password
                  </MDBBtn>
                  {passChangedMessage ? (
                    <span className="small success-text ml-2">
                      Password Changed Successfully!
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  <MDBBtn
                    onClick={(e) =>
                      toggleModal(e, MODAL_TYPES.RESET_MFA, currentUser)
                    }
                    flat
                    className="m-0 px-0 py-1 primary-color-text"
                    size="sm"
                  >
                    Reset MFA
                  </MDBBtn>
                  {mfaResetMessage ? (
                    <span className="small success-text ml-2">
                      MFA Reset Successfully!
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <UserGuestStudiesDisplay
            loading={tableLoading}
            user={currentUser}
            tableData={tableData}
          />
        </MDBRow>
      </MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  )
}

export default UserShow
