import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateBudgetVisit,
  clearBudgetVisitAction
} from "actions/budgetVisitActions";

import AdminForm from "components/admin/AdminForm";

const BudgetVisitUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected,
  setSelected
}) => {
  const { lastAction, budgetVisitErrors } = useSelector(
    state => state.budgetVisits
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        name: selected.name
      });
    }
  }, [params, setParams, selected]);

  // watch for success
  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  useEffect(() => {
    return () => {
      dispatch(clearBudgetVisitAction());
    };
  }, [dispatch]);

  const handleSubmit = (e, updateParams, updateItem) => {
    e.preventDefault();

    dispatch(updateBudgetVisit(updateParams, updateItem));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params, selected)}
        className="form container"
      >
        <AdminForm
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          errors={budgetVisitErrors}
        />
      </form>
    );
  }

  return "";
};

export default BudgetVisitUpdate;
