import React, { useState } from "react";
import { updateUserPassword } from "utils/authUtils";
import { useAuth } from "hooks/useAuth.js";
import { Redirect } from "react-router-dom";
import Section from "components/common/Section";
import ProfileForm from "./ProfileForm";

const ProfileShow = ({ match }) => {
  const auth = useAuth();
  const [userErrors, setUserErrors] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [userSuccessMessage, setUserSuccessMessage] = useState([]);

  const handleSubmission = async (
    e,
    oldPassword,
    newPassword,
    confirmNewPassword
  ) => {
    e.preventDefault();
    setUserErrors([]);
    setUserSuccessMessage([]);
    const userParams = {
      cur_password: oldPassword,
      new_password: newPassword,
      new_password_2: confirmNewPassword,
      email: auth?.user?.email
    };
    const isForceNewPassword = auth?.user?.force_new_password;

    try {
      const res = await updateUserPassword(userParams, auth);
      const userClone = Object.assign({}, auth?.user);
      userClone.force_new_password = false;
      auth.setUser(userClone);
      if (isForceNewPassword) {
        setUserSuccessMessage(["Password reset successfully, redirecting..."]);
        // Redirect back to home page after a second
        await new Promise(r => setTimeout(r, 1000));
        setRedirect(true);
      } else {
        setUserSuccessMessage(["Password updated"]);
      }
    } catch (err) {
      const errorMessages = [];
      if (err.response.data.message) {
        errorMessages.push(err.message, err.response.data.message);
      }
      if (err?.response?.data?.error?.length > 0) {
        errorMessages.push(...err.response.data.error);
      }
      setUserErrors(errorMessages);
    }
  };

  const description = auth?.user?.force_new_password
    ? "You must reset your password before proceeding"
    : "Change your password";
  return redirect ? (
    <Redirect to="/" />
  ) : (
    <Section
      header="Change Password"
      description={description}
      loading={!auth?.user?.id}
    >
      <ProfileForm
        btnText="Change Password"
        user={auth.user}
        submit={handleSubmission}
        errors={userErrors}
        success={userSuccessMessage}
        userCanEdit={auth?.user?.id?.toString()}
      />
    </Section>
  );
};

export default ProfileShow;
