import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MDBCol, MDBRow, MDBCardBody, MDBSpinner } from "mdbreact";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";

import { fetchContractActivityAlert } from "actions/contracts/contractActivityAlertActions";

import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import Modal from "components/common/Modal";
import BadgeLabel from "components/common/badges/BadgeLabel";
import ContractActivityAlertDelete from "./ContractActivityAlertDelete";

import { isUserNotReadOnly } from "utils/authUtils";
import { formatDateToLocal } from "utils/commonUtils";

const ContractActivityAlertShow = ({ match }) => {
  const activityAlertId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();

  const { activityAlertsAvailable, loading } = useSelector(
    state => state.contractActivityAlerts
  );

  const [currentActivityAlert, setCurrentActivityAlert] = useState(
    activityAlertsAvailable[activityAlertId]
  );

  const auth = useAuth();
  const dispatch = useDispatch();

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Contact");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  useEffect(() => {
    dispatch(fetchContractActivityAlert());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(activityAlertsAvailable).length) {
      setCurrentActivityAlert(activityAlertsAvailable[activityAlertId]);
    }
  }, [
    activityAlertsAvailable,
    activityAlertId,
    setCurrentActivityAlert,
    loading
  ]);

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: []
  };

  const renderBody = () => {
    if (loading) {
      return <MDBSpinner />;
    }
    if (_.size(currentActivityAlert)) {
      sectionHeaderMenu.linkItems = isUserNotReadOnly(auth?.user?.user_level)
        ? [
            {
              name: "View",
              url: `/admin/contracts/activity-alerts/${currentActivityAlert.id}`,
              exact: true
            },
            {
              name: "Edit",
              url: `/admin/contracts/activity-alerts/${currentActivityAlert.id}/edit`,
              exact: true
            }
          ]
        : [];

      if (auth?.user?.is_admin) {
        sectionHeaderMenu.linkItems.push({
          name: "Delete",
          url: `/#!`,
          exact: true,
          additionalProps: {
            onClick: e => toggleModal(e, "delete", currentActivityAlert),
            className: "text-danger"
          }
        });
      }
      return (
        <MDBCardBody>
          <MDBRow>
            <MDBCol
              md="6"
              className="d-flex flex-column align-items-start justify-content-start"
            >
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Emails</BadgeLabel>
                {currentActivityAlert?.email ?? ""}
              </div>
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Contract Location</BadgeLabel>
                {currentActivityAlert?.contract_location?.name ?? ""}
              </div>
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>User</BadgeLabel>
                {currentActivityAlert?.contract_property?.name ?? ""}
              </div>
            </MDBCol>
            <MDBCol
              md="6"
              className="d-flex flex-column align-items-start justify-content-start"
            >
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Contract Type</BadgeLabel>
                {currentActivityAlert?.contract_type.name ?? ""}
              </div>
              <div className="h5 d-flex flex-column align-items-start mb-3 font-weight-normal">
                <BadgeLabel>Contract Status</BadgeLabel>
                {currentActivityAlert?.contract_status.name ?? ""}
              </div>
            </MDBCol>
          </MDBRow>
          <hr className="mt-3 mb-3 w-100" />
          <div className="flex-grow-1 d-flex align-items-end">
            <strong>Created on: </strong>{" "}
            {formatDateToLocal(currentActivityAlert.created_at, "LLLL")}
          </div>
        </MDBCardBody>
      );
    }
    return "";
  };

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      {renderBody()}
      {auth?.user?.is_admin ? (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <ContractActivityAlertDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default ContractActivityAlertShow;
