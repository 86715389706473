import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";

import {
  clearContactErrors,
  clearContactAction
} from "actions/contacts/contactActions";
import { clearCountries } from "actions/countryActions";
import {
  fetchContactPropertyOptions,
  fetchContactProperties
} from "actions/contacts/contactPropertyActions";
import { clearSiteOptions } from "actions/sites/siteActions";

import ErrorPanel from "components/common/panel/ErrorPanel";
import Select from "components/common/forms/Select";
import BadgeLabel from "components/common/badges/BadgeLabel";
import CustomPropertyManagement from "components/common/properties/CustomPropertyManagement";

import { inputErrorClass } from "utils/formUtils";

const ContactForm = ({
  submit,
  contact,
  countries,
  sites,
  lastSiteAction,
  errors,
  customProperties,
  setCustomProperties
}) => {
  const dispatch = useDispatch();

  const { propertyOptions } = useSelector(state => state.contactProperties);

  const [contactParams, setContactParams] = useState({
    first_name: contact ? contact.first_name : "",
    last_name: contact ? contact.last_name : "",
    email: contact && contact.email ? contact.email : "",
    phone: contact && contact.phone ? contact.phone : "",
    address_1: contact && contact.address_1 ? contact.address_1 : "",
    address_2: contact && contact.address_2 ? contact.address_2 : "",
    city: contact && contact.city ? contact.city : "",
    state: contact && contact.state ? contact.state : "",
    zip: contact && contact.zip ? contact.zip : ""
  });

  const handleInputChange = (value, key) => {
    setContactParams({ ...contactParams, [key]: value });
  };

  useEffect(() => {
    dispatch(fetchContactPropertyOptions());
    return () => {
      dispatch(clearContactAction());
      dispatch(clearContactErrors());
      dispatch(fetchContactProperties());
      dispatch(clearCountries());
      dispatch(clearSiteOptions());
    };
  }, [dispatch]);

  return (
    <form
      id="form_contact"
      className="form row"
      onSubmit={e => submit(e, contactParams, countries, sites)}
      noValidate
    >
      <MDBCol md="6">
        <MDBInput
          value={contactParams.first_name}
          getValue={value => handleInputChange(value, "first_name")}
          id="contact_first-name-input"
          label="First Name"
          outline
          required
          autoFocus
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !contactParams.first_name
          )}`}
        />
        <MDBInput
          value={contactParams.last_name}
          getValue={value => handleInputChange(value, "last_name")}
          id="contact_last-name-input"
          label="Last Name"
          outline
          required
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !contactParams.last_name
          )}`}
        />
        <MDBRow>
          <MDBCol md="6">
            <MDBInput
              value={contactParams.email}
              getValue={value => handleInputChange(value, "email")}
              id="contact_email-input"
              label="Email"
              outline
            />
          </MDBCol>
          <MDBCol md="6">
            <MDBInput
              value={contactParams.phone}
              getValue={value => handleInputChange(value, "phone")}
              id="contact_phone-input"
              label="Phone"
              outline
            />
          </MDBCol>
        </MDBRow>
        {sites.length ? (
          <Select
            id="select__site"
            options={sites}
            label="Site"
            defaultOption="Choose A Site"
            search
          />
        ) : (
          ""
        )}
        {!sites.length && lastSiteAction !== null ? (
          <>
            <BadgeLabel>Site</BadgeLabel>
            <div className="text-muted small">No sites have been created.</div>
          </>
        ) : (
          ""
        )}
      </MDBCol>
      <MDBCol md="6">
        <MDBInput
          value={contactParams.address_1}
          getValue={value => handleInputChange(value, "address_1")}
          id="contact_address-1-input"
          label="Street Address"
          outline
        />
        <MDBInput
          value={contactParams.address_2}
          getValue={value => handleInputChange(value, "address_2")}
          id="contact_address-2-input"
          label="Apartment, suite, building, etc"
          outline
        />
        <MDBRow>
          <MDBCol md="5">
            <MDBInput
              value={contactParams.city}
              getValue={value => handleInputChange(value, "city")}
              id="contact_city-input"
              label="City"
              outline
            />
          </MDBCol>
          <MDBCol md="7">
            <MDBInput
              value={contactParams.state}
              getValue={value => handleInputChange(value, "state")}
              id="contact_state-input"
              label="State / Province / Region"
              outline
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="4">
            <MDBInput
              value={contactParams.zip}
              getValue={value => handleInputChange(value, "zip")}
              id="contact_zip-input"
              label="Postal Code"
              outline
            />
          </MDBCol>
          <MDBCol md="8">
            {countries.length ? (
              <Select
                id="select__country"
                options={countries}
                label="Country"
                defaultOption="Choose A Country"
                required
                search
                errors={errors}
              />
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <CustomPropertyManagement
        params={contactParams}
        setParams={setContactParams}
        current={contact ? contact : false}
        propertyOptions={propertyOptions?.custom_properties}
        customProperties={customProperties || false}
        setCustomProperties={setCustomProperties || undefined}
      />
      <ErrorPanel results={errors} />
    </form>
  );
};

export default ContactForm;
