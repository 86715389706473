import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createUser,
  clearUserErrors,
  clearUserAction
} from "actions/users/userActions";

import UserForm from "./UserForm";

const UserCreate = ({ formId, params, setParams, closeModal }) => {
  const { lastAction, userErrors } = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearUserErrors());
      dispatch(clearUserAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        first_name: "",
        last_name: "",
        email: "",
        user_level: ""
      });
    }
  }, [params, setParams]);

  // watch for success
  useEffect(() => {
    if (lastAction === "create") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();

    dispatch(createUser(createParams));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        <UserForm params={params} setParams={setParams} errors={userErrors} />
      </form>
    );
  }

  return "";
};

export default UserCreate;
