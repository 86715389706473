// remove trailing zeroes, commas for large numbers
export const formatNumber = (number, options) => {
  if (isNaN(Number(number))) {
    return "";
  }
  // two decimal places if there are numbers after the decimal
  let numberWithoutZeroes = +Number(number).toFixed(2);
  let stringWithoutZeroes = numberWithoutZeroes.toString();
  if (options?.prepend) {
    stringWithoutZeroes = `${options.prepend}${stringWithoutZeroes}`;
  }
  return stringWithoutZeroes.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
