import budgetVisitReducer from './budgetVisitReducer'
import contractActivityChartsReducer from './charts/contractActivityChartReducer'
import srDocumentActivityChartsReducer from './charts/srDocumentActivityChartsReducer'
import contactPropertyReducer from './contacts/contactPropertyReducer'
import contactReducer from './contacts/contactReducer'
import contractActivityAlertReducer from './contracts/contractActivityAlertReducer'
import contractActivityReducer from './contracts/contractActivityReducer'
import contractBudgetReducer from './contracts/contractBudgetReducer'
import contractColumnReducer from './contracts/contractColumnReducer'
import contractDocumentReducer from './contracts/contractDocumentReducer'
import contractDocumentTypeReducer from './contracts/contractDocumentTypeReducer'
import contractLocationReducer from './contracts/contractLocationReducer'
import contractPropertyReducer from './contracts/contractPropertyReducer'
import contractReducer from './contracts/contractReducer'
import contractStateReducer from './contracts/contractStateReducer'
import contractStatusReducer from './contracts/contractStatusReducer'
import contractSubtypeReducer from './contracts/contractSubtypeReducer'
import contractTypeReducer from './contracts/contractTypeReducer'
import contractViewReducer from './contracts/contractViewReducer'
import countryReducer from './countryReducer'
import currencyReducer from './currencyReducer'
import dataImportReducer from './dataImportReducer'
import invoiceMilestoneReducer from './invoiceMilestones/invoiceMilestoneReducer'
import customPropertyListItemReducer from './properties/customPropertyListItemReducer'
import customPropertyReducer from './properties/customPropertyReducer'
import customPropertyTypeReducer from './properties/customPropertyTypeReducer'
import reportReducer from './reportReducer'
import sitePropertyReducer from './sites/sitePropertyReducer'
import siteReducer from './sites/siteReducer'
import srDocumentActivityReducer from './srDocuments/srDocumentActivityReducer'
import srDocumentColumnReducer from './srDocuments/srDocumentColumnReducer'
import srDocumentDocReducer from './srDocuments/srDocumentDocReducer'
import srDocumentDocTypeReducer from './srDocuments/srDocumentDocTypeReducer'
import srDocumentLocationReducer from './srDocuments/srDocumentLocationReducer'
import srDocumentPropertyReducer from './srDocuments/srDocumentPropertyReducer'
import srDocumentReducer from './srDocuments/srDocumentReducer'
import srDocumentStateReducer from './srDocuments/srDocumentStateReducer'
import srDocumentStatusesReducer from './srDocuments/srDocumentStatusesReducer'
import srDocumentSubtypeReducer from './srDocuments/srDocumentSubtypeReducer'
import srDocumentTypeReducer from './srDocuments/srDocumentTypeReducer'
import srDocumentViewReducer from './srDocuments/srDocumentViewReducer'
import studyBudgetVisitReducer from './studies/studyBudgetVisitReducer'
import studyContractValueReducer from './studies/studyContractValueReducer'
import studyPropertyReducer from './studies/studyPropertyReducer'
import studyReducer from './studies/studyReducer'
import studyStatReducer from './studies/studyStatReducer'
import authReducer from './users/authReducer'
import currentCustomerReducer from './users/currentCustomerReducer'
import userReducer from './users/userReducer'
import { combineReducers } from 'redux'

const appReducer = combineReducers({
  authorization: authReducer,
  budgetVisits: budgetVisitReducer,
  contactProperties: contactPropertyReducer,
  contacts: contactReducer,
  contractActivities: contractActivityReducer,
  contractActivityAlerts: contractActivityAlertReducer,
  contractActivityCharts: contractActivityChartsReducer,
  contractBudgets: contractBudgetReducer,
  contractColumns: contractColumnReducer,
  contractDocumentTypes: contractDocumentTypeReducer,
  contractDocuments: contractDocumentReducer,
  contractLocations: contractLocationReducer,
  contractProperties: contractPropertyReducer,
  contractStates: contractStateReducer,
  contractStatuses: contractStatusReducer,
  contractSubtypes: contractSubtypeReducer,
  contractTypes: contractTypeReducer,
  contractViews: contractViewReducer,
  contracts: contractReducer,
  countries: countryReducer,
  currencies: currencyReducer,
  currentCustomer: currentCustomerReducer,
  customProperties: customPropertyReducer,
  customPropertyListItems: customPropertyListItemReducer,
  customPropertyTypes: customPropertyTypeReducer,
  dataImports: dataImportReducer,
  invoiceMilestones: invoiceMilestoneReducer,
  reports: reportReducer,
  siteProperties: sitePropertyReducer,
  srDocumentTypes: srDocumentTypeReducer,
  srDocumentSubtypes: srDocumentSubtypeReducer,
  srDocumentStatuses: srDocumentStatusesReducer,
  srDocumentLocations: srDocumentLocationReducer,
  srDocumentProperties: srDocumentPropertyReducer,
  srDocumentColumns: srDocumentColumnReducer,
  srDocumentViews: srDocumentViewReducer,
  srDocuments: srDocumentReducer,
  srDocumentStates: srDocumentStateReducer,
  srDocumentActivities: srDocumentActivityReducer,
  srDocumentActivityCharts: srDocumentActivityChartsReducer,
  srDocumentDocs: srDocumentDocReducer,
  srDocumentDocTypes: srDocumentDocTypeReducer,
  sites: siteReducer,
  studies: studyReducer,
  studyBudgetVisits: studyBudgetVisitReducer,
  studyContractValues: studyContractValueReducer,
  studyProperties: studyPropertyReducer,
  studyStats: studyStatReducer,
  users: userReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'AUTHORIZATION_RESET') {
    // this forces all state minus authorization to be reset
    const { authorization } = state
    state = { authorization }
  }

  return appReducer(state, action)
}

export default rootReducer
