import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";
import { updateSite, clearSiteErrors } from "actions/sites/siteActions";
import { fetchCountries, clearCountries } from "actions/countryActions";

import Modal from "components/common/Modal";
import Section from "components/common/Section";
import SiteForm from "./SiteForm";
import SiteDelete from "./SiteDelete";

import { findSelected, selectValueSplit } from "utils/formUtils";

const SiteUpdate = ({ match }) => {
  const siteId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();
  const { sitesAvailable, siteErrors } = useSelector(state => state.sites);
  const { countryOptions } = useSelector(state => state.countries);
  const [currentSite, setCurrentSite] = useState(sitesAvailable[siteId]);
  const [customProperties, setCustomProperties] = useState({});
  const auth = useAuth();
  const dispatch = useDispatch();

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Site");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  useEffect(() => {
    if (Object.keys(sitesAvailable).length) {
      setCurrentSite(sitesAvailable[siteId]);
      setCustomProperties(sitesAvailable[siteId].custom_properties);
    }
  }, [sitesAvailable, siteId, setCurrentSite, setCustomProperties]);

  useEffect(() => {
    if (_.size(currentSite)) {
      dispatch(fetchCountries(currentSite.country));
    }
    return () => {
      dispatch(clearCountries());
      dispatch(clearSiteErrors());
    };
  }, [dispatch, currentSite]);

  const handleSubmission = (e, params, countries) => {
    e.preventDefault();
    const countrySelected = findSelected(countries);

    const updatedParams = {};
    Object.keys(params).forEach(paramKey => {
      // find where there was a change
      // and if the field previously filled in and now removed
      if (
        currentSite[paramKey] !== params[paramKey] &&
        (params[paramKey] !== "" || currentSite[paramKey])
      ) {
        updatedParams[paramKey] = params[paramKey];
      }
    });

    updatedParams.id = currentSite.id;

    const updatedSite = _.mergeWith(currentSite, updatedParams);

    if (countrySelected.length > 0) {
      updatedParams.country_id = countrySelected[0].id;
      if (countrySelected[0].id !== currentSite.country.id) {
        const splitCountryValue = selectValueSplit(countrySelected[0].value);
        updatedSite.country = {
          id: countrySelected[0].id,
          name: splitCountryValue[0],
          code: splitCountryValue[1]
        };
      }
    }
    Object.assign(updatedSite.custom_properties, customProperties);

    dispatch(updateSite(updatedParams, updatedSite));
  };

  if (currentSite) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: [
        {
          name: "View",
          url: `/sites/${currentSite.id}`,
          exact: true
        },
        {
          name: "Edit",
          url: `/sites/${currentSite.id}/edit`,
          exact: true
        }
      ]
    };

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: "Delete",
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: e => toggleModal(e, "delete", currentSite),
          className: "text-danger"
        }
      });
    }

    return (
      <Section
        header={`${currentSite.name} (${currentSite.website})`}
        description="Change inputs to edit site"
        breadcrumbs
        menu={sectionHeaderMenu}
        footer={[
          {
            text: "Update",
            color: "primary",
            additionalProps: {
              form: "form_site",
              type: "submit"
            }
          }
        ]}
      >
        {countryOptions.length ? (
          <SiteForm
            submit={handleSubmission}
            site={currentSite}
            countries={countryOptions}
            errors={siteErrors}
            customProperties={customProperties}
            setCustomProperties={setCustomProperties}
          />
        ) : (
          ""
        )}
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <SiteDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      </Section>
    );
  }

  return "";
};

export default SiteUpdate;
