import {
  fetchBudgetVisits,
  fetchExistingBudgetVisitOptions,
  clearBudgetVisitOptions,
} from 'actions/budgetVisitActions'
import {
  fetchStudies,
  fetchExistingStudies,
  clearStudyOptions,
} from 'actions/studies/studyActions'
import {
  createStudyBudgetVisit,
  clearStudyBudgetVisitAction,
  clearStudyBudgetVisitErrors,
} from 'actions/studies/studyBudgetVisitActions'
import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import _ from 'lodash'
import { MDBRow, MDBCol } from 'mdbreact'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const StudyBudgetVisitCreate = ({ formId, params, setParams, closeModal }) => {
  const studies = useSelector((state) => state.studies)
  const budgetVisits = useSelector((state) => state.budgetVisits)
  const { studyBudgetVisitErrors, lastAction } = useSelector(
    (state) => state.studyBudgetVisits
  )
  const dispatch = useDispatch()

  // set up budget options
  useEffect(() => {
    if (
      _.isEmpty(budgetVisits.budgetVisitsAvailable) &&
      !budgetVisits.budgetVisitOptions.length
    ) {
      dispatch(fetchBudgetVisits('form'))
    } else if (!budgetVisits.budgetVisitOptions.length) {
      dispatch(fetchExistingBudgetVisitOptions())
    }
  }, [dispatch, budgetVisits])

  // set up study options
  useEffect(() => {
    if (_.isEmpty(studies.studiesAvailable) && !studies.studyOptions.length) {
      dispatch(fetchStudies('form'))
    } else if (!studies.studyOptions.length) {
      dispatch(fetchExistingStudies())
    }
  }, [dispatch, studies])

  useEffect(() => {
    return () => {
      dispatch(clearStudyOptions())
      dispatch(clearBudgetVisitOptions())
      dispatch(clearStudyBudgetVisitAction())
      dispatch(clearStudyBudgetVisitErrors())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        study_id: '',
        budget_visit_id: '',
      })
    }
  }, [params, setParams])

  // watch for success
  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal, dispatch])

  const handleSubmit = (e, createParams) => {
    e.preventDefault()
    dispatch(createStudyBudgetVisit(createParams))
  }

  const handleStudySelection = (v) => {
    if (v !== 'Choose A Sponsor Study Name') {
      const studySelection = studies.studyOptions.filter(
        (study) => study.value === v
      )
      setParams({ ...params, study_id: studySelection[0].id })
    }
  }

  const handleBudgetVisitSelection = (v) => {
    if (v !== 'Choose A Budget Visit') {
      const budgetVisitSelection = budgetVisits.budgetVisitOptions.filter(
        (budgetVisit) => budgetVisit.value === v
      )
      setParams({ ...params, budget_visit_id: budgetVisitSelection[0].id })
    }
  }

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={(e) => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        <MDBRow>
          <MDBCol md="12">
            <Select
              id="select__studies"
              options={studies.studyOptions}
              getTextContent={handleStudySelection}
              label="Sponsor Study Name"
              defaultOption="Choose A Sponsor Study Name"
              required
              search
            />
          </MDBCol>
          <MDBCol md="12">
            <Select
              id="select__budget-visits"
              options={budgetVisits.budgetVisitOptions}
              getTextContent={handleBudgetVisitSelection}
              label="Budget Visit"
              defaultOption="Choose A Budget Visit"
              required
              search
              errors={studyBudgetVisitErrors}
            />
          </MDBCol>
          <ErrorPanel results={studyBudgetVisitErrors} />
        </MDBRow>
      </form>
    )
  }

  return ''
}

export default StudyBudgetVisitCreate
