import {
  SET_SR_DOCUMENT_ACTIVITIES,
  UNSET_SR_DOCUMENT_ACTIVITIES,
  CREATE_SR_DOCUMENT_ACTIVITY,
  DELETE_SR_DOCUMENT_ACTIVITY,
  UPDATE_SR_DOCUMENT_ACTIVITY,
  UNSET_SR_DOCUMENT_ACTIVITY_ACTION,
  ERROR_SR_DOCUMENT_ACTIVITY,
  UNSET_ERROR_SR_DOCUMENT_ACTIVITY,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentActivityEndPoint = '/sr-documents/activity'

// Passing data back to reducers
export const setSrDocumentActivity = (obj, type, srDocumentActivity) => {
  const action = { type: type, payload: obj }
  if (srDocumentActivity) action.currentSrDocumentActivity = srDocumentActivity
  return action
}

// Non-API calls
export const clearSrDocumentActivities = () => async (dispatch) => {
  dispatch(setSrDocumentActivity({}, UNSET_SR_DOCUMENT_ACTIVITIES))
}
export const clearSrDocumentActivityAction = () => async (dispatch) => {
  dispatch(setSrDocumentActivity({}, UNSET_SR_DOCUMENT_ACTIVITY_ACTION))
}
export const clearSrDocumentActivityErrors = () => async (dispatch) => {
  dispatch(setSrDocumentActivity({}, UNSET_ERROR_SR_DOCUMENT_ACTIVITY))
}

// API calls
export const fetchSrDocumentActivities = (id) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${srDocumentActivityEndPoint}/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentActivity(res.data, SET_SR_DOCUMENT_ACTIVITIES))
  }
}

export const createSrDocumentActivity = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentActivityEndPoint, params, {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
          'Content-Type': `multipart/form-data; boundary=${params._boundary}`,
        },
      })
      dispatch(setSrDocumentActivity(res.data, CREATE_SR_DOCUMENT_ACTIVITY))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentActivity(error, ERROR_SR_DOCUMENT_ACTIVITY))
    }
  }
}

export const deleteSrDocumentActivity = (activity) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentActivityEndPoint, {
      data: { id: activity.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentActivity(res.data, DELETE_SR_DOCUMENT_ACTIVITY, activity)
    )
  }
}

export const updateSrDocumentActivity =
  (params, activity) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(srDocumentActivityEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setSrDocumentActivity(res.data, UPDATE_SR_DOCUMENT_ACTIVITY, activity)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setSrDocumentActivity(error, ERROR_SR_DOCUMENT_ACTIVITY))
      }
    }
  }
