import ContractActivityForm from '../forms/ContractActivityForm'
import {
  createContractActivity,
  clearContractActivityErrors,
  clearContractActivityAction,
} from 'actions/contracts/contractActivityActions'
import { fetchContractDocuments } from 'actions/contracts/contractDocumentActions'
import { fetchContractDocumentTypes } from 'actions/contracts/contractDocumentTypeActions'
import { fetchContractLocations } from 'actions/contracts/contractLocationActions'
import { fetchContractStatuses } from 'actions/contracts/contractStatusActions'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ContractActivityCreate = ({
  formId,
  contractId,
  params,
  setParams,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, activityErrors } = useSelector(
    (state) => state.contractActivities
  )
  const statusOptions = useSelector(
    (state) => state.contractStatuses.statusOptions
  )
  const locationOptions = useSelector(
    (state) => state.contractLocations.locationOptions
  )
  const documentTypeOptions = useSelector(
    (state) => state.contractDocumentTypes.documentTypeOptions
  )

  //// LOCAL STATE.
  const [occurDate, setOccurDate] = useState(moment())
  const [occurTime, setOccurTime] = useState(moment())

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractStatuses('form', null, true))
    dispatch(fetchContractLocations('form', null, true))
    dispatch(fetchContractDocumentTypes('form'))
    return () => {
      dispatch(clearContractActivityErrors())
      dispatch(clearContractActivityAction())
      dispatch(fetchContractDocuments({ contract_id: contractId }))
    }
  }, [dispatch, contractId])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        contract_id: contractId,
        contract_status_id: '',
        contract_location_id: '',
        contract_document_type_id: '',
        comment: '',
        import_file: null,
        activity_occurred_at: moment().toISOString().split('.')[0],
      })
    }
  }, [contractId, params, setParams])

  // watch for success
  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.

  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        const activityParams = new FormData()
        activityParams.append('contract_id', params.contract_id)
        activityParams.append('contract_status_id', params.contract_status_id)
        activityParams.append(
          'contract_location_id',
          params.contract_location_id
        )
        activityParams.append(
          'activity_occurred_at',
          params.activity_occurred_at
        )
        activityParams.append('comment', params.comment)
        if (params.import_file) {
          activityParams.append('import_file', params.import_file)
          activityParams.append(
            'contract_document_type_id',
            params.contract_document_type_id
          )
        }

        dispatch(createContractActivity(activityParams))
      }}
      className="form container"
      noValidate
    >
      <ContractActivityForm
        params={params}
        setParams={setParams}
        statusOptions={statusOptions}
        locationOptions={locationOptions}
        documentTypeOptions={documentTypeOptions}
        occurDate={occurDate}
        setOccurDate={setOccurDate}
        occurTime={occurTime}
        setOccurTime={setOccurTime}
        errors={activityErrors}
      />
    </form>
  )
}

export default ContractActivityCreate
