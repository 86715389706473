import restApi from "apis/restApi";
import {
  STUDY_STAT_CLEAR_ACTION,
  STUDY_STAT_TIME_PER_CONTRACT,
  STUDY_STAT_TIME_PER_CONTRACT_CLEAR,
  STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE,
  STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE_CLEAR,
  STUDY_STAT_TIME_PER_STATUS,
  STUDY_STAT_TIME_PER_STATUS_CLEAR,
  STUDY_STAT_TIME_PER_LOCATION,
  STUDY_STAT_TIME_PER_LOCATION_CLEAR
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const tatStudyPerContract = "/stats/study";

// Passing data back to reducers
export const setStudyStat = (obj, type) => {
  const action = { type: type, payload: obj };
  return action;
};

// clear state

export const clearStudyStatAction = () => async dispatch => {
  dispatch(setStudyStat({}, STUDY_STAT_CLEAR_ACTION));
};

export const clearStudyTimePerCompletedContract = () => async dispatch => {
  dispatch(setStudyStat({}, STUDY_STAT_TIME_PER_CONTRACT_CLEAR));
};

export const clearStudyTimePerCompletedContractPerContractType = () => async dispatch => {
  dispatch(
    setStudyStat({}, STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE_CLEAR)
  );
};

export const clearStudyStatusTimePerContract = () => async dispatch => {
  dispatch(setStudyStat({}, STUDY_STAT_TIME_PER_STATUS_CLEAR));
};

export const clearStudyLocationTimePerContract = () => async dispatch => {
  dispatch(setStudyStat({}, STUDY_STAT_TIME_PER_LOCATION_CLEAR));
};

// API calls
export const fetchStudyTimePerCompletedContract = id => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${tatStudyPerContract}/tat/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setStudyStat(res.data, STUDY_STAT_TIME_PER_CONTRACT));
  }
};

export const fetchStudyTimePerCompletedContractPerContractType = id => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${tatStudyPerContract}/tat/type/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setStudyStat(res.data, STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE)
    );
  }
};

export const fetchStudyStatusTimePerContract = id => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${tatStudyPerContract}/status/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setStudyStat(res.data, STUDY_STAT_TIME_PER_STATUS));
  }
};

export const fetchStudyLocationTimePerContract = id => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${tatStudyPerContract}/location/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setStudyStat(res.data, STUDY_STAT_TIME_PER_LOCATION));
  }
};
