import {
  SET_CONTRACT_COLUMNS,
  UNSET_CONTRACT_COLUMNS,
  CREATE_CONTRACT_COLUMNS,
  ERROR_CONTRACT_COLUMNS,
  UNSET_CONTRACT_COLUMN_ACTION,
  UNSET_ERROR_CONTRACT_COLUMN,
} from 'actions/types'

const INITIAL_STATE = {
  lastAction: null,
  contractColumns: [],
  contractColumnErrors: {},
}

const contractColumnReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_COLUMNS:
      return {
        contractColumns: action.payload,
        lastAction: 'fetch',
      }
    case UNSET_CONTRACT_COLUMNS:
      return INITIAL_STATE
    case CREATE_CONTRACT_COLUMNS:
      const columns = action?.payload?.contract_columns.split(',') ?? []
      const newState = {
        lastAction: 'create',
        contractColumns: columns,
      }
      return newState
    case ERROR_CONTRACT_COLUMNS:
      return {
        ...state,
        contractColumnErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_COLUMN:
      return {
        ...state,
        contractColumnErrors: {},
      }
    case UNSET_CONTRACT_COLUMN_ACTION:
      return { ...state, lastAction: null }
    default:
      return state
  }
}

export default contractColumnReducer
