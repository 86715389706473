import React from "react";
import { Link } from "react-router-dom";
import { MDBBreadcrumbItem } from "mdbreact";

const BreadcrumbContactItems = ({ match }) => {
  const { path } = match;
  const renderContactListItem = () => {
    return (
      <MDBBreadcrumbItem>
        <Link to="/contacts">Contacts</Link>
      </MDBBreadcrumbItem>
    );
  };
  const renderContactCreateItem = () => {
    if (path === "/contacts/new") {
      return <MDBBreadcrumbItem active>Create</MDBBreadcrumbItem>;
    }
    return "";
  };
  const renderContactShowItem = () => {
    if (path === "/contacts/:id") {
      return <MDBBreadcrumbItem active>#{match.params.id}</MDBBreadcrumbItem>;
    } else if (path === "/contacts/:id/edit") {
      return (
        <MDBBreadcrumbItem>
          <Link to={`/contacts/${match.params.id}`}>#{match.params.id}</Link>
        </MDBBreadcrumbItem>
      );
    }
    return "";
  };
  const renderContactEditItem = () => {
    if (path === "/contacts/:id/edit") {
      return <MDBBreadcrumbItem active>Edit</MDBBreadcrumbItem>;
    }
    return "";
  };

  return (
    <>
      {renderContactListItem()}
      {renderContactCreateItem()}
      {renderContactShowItem()}
      {renderContactEditItem()}
    </>
  );
};

export default BreadcrumbContactItems;
