import { fetchCustomProperties } from 'actions/properties/customPropertyActions'
import {
  clearSrDocumentPropertyAction,
  clearSrDocumentPropertyErrors,
  createSrDocumentProperty,
  fetchSrDocumentPropertyOptions,
} from 'actions/srDocuments/srDocumentPropertyActions'
import SrCustomPropertyForm from 'components/common/properties/SrCustomPropertyForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const SrDocumentPropertyCreate = ({
  formId,
  params,
  setParams,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, srDocumentPropertyErrors, srDocumentPropertyOptions } =
    useSelector((state) => state.srDocumentProperties)
  const customProperties = useSelector(
    (state) => state.customProperties.propertiesAvailable
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (_.isEmpty(customProperties)) {
      dispatch(fetchCustomProperties())
    }
    if (_.isEmpty(srDocumentPropertyOptions?.sr_document_type_id)) {
      dispatch(fetchSrDocumentPropertyOptions())
    }
  }, [dispatch, customProperties, srDocumentPropertyOptions])

  useEffect(() => {
    return () => {
      dispatch(clearSrDocumentPropertyErrors())
      dispatch(clearSrDocumentPropertyAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        property_id: null,
        required: false,
        sr_document_type_id: 0,
      })
    }
  }, [params, setParams])

  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(createSrDocumentProperty(params))
      }}
      className="form container"
      noValidate
    >
      {_.size(customProperties) && _.size(srDocumentPropertyOptions) ? (
        <SrCustomPropertyForm
          create={true}
          isSiteReadiness={true}
          customProperties={customProperties}
          params={params}
          setParams={setParams}
          srDocumentTypes={srDocumentPropertyOptions.sr_document_type_id}
          srDocumentTypeDefaultOption="All Site Readiness Types"
          errors={srDocumentPropertyErrors}
        />
      ) : (
        ''
      )}
    </form>
  )
}

export default SrDocumentPropertyCreate
