import { SET_SR_DOCUMENT_STATES, CLEAR_SR_DOCUMENT_STATES } from 'actions/types'

const INITIAL_STATE = []

const srDocumentStateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_STATES:
      return action.payload
    case CLEAR_SR_DOCUMENT_STATES:
      return []
    default:
      return state
  }
}

export default srDocumentStateReducer
