import {
  SET_CONTRACT_PROPERTIES,
  SET_CONTRACT_PROPERTY_OPTIONS,
  UNSET_CONTRACT_PROPERTY_OPTIONS,
  CREATE_CONTRACT_PROPERTY,
  DELETE_CONTRACT_PROPERTY,
  UPDATE_CONTRACT_PROPERTY,
  ERROR_CONTRACT_PROPERTY,
  UNSET_CONTRACT_PROPERTY_ACTION,
  UNSET_ERROR_CONTRACT_PROPERTY,
  LOADING_CONTRACT_PROPERTIES,
  TOGGLE_CONTRACT_PROPERTY_GUEST_VISIBILITY,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractPropertyEndPoint = '/property/custom/contract'

// Passing data back to reducers
const setContractProperty = (obj, type, contractProperty) => {
  const action = { type: type, payload: obj }
  if (contractProperty) action.currentContractProperty = contractProperty
  return action
}

// Non-API calls
export const clearContractPropertyOptions = () => async (dispatch) => {
  dispatch(setContractProperty({}, UNSET_CONTRACT_PROPERTY_OPTIONS))
}
export const clearContractPropertyAction = () => async (dispatch) => {
  dispatch(setContractProperty({}, UNSET_CONTRACT_PROPERTY_ACTION))
}
export const clearContractPropertyErrors = () => async (dispatch) => {
  dispatch(setContractProperty({}, UNSET_ERROR_CONTRACT_PROPERTY))
}

// API calls
export const fetchContractProperties = () => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(contractPropertyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractProperty(res.data, SET_CONTRACT_PROPERTIES))
  }
}

export const fetchContractPropertyOptions = () => async (dispatch) => {
  dispatch(setContractProperty([], LOADING_CONTRACT_PROPERTIES))

  if (fetchToken()) {
    const res = await restApi.get(`${contractPropertyEndPoint}/options`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractProperty(res.data, SET_CONTRACT_PROPERTY_OPTIONS))
  }
}

export const createContractProperty = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractPropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractProperty(res.data, CREATE_CONTRACT_PROPERTY))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractProperty(error, ERROR_CONTRACT_PROPERTY))
    }
  }
}

export const deleteContractProperty = (property) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractPropertyEndPoint, {
      data: { id: property.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractProperty(res.data, DELETE_CONTRACT_PROPERTY, property))
  }
}

export const updateContractProperty =
  (params, property) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractPropertyEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setContractProperty(res.data, UPDATE_CONTRACT_PROPERTY, property)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractProperty(error, ERROR_CONTRACT_PROPERTY))
      }
    }
  }

export const toggleContractPropertyGuestVisibility =
  (type, toggle) => async (dispatch) => {
    const toggleEndPoint = `${contractPropertyEndPoint}/${
      toggle ? 'guestVisibilityEnable' : 'guestVisibilityDisable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: type.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setContractProperty(
          { res, toggle: toggle },
          TOGGLE_CONTRACT_PROPERTY_GUEST_VISIBILITY,
          type
        )
      )
    }
  }
