import restApi from "apis/restApi";
import {
  SET_CONTRACT_BUDGETS,
  UNSET_CONTRACT_BUDGETS,
  UPDATE_CONTRACT_BUDGET,
  ERROR_CONTRACT_BUDGET,
  UNSET_ERROR_CONTRACT_BUDGET,
  SET_CONTRACT_BUDGET_HISTORY,
  UNSET_CONTRACT_BUDGET_HISTORY
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const budgetEndPoint = "/budget/contract";

// Pass data to reducers
export const setContractBudget = (obj, type, budget) => {
  const action = { type: type, payload: obj };
  if (budget) action.currentContractBudget = budget;
  return action;
};

// Non-API calls
export const clearContractBudgets = () => async dispatch => {
  dispatch(setContractBudget({}, UNSET_CONTRACT_BUDGETS));
};
export const clearContractBudgetHistory = () => async dispatch => {
  dispatch(setContractBudget({}, UNSET_CONTRACT_BUDGET_HISTORY));
};
export const clearContractBudgetErrors = () => async dispatch => {
  dispatch(setContractBudget({}, UNSET_ERROR_CONTRACT_BUDGET));
};

// API Calls
export const fetchContractBudgets = contractId => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${budgetEndPoint}/${contractId}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContractBudget(res.data, SET_CONTRACT_BUDGETS));
  }
};

export const fetchContractBudgetHistory = (
  contractId,
  contractBudgetId
) => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(
      `${budgetEndPoint}/${contractId}/${contractBudgetId}`,
      {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      }
    );
    dispatch(setContractBudget(res.data, SET_CONTRACT_BUDGET_HISTORY));
  }
};

export const updateContractBudget = (
  contractId,
  params,
  item
) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(
        `${budgetEndPoint}/${contractId}`,
        params,
        {
          headers: { Authorization: `Bearer ${fetchToken()}` }
        }
      );
      dispatch(setContractBudget(res.data, UPDATE_CONTRACT_BUDGET, item));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setContractBudget(error, ERROR_CONTRACT_BUDGET));
    }
  }
};
