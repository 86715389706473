import { SET_CONTRACT_STATES, CLEAR_CONTRACT_STATES } from 'actions/types'

const INITIAL_STATE = []

const contractStateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_STATES:
      return action.payload
    case CLEAR_CONTRACT_STATES:
      return []
    default:
      return state
  }
}

export default contractStateReducer
