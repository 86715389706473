import _ from "lodash";
import moment from "moment";

import {
  SET_USERS,
  CREATE_USER,
  DELETE_USER,
  UNSET_USER_ACTION,
  UPDATE_USER,
  TOGGLE_USER,
  ERROR_USER,
  UNSET_ERROR_USER,
  MANAGE_STUDIES_GUEST_USER
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  usersAvailable: {},
  userErrors: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        lastAction: "fetch",
        usersAvailable: _.mapKeys(action.payload, "id")
      };
    case UNSET_USER_ACTION:
      return { ...state, lastAction: null };
    case CREATE_USER:
      const createdItem = action.payload.created_items[0];
      const { id } = createdItem;
      const newState = {
        ...state,
        lastAction: "create",
        usersAvailable: {
          ...state.usersAvailable,
          [id]: createdItem
        }
      };
      return newState;
    case UPDATE_USER:
      const updatedItem = (state.usersAvailable[action.currentUser.id] =
        action.currentUser);
      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        updatedItem
      };
    case MANAGE_STUDIES_GUEST_USER:
      const updatedGuest = state.usersAvailable[action.payload.id];
      updatedGuest.studies = action.payload.study_id.map(id => Number(id));
      return {
        ...state,
        lastAction: "setStudies",
        usersAvailable: {
          ...state.usersAvailable,
          [updatedGuest.id]: updatedGuest
        }
      };
    case ERROR_USER:
      return {
        ...state,
        userErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_USER:
      return {
        ...state,
        userErrors: {}
      };
    case TOGGLE_USER:
      const toggledItem = (state.usersAvailable[action.currentUser.id] =
        action.currentUser);
      toggledItem.is_active = action.payload.toggle;
      return {
        ...state,
        lastAction: action.payload.toggle ? "enable" : "disable",
        toggledItem
      };
    case DELETE_USER:
      const updated = state.usersAvailable;
      _.unset(updated, `${action.currentUser.id}`);
      return { ...state, lastAction: "delete", usersAvailable: updated };
    default:
      return state;
  }
};
