import {
  updateContractProperty,
  clearContractPropertyErrors,
  clearContractPropertyAction,
} from 'actions/contracts/contractPropertyActions'
import CustomPropertyForm from 'components/common/properties/CustomPropertyForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ContractPropertyUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, contractPropertyErrors } = useSelector(
    (state) => state.contractProperties
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearContractPropertyAction())
      dispatch(clearContractPropertyErrors())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        required: selected.required || false,
      })
    }
  }, [params, setParams, selected])

  useEffect(() => {
    if (lastAction === 'edit') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        const updatedParams = _.mapValues(params, (prop, key) => {
          const propValue = prop !== '' && prop !== null
          const newValue = selected[key] !== prop
          if (newValue && propValue) return prop
        })
        updatedParams.id = selected.id

        const updatedProperty = _.mergeWith(selected, updatedParams)
        dispatch(updateContractProperty(updatedParams, updatedProperty))
      }}
      className="form container"
      noValidate
    >
      <CustomPropertyForm
        params={params}
        setParams={setParams}
        contractTypeDefaultOption="All Contact Types"
        errors={contractPropertyErrors}
      />
    </form>
  )
}

export default ContractPropertyUpdate
