import Home from 'components/Home'
import Report from 'components/Report'
import AdminHome from 'components/admin/AdminHome'
import LoginForm from 'components/auth/LoginForm'
import MFAForm from 'components/auth/MFAForm'
import ContactHome from 'components/contacts/ContactHome'
import ContractHome from 'components/contracts/ContractHome'
import SrDocumentHome from 'components/contracts/SrDocumentHome'
import ProfileShow from 'components/profiles/ProfileShow'
import SiteHome from 'components/sites/SiteHome'
import StudyHome from 'components/studies/StudyHome'
import { useAuth } from 'hooks/useAuth.js'
import { useRequireAuth } from 'hooks/useRequireAuth'
import React, { useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { isUserGuest, getMFAQR } from 'utils/authUtils'

const ParentRoutes = () => {
  //// HOOKS.
  const { user, token } = useAuth() ?? {}
  const { user_level } = user ?? {}
  const requireAuth = useRequireAuth()

  //// GLOBAL STATE.

  //// LOCAL STATE.
  const [reqMFA, setMFA] = useState(getMFAQR())

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.
  const hasUser = user && (token || token === '')

  //// RENDER.
  return (
    <Switch>
      <Route
        path="/login"
        render={() =>
          hasUser ? (
            <Redirect to="/" />
          ) : reqMFA ? (
            <MFAForm setMFA={setMFA} />
          ) : (
            <LoginForm setMFA={setMFA} />
          )
        }
      />
      {requireAuth && (
        <>
          <Route exact path="/" component={Home} />
          <Route exact path="/reports" component={Report} />
          <Route path="/contracts" component={ContractHome} />
          <Route path="/sr-documents" component={SrDocumentHome} />
          <Route path="/studies" component={StudyHome} />
          {!isUserGuest(user_level) && (
            <>
              <Route path="/contacts" component={ContactHome} />
              <Route path="/sites" component={SiteHome} />
            </>
          )}
          <Route path="/admin" component={AdminHome} />
          <Route exact path="/change-password" component={ProfileShow} />
        </>
      )}
    </Switch>
  )
}

export default ParentRoutes
