import {
  CREATE_SR_DOCUMENT_VIEW,
  DELETE_SR_DOCUMENT_VIEW,
  ERROR_SR_DOCUMENT_VIEW,
  SET_SR_DOCUMENT_VIEWS,
  UNSET_SR_DOCUMENT_VIEWS,
  UNSET_SR_DOCUMENT_VIEW_ACTION,
  UNSET_ERROR_SR_DOCUMENT_VIEW,
  UPDATE_SR_DOCUMENT_VIEW,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  srDocumentViewsAvailable: {},
  srDocumentViewErrors: {},
}

const srDocumentViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_VIEWS:
      return {
        ...state,
        lastAction: 'fetch',
        srDocumentViewsAvailable: _.mapKeys(action.payload, 'id'),
      }
    case UNSET_SR_DOCUMENT_VIEWS:
      return INITIAL_STATE
    case CREATE_SR_DOCUMENT_VIEW:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        srDocumentViewsAvailable: {
          ...state.srDocumentViewsAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SR_DOCUMENT_VIEW:
      const updatedItem = (state.srDocumentViewsAvailable[
        action.currentSrDocumentView.id
      ] = action.currentSrDocumentView)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_VIEW:
      return {
        ...state,
        srDocumentViewErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_VIEW:
      return {
        ...state,
        srDocumentViewErrors: {},
      }
    case UNSET_SR_DOCUMENT_VIEW_ACTION:
      return { ...state, lastAction: null }
    case DELETE_SR_DOCUMENT_VIEW:
      let updatedSrDocumentViews = state.srDocumentViewsAvailable
      _.unset(updatedSrDocumentViews, `${action.currentSrDocumentView.id}`)
      return {
        ...state,
        lastAction: 'delete',
        srDocumentViewsAvailable: updatedSrDocumentViews,
      }
    default:
      return state
  }
}

export default srDocumentViewReducer
