import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createContractActivityAlert } from "actions/contracts/contractActivityAlertActions";
import { fetchContractTypes } from "actions/contracts/contractTypeActions";
import { fetchContractLocations } from "actions/contracts/contractLocationActions";
import { fetchContractStatuses } from "actions/contracts/contractStatusActions";
import { fetchContractPropertyOptions } from "actions/contracts/contractPropertyActions";

import { MDBCardBody, MDBCardFooter, MDBBtn } from "mdbreact";

import ContractActivityAlertForm from "./ContractActivityAlertForm";

import { findSelected } from "utils/formUtils";

const ContractActivityAlertCreate = () => {
  const { activityAlertErrors } = useSelector(
    state => state.contractActivityAlerts
  );
  const { typeOptions } = useSelector(state => state.contractTypes);
  const { locationOptions } = useSelector(state => state.contractLocations);
  const { statusOptions } = useSelector(state => state.contractStatuses);
  const { contractPropertyOptions, loading, lastAction } = useSelector(
    state => state.contractProperties
  );

  const [userPropertyOptions, setUserPropertyOptions] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractPropertyOptions());
    dispatch(fetchContractTypes("form"));
    dispatch(fetchContractLocations("form"));
    dispatch(fetchContractStatuses("form"));
  }, [dispatch]);

  const handleSubmission = (
    e,
    params,
    types,
    locations,
    statuses,
    contractProperties
  ) => {
    e.preventDefault();
    const typeSelected = findSelected(types);
    const locationSelected = findSelected(locations);
    const statusSelected = findSelected(statuses);
    const contractPropertySelected = findSelected(contractProperties);
    const activityAlertParams = _.pickBy(params, _.identity);

    if (typeSelected.length > 0) {
      activityAlertParams.contract_type_id = typeSelected[0].id;
    }

    if (locationSelected.length > 0) {
      activityAlertParams.contract_location_id = locationSelected[0].id;
    }

    if (statusSelected.length > 0) {
      activityAlertParams.contract_status_id = statusSelected[0].id;
    }

    if (contractPropertySelected.length > 0) {
      activityAlertParams.contract_property_id = contractPropertySelected[0].id;
    }

    dispatch(createContractActivityAlert(activityAlertParams));
  };

  useEffect(() => {
    if (
      _.size(contractPropertyOptions) &&
      !loading &&
      lastAction === "fetchOptions" &&
      _.size(typeOptions)
    ) {
      const onlyUserProperties = contractPropertyOptions?.custom_properties?.filter(
        customProp => customProp.property.property_type.code === "users"
      );

      const userPropValues = onlyUserProperties.map(prop => {
        const type = typeOptions.find(type => type.id === prop.contract_type_id)
          ?.value;
        return {
          id: prop.contract_property_id,
          value: `${prop.property.name} - ${type}`,
          checked: false
        };
      });
      setUserPropertyOptions(userPropValues);
    }
  }, [contractPropertyOptions, loading, lastAction, typeOptions]);

  return (
    <>
      <MDBCardBody>
        <ContractActivityAlertForm
          typeOptions={typeOptions || []}
          locationOptions={locationOptions || []}
          statusOptions={statusOptions || []}
          contractProperties={userPropertyOptions || []}
          submit={handleSubmission}
          errors={activityAlertErrors}
        />
      </MDBCardBody>
      <MDBCardFooter className="text-right">
        <MDBBtn
          key="submit"
          color="primary"
          form="form_contract_activity_alert"
          type="submit"
        >
          Submit
        </MDBBtn>
      </MDBCardFooter>
    </>
  );
};

export default ContractActivityAlertCreate;
