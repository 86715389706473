import {
  SET_SR_DOCUMENT_DOCS,
  UNSET_SR_DOCUMENT_DOCS,
  CREATE_SR_DOCUMENT_DOC,
  DELETE_SR_DOCUMENT_DOC,
  UPDATE_SR_DOCUMENT_DOC,
  UNSET_SR_DOCUMENT_DOC_ACTION,
  ERROR_SR_DOCUMENT_DOC,
  UNSET_ERROR_SR_DOCUMENT_DOC,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentDocEndPoint = '/documents/sr-document'

// Passing data back to reducers
export const setSrDocumentDoc = (obj, type, srDocumentDoc) => {
  const action = { type: type, payload: obj }
  if (srDocumentDoc) action.currentSrDocumentDoc = srDocumentDoc
  return action
}

// Non-API calls
export const clearSrDocumentDocs = () => async (dispatch) => {
  dispatch(setSrDocumentDoc({}, UNSET_SR_DOCUMENT_DOCS))
}
export const clearSrDocumentDocAction = () => async (dispatch) => {
  dispatch(setSrDocumentDoc({}, UNSET_SR_DOCUMENT_DOC_ACTION))
}
export const clearSrDocumentDocErrors = () => async (dispatch) => {
  dispatch(setSrDocumentDoc({}, UNSET_ERROR_SR_DOCUMENT_DOC))
}

// API calls
export const fetchSrDocumentDocs = (params) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${srDocumentDocEndPoint}`, {
      params: params,
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentDoc(res.data, SET_SR_DOCUMENT_DOCS))
  }
}

export const createSrDocumentDoc = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentDocEndPoint, params, {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
          'Content-Type': `multipart/form-data; boundary=${params._boundary}`,
        },
      })
      dispatch(setSrDocumentDoc(res.data, CREATE_SR_DOCUMENT_DOC))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentDoc(error, ERROR_SR_DOCUMENT_DOC))
    }
  }
}

export const deleteSrDocumentDoc = (activity) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentDocEndPoint, {
      data: { id: activity.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentDoc(res.data, DELETE_SR_DOCUMENT_DOC, activity))
  }
}

export const updateSrDocumentDoc = (params, activity) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(srDocumentDocEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentDoc(res.data, UPDATE_SR_DOCUMENT_DOC, activity))
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setSrDocumentDoc(error, ERROR_SR_DOCUMENT_DOC))
    }
  }
}
