/** @jsx jsx */
import { MDBSideNav, MDBLink } from "mdbreact";
import { jsx } from "@emotion/core";

const SideNavCanvas = ({ logo, styles, toggle, children }) => {
  return (
    <MDBSideNav css={styles} hidden triggerOpening={toggle} tag="aside">
      <MDBLink to="/" className="logo-wrapper ripple-parent">
        <img src={logo} alt="epicenter portal" />
      </MDBLink>
      {children}
    </MDBSideNav>
  );
};

export default SideNavCanvas;
