import {
  CHART_SR_DOCUMENT_ACTIVITY_BY_LOCATION,
  CHART_SR_DOCUMENT_ACTIVITY_CLEAR_ACTION,
  CHART_SR_DOCUMENT_ACTIVITY_CLEAR_BY_LOCATION,
} from 'actions/types'

const INITIAL_STATE = {
  charts: {},
  lastAction: null,
}

const srDocumentActivityChartsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHART_SR_DOCUMENT_ACTIVITY_BY_LOCATION:
      return {
        ...state,
        lastAction: 'locationChart',
        charts: { ...state.charts, byLocation: action.payload },
      }
    case CHART_SR_DOCUMENT_ACTIVITY_CLEAR_BY_LOCATION:
      return {
        ...state,
        lastAction: 'locationChartCleared',
        charts: { ...state.charts, byLocation: null },
      }
    case CHART_SR_DOCUMENT_ACTIVITY_CLEAR_ACTION:
      return { ...state, lastAction: null }
    default:
      return state
  }
}

export default srDocumentActivityChartsReducer
