import restApi from "apis/restApi";
import {
  SET_SITE_PROPERTIES,
  SET_SITE_PROPERTY_OPTIONS,
  UNSET_SITE_PROPERTY_OPTIONS,
  CREATE_SITE_PROPERTY,
  DELETE_SITE_PROPERTY,
  UPDATE_SITE_PROPERTY,
  ERROR_SITE_PROPERTY,
  UNSET_SITE_PROPERTY_ACTION,
  UNSET_ERROR_SITE_PROPERTY
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const sitePropertyEndPoint = "/property/custom/org";

// Passing data back to reducers
const setSiteProperty = (obj, type, siteProperty) => {
  const action = { type: type, payload: obj };
  if (siteProperty) action.currentSiteProperty = siteProperty;
  return action;
};

// Non-API calls
export const clearSitePropertyOptions = () => async dispatch => {
  dispatch(setSiteProperty({}, UNSET_SITE_PROPERTY_OPTIONS));
};
export const clearSitePropertyAction = () => async dispatch => {
  dispatch(setSiteProperty({}, UNSET_SITE_PROPERTY_ACTION));
};
export const clearSitePropertyErrors = () => async dispatch => {
  dispatch(setSiteProperty({}, UNSET_ERROR_SITE_PROPERTY));
};

// API calls
export const fetchSiteProperties = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(sitePropertyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setSiteProperty(res.data, SET_SITE_PROPERTIES));
  }
};

export const fetchSitePropertyOptions = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(`${sitePropertyEndPoint}/options`, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setSiteProperty(res.data, SET_SITE_PROPERTY_OPTIONS));
  }
};

export const createSiteProperty = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(sitePropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setSiteProperty(res.data, CREATE_SITE_PROPERTY));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setSiteProperty(error, ERROR_SITE_PROPERTY));
    }
  }
};

export const deleteSiteProperty = property => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(sitePropertyEndPoint, {
      data: { id: property.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setSiteProperty(res.data, DELETE_SITE_PROPERTY, property));
  }
};

export const updateSiteProperty = (params, property) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(sitePropertyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setSiteProperty(res.data, UPDATE_SITE_PROPERTY, property));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setSiteProperty(error, ERROR_SITE_PROPERTY));
    }
  }
};
