import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchContractTypes } from "actions/contracts/contractTypeActions";

import {
  clearContractValueErrors,
  clearContractValueAction,
  updateContractValue,
  fetchAvailableCountries,
  setContractValueErrors
} from "actions/studies/studyContractValueActions";

import {
  contractFormParamValidator,
  ContractValueForm
} from "components/studies/contractValues/ContractValueForm";

const ContractValueUpdate = ({
  studyId,
  formId,
  params,
  setParams,
  selected,
  setSelected,
  closeModal
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch Contract Types, Status, Locations
    dispatch(fetchContractTypes("form", null, true));
    dispatch(fetchAvailableCountries(selected));
    return () => {
      // Cleanup error, actions, get updated milestone
      dispatch(clearContractValueErrors());
      dispatch(clearContractValueAction());
    };
  }, [dispatch, selected]);

  const { lastAction, contractValueErrors, countryOptions } = useSelector(
    state => state.studyContractValues
  );

  const typeOptions = useSelector(state => state.contractTypes.typeOptions);

  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  // Set modal with param values
  useEffect(() => {
    if (_.isEmpty(params)) {
      const initialParams = {
        id: selected.id,
        country_id: selected.country.id,
        study_id: selected.study.id
      };
      Object.keys(selected.values).forEach(v => {
        const contractParamName = `contract_type_${v}`;
        initialParams[contractParamName] = selected.values[v]?.amount;
      });
      setParams(initialParams);
    }
  }, [selected, setSelected, params, setParams]);

  const handleUpdate = (e, contractValueParams, currentContractValues) => {
    e.preventDefault();

    const formErrors = contractFormParamValidator(contractValueParams);
    if (formErrors.length) {
      dispatch(setContractValueErrors(formErrors));
    } else {
      dispatch(updateContractValue(contractValueParams, currentContractValues));
    }
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleUpdate(e, params, selected)}
        className="form container"
        noValidate
      >
        <ContractValueForm
          params={params}
          setParams={setParams}
          actionType={"update"}
          countryOptions={countryOptions}
          typeOptions={typeOptions}
          errors={contractValueErrors}
        />
      </form>
    );
  }
  return "";
};

export default ContractValueUpdate;
