import {
  SET_SR_DOCUMENT_DOC_TYPES,
  SET_SR_DOCUMENT_DOC_TYPE_OPTIONS,
  UNSET_SR_DOCUMENT_DOC_TYPE_OPTIONS,
  CREATE_SR_DOCUMENT_DOC_TYPE,
  DELETE_SR_DOCUMENT_DOC_TYPE,
  UNSET_SR_DOCUMENT_DOC_TYPE_ACTION,
  UPDATE_SR_DOCUMENT_DOC_TYPE,
  TOGGLE_SR_DOCUMENT_DOC_TYPE,
  ERROR_SR_DOCUMENT_DOC_TYPE,
  UNSET_ERROR_SR_DOCUMENT_DOC_TYPE,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  docTypesAvailable: {},
  docTypeOptions: [],
  docTypeErrors: [],
}

const srDocumentDocTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_DOC_TYPES:
      return {
        ...state,
        lastAction: 'fetch',
        docTypesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_SR_DOCUMENT_DOC_TYPE_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentSrDocumentDocType ? action.currentSrDocumentDocType.id : 0
      )
      return { ...state, docTypeOptions: options }
    case UNSET_SR_DOCUMENT_DOC_TYPE_OPTIONS:
      if (state.docTypeOptions) {
        return { ...state, docTypeOptions: [] }
      }
      return state
    case UNSET_SR_DOCUMENT_DOC_TYPE_ACTION:
      return { ...state, lastAction: null }
    case CREATE_SR_DOCUMENT_DOC_TYPE:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        docTypesAvailable: {
          ...state.docTypesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SR_DOCUMENT_DOC_TYPE:
      const updatedItem = (state.docTypesAvailable[
        action.currentSrDocumentDocType.id
      ] = action.currentSrDocumentDocType)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_DOC_TYPE:
      return {
        ...state,
        docTypeErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_DOC_TYPE:
      return {
        ...state,
        docTypeErrors: {},
      }
    case TOGGLE_SR_DOCUMENT_DOC_TYPE:
      const toggledItem = (state.docTypesAvailable[
        action.currentSrDocumentDocType.id
      ] = action.currentSrDocumentDocType)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_SR_DOCUMENT_DOC_TYPE:
      const updated = state.docTypesAvailable
      _.unset(updated, `${action.currentSrDocumentDocType.id}`)
      return {
        ...state,
        lastAction: 'delete',
        docTypesAvailable: updated,
      }
    default:
      return state
  }
}

export default srDocumentDocTypeReducer
