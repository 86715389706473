import restApi from "apis/restApi";
import {
  SET_STUDY_BUDGET_VISITS,
  UNSET_STUDY_BUDGET_VISITS,
  CREATE_STUDY_BUDGET_VISIT,
  DELETE_STUDY_BUDGET_VISIT,
  ERROR_STUDY_BUDGET_VISIT,
  UNSET_ERROR_STUDY_BUDGET_VISIT,
  UNSET_STUDY_BUDGET_VISIT_ACTION
} from "actions/types";
import { fetchToken } from "utils/authUtils";
import { setBudgetVisit } from "actions/budgetVisitActions";

const studyBudgetEndPoint = "/budget/study";

// Pass data to reducers
export const setStudyBudgetVisit = (obj, type, studyBudget) => {
  const action = { type: type, payload: obj };
  if (studyBudget) action.currentStudyBudgetVisit = studyBudget;
  return action;
};

// Non-API calls
export const clearStudyBudgetVisits = () => async dispatch => {
  dispatch(setStudyBudgetVisit({}, UNSET_STUDY_BUDGET_VISITS));
};
export const clearStudyBudgetVisitAction = () => async dispatch => {
  dispatch(setStudyBudgetVisit({}, UNSET_STUDY_BUDGET_VISIT_ACTION));
};
export const clearStudyBudgetVisitErrors = () => async dispatch => {
  dispatch(setStudyBudgetVisit({}, UNSET_ERROR_STUDY_BUDGET_VISIT));
};

// API calls
export const fetchStudyBudgetVisits = () => async dispatch => {
  const res = await restApi.get(studyBudgetEndPoint, {
    headers: { Authorization: `Bearer ${fetchToken()}` }
  });

  dispatch(setStudyBudgetVisit(res.data, SET_STUDY_BUDGET_VISITS));
};

export const createStudyBudgetVisit = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(studyBudgetEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setStudyBudgetVisit(res.data, CREATE_STUDY_BUDGET_VISIT));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setBudgetVisit(error, ERROR_STUDY_BUDGET_VISIT));
    }
  }
};

export const deleteStudyBudgetVisit = studyBudget => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(studyBudgetEndPoint, {
      data: { id: studyBudget.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setStudyBudgetVisit(res.data, DELETE_STUDY_BUDGET_VISIT, studyBudget)
    );
  }
};
