import {
  SET_CONTRACT_DOCUMENTS,
  UNSET_CONTRACT_DOCUMENTS,
  CREATE_CONTRACT_DOCUMENT,
  DELETE_CONTRACT_DOCUMENT,
  UPDATE_CONTRACT_DOCUMENT,
  UNSET_CONTRACT_DOCUMENT_ACTION,
  ERROR_CONTRACT_DOCUMENT,
  UNSET_ERROR_CONTRACT_DOCUMENT,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractDocumentEndPoint = '/documents/contract'

// Passing data back to reducers
export const setContractDocument = (obj, type, contractDocument) => {
  const action = { type: type, payload: obj }
  if (contractDocument) action.currentContractDocument = contractDocument
  return action
}

// Non-API calls
export const clearContractDocuments = () => async (dispatch) => {
  dispatch(setContractDocument({}, UNSET_CONTRACT_DOCUMENTS))
}
export const clearContractDocumentAction = () => async (dispatch) => {
  dispatch(setContractDocument({}, UNSET_CONTRACT_DOCUMENT_ACTION))
}
export const clearContractDocumentErrors = () => async (dispatch) => {
  dispatch(setContractDocument({}, UNSET_ERROR_CONTRACT_DOCUMENT))
}

// API calls
export const fetchContractDocuments = (params) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${contractDocumentEndPoint}`, {
      params: params,
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractDocument(res.data, SET_CONTRACT_DOCUMENTS))
  }
}

export const createContractDocument = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractDocumentEndPoint, params, {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
          'Content-Type': `multipart/form-data; boundary=${params._boundary}`,
        },
      })
      dispatch(setContractDocument(res.data, CREATE_CONTRACT_DOCUMENT))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractDocument(error, ERROR_CONTRACT_DOCUMENT))
    }
  }
}

export const deleteContractDocument = (activity) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractDocumentEndPoint, {
      data: { id: activity.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractDocument(res.data, DELETE_CONTRACT_DOCUMENT, activity))
  }
}

export const updateContractDocument =
  (params, activity) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractDocumentEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setContractDocument(res.data, UPDATE_CONTRACT_DOCUMENT, activity)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractDocument(error, ERROR_CONTRACT_DOCUMENT))
      }
    }
  }
