import {
  CREATE_SR_DOCUMENT_STATUS,
  DELETE_SR_DOCUMENT_STATUS,
  ERROR_SR_DOCUMENT_STATUS,
  SET_SR_DOCUMENT_STATUSES,
  SET_SR_DOCUMENT_STATUS_OPTIONS,
  TOGGLE_SR_DOCUMENT_STATUS,
  UNSET_ERROR_SR_DOCUMENT_STATUS,
  UNSET_SR_DOCUMENT_STATUS_ACTION,
  UNSET_SR_DOCUMENT_STATUS_OPTIONS,
  UPDATE_SR_DOCUMENT_STATUS,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentTypeEndPoint = '/sr-documents/status'

// Passing data back to reducers
const setSrDocumentStatus = (
  obj,
  type,
  srDocumentStatus,
  noDefaultOptions = false
) => {
  const action = { type: type, payload: obj }
  if (srDocumentStatus) action.currentSrDocumentStatus = srDocumentStatus
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions
  return action
}

// Non-API calls
export const clearSrDocumentStatusOptions = () => async (dispatch) => {
  dispatch(setSrDocumentStatus({}, UNSET_SR_DOCUMENT_STATUS_OPTIONS))
}
export const clearSrDocumentStatusAction = () => async (dispatch) => {
  dispatch(setSrDocumentStatus({}, UNSET_SR_DOCUMENT_STATUS_ACTION))
}
export const clearSrDocumentStatusErrors = () => async (dispatch) => {
  dispatch(setSrDocumentStatus({}, UNSET_ERROR_SR_DOCUMENT_STATUS))
}

// API calls
export const fetchSrDocumentStatuses =
  (results, srDocumentStatus, noDefaultOptions = false) =>
  async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(srDocumentTypeEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setSrDocumentStatus(res.data, SET_SR_DOCUMENT_STATUSES))
      } else if (results === 'form') {
        dispatch(
          setSrDocumentStatus(
            res.data,
            SET_SR_DOCUMENT_STATUS_OPTIONS,
            srDocumentStatus,
            noDefaultOptions
          )
        )
      }
    }
  }

export const createSrDocumentStatus = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentStatus(res.data, CREATE_SR_DOCUMENT_STATUS))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentStatus(error, ERROR_SR_DOCUMENT_STATUS))
    }
  }
}

export const deleteSrDocumentStatus = (status) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentTypeEndPoint, {
      data: { id: status.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentStatus(res.data, DELETE_SR_DOCUMENT_STATUS, status))
  }
}

export const updateSrDocumentStatus = (params, status) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(srDocumentTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentStatus(res.data, UPDATE_SR_DOCUMENT_STATUS, status))
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setSrDocumentStatus(error, ERROR_SR_DOCUMENT_STATUS))
    }
  }
}

export const toggleSrDocumentStatus = (status, toggle) => async (dispatch) => {
  const toggleEndPoint = `${srDocumentTypeEndPoint}/${
    toggle ? 'enable' : 'disable'
  }`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: status.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentStatus(
        { res, toggle: toggle },
        TOGGLE_SR_DOCUMENT_STATUS,
        status
      )
    )
  }
}
