import React, { useState, useEffect, useContext, createContext } from "react";
import restApi from "apis/restApi";

import {
  fetchOTPReq,
  fetchToken,
  setOTPReq,
  setMFAQR,
  clearMFAQR
} from "utils/authUtils";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(fetchToken() ?? null);
  // Adding state for user info in context state instead of redux
  const [isOTPReq, setIsOTPReq] = useState(fetchOTPReq() ?? null);
  const [hasUserInfo, setHasUserInfo] = useState(false);

  const setUserToken = token => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  // sets app MFA requirement and store in local storaage
  const setOTPCode = req => {
    setOTPReq(req);
    setIsOTPReq(req);
  };

  // Wrap any methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email, password, otp) => {
    const payload = {
      email: email,
      password: password
    };
    if (otp) {
      payload.otp_code = otp;
    }

    const res = await restApi.post("/user/login", payload);
    // if force new password is true, we need to set user
    // so we can change password
    if (res.data.token || res.data.force_new_password) {
      setUserToken(res.data.token);
      // We only set user if user has a token
      setUser(res.data);
      setHasUserInfo(true);
    }

    if (res?.data?.otp_code_required !== undefined) {
      setOTPCode(res?.data?.otp_code_required);
    }

    if (res?.data?.mfa_image) {
      setMFAQR(res?.data?.mfa_image);
    }

    return res.data;
  };

  const signout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setUser(false);
    setHasUserInfo(false);
    clearMFAQR();
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const res = await restApi.get("/user", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(res.data);
        setHasUserInfo(true);
      } catch (err) {
        setUser(false);
        setHasUserInfo(false);
      }
    };
    // set user if token exists, set to false if not
    if (token) {
      fetchCurrentUser();
    } else {
      setUser(false);
    }
  }, [token, setUser]);

  // Return the user object and auth methods
  return {
    user,
    token,
    signin,
    signout,
    setUser,
    setUserToken,
    hasUserInfo
  };
}
