import _ from "lodash";
import React from "react";

import BadgeLabel from "components/common/badges/BadgeLabel";
import CardPanel from "components/common/panel/CardPanel";

import { formatDateToLocal, formatDateStandard } from "utils/commonUtils";
import { formatNumber } from "utils/formatNumbersUtil";

const CustomPropertyDisplay = ({ customProperties, propertyOptions }) => {
  const doCustomPropertiesNotExist = () => {
    const customPropertiesWithValues = Object.keys(customProperties)
      .map(prop => customProperties[prop].value)
      .filter(value => value !== null);

    if (customPropertiesWithValues.length === 0) {
      return true;
    }
    return false;
  };
  const fetchPropertyTypeCode = key => {
    for (const item of propertyOptions) {
      if (item.property.name === key) {
        return item.property.property_type.code;
      }
    }
  };

  const renderValue = (key, prop) => {
    switch (fetchPropertyTypeCode(key)) {
      case "boolean":
        return prop.value.toString();
      case "number":
        return formatNumber(prop.value);
      case "datetime":
        return formatDateToLocal(prop.value, "LLLL");
      case "date":
        return formatDateStandard(prop.value, "LL");
      default:
        return prop.value;
    }
  };

  const renderResults = () => {
    return _.map(customProperties, (prop, key) => {
      if (prop.value !== null && prop.value !== "") {
        return (
          <div key={key} className="d-flex flex-column align-items-start mb-3">
            <BadgeLabel>{key}</BadgeLabel>
            {renderValue(key, prop)}
          </div>
        );
      }
    });
  };

  if (doCustomPropertiesNotExist() || _.isEmpty(propertyOptions)) return "";
  return <CardPanel title="Custom Properties">{renderResults()}</CardPanel>;
};

export default CustomPropertyDisplay;
