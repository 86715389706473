import ContractPropertyCreate from './ContractPropertyCreate'
import ContractPropertyDelete from './ContractPropertyDelete'
import ContractPropertyUpdate from './ContractPropertyUpdate'
import {
  fetchContractProperties,
  fetchContractPropertyOptions,
  toggleContractPropertyGuestVisibility,
} from 'actions/contracts/contractPropertyActions'
import { fetchCustomProperties } from 'actions/properties/customPropertyActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Table from 'components/common/tables/Table'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBIcon, MDBCardBody } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const NEW_HEADER = 'New Contract Property'
const EDIT_HEADER = 'Edit Contract Property'
const DELETE_HEADER = 'Delete Contract Property'

const ContractPropertyList = () => {
  //// HOOKS.
  const dispatch = useDispatch()
  const auth = useAuth()
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { contractPropertiesAvailable, lastAction, contractPropertyOptions } =
    useSelector((state) => state.contractProperties)

  const { propertiesAvailable } = useSelector((state) => state.customProperties)

  //// LOCAL STATE.
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'desc',
      },
      {
        label: 'Name',
        field: 'property.name',
        sort: 'desc',
        minimal: 'lg',
      },
      {
        label: 'Contract Type',
        field: 'contract_type.name',
        sort: 'desc',
      },
      {
        label: 'Property Type',
        field: 'property.property_type.name',
        sort: 'desc',
      },
      {
        label: 'Required',
        field: 'required',
        sort: 'desc',
      },
      {
        label: 'Visible to Guests',
        field: 'is_visible_to_guests',
        toggleControl: true,
        isListOnly: true,
        sort: 'disabled',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractProperties())
    dispatch(fetchCustomProperties())
    dispatch(fetchContractPropertyOptions())
  }, [dispatch])

  //// RENDER VARS & HELPERS.
  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      switch (type) {
        case 'new':
          setHeaderText(NEW_HEADER)
          setFooter(true)
          setActions([
            {
              text: 'Submit',
              color: 'primary',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ])
          setModalState(true)
          break
        case 'edit':
          setHeaderText(EDIT_HEADER)
          setFooter(true)
          setActions([
            {
              text: 'Update',
              color: 'primary',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ])
          setSelected(item)
          setModalState(true)
          break
        case 'delete':
          setHeaderText(DELETE_HEADER)
          setFooter(true)
          setActions([
            {
              text: 'Delete',
              color: 'danger',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ])
          setSelected(item)
          setModalState(true)
          break
        default:
          setModalState(false)
          break
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  //// RENDER.
  return (
    <>
      <SectionHeaderMenu
        menuData={
          _.isEmpty(contractPropertyOptions?.contract_type_id) ||
          _.isEmpty(propertiesAvailable) || {
            order: 2,
            show: true,
            linkItems: [
              {
                name: (
                  <>
                    <MDBIcon icon="fas fa-plus" /> Add Contract Property
                  </>
                ),
                url: `/contracts/properties`,
                exact: true,
                additionalProps: {
                  active: true,
                  onClick: (e) => toggleModal(e, 'new'),
                  className: 'btn-sm',
                },
              },
            ],
          }
        }
      />
      <MDBCardBody>
        {_.size(contractPropertiesAvailable) ? (
          <Table
            section="Property"
            tableData={tableData}
            setTableData={setTableData}
            rowData={contractPropertiesAvailable}
            sort={['id', 'desc']}
            toggleModal={toggleModal}
            updatedState={lastAction}
            downloadableCSV
            editButton={auth?.user?.is_admin}
            deleteButton={auth?.user?.is_admin}
            toggleAvailability={(item, toggle) => {
              dispatch(toggleContractPropertyGuestVisibility(item, toggle))
            }}
          />
        ) : _.isEmpty(propertiesAvailable) ? (
          <EmptyList
            name="custom property"
            namePlural="custom properties"
            customText="At least one custom property is required before making contract properties. "
            userRole={auth?.user?.user_level}
            createElement={<Link to="/admin/properties">Create</Link>}
          />
        ) : _.isEmpty(contractPropertyOptions?.contract_type_id) ? (
          <EmptyList
            name="contract type"
            namePlural="contract types"
            customText="At least one contract type is required before making contract properties. "
            userRole={auth?.user?.user_level}
            createElement={<Link to="/admin/contracts/types">Create</Link>}
          />
        ) : _.isEmpty(contractPropertiesAvailable) ? (
          <EmptyList
            name="contract property"
            namePlural="contract properties"
            userRole={auth?.user?.user_level}
            createElement={
              <span className="link" onClick={(e) => toggleModal(e, 'new')}>
                Create
              </span>
            }
          />
        ) : (
          ''
        )}
      </MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {headerText === NEW_HEADER ? (
          <ContractPropertyCreate
            formId={formId}
            params={params}
            setParams={setParams}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === EDIT_HEADER ? (
          <ContractPropertyUpdate
            formId={formId}
            params={params}
            setParams={setParams}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === DELETE_HEADER ? (
          <ContractPropertyDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  )
}

export default ContractPropertyList
