import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createDataImport,
  clearDataImportErrors,
  clearDataImportStatus
} from "actions/dataImportActions";

import DataImportForm from "./DataImportForm";

const DataImportCreate = ({ formId, params, setParams, closeModal }) => {
  const { status, errors } = useSelector(state => state.dataImports);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearDataImportErrors());
      dispatch(clearDataImportStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        type: "",
        import_file: null
      });
    }
  }, [params, setParams]);

  // watch for success
  useEffect(() => {
    if (status.uploadSuccess) {
      closeModal();
    }
  }, [status.uploadSuccess, closeModal]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();

    const importParams = new FormData();
    importParams.append("import_file", createParams.import_file);
    importParams.append("type", createParams.type);

    dispatch(createDataImport(importParams));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        <DataImportForm params={params} setParams={setParams} errors={errors} />
      </form>
    );
  }

  return "";
};

export default DataImportCreate;
