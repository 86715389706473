import {
  STUDY_STAT_CLEAR_ACTION,
  STUDY_STAT_TIME_PER_CONTRACT,
  STUDY_STAT_TIME_PER_CONTRACT_CLEAR,
  STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE,
  STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE_CLEAR,
  STUDY_STAT_TIME_PER_STATUS,
  STUDY_STAT_TIME_PER_STATUS_CLEAR,
  STUDY_STAT_TIME_PER_LOCATION,
  STUDY_STAT_TIME_PER_LOCATION_CLEAR
} from "actions/types";

const INITIAL_STATE = {
  stats: {},
  lastAction: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STUDY_STAT_TIME_PER_CONTRACT:
      return {
        ...state,
        lastAction: "timePerContract",
        stats: { ...state.stats, timePerContract: action.payload }
      };
    case STUDY_STAT_TIME_PER_CONTRACT_CLEAR:
      return {
        ...state,
        lastAction: "timePerContractCleared",
        stats: { ...state.stats, timePerContract: null }
      };
    case STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE:
      return {
        ...state,
        lastAction: "timePerContractPerContractType",
        stats: {
          ...state.stats,
          timePerContractPerContractType: action.payload
        }
      };
    case STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE_CLEAR:
      return {
        ...state,
        lastAction: "timePerContractPerContractTypeCleared",
        stats: { ...state.stats, timePerContractPerContractType: null }
      };
    case STUDY_STAT_TIME_PER_STATUS:
      return {
        ...state,
        lastAction: "timePerStatus",
        stats: { ...state.stats, timePerStatus: action.payload }
      };
    case STUDY_STAT_TIME_PER_STATUS_CLEAR:
      return {
        ...state,
        lastAction: "timePerStatusCleared",
        stats: { ...state.stats, timePerStatus: null }
      };
    case STUDY_STAT_TIME_PER_LOCATION:
      return {
        ...state,
        lastAction: "timePerLocation",
        stats: { ...state.stats, timePerLocation: action.payload }
      };
    case STUDY_STAT_TIME_PER_LOCATION_CLEAR:
      return {
        ...state,
        lastAction: "timePerLocationCleared",
        stats: { ...state.stats, timePerLocation: null }
      };
    case STUDY_STAT_CLEAR_ACTION:
      return { ...state, lastAction: null };
    default:
      return state;
  }
};
