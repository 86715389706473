import {
  SET_SR_DOCUMENT_ACTIVITIES,
  UNSET_SR_DOCUMENT_ACTIVITIES,
  CREATE_SR_DOCUMENT_ACTIVITY,
  DELETE_SR_DOCUMENT_ACTIVITY,
  UNSET_SR_DOCUMENT_ACTIVITY_ACTION,
  UPDATE_SR_DOCUMENT_ACTIVITY,
  ERROR_SR_DOCUMENT_ACTIVITY,
  UNSET_ERROR_SR_DOCUMENT_ACTIVITY,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  activitiesAvailable: {},
  activityErrors: {},
}

const srDocumentActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_ACTIVITIES:
      return {
        ...state,
        lastAction: 'fetch',
        activitiesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case UNSET_SR_DOCUMENT_ACTIVITIES:
      return INITIAL_STATE
    case UNSET_SR_DOCUMENT_ACTIVITY_ACTION:
      return { ...state, lastAction: null }
    case CREATE_SR_DOCUMENT_ACTIVITY:
      const createdItem = action.payload.created_items[0]
      //// we are not implementing srdoc documents for now
      // if (createdItem?.sr_document_document?.id) {
      //   createdItem.sr_document_document_id = createdItem.sr_document_document.id
      // }
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        activitiesAvailable: {
          ...state.activitiesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SR_DOCUMENT_ACTIVITY:
      const updatedItem = (state.activitiesAvailable[
        action.currentSrDocumentActivity.id
      ] = action.currentSrDocumentActivity)
      updatedItem.created_at =
        state.activitiesAvailable[
          action.currentSrDocumentActivity.id
        ].created_at
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_ACTIVITY:
      return {
        ...state,
        activityErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_ACTIVITY:
      return {
        ...state,
        activityErrors: {},
      }
    case DELETE_SR_DOCUMENT_ACTIVITY:
      const updated = state.activitiesAvailable
      _.unset(updated, `${action.currentSrDocumentActivity.id}`)
      return { ...state, lastAction: 'delete', activitiesAvailable: updated }
    default:
      return state
  }
}

export default srDocumentActivityReducer
