import SrDocumentDocForm from '../forms/SrDocumentDocForm'
import {
  clearSrDocumentDocAction,
  clearSrDocumentDocErrors,
  createSrDocumentDoc,
} from 'actions/srDocuments/srDocumentDocActions'
import { fetchSrDocumentDocTypes } from 'actions/srDocuments/srDocumentDocTypeActions'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const SrDocumentDocCreate = ({
  formId,
  srDocumentId,
  params,
  setParams,
  closeModal,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, docErrors } = useSelector((state) => state.srDocumentDocs)
  const docTypeOptions = useSelector(
    (state) => state.srDocumentDocTypes.docTypeOptions
  )

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchSrDocumentDocTypes('form'))
    return () => {
      dispatch(clearSrDocumentDocErrors())
      dispatch(clearSrDocumentDocAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        sr_document_id: srDocumentId,
        sr_document_doc_type_id: '',
        comment: '',
        import_file: null,
      })
    }
  }, [srDocumentId, params, setParams])

  // watch for success
  useEffect(() => {
    if (lastAction === 'create') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        console.log('params', params)

        const docParams = new FormData()
        docParams.append('sr_document_id', params.sr_document_id)
        docParams.append(
          'sr_document_doc_type_id',
          params.sr_document_doc_type_id
        )
        docParams.append('import_file', params.import_file)
        if (params.comment) docParams.append('comment', params.comment)

        dispatch(createSrDocumentDoc(docParams))
      }}
      className="form container"
      noValidate
    >
      <SrDocumentDocForm
        params={params}
        setParams={setParams}
        docTypeOptions={docTypeOptions}
        errors={docErrors}
      />
    </form>
  )
}

export default SrDocumentDocCreate
