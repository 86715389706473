import restApi from "apis/restApi";
import {
  UNSET_STUDY_CONTRACT_VALUE_ACTION,
  SET_STUDY_CONTRACT_VALUES,
  UNSET_STUDY_CONTRACT_VALUES,
  UPDATE_STUDY_CONTRACT_VALUE,
  CREATE_STUDY_CONTRACT_VALUE,
  ERROR_STUDY_CONTRACT_VALUE,
  UNSET_ERROR_STUDY_CONTRACT_VALUE,
  UPDATE_STUDY_CONTRACT_VALUE_COUNTRY_OPTIONS
} from "actions/types";
import { fetchToken } from "utils/authUtils";
import { formatNumber } from "utils/formatNumbersUtil";

const contractValueEndPoint = "/contractValue";
const countryEndPoint = "/country";

// Passing data back to reducers
export const setContractValue = (obj, type, contractValue) => {
  const action = { type: type, payload: obj };
  if (contractValue) action.currentContractValue = contractValue;
  return action;
};

// clear state
export const clearContractValues = () => async dispatch => {
  dispatch(setContractValue({}, UNSET_STUDY_CONTRACT_VALUES));
};

export const clearContractValueErrors = () => async dispatch => {
  dispatch(setContractValue({}, UNSET_ERROR_STUDY_CONTRACT_VALUE));
};

export const clearContractValueAction = () => async dispatch => {
  dispatch(setContractValue({}, UNSET_STUDY_CONTRACT_VALUE_ACTION));
};

export const setContractValueErrors = error => async dispatch => {
  dispatch(
    setContractValue({ error, type: "error" }, ERROR_STUDY_CONTRACT_VALUE)
  );
};

// API calls
export const fetchContractValues = id => async dispatch => {
  if (fetchToken()) {
    const endPoint = `${contractValueEndPoint}?study_id=${id}`;

    const res = await restApi.get(endPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContractValue(res.data, SET_STUDY_CONTRACT_VALUES));
  }
};

export const createContractValue = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractValueEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContractValue(res.data, CREATE_STUDY_CONTRACT_VALUE));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setContractValue(error, ERROR_STUDY_CONTRACT_VALUE));
    }
  }
};

export const updateContractValue = (
  params,
  currentContractValue
) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contractValueEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      Object.keys(params).forEach(v => {
        if (v.startsWith("contract_type_")) {
          const updatedAmount = params[v];
          const contractTypeId = v.replace("contract_type_", "");
          // If the current contract type already exist in the current state
          if (currentContractValue.values[contractTypeId]) {
            currentContractValue.values[contractTypeId].amount = formatNumber(
              updatedAmount
            );
          } else {
            // if contract type is new, we need to create the state
            currentContractValue.values[contractTypeId] = {
              contract_type: {
                id: Number(contractTypeId),
                name: ""
              },
              amount: formatNumber(updatedAmount)
            };
          }
        }
      });
      dispatch(
        setContractValue(
          res.data,
          UPDATE_STUDY_CONTRACT_VALUE,
          currentContractValue
        )
      );
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setContractValue(error, ERROR_STUDY_CONTRACT_VALUE));
    }
  }
};

export const fetchAvailableCountries = selectedCountry => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(countryEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setContractValue(
        res.data,
        UPDATE_STUDY_CONTRACT_VALUE_COUNTRY_OPTIONS,
        selectedCountry
      )
    );
  }
};
