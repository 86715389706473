import {
  SET_CONTRACT_DOCUMENTS,
  UNSET_CONTRACT_DOCUMENTS,
  CREATE_CONTRACT_DOCUMENT,
  DELETE_CONTRACT_DOCUMENT,
  UNSET_CONTRACT_DOCUMENT_ACTION,
  UPDATE_CONTRACT_DOCUMENT,
  ERROR_CONTRACT_DOCUMENT,
  UNSET_ERROR_CONTRACT_DOCUMENT,
  CREATE_CONTRACT_ACTIVITY,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  documentsAvailable: {},
  documentErrors: {},
}

const contractDocumentReducer = (state = INITIAL_STATE, action) => {
  let currentDocAvail = state.documentsAvailable
  const isExistingDoc = (item) => {
    return _.find(state.documentsAvailable, (o) => {
      return o.contract_document_type.id === item.contract_document_type.id
    })
  }

  switch (action.type) {
    case SET_CONTRACT_DOCUMENTS:
      return {
        ...state,
        lastAction: 'fetch',
        documentsAvailable: _.mapKeys(action.payload, 'id'),
      }
    case UNSET_CONTRACT_DOCUMENTS:
      return INITIAL_STATE
    case UNSET_CONTRACT_DOCUMENT_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_DOCUMENT:
      const createdItem = action.payload.created_items[0]

      const newState = {
        ...state,
        lastAction: 'create',
        documentsAvailable: {
          ...state.documentsAvailable,
          [createdItem.id]: createdItem,
        },
      }

      const createdItemExists = isExistingDoc(createdItem)

      if (createdItemExists !== undefined) {
        _.unset(currentDocAvail, `${createdItemExists.id}`)
        currentDocAvail = {
          ...currentDocAvail,
          [createdItem.id]: createdItem,
        }
        return {
          ...state,
          lastAction: 'create',
          documentsAvailable: currentDocAvail,
        }
      } else {
        return newState
      }
    case CREATE_CONTRACT_ACTIVITY:
      if (action.payload.created_items[0].contract_document.length) {
        const createdActivityItem =
          action.payload.created_items[0].contract_document

        const newActivityState = {
          ...state,
          lastAction: 'create',
          documentsAvailable: {
            ...state.documentsAvailable,
            [createdActivityItem.id]: createdActivityItem,
          },
        }

        const createdItemFromActExists = isExistingDoc(createdActivityItem)

        if (createdItemFromActExists !== undefined) {
          _.unset(currentDocAvail, `${createdItemFromActExists.id}`)
          currentDocAvail = {
            ...currentDocAvail,
            [createdActivityItem.id]: createdActivityItem,
          }
          return {
            ...state,
            lastAction: 'create',
            documentsAvailable: currentDocAvail,
          }
        } else {
          return newActivityState
        }
      } else {
        return { ...state }
      }
    case UPDATE_CONTRACT_DOCUMENT:
      const updatedItem = (state.documentsAvailable[
        action.currentContractDocument.id
      ] = action.currentContractDocument)
      updatedItem.created_at =
        state.documentsAvailable[action.currentContractDocument.id].created_at
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_DOCUMENT:
      return {
        ...state,
        documentErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_DOCUMENT:
      return {
        ...state,
        documentErrors: {},
      }
    case DELETE_CONTRACT_DOCUMENT:
      const updated = state.documentsAvailable
      _.unset(updated, `${action.currentContractDocument.id}`)
      return { ...state, lastAction: 'delete', documentsAvailable: updated }
    default:
      return state
  }
}

export default contractDocumentReducer
