import React, { useState, useEffect, useCallback } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import moment from "moment";
import DateFnsUtils from "@date-io/moment"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

const CustomPropertyDateOnly = ({
  item,
  params,
  setParams,
  current,
  customProperties,
  setCustomProperties,
  siteSection
}) => {
  const materialTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#f88b5e",
        dark: "#f88b5e"
      },
      secondary: {
        main: "#2d3848"
      },
      contrastThreshold: 3,
      tonalOffset: 0.2
    },
    typography: {
      useNextVariants: true
    }
  });

  // this `item` doesn't have an `id`, I think it's supposed to be `item.contract_property_id` but not 100%; if I change it, the dates show up correctly but they seem to be getting changed improperly
  //const {id} = item;
  const id = item?.id || item?.[`${siteSection}_property_id`];

  const convertToISOString = useCallback(date => {
    return moment.utc(`${date}`, "YYYY-MM-DD").toISOString();
  }, []);
  const convertToMoment = time => {
    const utcTime = time === "" ? "" : moment.utc(time + " 00:00:00Z");
    //const utcTime = moment.utc(time+" 00:00:00Z").format("YYYY-MM-DD HH:mm:ssZ");
    return utcTime;
  };

  const [currentDate, setCurrentDate] = useState(
    params[`id_${id}`] ? convertToMoment(params[`id_${id}`]) : ""
  );
  const [loading, setLoading] = useState(true);

  const adjustLoading = useCallback(setLoading, []);
  const buildParams = useCallback(setParams, []);

  useEffect(() => {
    if (loading) {
      buildParams(prev => ({
        ...prev,
        [`id_${id}`]: currentDate?._isAMomentObject
          ? currentDate.format("YYYY-MM-DD")
          : ""
      }));
      adjustLoading(false);
    }
  }, [
    loading,
    adjustLoading,
    buildParams,
    currentDate,
    convertToISOString,
    id
  ]);

  const handleDate = (item, value) => {
    const newDate = value ? moment(value).format("YYYY-MM-DD") : ""; // not using moment.utc() because DatePicker is running local time internally
    if (!currentDate || newDate !== currentDate.format("YYYY-MM-DD")) {
      if (setCurrentDate) setCurrentDate(convertToMoment(newDate));
      setParams({
        ...params,
        [`id_${id}`]: newDate
      });

      if (current && customProperties) {
        setCustomProperties(prev => ({
          ...prev,
          [`${item.property.name}`]: {
            [`${siteSection}_property_id`]: item.id,
            value: newDate
          }
        }));
      }
    }
  };

  return (
    <>
      <h6 className="text-muted">{item.property.name}</h6>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={materialTheme}>
          <MDBRow className="mb-4">
            <MDBCol
              md={item.property.property_type.code === "date" ? "12" : "6"}
            >
              <DatePicker
                className="picker"
                format="MMMM Do, YYYY"
                value={
                  currentDate
                    ? moment.utc(currentDate).format("YYYY-MM-DD")
                    : null
                }
                onChange={value => handleDate(item, value)}
                clearable={true}
                clearLabel="Remove"
              />
            </MDBCol>
          </MDBRow>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default CustomPropertyDateOnly;
