import StudyDelete from './StudyDelete'
import ContractValuesList from './contractValues/ContractValuesList'
import { fetchStudyPropertyOptions } from 'actions/studies/studyPropertyActions'
import {
  fetchStudyTimePerCompletedContract,
  fetchStudyTimePerCompletedContractPerContractType,
  fetchStudyStatusTimePerContract,
  fetchStudyLocationTimePerContract,
} from 'actions/studies/studyStatActions'
import HorizontalBarChart from 'components/common/HorizontalBarChart'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import BadgeLabel from 'components/common/badges/BadgeLabel'
import CustomPropertyDisplay from 'components/common/properties/CustomPropertyDisplay'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBRow, MDBCol } from 'mdbreact'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isUserNotReadOnly } from 'utils/authUtils'
import { formatDateToLocal } from 'utils/commonUtils'

const StudyShow = ({ match }) => {
  const studyId = match.params.id
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()
  const allStudies = useSelector((state) => state.studies.studiesAvailable)
  const { propertyOptions } = useSelector((state) => state.studyProperties)
  const { stats } = useSelector((state) => state.studyStats)
  const [currentStudy, setCurrentStudy] = useState(allStudies[studyId])
  const auth = useAuth()
  const dispatch = useDispatch()

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Study')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  useEffect(() => {
    dispatch(fetchStudyPropertyOptions())
    dispatch(fetchStudyTimePerCompletedContract(studyId))
    dispatch(fetchStudyTimePerCompletedContractPerContractType(studyId))
    dispatch(fetchStudyStatusTimePerContract(studyId))
    dispatch(fetchStudyLocationTimePerContract(studyId))
  }, [dispatch, studyId])

  useEffect(() => {
    if (Object.keys(allStudies).length) {
      setCurrentStudy(allStudies[studyId])
    }
  }, [allStudies, studyId, setCurrentStudy])

  if (currentStudy) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: isUserNotReadOnly(auth?.user?.user_level)
        ? [
            {
              name: 'View',
              url: `/studies/${currentStudy.id}`,
              exact: true,
            },
            {
              name: 'Edit',
              url: `/studies/${currentStudy.id}/edit`,
              exact: true,
            },
          ]
        : [],
    }

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: 'Delete',
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: (e) => toggleModal(e, 'delete', currentStudy),
          className: 'text-danger',
        },
      })
    }

    return (
      <>
        <Section
          header={`Study #${currentStudy.id}`}
          breadcrumbs
          menu={sectionHeaderMenu}
        >
          <MDBRow>
            <MDBCol
              md="6"
              className="d-flex flex-column align-items-start justify-content-start"
            >
              <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
                <BadgeLabel>Sponsor Study Name</BadgeLabel>
                {currentStudy.name}
              </div>
              <hr className="mt-3 mb-3 w-100" />
              <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
                <BadgeLabel>Protocol</BadgeLabel>
                {currentStudy.protocol}
              </div>
              <hr className="mt-3 mb-3 w-100" />
              <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
                <BadgeLabel>Avg Turn Around Time</BadgeLabel>
                {stats?.timePerContract?.days
                  ? `${stats.timePerContract.days} days`
                  : 'No Completed Contracts'}
              </div>
              <hr className="mt-3 mb-3 w-100" />
              <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
                <BadgeLabel>Created on</BadgeLabel>
                {formatDateToLocal(currentStudy.created_at, 'LLLL')}
              </div>
            </MDBCol>
            <MDBCol md="6">
              {!_.isEmpty(stats.timePerContractPerContractType) && (
                <HorizontalBarChart
                  graphData={stats.timePerContractPerContractType}
                  graphTitle="Avg Turn Around Time per Contract per Contract Type"
                  xAxisTitle="Avg Time (days)"
                />
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <hr className="mt-3 mb-3 w-100" />
              <CustomPropertyDisplay
                propertyOptions={propertyOptions}
                customProperties={currentStudy.custom_properties}
              />
            </MDBCol>
          </MDBRow>
          <Modal
            isOpen={modalOpen}
            toggle={toggleModal}
            headerText={headerText}
            includeFooter={true}
            actionButtons={[
              {
                text: 'Delete',
                color: 'danger',
                additionalProps: {
                  form: formId,
                  type: 'submit',
                },
              },
            ]}
          >
            <StudyDelete
              formId={formId}
              selected={selected}
              closeModal={() => setModalState(false)}
            />
          </Modal>
        </Section>
        {(!_.isEmpty(stats?.timePerLocation?.data) ||
          !_.isEmpty(stats?.timePerStatus?.data)) && (
          <Section
            header="Average Time per Contract"
            description="The average amount of time for all contracts by location or status"
          >
            <MDBRow>
              <MDBCol md="6">
                {!_.isEmpty(stats.timePerLocation) && (
                  <HorizontalBarChart
                    graphData={stats.timePerLocation}
                    graphTitle="Average Time by Location"
                    xAxisTitle="Avg Time (days)"
                  />
                )}
              </MDBCol>
              <MDBCol md="6">
                {!_.isEmpty(stats.timePerStatus) && (
                  <HorizontalBarChart
                    graphData={stats.timePerStatus}
                    graphTitle="Average Time by Status"
                    xAxisTitle="Avg Time (days)"
                  />
                )}
              </MDBCol>
            </MDBRow>
          </Section>
        )}
        {auth?.user?.is_admin && (
          <Section>
            <ContractValuesList studyId={studyId} />
          </Section>
        )}
      </>
    )
  }

  return ''
}

export default StudyShow
