import { isInitializingContractViewsAtom } from './HomeContractViews'
import SrDocumentTable, {
  srDocumentsColumnsAtom,
  srDocumentsFilterParamsAtom,
  srDocumentsQueryParamsAtom,
  srDocumentsTableDataAtom,
  srDocumentsViewParamsAtom,
  useResetSrDocuments,
} from './srDocuments/SrDocumentTable'
import SrDocumentView from './srDocuments/SrDocumentView'
import { fetchSrDocumentPropertyOptions } from 'actions/srDocuments/srDocumentPropertyActions'
import {
  clearSrDocumentViews,
  fetchSrDocumentViews,
} from 'actions/srDocuments/srDocumentViewActions'
import EmptyList from 'components/common/EmptyList'
import Section from 'components/common/Section'
import { useAuth } from 'hooks/useAuth'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setSrDocumentColumns } from 'utils/tableUtils'

const fetchTableLink = (type, item) => {
  switch (type) {
    case 'show':
      return `/sr-documents/${item.id}`
    default:
      return null
  }
}

export const isInitializingSrDocumentViewsAtom = atom(true)

const HomeSrDocumentViews = () => {
  //// HOOKS.
  const auth = useAuth()
  const dispatch = useDispatch()
  const resetSrDocuments = useResetSrDocuments(dispatch)

  //// GLOBAL STATE.
  const [srDocumentsTableData, setSrDocumentsTableData] = useAtom(
    srDocumentsTableDataAtom
  )
  const [
    setSrDocumentsFilterParams,
    setSrDocumentsViewParams,
    setSrDocumentsQueryParams,
    setSrDocumentsColumns,
  ] = [
    useSetAtom(srDocumentsFilterParamsAtom),
    useSetAtom(srDocumentsViewParamsAtom),
    useSetAtom(srDocumentsQueryParamsAtom),
    useSetAtom(srDocumentsColumnsAtom),
  ]

  const {
    srDocumentViews: {
      srDocumentViewsAvailable,
      lastAction: lastSrDocumentViewAction,
    },
    srDocuments: { srDocumentsAvailable, lastAction, isLoadingMore },
    srDocumentProperties: {
      srDocumentPropertyOptions,
      lastAction: lastActionSrDocumentProperties,
    },
  } = useSelector(({ srDocumentViews, srDocuments, srDocumentProperties }) => ({
    srDocumentViews,
    srDocuments,
    srDocumentProperties,
  }))

  const [[isInitializing, setIsInitializing], isContractViewsInitializing] = [
    useAtom(isInitializingSrDocumentViewsAtom),
    useAtomValue(isInitializingContractViewsAtom),
  ]

  //// LOCAL STATE.
  const [defaultView, setDefaultView] = useState({})

  //// EFFECTS.
  useEffect(
    () => {
      setIsInitializing(true)
      resetSrDocuments()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    // loading init data.
    Promise.all(
      [fetchSrDocumentPropertyOptions(), fetchSrDocumentViews()].map((action) =>
        dispatch(action)
      )
    ).finally(() => {
      setIsInitializing(false)
    })

    return () => {
      dispatch(clearSrDocumentViews())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // build home page on first load
  useEffect(() => {
    if (
      !isInitializing &&
      lastSrDocumentViewAction === 'fetch' &&
      lastActionSrDocumentProperties === 'fetchOptions'
    ) {
      if (_.size(srDocumentViewsAvailable)) {
        const selectedView =
          _.find(srDocumentViewsAvailable, 'is_primary') ||
          _.find(srDocumentViewsAvailable, 'id')
        setDefaultView(selectedView)
        setSrDocumentsViewParams(selectedView.selection_params)
        setSrDocumentsFilterParams(selectedView.selection_params)
        setSrDocumentsQueryParams(selectedView.url_params)
        const srDocumentColumns = setSrDocumentColumns(
          selectedView.column_params,
          srDocumentPropertyOptions
        )
        const srDocumentColumnIds = srDocumentColumns.map((col) => col.field)
        setSrDocumentsColumns(srDocumentColumnIds)
        setSrDocumentsTableData({
          ...srDocumentsTableData,
          columns: srDocumentColumns,
        })
      }
    }
    // eslint-disable-next-line
  }, [
    isInitializing,
    lastSrDocumentViewAction,
    srDocumentViewsAvailable,
    lastActionSrDocumentProperties,
    srDocumentPropertyOptions,
  ])

  //// RENDER.
  return (
    !isInitializing &&
    !isContractViewsInitializing && (
      <Section
        header="Saved Site Readiness Document Views"
        menu={{
          order: 2,
          show: true,
          linkItems: [],
          leftSide: <SrDocumentView defaultView={defaultView} />,
        }}
      >
        {_.isEmpty(srDocumentViewsAvailable) ? (
          <EmptyList
            name="site readiness document view"
            namePlural="site readiness document views"
            createElement={<Link to="/sr-documents">Create</Link>}
            userRole={auth?.user?.user_level}
          />
        ) : (
          <SrDocumentTable
            srDocumentsAvailable={srDocumentsAvailable}
            isLoadingMore={isLoadingMore}
            lastAction={lastAction}
            srDocumentPropertyOptions={srDocumentPropertyOptions}
            fetchTableLink={fetchTableLink}
            homePageView
            section="Home"
            willSaveColumns={false}
            shouldRequireQueryParams
          />
        )}
      </Section>
    )
  )
}

export default HomeSrDocumentViews
