import ContactItems from './BreadcrumbContactItems'
import ContractItems from './BreadcrumbContractItems'
import SiteItems from './BreadcrumbSiteItems'
import BreadcrumbSrDocumentItems from './BreadcrumbSrDocumentItems'
import StudyItems from './BreadcrumbStudyItems'
import useRouter from 'hooks/useRouter'
import { MDBBreadcrumb } from 'mdbreact'
import React from 'react'

const BreadcrumbNav = () => {
  const routingParams = useRouter()

  return (
    <MDBBreadcrumb className="mb-2">
      {routingParams.pathname.includes('contracts') ? (
        <ContractItems match={routingParams.match} />
      ) : routingParams.pathname.includes('sr-documents') ? (
        <BreadcrumbSrDocumentItems match={routingParams.match} />
      ) : routingParams.pathname.includes('studies') ? (
        <StudyItems match={routingParams.match} />
      ) : routingParams.pathname.includes('contacts') ? (
        <ContactItems match={routingParams.match} />
      ) : routingParams.pathname.includes('sites') ? (
        <SiteItems match={routingParams.match} />
      ) : (
        ''
      )}
    </MDBBreadcrumb>
  )
}

export default BreadcrumbNav
