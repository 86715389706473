/** @jsx jsx */
import _ from "lodash";
import { Link } from "react-router-dom";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { jsx, css } from "@emotion/core";

const TableSimple = ({ tableData, to, btn, autoWidth }) => {
  const tableStandard = css`
    .th-id,
    .th-check {
      width: 50px;
    }
  `;

  if (_.isEmpty(tableData.rows)) return "";

  return (
    <div>
      <MDBTable
        css={tableStandard}
        small
        btn={btn ?? false}
        autoWidth={autoWidth ?? false}
      >
        <MDBTableHead columns={tableData.columns} />
        <MDBTableBody rows={tableData.rows} />
      </MDBTable>
      {to ? (
        <div className="text-right">
          <Link
            to={to}
            className="btn ripple-parent btn-rounded btn-flat btn-outline-primary"
          >
            View All
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TableSimple;
