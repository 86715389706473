export const AUTHORIZATION_SET = 'AUTHORIZATION_SET'
export const AUTHORIZATION_RESET = 'AUTHORIZATION_RESET'

export const SET_CURRENT_CUSTOMERS = 'SET_CURRENT_CUSTOMERS'

// Budgets
export const SET_BUDGET_VISITS = 'SET_BUDGET_VISITS'
export const SET_BUDGET_VISIT_OPTIONS = 'SET_BUDGET_VISIT_OPTIONS'
export const UNSET_BUDGET_VISIT_OPTIONS = 'UNSET_BUDGET_VISIT_OPTIONS'
export const UNSET_BUDGET_VISIT_ACTION = 'UNSET_BUDGET_VISIT_ACTION'
export const ERROR_BUDGET_VISIT = 'ERROR_BUDGET_VISIT'
export const UNSET_ERROR_BUDGET_VISIT = 'UNSET_ERROR_BUDGET_VISIT'
export const CREATE_BUDGET_VISIT = 'CREATE_BUDGET_VISIT'
export const DELETE_BUDGET_VISIT = 'DELETE_BUDGET_VISIT'
export const UPDATE_BUDGET_VISIT = 'UPDATE_BUDGET_VISIT'
export const TOGGLE_BUDGET_VISIT = 'TOGGLE_BUDGET_VISIT'

// Charts
export const CHART_CONTRACT_ACTIVITY_BY_LOCATION =
  'CHART_CONTRACT_ACTIVITY_BY_LOCATION'
export const CHART_CONTRACT_ACTIVITY_CLEAR_ACTION =
  'CHART_CONTRACT_ACTIVITY_CLEAR_ACTION'
export const CHART_CONTRACT_ACTIVITY_CLEAR_BY_LOCATION =
  'CHART_CONTRACT_ACTIVITY_CLEAR_BY_LOCATION'

// Contacts
export const SET_CONTACTS = 'SET_CONTACTS'
export const SET_CONTACT = 'SET_CONTACT'
export const UNSET_CONTACTS = 'UNSET_CONTACTS'
export const SET_CONTACT_OPTIONS = 'SET_CONTACT_OPTIONS'
export const UNSET_CONTACT_OPTIONS = 'UNSET_CONTACT_OPTIONS'
export const ERROR_CONTACT = 'ERROR_CONTACT'
export const UNSET_ERROR_CONTACT = 'UNSET_ERROR_CONTACT'
export const UNSET_CONTACT_ACTION = 'UNSET_CONTACT_ACTION'
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const TOGGLE_CONTACT = 'TOGGLE_CONTACT'

// Contact Properties
export const SET_CONTACT_PROPERTIES = 'SET_CONTACT_PROPERTIES'
export const SET_CONTACT_PROPERTY_OPTIONS = 'SET_CONTACT_PROPERTY_OPTIONS'
export const UNSET_CONTACT_PROPERTY_OPTIONS = 'UNSET_CONTACT_PROPERTY_OPTIONS'
export const ERROR_CONTACT_PROPERTY = 'ERROR_CONTACT_PROPERTY'
export const UNSET_ERROR_CONTACT_PROPERTY = 'UNSET_ERROR_CONTACT_PROPERTY'
export const CREATE_CONTACT_PROPERTY = 'CREATE_CONTACT_PROPERTY'
export const DELETE_CONTACT_PROPERTY = 'DELETE_CONTACT_PROPERTY'
export const UPDATE_CONTACT_PROPERTY = 'UPDATE_CONTACT_PROPERTY'
export const UNSET_CONTACT_PROPERTY_ACTION = 'UNSET_CONTACT_PROPERTY_ACTION'

// Contracts
export const APPEND_CONTRACTS = 'APPEND_CONTRACTS'
export const SET_CONTRACT = 'SET_CONTRACT'
export const SET_CONTRACTS = 'SET_CONTRACTS'
export const UNSET_CONTRACTS = 'UNSET_CONTRACTS'
export const ERROR_CONTRACT = 'ERROR_CONTRACT'
export const UNSET_ERROR_CONTRACT = 'UNSET_ERROR_CONTRACT'
export const UNSET_CONTRACT_ACTION = 'UNSET_CONTRACT_ACTION'
export const CREATE_CONTRACT = 'CREATE_CONTRACT'
export const DELETE_CONTRACT = 'DELETE_CONTRACT'
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT'

// Contract Activity
export const SET_CONTRACT_ACTIVITIES = 'SET_CONTRACT_ACTIVITIES'
export const UNSET_CONTRACT_ACTIVITIES = 'UNSET_CONTRACT_ACTIVITIES'
export const ERROR_CONTRACT_ACTIVITY = 'ERROR_CONTRACT_ACTIVITY'
export const UNSET_ERROR_CONTRACT_ACTIVITY = 'UNSET_ERROR_CONTRACT_ACTIVITY'
export const CREATE_CONTRACT_ACTIVITY = 'CREATE_CONTRACT_ACTIVITY'
export const DELETE_CONTRACT_ACTIVITY = 'DELETE_CONTRACT_ACTIVITY'
export const UPDATE_CONTRACT_ACTIVITY = 'UPDATE_CONTRACT_ACTIVITY'
export const UNSET_CONTRACT_ACTIVITY_ACTION = 'UNSET_CONTRACT_ACTIVITY_ACTION'

// Contract Activity Alerts
export const LOADING_CONTRACT_ACTIVITY_ALERTS =
  'LOADING_CONTRACT_ACTIVITY_ALERTS'
export const SET_CONTRACT_ACTIVITY_ALERTS = 'SET_CONTRACT_ACTIVITY_ALERTS'
export const UNSET_CONTRACT_ACTIVITY_ALERTS = 'UNSET_CONTRACT_ACTIVITY_ALERTS'
export const CREATE_CONTRACT_ACTIVITY_ALERT = 'CREATE_CONTRACT_ACTIVITY_ALERT'
export const DELETE_CONTRACT_ACTIVITY_ALERT = 'DELETE_CONTRACT_ACTIVITY_ALERT'
export const UPDATE_CONTRACT_ACTIVITY_ALERT = 'UPDATE_CONTRACT_ACTIVITY_ALERT'
export const ERROR_CONTRACT_ACTIVITY_ALERT = 'ERROR_CONTRACT_ACTIVITY_ALERT'
export const UNSET_CONTRACT_ACTIVITY_ALERT_ACTION =
  'UNSET_CONTRACT_ACTIVITY_ALERT_ACTION'
export const UNSET_ERROR_CONTRACT_ACTIVITY_ALERT =
  'UNSET_ERROR_CONTRACT_ACTIVITY_ALERT'

// Contract Budgets
export const SET_CONTRACT_BUDGETS = 'SET_CONTRACT_BUDGETS'
export const UNSET_CONTRACT_BUDGETS = 'UNSET_CONTRACT_BUDGETS'
export const ERROR_CONTRACT_BUDGET = 'ERROR_CONTRACT_BUDGET'
export const UNSET_ERROR_CONTRACT_BUDGET = 'UNSET_ERROR_CONTRACT_BUDGET'
export const UPDATE_CONTRACT_BUDGET = 'UPDATE_CONTRACT_BUDGET'
export const SET_CONTRACT_BUDGET_HISTORY = 'SET_CONTRACT_BUDGET_HISTORY'
export const UNSET_CONTRACT_BUDGET_HISTORY = 'UNSET_CONTRACT_BUDGET_HISTORY'

// Contract Activity
export const SET_CONTRACT_DOCUMENTS = 'SET_CONTRACT_DOCUMENTS'
export const UNSET_CONTRACT_DOCUMENTS = 'UNSET_CONTRACT_DOCUMENTS'
export const ERROR_CONTRACT_DOCUMENT = 'ERROR_CONTRACT_DOCUMENT'
export const UNSET_ERROR_CONTRACT_DOCUMENT = 'UNSET_ERROR_CONTRACT_DOCUMENT'
export const CREATE_CONTRACT_DOCUMENT = 'CREATE_CONTRACT_DOCUMENT'
export const DELETE_CONTRACT_DOCUMENT = 'DELETE_CONTRACT_DOCUMENT'
export const UPDATE_CONTRACT_DOCUMENT = 'UPDATE_CONTRACT_DOCUMENT'
export const UNSET_CONTRACT_DOCUMENT_ACTION = 'UNSET_CONTRACT_DOCUMENT_ACTION'

// Contract Columns
export const SET_CONTRACT_COLUMNS = 'SET_CONTRACT_COLUMNS'
export const UNSET_CONTRACT_COLUMNS = 'UNSET_CONTRACT_COLUMNS'
export const CREATE_CONTRACT_COLUMNS = 'CREATE_CONTRACT_COLUMNS'
export const ERROR_CONTRACT_COLUMNS = 'ERROR_CONTRACT_COLUMNS'
export const UNSET_CONTRACT_COLUMN_ACTION = 'UNSET_CONTRACT_COLUMN_ACTION'
export const UNSET_ERROR_CONTRACT_COLUMN = 'UNSET_ERROR_CONTRACT_COLUMN'

// Contract Document Types
export const SET_CONTRACT_DOCUMENT_TYPES = 'SET_CONTRACT_DOCUMENT_TYPES'
export const SET_CONTRACT_DOCUMENT_TYPE_OPTIONS =
  'SET_CONTRACT_DOCUMENT_TYPE_OPTIONS'
export const UNSET_CONTRACT_DOCUMENT_TYPE_OPTIONS =
  'UNSET_CONTRACT_DOCUMENT_TYPE_OPTIONS'
export const ERROR_CONTRACT_DOCUMENT_TYPE = 'ERROR_CONTRACT_DOCUMENT_TYPE'
export const UNSET_ERROR_CONTRACT_DOCUMENT_TYPE =
  'UNSET_ERROR_CONTRACT_DOCUMENT_TYPE'
export const CREATE_CONTRACT_DOCUMENT_TYPE = 'CREATE_CONTRACT_DOCUMENT_TYPE'
export const DELETE_CONTRACT_DOCUMENT_TYPE = 'DELETE_CONTRACT_DOCUMENT_TYPE'
export const UPDATE_CONTRACT_DOCUMENT_TYPE = 'UPDATE_CONTRACT_DOCUMENT_TYPE'
export const TOGGLE_CONTRACT_DOCUMENT_TYPE = 'TOGGLE_CONTRACT_DOCUMENT_TYPE'
export const UNSET_CONTRACT_DOCUMENT_TYPE_ACTION =
  'UNSET_CONTRACT_DOCUMENT_TYPE_ACTION'

// Contract Locations
export const SET_CONTRACT_LOCATIONS = 'SET_CONTRACT_LOCATIONS'
export const SET_CONTRACT_LOCATION_OPTIONS = 'SET_CONTRACT_LOCATION_OPTIONS'
export const UNSET_CONTRACT_LOCATION_OPTIONS = 'UNSET_CONTRACT_LOCATION_OPTIONS'
export const ERROR_CONTRACT_LOCATION = 'ERROR_CONTRACT_LOCATION'
export const UNSET_ERROR_CONTRACT_LOCATION = 'UNSET_ERROR_CONTRACT_LOCATION'
export const CREATE_CONTRACT_LOCATION = 'CREATE_CONTRACT_LOCATION'
export const DELETE_CONTRACT_LOCATION = 'DELETE_CONTRACT_LOCATION'
export const UPDATE_CONTRACT_LOCATION = 'UPDATE_CONTRACT_LOCATION'
export const TOGGLE_CONTRACT_LOCATION = 'TOGGLE_CONTRACT_LOCATION'
export const UNSET_CONTRACT_LOCATION_ACTION = 'UNSET_CONTRACT_LOCATION_ACTION'

// Contract Properties
export const SET_CONTRACT_PROPERTIES = 'SET_CONTRACT_PROPERTIES'
export const SET_CONTRACT_PROPERTY_OPTIONS = 'SET_CONTRACT_PROPERTY_OPTIONS'
export const UNSET_CONTRACT_PROPERTY_OPTIONS = 'UNSET_CONTRACT_PROPERTY_OPTIONS'
export const ERROR_CONTRACT_PROPERTY = 'ERROR_CONTRACT_PROPERTY'
export const UNSET_ERROR_CONTRACT_PROPERTY = 'UNSET_ERROR_CONTRACT_PROPERTY'
export const CREATE_CONTRACT_PROPERTY = 'CREATE_CONTRACT_PROPERTY'
export const DELETE_CONTRACT_PROPERTY = 'DELETE_CONTRACT_PROPERTY'
export const UPDATE_CONTRACT_PROPERTY = 'UPDATE_CONTRACT_PROPERTY'
export const UNSET_CONTRACT_PROPERTY_ACTION = 'UNSET_CONTRACT_PROPERTY_ACTION'
export const LOADING_CONTRACT_PROPERTIES = 'LOADING_CONTRACT_PROPERTIES'
export const TOGGLE_CONTRACT_PROPERTY_GUEST_VISIBILITY =
  'TOGGLE_CONTRACT_PROPERTY_GUEST_VISIBILITY'

// Contract States
export const SET_CONTRACT_STATES = 'SET_CONTRACT_STATES'
export const CLEAR_CONTRACT_STATES = 'CLEAR_CONTRACT_STATES'

// Contract Statuses
export const SET_CONTRACT_STATUSES = 'SET_CONTRACT_STATUSES'
export const SET_CONTRACT_STATUS_OPTIONS = 'SET_CONTRACT_STATUS_OPTIONS'
export const UNSET_CONTRACT_STATUS_OPTIONS = 'UNSET_CONTRACT_STATUS_OPTIONS'
export const ERROR_CONTRACT_STATUS = 'ERROR_CONTRACT_STATUS'
export const UNSET_ERROR_CONTRACT_STATUS = 'UNSET_ERROR_CONTRACT_STATUS'
export const CREATE_CONTRACT_STATUS = 'CREATE_CONTRACT_STATUS'
export const DELETE_CONTRACT_STATUS = 'DELETE_CONTRACT_STATUS'
export const UPDATE_CONTRACT_STATUS = 'UPDATE_CONTRACT_STATUS'
export const TOGGLE_CONTRACT_STATUS = 'TOGGLE_CONTRACT_STATUS'
export const UNSET_CONTRACT_STATUS_ACTION = 'UNSET_CONTRACT_STATUS_ACTION'

// Contract Subtypes
export const SET_CONTRACT_SUBTYPES = 'SET_CONTRACT_SUBTYPES'
export const SET_CONTRACT_SUBTYPE_OPTIONS = 'SET_CONTRACT_SUBTYPE_OPTIONS'
export const UNSET_CONTRACT_SUBTYPE_OPTIONS = 'UNSET_CONTRACT_SUBTYPE_OPTIONS'
export const ERROR_CONTRACT_SUBTYPE = 'ERROR_CONTRACT_SUBTYPE'
export const UNSET_ERROR_CONTRACT_SUBTYPE = 'UNSET_ERROR_CONTRACT_SUBTYPE'
export const CREATE_CONTRACT_SUBTYPE = 'CREATE_CONTRACT_SUBTYPE'
export const DELETE_CONTRACT_SUBTYPE = 'DELETE_CONTRACT_SUBTYPE'
export const UPDATE_CONTRACT_SUBTYPE = 'UPDATE_CONTRACT_SUBTYPE'
export const TOGGLE_CONTRACT_SUBTYPE = 'TOGGLE_CONTRACT_SUBTYPE'
export const UNSET_CONTRACT_SUBTYPE_ACTION = 'UNSET_CONTRACT_SUBTYPE_ACTION'

// Contract Types
export const SET_CONTRACT_TYPES = 'SET_CONTRACT_TYPES'
export const SET_CONTRACT_TYPE_OPTIONS = 'SET_CONTRACT_TYPE_OPTIONS'
export const UNSET_CONTRACT_TYPE_OPTIONS = 'UNSET_CONTRACT_TYPE_OPTIONS'
export const ERROR_CONTRACT_TYPE = 'ERROR_CONTRACT_TYPE'
export const UNSET_ERROR_CONTRACT_TYPE = 'UNSET_ERROR_CONTRACT_TYPE'
export const CREATE_CONTRACT_TYPE = 'CREATE_CONTRACT_TYPE'
export const DELETE_CONTRACT_TYPE = 'DELETE_CONTRACT_TYPE'
export const UPDATE_CONTRACT_TYPE = 'UPDATE_CONTRACT_TYPE'
export const TOGGLE_CONTRACT_TYPE = 'TOGGLE_CONTRACT_TYPE'
export const UNSET_CONTRACT_TYPE_ACTION = 'UNSET_CONTRACT_TYPE_ACTION'

// Contract Views
export const SET_CONTRACT_VIEWS = 'SET_CONTRACT_VIEWS'
export const UNSET_CONTRACT_VIEWS = 'UNSET_CONTRACT_VIEWS'
export const CREATE_CONTRACT_VIEW = 'CREATE_CONTRACT_VIEW'
export const DELETE_CONTRACT_VIEW = 'DELETE_CONTRACT_VIEW'
export const UPDATE_CONTRACT_VIEW = 'UPDATE_CONTRACT_VIEW'
export const ERROR_CONTRACT_VIEW = 'ERROR_CONTRACT_VIEW'
export const UNSET_CONTRACT_VIEW_ACTION = 'UNSET_CONTRACT_VIEW_ACTION'
export const UNSET_ERROR_CONTRACT_VIEW = 'UNSET_ERROR_CONTRACT_VIEW'

// Countries
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const UNSET_COUNTRIES = 'UNSET_COUNTRIES'

// Currencies
export const SET_CURRENCIES = 'SET_CURRENCIES'
export const UNSET_CURRENCIES = 'UNSET_CURRENCIES'

// Custom Properties
export const SET_CUSTOM_PROPERTIES = 'SET_CUSTOM_PROPERTIES'
export const ERROR_CUSTOM_PROPERTY = 'ERROR_CUSTOM_PROPERTY'
export const UNSET_ERROR_CUSTOM_PROPERTY = 'UNSET_ERROR_CUSTOM_PROPERTY'
export const CREATE_CUSTOM_PROPERTY = 'CREATE_CUSTOM_PROPERTY'
export const DELETE_CUSTOM_PROPERTY = 'DELETE_CUSTOM_PROPERTY'
export const UPDATE_CUSTOM_PROPERTY = 'UPDATE_CUSTOM_PROPERTY'
export const UNSET_CUSTOM_PROPERTY_ACTION = 'UNSET_CUSTOM_PROPERTY_ACTION'

// Custom Property List Items
export const SET_CUSTOM_PROPERTY_LIST_ITEMS = 'SET_CUSTOM_PROPERTY_LIST_ITEMS'
export const ERROR_CUSTOM_PROPERTY_LIST_ITEM = 'ERROR_CUSTOM_PROPERTY_LIST_ITEM'
export const UNSET_ERROR_CUSTOM_PROPERTY_LIST_ITEM =
  'UNSET_ERROR_CUSTOM_PROPERTY_LIST_ITEM'
export const CREATE_CUSTOM_PROPERTY_LIST_ITEM =
  'CREATE_CUSTOM_PROPERTY_LIST_ITEM'
export const DELETE_CUSTOM_PROPERTY_LIST_ITEM =
  'DELETE_CUSTOM_PROPERTY_LIST_ITEM'
export const UPDATE_CUSTOM_PROPERTY_LIST_ITEM =
  'UPDATE_CUSTOM_PROPERTY_LIST_ITEM'
export const TOGGLE_CUSTOM_PROPERTY_LIST_ITEM =
  'TOGGLE_CUSTOM_PROPERTY_LIST_ITEM'
export const UNSET_CUSTOM_PROPERTY_LIST_ITEM_ACTION =
  'UNSET_CUSTOM_PROPERTY_LIST_ITEM_ACTION'

// Custom Property Types
export const SET_CUSTOM_PROPERTY_TYPES = 'SET_CUSTOM_PROPERTY_TYPES'

// Data Imports
export const REQUEST_DATA_IMPORTS = 'REQUEST_DATA_IMPORTS'
export const SET_DATA_IMPORTS = 'SET_DATA_IMPORTS'
export const SET_SINGLE_DATA_IMPORT = 'SET_SINGLE_DATA_IMPORT'
export const UNSET_DATA_IMPORTS = 'UNSET_DATA_IMPORTS'
export const UNSET_DATA_IMPORT_STATUS = 'UNSET_DATA_IMPORT_STATUS'
export const UPLOAD_REQUEST_DATA_IMPORTS = 'UPLOAD_REQUEST_DATA_IMPORTS'
export const CREATE_DATA_IMPORT = 'CREATE_DATA_IMPORT'
export const ERROR_DATA_IMPORT = 'ERROR_DATA_IMPORT'
export const UNSET_ERROR_DATA_IMPORT = 'UNSET_ERROR_DATA_IMPORT'

// Sites
export const SET_SITES = 'SET_SITES'
export const SET_SITES_SELECTABLES = 'SET_SITES_SELECTABLES'
export const UNSET_SITES = 'UNSET_SITES'
export const SET_SITE_OPTIONS = 'SET_SITE_OPTIONS'
export const UNSET_SITE_OPTIONS = 'UNSET_SITE_OPTIONS'
export const ERROR_SITE = 'ERROR_SITE'
export const UNSET_ERROR_SITE = 'UNSET_ERROR_SITE'
export const UNSET_SITE_ACTION = 'UNSET_SITE_ACTION'
export const CREATE_SITE = 'CREATE_SITE'
export const DELETE_SITE = 'DELETE_SITE'
export const UPDATE_SITE = 'UPDATE_SITE'
export const TOGGLE_SITE = 'TOGGLE_SITE'

// Site Properties
export const SET_SITE_PROPERTIES = 'SET_SITE_PROPERTIES'
export const SET_SITE_PROPERTY_OPTIONS = 'SET_SITE_PROPERTY_OPTIONS'
export const UNSET_SITE_PROPERTY_OPTIONS = 'UNSET_SITE_PROPERTY_OPTIONS'
export const ERROR_SITE_PROPERTY = 'ERROR_SITE_PROPERTY'
export const UNSET_ERROR_SITE_PROPERTY = 'UNSET_ERROR_SITE_PROPERTY'
export const CREATE_SITE_PROPERTY = 'CREATE_SITE_PROPERTY'
export const DELETE_SITE_PROPERTY = 'DELETE_SITE_PROPERTY'
export const UPDATE_SITE_PROPERTY = 'UPDATE_SITE_PROPERTY'
export const UNSET_SITE_PROPERTY_ACTION = 'UNSET_SITE_PROPERTY_ACTION'

// Reports
export const INITIATE_REPORT = 'INITIATE_REPORT'
export const REQUEST_REPORT = 'REQUEST_REPORT'
export const CLEAR_REPORT = 'CLEAR_REPORT'
export const SET_REPORT = 'SET_REPORT'

// Studies
export const SET_STUDIES = 'SET_STUDIES'
export const UNSET_STUDIES = 'UNSET_STUDIES'
export const SET_STUDY_OPTIONS = 'SET_STUDY_OPTIONS'
export const UNSET_STUDY_OPTIONS = 'UNSET_STUDY_OPTIONS'
export const ERROR_STUDY = 'ERROR_STUDY'
export const UNSET_ERROR_STUDY = 'UNSET_ERROR_STUDY'
export const UNSET_STUDY_ACTION = 'UNSET_STUDY_ACTION'
export const CREATE_STUDY = 'CREATE_STUDY'
export const DELETE_STUDY = 'DELETE_STUDY'
export const UPDATE_STUDY = 'UPDATE_STUDY'
export const TOGGLE_STUDY = 'TOGGLE_STUDY'

// Study Budget Visits
export const SET_STUDY_BUDGET_VISITS = 'SET_STUDY_BUDGET_VISITS'
export const UNSET_STUDY_BUDGET_VISITS = 'UNSET_STUDY_BUDGET_VISITS'
export const UNSET_STUDY_BUDGET_VISIT_ACTION = 'UNSET_STUDY_BUDGET_VISIT_ACTION'
export const ERROR_STUDY_BUDGET_VISIT = 'ERROR_STUDY_BUDGET_VISIT'
export const UNSET_ERROR_STUDY_BUDGET_VISIT = 'UNSET_ERROR_STUDY_BUDGET_VISIT'
export const CREATE_STUDY_BUDGET_VISIT = 'CREATE_STUDY_BUDGET_VISIT'
export const DELETE_STUDY_BUDGET_VISIT = 'DELETE_STUDY_BUDGET_VISIT'

// Study Properties
export const SET_STUDY_PROPERTIES = 'SET_STUDY_PROPERTIES'
export const SET_STUDY_PROPERTY_OPTIONS = 'SET_STUDY_PROPERTY_OPTIONS'
export const UNSET_STUDY_PROPERTY_OPTIONS = 'UNSET_STUDY_PROPERTY_OPTIONS'
export const ERROR_STUDY_PROPERTY = 'ERROR_STUDY_PROPERTY'
export const UNSET_ERROR_STUDY_PROPERTY = 'UNSET_ERROR_STUDY_PROPERTY'
export const CREATE_STUDY_PROPERTY = 'CREATE_STUDY_PROPERTY'
export const DELETE_STUDY_PROPERTY = 'DELETE_STUDY_PROPERTY'
export const UPDATE_STUDY_PROPERTY = 'UPDATE_STUDY_PROPERTY'
export const UNSET_STUDY_PROPERTY_ACTION = 'UNSET_STUDY_PROPERTY_ACTION'

// Study Stats
export const STUDY_STAT_CLEAR_ACTION = 'STUDY_STAT_CLEAR_ACTION'
export const STUDY_STAT_TIME_PER_CONTRACT = 'STUDY_STAT_TIME_PER_CONTRACT'
export const STUDY_STAT_TIME_PER_CONTRACT_CLEAR =
  'STUDY_STAT_TIME_PER_CONTRACT_CLEAR'
export const STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE =
  'STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE'
export const STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE_CLEAR =
  'STUDY_STAT_TIME_PER_CONTRACT_PER_CONTRACT_TYPE_CLEAR'
export const STUDY_STAT_TIME_PER_STATUS = 'STUDY_STAT_TIME_PER_STATUS'
export const STUDY_STAT_TIME_PER_STATUS_CLEAR =
  'STUDY_STAT_TIME_PER_STATUS_CLEAR'
export const STUDY_STAT_TIME_PER_LOCATION = 'STUDY_STAT_TIME_PER_LOCATION'
export const STUDY_STAT_TIME_PER_LOCATION_CLEAR =
  'STUDY_STAT_TIME_PER_LOCATION_CLEAR'

// Users
export const SET_USERS = 'SET_USERS'
export const ERROR_USER = 'ERROR_USER'
export const UNSET_ERROR_USER = 'UNSET_ERROR_USER'
export const CREATE_USER = 'CREATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const TOGGLE_USER = 'TOGGLE_USER'
export const UNSET_USER_ACTION = 'UNSET_USER_ACTION'
export const MANAGE_STUDIES_GUEST_USER = 'MANAGE_STUDIES_GUEST_USER'

// Invoice Milestones
export const SET_INVOICE_MILESTONES = 'SET_INVOICE_MILESTONES'
export const UNSET_INVOICE_MILESTONES = 'UNSET_INVOICE_MILESTONES'
export const ERROR_INVOICE_MILESTONE = 'ERROR_INVOICE_MILESTONE'
export const UNSET_ERROR_INVOICE_MILESTONE = 'UNSET_ERROR_INVOICE_MILESTONE'
export const CREATE_INVOICE_MILESTONE = 'CREATE_INVOICE_MILESTONE'
export const DELETE_INVOICE_MILESTONE = 'DELETE_INVOICE_MILESTONE'
export const UPDATE_INVOICE_MILESTONE = 'UPDATE_INVOICE_MILESTONE'
export const UNSET_INVOICE_MILESTONE_ACTION = 'UNSET_INVOICE_MILESTONE_ACTION'

// Study Contract Values
export const UNSET_STUDY_CONTRACT_VALUE_ACTION =
  'UNSET_STUDY_CONTRACT_VALUE_ACTION'
export const SET_STUDY_CONTRACT_VALUES = 'SET_STUDY_CONTRACT_VALUES'
export const UNSET_STUDY_CONTRACT_VALUES = 'UNSET_STUDY_CONTRACT_VALUES'
export const UPDATE_STUDY_CONTRACT_VALUE = 'UPDATE_STUDY_CONTRACT_VALUE'
export const CREATE_STUDY_CONTRACT_VALUE = 'CREATE_STUDY_CONTRACT_VALUE'
export const ERROR_STUDY_CONTRACT_VALUE = 'ERROR_STUDY_CONTRACT_VALUE'
export const UNSET_ERROR_STUDY_CONTRACT_VALUE =
  'UNSET_ERROR_STUDY_CONTRACT_VALUE'
export const UPDATE_STUDY_CONTRACT_VALUE_COUNTRY_OPTIONS =
  'UPDATE_STUDY_CONTRACT_VALUE_COUNTRY_OPTIONS'

// srDocument Types
export const SET_SR_DOCUMENT_TYPES = 'SET_SRDOCUMENT_TYPES'
export const SET_SR_DOCUMENT_TYPE_OPTIONS = 'SET_SRDOCUMENT_TYPE_OPTIONS'
export const UNSET_SR_DOCUMENT_TYPE_OPTIONS = 'UNSET_SRDOCUMENT_TYPE_OPTIONS'
export const CREATE_SR_DOCUMENT_TYPE = 'CREATE_SRDOCUMENT_TYPE'
export const DELETE_SR_DOCUMENT_TYPE = 'DELETE_SRDOCUMENT_TYPE'
export const UNSET_SR_DOCUMENT_TYPE_ACTION = 'UNSET_SRDOCUMENT_TYPE_ACTION'
export const UPDATE_SR_DOCUMENT_TYPE = 'UPDATE_SRDOCUMENT_TYPE'
export const TOGGLE_SR_DOCUMENT_TYPE = 'TOGGLE_SRDOCUMENT_TYPE'
export const ERROR_SR_DOCUMENT_TYPE = 'ERROR_SRDOCUMENT_TYPE'
export const UNSET_ERROR_SR_DOCUMENT_TYPE = 'UNSET_ERROR_SRDOCUMENT_TYPE'

// srDocument Subtypes
export const CREATE_SR_DOCUMENT_SUBTYPE = 'CREATE_SR_DOCUMENT_SUBTYPE'
export const DELETE_SR_DOCUMENT_SUBTYPE = 'DELETE_SR_DOCUMENT_SUBTYPE'
export const ERROR_SR_DOCUMENT_SUBTYPE = 'ERROR_SR_DOCUMENT_SUBTYPE'
export const SET_SR_DOCUMENT_SUBTYPES = 'SET_SR_DOCUMENT_SUBTYPES'
export const SET_SR_DOCUMENT_SUBTYPE_OPTIONS = 'SET_SR_DOCUMENT_SUBTYPE_OPTIONS'
export const TOGGLE_SR_DOCUMENT_SUBTYPE = 'TOGGLE_SR_DOCUMENT_SUBTYPE'
export const UNSET_SR_DOCUMENT_SUBTYPE_ACTION =
  'UNSET_SR_DOCUMENT_SUBTYPE_ACTION'
export const UNSET_SR_DOCUMENT_SUBTYPE_OPTIONS =
  'UNSET_SR_DOCUMENT_SUBTYPE_OPTIONS'
export const UNSET_ERROR_SR_DOCUMENT_SUBTYPE = 'UNSET_ERROR_SR_DOCUMENT_SUBTYPE'
export const UPDATE_SR_DOCUMENT_SUBTYPE = 'UPDATE_SR_DOCUMENT_SUBTYPE'

// srDocument Statuses
export const CREATE_SR_DOCUMENT_STATUS = 'CREATE_SR_DOCUMENT_STATUS'
export const DELETE_SR_DOCUMENT_STATUS = 'DELETE_SR_DOCUMENT_STATUS'
export const ERROR_SR_DOCUMENT_STATUS = 'ERROR_SR_DOCUMENT_STATUS'
export const SET_SR_DOCUMENT_STATUSES = 'SET_SR_DOCUMENT_STATUSES'
export const SET_SR_DOCUMENT_STATUS_OPTIONS = 'SET_SR_DOCUMENT_STATUS_OPTIONS'
export const TOGGLE_SR_DOCUMENT_STATUS = 'TOGGLE_SR_DOCUMENT_STATUS'
export const UNSET_ERROR_SR_DOCUMENT_STATUS = 'UNSET_ERROR_SR_DOCUMENT_STATUS'
export const UNSET_SR_DOCUMENT_STATUS_ACTION = 'UNSET_SR_DOCUMENT_STATUS_ACTION'
export const UNSET_SR_DOCUMENT_STATUS_OPTIONS =
  'UNSET_SR_DOCUMENT_STATUS_OPTIONS'
export const UPDATE_SR_DOCUMENT_STATUS = 'UPDATE_SR_DOCUMENT_STATUS'

// srDocument Locations
export const CREATE_SR_DOCUMENT_LOCATION = 'CREATE_SR_DOCUMENT_LOCATION'
export const DELETE_SR_DOCUMENT_LOCATION = 'DELETE_SR_DOCUMENT_LOCATION'
export const ERROR_SR_DOCUMENT_LOCATION = 'ERROR_SR_DOCUMENT_LOCATION'
export const SET_SR_DOCUMENT_LOCATIONS = 'SET_SR_DOCUMENT_LOCATIONS'
export const SET_SR_DOCUMENT_LOCATION_OPTIONS =
  'SET_SR_DOCUMENT_LOCATION_OPTIONS'
export const TOGGLE_SR_DOCUMENT_LOCATION = 'TOGGLE_SR_DOCUMENT_LOCATION'
export const UNSET_SR_DOCUMENT_LOCATION_ACTION =
  'UNSET_SR_DOCUMENT_LOCATION_ACTION'
export const UNSET_SR_DOCUMENT_LOCATION_OPTIONS =
  'UNSET_SR_DOCUMENT_LOCATION_OPTIONS'
export const UNSET_ERROR_SR_DOCUMENT_LOCATION =
  'UNSET_ERROR_SR_DOCUMENT_LOCATION'
export const UPDATE_SR_DOCUMENT_LOCATION = 'UPDATE_SR_DOCUMENT_LOCATION'

// srDocument Properties
export const CREATE_SR_DOCUMENT_PROPERTY = 'CREATE_SR_DOCUMENT_PROPERTY'
export const DELETE_SR_DOCUMENT_PROPERTY = 'DELETE_SR_DOCUMENT_PROPERTY'
export const ERROR_SR_DOCUMENT_PROPERTY = 'ERROR_SR_DOCUMENT_PROPERTY'
export const LOADING_SR_DOCUMENT_PROPERTIES = 'LOADING_SR_DOCUMENT_PROPERTIES'
export const SET_SR_DOCUMENT_PROPERTIES = 'SET_SR_DOCUMENT_PROPERTIES'
export const SET_SR_DOCUMENT_PROPERTY_OPTIONS =
  'SET_SR_DOCUMENT_PROPERTY_OPTIONS'
export const TOGGLE_SR_DOCUMENT_PROPERTY_GUEST_VISIBILITY =
  'TOGGLE_SR_DOCUMENT_PROPERTY_GUEST_VISIBILITY'
export const UNSET_ERROR_SR_DOCUMENT_PROPERTY =
  'UNSET_ERROR_SR_DOCUMENT_PROPERTY'
export const UNSET_SR_DOCUMENT_PROPERTY_ACTION =
  'UNSET_SR_DOCUMENT_PROPERTY_ACTION'
export const UNSET_SR_DOCUMENT_PROPERTY_OPTIONS =
  'UNSET_SR_DOCUMENT_PROPERTY_OPTIONS'
export const UPDATE_SR_DOCUMENT_PROPERTY = 'UPDATE_SR_DOCUMENT_PROPERTY'

// srDocument Columns
export const CREATE_SR_DOCUMENT_COLUMNS = 'CREATE_SR_DOCUMENT_COLUMNS'
export const ERROR_SR_DOCUMENT_COLUMNS = 'ERROR_SR_DOCUMENT_COLUMNS'
export const SET_SR_DOCUMENT_COLUMNS = 'SET_SR_DOCUMENT_COLUMNS'
export const UNSET_SR_DOCUMENT_COLUMNS = 'UNSET_SR_DOCUMENT_COLUMNS'
export const UNSET_SR_DOCUMENT_COLUMN_ACTION = 'UNSET_SR_DOCUMENT_COLUMN_ACTION'
export const UNSET_ERROR_SR_DOCUMENT_COLUMN = 'UNSET_ERROR_SR_DOCUMENT_COLUMN'

// srDocument Views
export const CREATE_SR_DOCUMENT_VIEW = 'CREATE_SR_DOCUMENT_VIEW'
export const DELETE_SR_DOCUMENT_VIEW = 'DELETE_SR_DOCUMENT_VIEW'
export const ERROR_SR_DOCUMENT_VIEW = 'ERROR_SR_DOCUMENT_VIEW'
export const SET_SR_DOCUMENT_VIEWS = 'SET_SR_DOCUMENT_VIEWS'
export const UNSET_SR_DOCUMENT_VIEWS = 'UNSET_SR_DOCUMENT_VIEWS'
export const UNSET_SR_DOCUMENT_VIEW_ACTION = 'UNSET_SR_DOCUMENT_VIEW_ACTION'
export const UNSET_ERROR_SR_DOCUMENT_VIEW = 'UNSET_ERROR_SR_DOCUMENT_VIEW'
export const UPDATE_SR_DOCUMENT_VIEW = 'UPDATE_SR_DOCUMENT_VIEW'

// srDocuments
export const APPEND_SR_DOCUMENTS = 'APPEND_SR_DOCUMENTS'
export const CREATE_SR_DOCUMENT = 'CREATE_SR_DOCUMENT'
export const DELETE_SR_DOCUMENT = 'DELETE_SR_DOCUMENT'
export const ERROR_SR_DOCUMENT = 'ERROR_SR_DOCUMENT'
export const SET_SR_DOCUMENT = 'SET_SR_DOCUMENT'
export const SET_SR_DOCUMENTS = 'SET_SR_DOCUMENTS'
export const UNSET_SR_DOCUMENTS = 'UNSET_SR_DOCUMENTS'
export const UNSET_SR_DOCUMENT_ACTION = 'UNSET_SR_DOCUMENT_ACTION'
export const UNSET_ERROR_SR_DOCUMENT = 'UNSET_ERROR_SR_DOCUMENT'
export const UPDATE_SR_DOCUMENT = 'UPDATE_SR_DOCUMENT'

// srDocument State
export const SET_SR_DOCUMENT_STATES = 'SET_SR_DOCUMENT_STATES'
export const CLEAR_SR_DOCUMENT_STATES = 'CLEAR_SR_DOCUMENT_STATES'

// srDocument Activity
export const SET_SR_DOCUMENT_ACTIVITIES = 'SET_SR_DOCUMENT_ACTIVITIES'
export const UNSET_SR_DOCUMENT_ACTIVITIES = 'UNSET_SR_DOCUMENT_ACTIVITIES'
export const CREATE_SR_DOCUMENT_ACTIVITY = 'CREATE_SR_DOCUMENT_ACTIVITY'
export const DELETE_SR_DOCUMENT_ACTIVITY = 'DELETE_SR_DOCUMENT_ACTIVITY'
export const UNSET_SR_DOCUMENT_ACTIVITY_ACTION =
  'UNSET_SR_DOCUMENT_ACTIVITY_ACTION'
export const UPDATE_SR_DOCUMENT_ACTIVITY = 'UPDATE_SR_DOCUMENT_ACTIVITY'
export const ERROR_SR_DOCUMENT_ACTIVITY = 'ERROR_SR_DOCUMENT_ACTIVITY'
export const UNSET_ERROR_SR_DOCUMENT_ACTIVITY =
  'UNSET_ERROR_SR_DOCUMENT_ACTIVITY'

// srDocument Activity Chart
export const CHART_SR_DOCUMENT_ACTIVITY_BY_LOCATION =
  'CHART_SR_DOCUMENT_ACTIVITY_BY_LOCATION'
export const CHART_SR_DOCUMENT_ACTIVITY_CLEAR_ACTION =
  'CHART_SR_DOCUMENT_ACTIVITY_CLEAR_ACTION'
export const CHART_SR_DOCUMENT_ACTIVITY_CLEAR_BY_LOCATION =
  'CHART_SR_DOCUMENT_ACTIVITY_CLEAR_BY_LOCATION'

// srDocument File
export const SET_SR_DOCUMENT_DOCS = 'SET_SR_DOCUMENT_DOCS'
export const UNSET_SR_DOCUMENT_DOCS = 'UNSET_SR_DOCUMENT_DOCS'
export const CREATE_SR_DOCUMENT_DOC = 'CREATE_SR_DOCUMENT_DOC'
export const DELETE_SR_DOCUMENT_DOC = 'DELETE_SR_DOCUMENT_DOC'
export const UPDATE_SR_DOCUMENT_DOC = 'UPDATE_SR_DOCUMENT_DOC'
export const UNSET_SR_DOCUMENT_DOC_ACTION = 'UNSET_SR_DOCUMENT_DOC_ACTION'
export const ERROR_SR_DOCUMENT_DOC = 'ERROR_SR_DOCUMENT_DOC'
export const UNSET_ERROR_SR_DOCUMENT_DOC = 'UNSET_ERROR_SR_DOCUMENT_DOC'

// srDocument File Types
export const SET_SR_DOCUMENT_DOC_TYPES = 'SET_SR_DOCUMENT_DOC_TYPES'
export const SET_SR_DOCUMENT_DOC_TYPE_OPTIONS =
  'SET_SR_DOCUMENT_DOC_TYPE_OPTIONS'
export const UNSET_SR_DOCUMENT_DOC_TYPE_OPTIONS =
  'UNSET_SR_DOCUMENT_DOC_TYPE_OPTIONS'
export const CREATE_SR_DOCUMENT_DOC_TYPE = 'CREATE_SR_DOCUMENT_DOC_TYPE'
export const DELETE_SR_DOCUMENT_DOC_TYPE = 'DELETE_SR_DOCUMENT_DOC_TYPE'
export const UPDATE_SR_DOCUMENT_DOC_TYPE = 'UPDATE_SR_DOCUMENT_DOC_TYPE'
export const TOGGLE_SR_DOCUMENT_DOC_TYPE = 'TOGGLE_SR_DOCUMENT_DOC_TYPE'
export const ERROR_SR_DOCUMENT_DOC_TYPE = 'ERROR_SR_DOCUMENT_DOC_TYPE'
export const UNSET_SR_DOCUMENT_DOC_TYPE_ACTION =
  'UNSET_SR_DOCUMENT_DOC_TYPE_ACTION'
export const UNSET_ERROR_SR_DOCUMENT_DOC_TYPE =
  'UNSET_ERROR_SR_DOCUMENT_DOC_TYPE'
