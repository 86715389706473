import React, { useState, useEffect, useCallback } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import moment from "moment";
import DateFnsUtils from "@date-io/moment"; // choose your lib
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from "material-ui-pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

const CustomPropertyDateTime = ({
  item,
  params,
  setParams,
  current,
  customProperties,
  setCustomProperties,
  siteSection
}) => {
  const materialTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#f88b5e",
        dark: "#f88b5e"
      },
      secondary: {
        main: "#2d3848"
      },
      contrastThreshold: 3,
      tonalOffset: 0.2
    },
    typography: {
      useNextVariants: true
    }
  });

  // this `item` doesn't have an `id`, I think it's supposed to be `item.contract_property_id` but not 100%; if I change it, the dates show up correctly but they seem to be getting changed improperly
  //const {id} = item;
  const id = item?.id || item?.[`${siteSection}_property_id`];

  const convertToISOString = useCallback((date, time) => {
    return moment(`${date} ${time}`, "YYYY-MM-DD hh:mmA").toISOString();
  }, []);
  const convertToLocal = time => {
    const utcTime = moment.utc(time).format("YYYY-MM-DD HH:mm:ssZ");
    return moment(utcTime, "YYYY-MM-DD HH:mm:ssZ").local();
  };

  const [currentDate, setCurrentDate] = useState(
    params[`id_${id}`] ? convertToLocal(params[`id_${id}`]) : moment()
  );
  const [currentTime, setCurrentTime] = useState(
    params[`id_${id}`] ? convertToLocal(params[`id_${id}`]) : moment()
  );
  const [loading, setLoading] = useState(true);

  const adjustLoading = useCallback(setLoading, []);
  const buildParams = useCallback(setParams, []);

  useEffect(() => {
    if (loading) {
      const timeToUTC = convertToISOString(currentDate, currentTime);
      buildParams(prev => ({
        ...prev,
        [`id_${id}`]: timeToUTC.split(".")[0]
      }));
      adjustLoading(false);
    }
  }, [
    loading,
    adjustLoading,
    buildParams,
    currentDate,
    currentTime,
    convertToISOString,
    id
  ]);

  const handleDate = (item, value) => {
    if (moment(value).toString() !== currentDate.toString()) {
      if (setCurrentDate) setCurrentDate(moment(value));
      const newDate = moment(value).format("YYYY-MM-DD");
      const newTime = moment(currentTime, "hh:mmA").format("hh:mmA");
      const timeToUTC = convertToISOString(newDate, newTime);

      setParams({
        ...params,
        [`id_${id}`]: timeToUTC.split(".")[0]
      });

      if (current && customProperties) {
        setCustomProperties(prev => ({
          ...prev,
          [`${item.property.name}`]: {
            [`${siteSection}_property_id`]: item.id,
            value: moment.utc(timeToUTC).format("YYYY-MM-DD")
          }
        }));
      }
    }
  };
  const handleTime = (item, value) => {
    if (value.format("hh:mmA") !== currentTime.format("hh:mmA")) {
      if (setCurrentTime) setCurrentTime(value);
      const newDate = currentDate.format("YYYY-MM-DD");
      const newTime = moment(value, "hh:mmA").format("hh:mmA");
      const timeToUTC = moment(
        `${newDate} ${newTime}`,
        "YYYY-MM-DD hh:mmA"
      ).toISOString();

      setParams({
        ...params,
        [`id_${id}`]: timeToUTC.split(".")[0]
      });

      if (current && customProperties) {
        setCustomProperties(prev => ({
          ...prev,
          [`${item.property.name}`]: {
            [`${siteSection}_property_id`]: item.id,
            value: moment.utc(timeToUTC).format("YYYY-MM-DD HH:mm:ss")
          }
        }));
      }
    }
  };

  return (
    <>
      <h6 className="text-muted">{item.property.name}</h6>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={materialTheme}>
          <MDBRow className="mb-4">
            {currentDate ? (
              <MDBCol
                md={item.property.property_type.code === "date" ? "12" : "6"}
              >
                <DatePicker
                  className="picker"
                  format="MMMM Do, YYYY"
                  value={currentDate.toDate()}
                  onChange={value => handleDate(item, value)}
                />
              </MDBCol>
            ) : (
              ""
            )}
            {item.property.property_type.code === "datetime" ? (
              <MDBCol md="6">
                <TimePicker
                  className="picker"
                  value={currentTime.toDate()}
                  onChange={value => handleTime(item, value)}
                />
              </MDBCol>
            ) : (
              ""
            )}
          </MDBRow>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default CustomPropertyDateTime;
