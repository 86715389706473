import {
  SET_CONTRACT_SUBTYPES,
  SET_CONTRACT_SUBTYPE_OPTIONS,
  UNSET_CONTRACT_SUBTYPE_OPTIONS,
  CREATE_CONTRACT_SUBTYPE,
  DELETE_CONTRACT_SUBTYPE,
  UNSET_CONTRACT_SUBTYPE_ACTION,
  UPDATE_CONTRACT_SUBTYPE,
  TOGGLE_CONTRACT_SUBTYPE,
  ERROR_CONTRACT_SUBTYPE,
  UNSET_ERROR_CONTRACT_SUBTYPE,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { filterByType } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  subtypesAvailable: {},
  subtypeOptions: [],
  subtypeErrors: {},
}

const contractSubtypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_SUBTYPES:
      return {
        ...state,
        lastAction: 'fetch',
        subtypesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_CONTRACT_SUBTYPE_OPTIONS:
      const options = selectObject(
        filterByType(action.payload, action.currentContractTypeId),
        action.currentContractSubtype ? action.currentContractSubtype.id : 0,
        true
      )
      return { ...state, subtypeOptions: options }
    case UNSET_CONTRACT_SUBTYPE_OPTIONS:
      if (state.subtypeOptions) {
        return { ...state, subtypeOptions: [] }
      }
      return state
    case UNSET_CONTRACT_SUBTYPE_ACTION:
      return { ...state, lastAction: null }
    case CREATE_CONTRACT_SUBTYPE:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        subtypesAvailable: {
          ...state.subtypesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT_SUBTYPE:
      const updatedItem = (state.subtypesAvailable[
        action.currentContractSubtype.id
      ] = action.currentContractSubtype)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT_SUBTYPE:
      return {
        ...state,
        subtypeErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT_SUBTYPE:
      return {
        ...state,
        subtypeErrors: {},
      }
    case TOGGLE_CONTRACT_SUBTYPE:
      const toggledItem = (state.subtypesAvailable[
        action.currentContractSubtype.id
      ] = action.currentContractSubtype)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_CONTRACT_SUBTYPE:
      const updated = state.subtypesAvailable
      _.unset(updated, `${action.currentContractSubtype.id}`)
      return { ...state, lastAction: 'delete', subtypesAvailable: updated }
    default:
      return state
  }
}

export default contractSubtypeReducer
