import { SET_CONTRACT_STATES, CLEAR_CONTRACT_STATES } from 'actions/types'

// Passing data back to reducers
const setContractState = (obj, type) => {
  const action = { type: type, payload: obj }
  return action
}

// Set up data
export const fetchContractStates = (contract) => async (dispatch) => {
  const options = [
    {
      text: 'Open',
      value: '0',
      checked: contract.current_state === 0 ? true : false,
    },
    {
      text: 'Completed',
      value: '1',
      checked: contract.current_state === 1 ? true : false,
    },
    {
      text: 'Abandoned',
      value: '2',
      checked: contract.current_state === 2 ? true : false,
    },
  ]

  dispatch(setContractState(options, SET_CONTRACT_STATES))
}

export const clearContractStates = () => async (dispatch) => {
  dispatch(setContractState([], CLEAR_CONTRACT_STATES))
}
