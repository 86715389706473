import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardTitle,
  MDBCardBody
} from "mdbreact";

import { fetchDataImports } from "actions/dataImportActions";

import BadgeLabel from "components/common/badges/BadgeLabel";
import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import TableSimple from "components/common/tables/TableSimple";

const DataImportshow = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();

  // redux hooks
  const { data: dataImports, status } = useSelector(state => state.dataImports);

  // local state
  const [currentImport, setCurrentImport] = useState(null);
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "#",
        field: "id",
        minimal: "id"
      },
      {
        label: "Row",
        field: "row_id",
        minimal: "id"
      },
      {
        label: "Message",
        field: "error_msg"
      }
    ]
  });

  useEffect(() => {
    if (status.fetchSuccess) {
      const buildRows = data => {
        if (data.length) {
          return data.map(error => ({
            id: error.id,
            row_id: error.row_id,
            error_msg: error.error_msg
          }));
        } else {
          return [];
        }
      };

      if (!currentImport || currentImport?.status === "Processing") {
        if (_.size(dataImports) && dataImports[id]?.import_errors) {
          setCurrentImport(dataImports[id]);
        }
      }
      if (
        currentImport?.import_errors &&
        currentImport?.status === "Completed" &&
        !tableData.rows
      ) {
        setTableData({
          ...tableData,
          rows: buildRows(currentImport.import_errors)
        });
      }
    }
  }, [
    status.fetchSuccess,
    dispatch,
    dataImports,
    id,
    currentImport,
    setCurrentImport,
    tableData,
    setTableData
  ]);

  // use effect setups
  useEffect(() => {
    dispatch(fetchDataImports(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (status.fetchSuccess && currentImport?.status === "Processing") {
      const interval = setInterval(() => {
        dispatch(fetchDataImports(id));
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [status.fetchSuccess, currentImport, dispatch, id]);

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    leftSide: (
      <Link to="/admin/imports">
        <MDBIcon icon="fas fa-chevron-left" /> Data Imports
      </Link>
    ),
    linkItems: []
  };

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>
        {_.size(currentImport) && status.fetchSuccess ? (
          <MDBRow>
            <MDBCol md="5">
              <div className="h5 d-flex flex-column align-items-start mb-4 font-weight-normal">
                <BadgeLabel>Type</BadgeLabel>
                {currentImport.type}
              </div>
              <div className="h5 d-flex flex-column align-items-start mb-4 font-weight-normal">
                <BadgeLabel>Status</BadgeLabel>
                {currentImport.status}
              </div>
              {currentImport?.user?.id ? (
                <div className="h5 d-flex flex-column align-items-start mb-4 font-weight-normal">
                  <BadgeLabel>User</BadgeLabel>
                  <Link to={`/admin/users/${currentImport.user.id}`}>
                    {currentImport.user.name}
                  </Link>
                </div>
              ) : (
                ""
              )}
              <MDBCard className="card-panel">
                <MDBCardBody className="rgba-grey-slight dark-grey-text">
                  <MDBCardTitle>Import Data</MDBCardTitle>
                  <div className="mb-3">
                    <BadgeLabel>Filename</BadgeLabel>
                    <div>{currentImport.filename}</div>
                  </div>
                  <div className="mb-3">
                    <BadgeLabel>Rows in file</BadgeLabel>
                    <span className="ml-2">{currentImport.rows_in_file}</span>
                  </div>
                  <div className="mb-3">
                    <BadgeLabel>Rows added:</BadgeLabel>
                    <span className="ml-2">{currentImport.rows_added}</span>
                  </div>
                  <div className="mb-3">
                    <BadgeLabel>Errors:</BadgeLabel>
                    <span className="ml-2">
                      {currentImport.data_import_errors}
                    </span>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="7">
              <h3 className="h5">Errors</h3>
              {currentImport?.import_errors?.length ? (
                <TableSimple tableData={tableData} authWidth />
              ) : (
                <p>No errors found in this import.</p>
              )}
            </MDBCol>
          </MDBRow>
        ) : (
          ""
        )}
      </MDBCardBody>
    </>
  );
};

export default DataImportshow;
