import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBCardBody, MDBBtn, MDBCardFooter } from "mdbreact";

import { useAuth } from "hooks/useAuth.js";
import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import Modal from "components/common/Modal";

import {
  updateContractActivityAlert,
  clearContractActivityAlertErrors,
  fetchContractActivityAlert
} from "actions/contracts/contractActivityAlertActions";
import {
  fetchContractTypes,
  clearContractTypeOptions
} from "actions/contracts/contractTypeActions";
import {
  fetchContractLocations,
  clearContractLocationOptions
} from "actions/contracts/contractLocationActions";
import {
  fetchContractStatuses,
  clearContractStatusOptions
} from "actions/contracts/contractStatusActions";
import {
  fetchContractPropertyOptions,
  clearContractPropertyOptions
} from "actions/contracts/contractPropertyActions";

import useModal from "hooks/useModal";

import { isUserNotReadOnly } from "utils/authUtils";

import ContractActivityAlertForm from "./ContractActivityAlertForm";
import ContractActivityAlertDelete from "./ContractActivityAlertDelete";

const ContractActivityAlertUpdate = ({ match }) => {
  const activityAlertId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();

  const { activityAlertsAvailable, activityAlertErrors } = useSelector(
    state => state.contractActivityAlerts
  );
  const { typeOptions } = useSelector(state => state.contractTypes);
  const { locationOptions } = useSelector(state => state.contractLocations);
  const { statusOptions } = useSelector(state => state.contractStatuses);
  const { contractPropertyOptions, loading, lastAction } = useSelector(
    state => state.contractProperties
  );

  const [currentActivityAlert, setCurrentActivityAlert] = useState(
    activityAlertsAvailable[activityAlertId]
  );
  const [userPropertyOptions, setUserPropertyOptions] = useState();

  const auth = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractActivityAlert());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(activityAlertsAvailable).length) {
      setCurrentActivityAlert(activityAlertsAvailable[activityAlertId]);
    }
  }, [activityAlertsAvailable, activityAlertId, setCurrentActivityAlert]);

  useEffect(() => {
    if (_.size(currentActivityAlert)) {
      dispatch(fetchContractTypes("form", currentActivityAlert.contract_type));
      dispatch(
        fetchContractLocations("form", currentActivityAlert.contract_location)
      );
      dispatch(
        fetchContractStatuses("form", currentActivityAlert.contract_status)
      );
      dispatch(fetchContractPropertyOptions());
    }
    return () => {
      dispatch(clearContractTypeOptions());
      dispatch(clearContractLocationOptions());
      dispatch(clearContractStatusOptions());
      dispatch(clearContractPropertyOptions());
      dispatch(clearContractActivityAlertErrors());
    };
  }, [dispatch, currentActivityAlert]);

  useEffect(() => {
    if (
      _.size(contractPropertyOptions) &&
      !loading &&
      lastAction === "fetchOptions" &&
      _.size(typeOptions)
    ) {
      const onlyUserProperties = contractPropertyOptions?.custom_properties?.filter(
        customProp => customProp.property.property_type.code === "users"
      );

      const userPropValues = onlyUserProperties.map(prop => {
        const type = typeOptions.find(type => type.id === prop.contract_type_id)
          ?.value;
        if (
          currentActivityAlert.custom_property_id === prop.contract_property_id
        )
          return {
            id: prop.contract_property_id,
            value: `${prop.property.name} - ${type}`,
            checked: true
          };
        else {
          return {
            id: prop.contract_property_id,
            value: `${prop.property.name} - ${type}`,
            checked: false
          };
        }
      });
      setUserPropertyOptions(userPropValues);
    }
  }, [
    contractPropertyOptions,
    loading,
    lastAction,
    typeOptions,
    currentActivityAlert.custom_property_id
  ]);

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Contact");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  const handleSubmission = (e, params) => {
    e.preventDefault();

    const updatedParams = {};
    Object.keys(params).forEach(paramKey => {
      // find where there was a change
      // and if the field previously filled in and now removed
      if (
        currentActivityAlert[paramKey] !== params[paramKey] &&
        (params[paramKey] !== "" || currentActivityAlert[paramKey])
      ) {
        updatedParams[paramKey] = params[paramKey];
      }
    });

    updatedParams.id = currentActivityAlert.id;

    const updatedActivityAlert = _.mergeWith(
      currentActivityAlert,
      updatedParams
    );

    dispatch(updateContractActivityAlert(updatedParams, updatedActivityAlert));
  };

  if (currentActivityAlert) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: isUserNotReadOnly(auth?.user?.user_level)
        ? [
            {
              name: "View",
              url: `/admin/contracts/activity-alerts/${currentActivityAlert.id}`,
              exact: true
            },
            {
              name: "Edit",
              url: `/admin/contracts/activity-alerts/${currentActivityAlert.id}/edit`,
              exact: true
            }
          ]
        : []
    };

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: "Delete",
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: e => toggleModal(e, "delete", currentActivityAlert),
          className: "text-danger"
        }
      });
    }
    return (
      <>
        <SectionHeaderMenu menuData={sectionHeaderMenu} />
        <MDBCardBody>
          <ContractActivityAlertForm
            activityAlert={currentActivityAlert}
            typeOptions={typeOptions || []}
            locationOptions={locationOptions || []}
            statusOptions={statusOptions || []}
            contractProperties={userPropertyOptions || []}
            submit={handleSubmission}
            errors={activityAlertErrors}
            isEdit
          />
        </MDBCardBody>
        <MDBCardFooter className="text-right">
          <MDBBtn
            key="submit"
            color="primary"
            form="form_contract_activity_alert"
            type="submit"
          >
            Submit
          </MDBBtn>
        </MDBCardFooter>
        {auth?.user?.is_admin ? (
          <Modal
            isOpen={modalOpen}
            toggle={toggleModal}
            headerText={headerText}
            includeFooter={true}
            actionButtons={[
              {
                text: "Delete",
                color: "danger",
                additionalProps: {
                  form: formId,
                  type: "submit"
                }
              }
            ]}
          >
            <ContractActivityAlertDelete
              formId={formId}
              selected={selected}
              closeModal={() => setModalState(false)}
            />
          </Modal>
        ) : (
          ""
        )}
      </>
    );
  }
  return "";
};

export default ContractActivityAlertUpdate;
