import {
  SET_SR_DOCUMENT_DOCS,
  UNSET_SR_DOCUMENT_DOCS,
  CREATE_SR_DOCUMENT_DOC,
  DELETE_SR_DOCUMENT_DOC,
  UNSET_SR_DOCUMENT_DOC_ACTION,
  UPDATE_SR_DOCUMENT_DOC,
  ERROR_SR_DOCUMENT_DOC,
  UNSET_ERROR_SR_DOCUMENT_DOC,
  CREATE_SR_DOCUMENT_ACTIVITY,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  docsAvailable: {},
  docErrors: {},
}

const srDocumentDocReducer = (state = INITIAL_STATE, action) => {
  let currentDocAvail = state.docsAvailable
  const isExistingDoc = (item) => {
    return _.find(state.docsAvailable, (o) => {
      return o.sr_document_doc_type.id === item.sr_document_doc_type.id
    })
  }

  switch (action.type) {
    case SET_SR_DOCUMENT_DOCS:
      return {
        ...state,
        lastAction: 'fetch',
        docsAvailable: _.mapKeys(action.payload, 'id'),
      }
    case UNSET_SR_DOCUMENT_DOCS:
      return INITIAL_STATE
    case UNSET_SR_DOCUMENT_DOC_ACTION:
      return { ...state, lastAction: null }
    case CREATE_SR_DOCUMENT_DOC:
      const createdItem = action.payload.created_items[0]

      const newState = {
        ...state,
        lastAction: 'create',
        docsAvailable: {
          ...state.docsAvailable,
          [createdItem.id]: createdItem,
        },
      }

      const createdItemExists = isExistingDoc(createdItem)

      if (createdItemExists !== undefined) {
        _.unset(currentDocAvail, `${createdItemExists.id}`)
        currentDocAvail = {
          ...currentDocAvail,
          [createdItem.id]: createdItem,
        }
        return {
          ...state,
          lastAction: 'create',
          docsAvailable: currentDocAvail,
        }
      } else {
        return newState
      }
    case CREATE_SR_DOCUMENT_ACTIVITY:
      if (action.payload.created_items[0].sr_document_doc?.length) {
        const createdActivityItem =
          action.payload.created_items[0].sr_document_doc

        const newActivityState = {
          ...state,
          lastAction: 'create',
          docsAvailable: {
            ...state.docsAvailable,
            [createdActivityItem.id]: createdActivityItem,
          },
        }

        const createdItemFromActExists = isExistingDoc(createdActivityItem)

        if (createdItemFromActExists !== undefined) {
          _.unset(currentDocAvail, `${createdItemFromActExists.id}`)
          currentDocAvail = {
            ...currentDocAvail,
            [createdActivityItem.id]: createdActivityItem,
          }
          return {
            ...state,
            lastAction: 'create',
            docsAvailable: currentDocAvail,
          }
        } else {
          return newActivityState
        }
      } else {
        return { ...state }
      }
    case UPDATE_SR_DOCUMENT_DOC:
      const updatedItem = (state.docsAvailable[action.currentSrDocumentDoc.id] =
        action.currentSrDocumentDoc)
      updatedItem.created_at =
        state.docsAvailable[action.currentSrDocumentDoc.id].created_at
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_DOC:
      return {
        ...state,
        docErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_DOC:
      return {
        ...state,
        docErrors: {},
      }
    case DELETE_SR_DOCUMENT_DOC:
      const updated = state.docsAvailable
      _.unset(updated, `${action.currentSrDocumentDoc.id}`)
      return { ...state, lastAction: 'delete', docsAvailable: updated }
    default:
      return state
  }
}

export default srDocumentDocReducer
