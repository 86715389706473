import _ from "lodash";
import moment from "moment";
import {
  SET_CONTRACT_BUDGETS,
  UNSET_CONTRACT_BUDGETS,
  UPDATE_CONTRACT_BUDGET,
  ERROR_CONTRACT_BUDGET,
  UNSET_ERROR_CONTRACT_BUDGET,
  SET_CONTRACT_BUDGET_HISTORY,
  UNSET_CONTRACT_BUDGET_HISTORY
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  contractBudgetsAvailable: {},
  contractBudgetErrors: {},
  contractBudgetHistory: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACT_BUDGETS:
      return {
        ...state,
        lastAction: "fetch",
        contractBudgetsAvailable: _.mapKeys(action.payload, "budget_visit.id")
      };
    case UNSET_CONTRACT_BUDGETS:
      return { ...state, contractBudgetsAvailable: {}, lastAction: null };
    case UPDATE_CONTRACT_BUDGET:
      const updatedItem = (state.contractBudgetsAvailable[
        action.currentContractBudget.budget_visit.id
      ] = action.currentContractBudget);
      return { ...state, lastAction: "edit", updatedItem };
    case ERROR_CONTRACT_BUDGET:
      return {
        ...state,
        contractBudgetErrors: action.payload,
        lastAction: "error"
      };
    case UNSET_ERROR_CONTRACT_BUDGET:
      return {
        ...state,
        contractBudgetErrors: {},
        lastAction: null
      };
    case SET_CONTRACT_BUDGET_HISTORY:
      const sortedHistory = action.payload.sort((a, b) =>
        moment(a.created_at) < moment(b.created_at) ? 1 : -1
      );
      return {
        ...state,
        lastAction: "fetch_history",
        contractBudgetHistory: sortedHistory
      };
    case UNSET_CONTRACT_BUDGET_HISTORY:
      return { ...state, contractBudgetHistory: [], lastAction: null };
    default:
      return state;
  }
};
