import history from '../../history'
import {
  SET_STUDIES,
  SET_STUDY_OPTIONS,
  UNSET_STUDY_OPTIONS,
  CREATE_STUDY,
  DELETE_STUDY,
  UPDATE_STUDY,
  TOGGLE_STUDY,
  ERROR_STUDY,
  UNSET_STUDY_ACTION,
  UNSET_ERROR_STUDY,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const studyEndPoint = '/study'

// Pass data to reducers
export const setStudy = (obj, type, study) => {
  const action = { type: type, payload: obj }
  if (study) action.currentStudy = study
  return action
}

// Non-API Calls
export const clearStudyOptions = () => async (dispatch) => {
  dispatch(setStudy([], UNSET_STUDY_OPTIONS))
}
export const fetchExistingStudies = (study) => async (dispatch) => {
  dispatch(setStudy([], SET_STUDY_OPTIONS, study))
}
export const clearStudyAction = () => async (dispatch) => {
  dispatch(setStudy({}, UNSET_STUDY_ACTION))
}
export const clearStudyErrors = () => async (dispatch) => {
  dispatch(setStudy({}, UNSET_ERROR_STUDY))
}

// API Calls
export const fetchStudies = (results, study) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(studyEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    if (results === 'list') {
      dispatch(setStudy(res.data, SET_STUDIES))
    } else if (results === 'form') {
      dispatch(setStudy(res.data, SET_STUDY_OPTIONS, study))
    }
  }
}

export const createStudy = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(studyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setStudy(res.data, CREATE_STUDY))
      history.push('/studies')
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setStudy(error, ERROR_STUDY))
    }
  }
}

export const deleteStudy = (study) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(studyEndPoint, {
      data: { id: study.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setStudy(res.data, DELETE_STUDY, study))
  }
}

export const updateStudy = (params, study) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(studyEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setStudy(res.data, UPDATE_STUDY, study))
      history.push(`/studies/${params.id}`)
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setStudy(error, ERROR_STUDY))
    }
  }
}

export const toggleStudy = (study, toggle) => async (dispatch) => {
  const toggleEndPoint = `${studyEndPoint}/${toggle ? 'enable' : 'disable'}`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: study.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setStudy({ res, toggle: toggle }, TOGGLE_STUDY, study))
  }
}
