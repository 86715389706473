import restApi from "apis/restApi";
import {
  SET_CUSTOM_PROPERTY_LIST_ITEMS,
  CREATE_CUSTOM_PROPERTY_LIST_ITEM,
  DELETE_CUSTOM_PROPERTY_LIST_ITEM,
  UPDATE_CUSTOM_PROPERTY_LIST_ITEM,
  TOGGLE_CUSTOM_PROPERTY_LIST_ITEM,
  ERROR_CUSTOM_PROPERTY_LIST_ITEM,
  UNSET_CUSTOM_PROPERTY_LIST_ITEM_ACTION,
  UNSET_ERROR_CUSTOM_PROPERTY_LIST_ITEM
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const customPropertyListItemEndPoint = "/property/list";

// Passing data back to reducers
const setCustomPropertyListItem = (obj, type, customPropertyListItem) => {
  const action = { type: type, payload: obj };
  if (customPropertyListItem)
    action.currentCustomPropertyListItem = customPropertyListItem;
  return action;
};

// Non-API calls
export const clearCustomPropertyListItemAction = () => async dispatch => {
  dispatch(
    setCustomPropertyListItem({}, UNSET_CUSTOM_PROPERTY_LIST_ITEM_ACTION)
  );
};
export const clearCustomPropertyListItemErrors = () => async dispatch => {
  dispatch(
    setCustomPropertyListItem({}, UNSET_ERROR_CUSTOM_PROPERTY_LIST_ITEM)
  );
};

// API calls
export const fetchCustomPropertyListItems = customPropertyId => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(
      `${customPropertyListItemEndPoint}/${customPropertyId}`,
      {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      }
    );
    dispatch(
      setCustomPropertyListItem(res.data, SET_CUSTOM_PROPERTY_LIST_ITEMS)
    );
  }
};

export const createCustomPropertyListItem = (
  customPropertyId,
  params
) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(
        `${customPropertyListItemEndPoint}/${customPropertyId}`,
        params,
        {
          headers: { Authorization: `Bearer ${fetchToken()}` }
        }
      );
      dispatch(
        setCustomPropertyListItem(res.data, CREATE_CUSTOM_PROPERTY_LIST_ITEM)
      );
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(
        setCustomPropertyListItem(error, ERROR_CUSTOM_PROPERTY_LIST_ITEM)
      );
    }
  }
};

export const deleteCustomPropertyListItem = (
  customPropertyId,
  listItem
) => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(
      `${customPropertyListItemEndPoint}/${customPropertyId}`,
      {
        data: { id: listItem.id },
        headers: { Authorization: `Bearer ${fetchToken()}` }
      }
    );
    dispatch(
      setCustomPropertyListItem(
        res.data,
        DELETE_CUSTOM_PROPERTY_LIST_ITEM,
        listItem
      )
    );
  }
};

export const updateCustomPropertyListItem = (
  customPropertyId,
  params,
  listItem
) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(
        `${customPropertyListItemEndPoint}/${customPropertyId}`,
        params,
        {
          headers: { Authorization: `Bearer ${fetchToken()}` }
        }
      );
      dispatch(
        setCustomPropertyListItem(
          res.data,
          UPDATE_CUSTOM_PROPERTY_LIST_ITEM,
          listItem
        )
      );
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(
        setCustomPropertyListItem(error, ERROR_CUSTOM_PROPERTY_LIST_ITEM)
      );
    }
  }
};

export const toggleCustomPropertyListItem = (
  customPropertyId,
  listItem,
  toggle
) => async dispatch => {
  const toggleEndPoint = `${customPropertyListItemEndPoint}/${customPropertyId}/${
    toggle ? "enable" : "disable"
  }`;
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: listItem.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setCustomPropertyListItem(
        { res, toggle: toggle },
        TOGGLE_CUSTOM_PROPERTY_LIST_ITEM,
        listItem
      )
    );
  }
};
