/** @jsx jsx */
import { useEffect } from "react";
import { MDBSideNav, MDBLink } from "mdbreact";
import { jsx } from "@emotion/core";

const SideNavDesktop = ({ logo, styles, children }) => {
  useEffect(() => {
    const body = document.querySelector("body");
    const closest = function (el, fn) {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };
    const sideNavChildLinks = document.querySelectorAll(".sidenav-child-link");
    if (!body.classList.contains("sidenav-transition-body")) {
      body.classList.add("sidenav-transition-body");
      body.style.marginLeft = "240px";
    }
    sideNavChildLinks.forEach(child => {
      if (child.classList.contains("active")) {
        const childContainer = closest(child, function (el) {
          return el.className === "collapse";
        });
        const parentLi = childContainer.parentNode;
        parentLi.querySelector(".collapsible-header").click();
      }
    });
    return () => {
      body.classList.remove("sidenav-transition-body");
      body.style.marginLeft = "0";
    };
  }, []);
  return (
    <MDBSideNav
      css={styles}
      fixed
      triggerOpening={true}
      breakWidth={0}
      tag="aside"
    >
      <MDBLink to="/" className="logo-wrapper ripple-parent">
        <img src={logo} alt="epicenter portal" />
      </MDBLink>
      {children}
    </MDBSideNav>
  );
};

export default SideNavDesktop;
