/** @jsx jsx */
import { MDBBadge } from "mdbreact";
import { css, jsx } from "@emotion/core";

const labelBadgeStyle = css`
  font-size: 0.8rem;
  line-height: 1;
  padding: 0;
  font-weight: 400;
  color: #999 !important;
`;

const BadgeLabel = ({ spacingClass, children }) => {
  const spacing = spacingClass ? spacingClass : "mb-1";
  return (
    <MDBBadge
      color="transparent"
      className={`label-badge shadow-none ${spacing}`}
      css={labelBadgeStyle}
    >
      {children}
    </MDBBadge>
  );
};

export default BadgeLabel;
