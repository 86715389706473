import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSite, clearSiteErrors } from "actions/sites/siteActions";
import { fetchCountries, clearCountries } from "actions/countryActions";
import { fetchSiteProperties } from "actions/sites/sitePropertyActions";

import Section from "components/common/Section";
import SiteForm from "./SiteForm";

import { findSelected } from "utils/formUtils";

const SiteCreate = () => {
  const { siteErrors } = useSelector(state => state.sites);
  const { countryOptions } = useSelector(state => state.countries);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    return () => {
      dispatch(clearCountries());
      dispatch(clearSiteErrors());
      dispatch(fetchSiteProperties());
    };
  }, [dispatch]);

  const handleSubmission = (e, params, countries) => {
    e.preventDefault();
    const countrySelected = findSelected(countries);
    const siteParams = _.pickBy(params, _.identity);

    if (countrySelected.length > 0) {
      siteParams.country_id = countrySelected[0].id;
    }

    dispatch(createSite(siteParams));
  };

  return (
    <Section
      header="New Site"
      description="Fill in form to create a new site"
      breadcrumbs
      footer={[
        {
          text: "Submit",
          color: "primary",
          additionalProps: {
            form: "form_site",
            type: "submit"
          }
        }
      ]}
    >
      {countryOptions.length ? (
        <SiteForm
          btnText="Submit"
          countries={countryOptions}
          submit={handleSubmission}
          errors={siteErrors}
        />
      ) : (
        ""
      )}
    </Section>
  );
};

export default SiteCreate;
