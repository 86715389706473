import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateContactProperty,
  clearContactPropertyErrors,
  clearContactPropertyAction
} from "actions/contacts/contactPropertyActions";

import CustomPropertyForm from "components/common/properties/CustomPropertyForm";

const ContactPropertyUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected
}) => {
  const { lastAction, propertyErrors } = useSelector(
    state => state.contactProperties
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearContactPropertyAction());
      dispatch(clearContactPropertyErrors());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        required: selected.required || false
      });
    }
  }, [params, setParams, selected]);

  // watch for success
  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = e => {
    e.preventDefault();
    const updatedParams = _.mapValues(params, (prop, key) => {
      const propValue = prop !== "" && prop !== null;
      const newValue = selected[key] !== prop;
      if (newValue && propValue) return prop;
    });
    updatedParams.id = selected.id;

    const updatedProperty = _.mergeWith(selected, updatedParams);
    dispatch(updateContactProperty(updatedParams, updatedProperty));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e)}
        className="form container"
        noValidate
      >
        <CustomPropertyForm
          params={params}
          setParams={setParams}
          errors={propertyErrors}
        />
      </form>
    );
  }

  return "";
};

export default ContactPropertyUpdate;
