import SrDocumentDocTypeCreate from './SrDocumentDocTypeCreate'
import SrDocumentDocTypeDelete from './SrDocumentDocTypeDelete'
import SrDocumentDocTypeUpdate from './SrDocumentDocTypeUpdate'
import {
  fetchSrDocumentDocTypes,
  toggleSrDocumentDocType,
} from 'actions/srDocuments/srDocumentDocTypeActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Table from 'components/common/tables/Table'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBCardBody } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const newHeader = 'New Site Readiness Document File Type'
const editHeader = 'Edit Site Readiness Document File Type'
const deleteHeader = 'Delete Site Readiness Document File Type'

const SrDocumentDocTypeList = () => {
  //// HOOKS.
  const auth = useAuth()
  const dispatch = useDispatch()
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { docTypesAvailable, lastAction } = useSelector(
    (state) => state.srDocumentDocTypes
  )

  //// LOCAL STATE.
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'desc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'desc',
        minimal: 'lg',
      },
      {
        label: 'Date Created (UTC)',
        field: 'created_at',
        date: true,
        sort: 'desc',
      },
      {
        label: 'Available',
        field: 'is_available',
        toggleControl: true,
        sort: 'disabled',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchSrDocumentDocTypes('list'))
  }, [dispatch])

  //// RENDER VARS & HELPERS.
  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'new') {
        setHeaderText(newHeader)
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      } else if (type === 'edit') {
        setHeaderText(editHeader)
        setFooter(true)
        setActions([
          {
            text: 'Update',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      } else if (type === 'delete') {
        setHeaderText(deleteHeader)
        setFooter(true)
        setActions([
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      }
      if (type) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  //// RENDER.
  return (
    <>
      <SectionHeaderMenu
        menuData={{
          order: 2,
          show: true,
          linkItems: [
            {
              name: 'Create',
              url: `/admin/sr-documents/doc-types`,
              exact: true,
              additionalProps: {
                active: true,
                onClick: (e) => toggleModal(e, 'new'),
                className: 'btn-sm',
              },
            },
          ],
        }}
      />
      <MDBCardBody>
        {_.size(docTypesAvailable) ? (
          <Table
            section="File Type"
            tableData={tableData}
            setTableData={setTableData}
            rowData={docTypesAvailable}
            sort={['id', 'desc']}
            toggleAvailability={(item, toggle) => {
              dispatch(toggleSrDocumentDocType(item, toggle))
            }}
            toggleModal={toggleModal}
            updatedState={lastAction}
            downloadableCSV
            editButton
            deleteButton
          />
        ) : _.isEmpty(docTypesAvailable) ? (
          <EmptyList
            name="site readiness document file"
            namePlural="site readiness document files"
            userRole={auth?.user?.user_level}
            createElement={
              <span className="link" onClick={(e) => toggleModal(e, 'new')}>
                Create
              </span>
            }
          />
        ) : (
          ''
        )}
      </MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {headerText === newHeader ? (
          <SrDocumentDocTypeCreate
            formId={formId}
            params={params}
            setParams={setParams}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === editHeader ? (
          <SrDocumentDocTypeUpdate
            formId={formId}
            params={params}
            setParams={setParams}
            selected={selected}
            setSelected={setSelected}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === deleteHeader ? (
          <SrDocumentDocTypeDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  )
}

export default SrDocumentDocTypeList
