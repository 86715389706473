import {
  clearSrDocumentDocs,
  fetchSrDocumentDocs,
} from 'actions/srDocuments/srDocumentDocActions'
import Section from 'components/common/Section'
import Table from 'components/common/tables/Table'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestSrDocumentDoc } from 'utils/downloadUtils'

const downloadFile = (e, item) => {
  e.preventDefault()
  requestSrDocumentDoc(item.id, item.filename)
}

const SrDocumentDocTypeHistory = ({ match }) => {
  const { srDocumentId, docTypeId } = match.params

  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { docsAvailable, lastAction } = useSelector(
    (state) => state.srDocumentDocs
  )

  //// LOCAL STATE.
  const [docTypeName, setDocTypeName] = useState('')
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: 'Date Stored (UTC)',
        field: 'created_at',
        sort: 'desc',
        date: true,
      },
      {
        label: 'File',
        field: 'filename',
        sort: 'desc',
      },
      {
        label: 'Comment',
        field: 'comment',
        sort: 'asc',
        minimal: 'lg',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(
      fetchSrDocumentDocs({
        sr_document_id: srDocumentId,
        sr_document_doc_type_id: docTypeId,
      })
    )
    return () => {
      dispatch(clearSrDocumentDocs())
    }
  }, [dispatch, srDocumentId, docTypeId])

  useEffect(() => {
    if (_.size(docsAvailable)) {
      const obj = _.find(docsAvailable, (o) => {
        return o.sr_document_doc_type.id
      })
      setDocTypeName(obj.sr_document_doc_type.name)
    }
  }, [docsAvailable, docTypeName, setDocTypeName])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return (
    <Section
      header={`${docTypeName} Files`}
      description={`View ${docTypeName} file type history for site readiness document #${srDocumentId}`}
      breadcrumbs
    >
      {_.size(docsAvailable) ? (
        <Table
          section="Site Readiness Document File"
          tableData={tableData}
          setTableData={setTableData}
          rowData={docsAvailable}
          sort={['created_at', 'desc']}
          downloadFile={downloadFile}
          updatedState={lastAction}
          downloadButton
        />
      ) : lastAction === 'fetch' && _.isEmpty(docsAvailable) ? (
        <p>It appears this file type doesn't have any files attached to it.</p>
      ) : (
        ''
      )}
    </Section>
  )
}

export default SrDocumentDocTypeHistory
