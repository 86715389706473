import {
  DELETE_SR_DOCUMENT_SUBTYPE,
  ERROR_SR_DOCUMENT_SUBTYPE,
  SET_SR_DOCUMENT_SUBTYPES,
  SET_SR_DOCUMENT_SUBTYPE_OPTIONS,
  TOGGLE_SR_DOCUMENT_SUBTYPE,
  UNSET_SR_DOCUMENT_SUBTYPE_ACTION,
  UNSET_SR_DOCUMENT_SUBTYPE_OPTIONS,
  UNSET_ERROR_SR_DOCUMENT_SUBTYPE,
  UPDATE_SR_DOCUMENT_SUBTYPE,
  CREATE_SR_DOCUMENT_SUBTYPE,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentSubtypeEndPoint = '/sr-documents/subtype'

// Passing data back to reducers
const setSrDocumentSubtype = (
  obj,
  type,
  srDocumentTypeId,
  srDocumentSubtype
) => {
  const action = { type: type, payload: obj }
  if (srDocumentTypeId) action.currentSrDocumentTypeId = srDocumentTypeId
  if (srDocumentSubtype) action.currentSrDocumentSubtype = srDocumentSubtype
  return action
}

// Non-API calls
export const clearSrDocumentSubtypeOptions = () => async (dispatch) => {
  dispatch(setSrDocumentSubtype({}, UNSET_SR_DOCUMENT_SUBTYPE_OPTIONS))
}
export const clearSrDocumentSubtypeAction = () => async (dispatch) => {
  dispatch(setSrDocumentSubtype({}, UNSET_SR_DOCUMENT_SUBTYPE_ACTION))
}
export const clearSrDocumentSubtypeErrors = () => async (dispatch) => {
  dispatch(setSrDocumentSubtype({}, UNSET_ERROR_SR_DOCUMENT_SUBTYPE))
}

// API calls
export const fetchSrDocumentSubtypes =
  (results, srDocumentTypeId, srDocumentSubtype) => async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(srDocumentSubtypeEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setSrDocumentSubtype(res.data, SET_SR_DOCUMENT_SUBTYPES))
      } else if (results === 'form') {
        dispatch(
          setSrDocumentSubtype(
            res.data,
            SET_SR_DOCUMENT_SUBTYPE_OPTIONS,
            srDocumentTypeId,
            srDocumentSubtype
          )
        )
      }
    }
  }

export const createSrDocumentSubtype = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentSubtypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentSubtype(res.data, CREATE_SR_DOCUMENT_SUBTYPE))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentSubtype(error, ERROR_SR_DOCUMENT_SUBTYPE))
    }
  }
}

export const deleteSrDocumentSubtype = (subtype) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentSubtypeEndPoint, {
      data: { id: subtype.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentSubtype(res.data, DELETE_SR_DOCUMENT_SUBTYPE, null, subtype)
    )
  }
}

export const updateSrDocumentSubtype =
  (params, subtype) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(srDocumentSubtypeEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setSrDocumentSubtype(
            res.data,
            UPDATE_SR_DOCUMENT_SUBTYPE,
            null,
            subtype
          )
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setSrDocumentSubtype(error, ERROR_SR_DOCUMENT_SUBTYPE))
      }
    }
  }

export const toggleSrDocumentSubtype =
  (subtype, toggle) => async (dispatch) => {
    const toggleEndPoint = `${srDocumentSubtypeEndPoint}/${
      toggle ? 'enable' : 'disable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: subtype.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setSrDocumentSubtype(
          { res, toggle: toggle },
          TOGGLE_SR_DOCUMENT_SUBTYPE,
          null,
          subtype
        )
      )
    }
  }
