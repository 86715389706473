import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateCustomPropertyListItem,
  clearCustomPropertyListItemErrors,
  clearCustomPropertyListItemAction
} from "actions/properties/customPropertyListItemActions";

import AdminForm from "components/admin/AdminForm";

const CustomPropertyListItemUpdate = ({
  propertyId,
  formId,
  params,
  setParams,
  closeModal,
  selected,
  setSelected
}) => {
  const { lastAction, listItemErrors } = useSelector(
    state => state.customPropertyListItems
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearCustomPropertyListItemAction());
      dispatch(clearCustomPropertyListItemErrors());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        value: selected.value
      });
    }
  }, [params, setParams, selected]);

  // watch for success
  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = (e, updateParams, updateItem) => {
    e.preventDefault();

    dispatch(
      updateCustomPropertyListItem(propertyId, updateParams, updateItem)
    );
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params, selected)}
        className="form container"
        noValidate
      >
        {/* Forms TODO: */}
        <AdminForm
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          errors={listItemErrors}
        />
      </form>
    );
  }

  return "";
};

export default CustomPropertyListItemUpdate;
