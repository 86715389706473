import {
  updateContractType,
  clearContractTypeAction,
  clearContractTypeErrors,
} from 'actions/contracts/contractTypeActions'
import AdminForm from 'components/admin/AdminForm'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ContractTypeUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected,
  setSelected,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()

  //// GLOBAL STATE.
  const { lastAction, typeErrors } = useSelector((state) => state.contractTypes)

  //// LOCAL STATE.

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    return () => {
      dispatch(clearContractTypeAction())
      dispatch(clearContractTypeErrors())
    }
  }, [dispatch])

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        name: selected.name,
      })
    }
  }, [params, setParams, selected])

  // watch for success
  useEffect(() => {
    if (lastAction === 'edit') {
      closeModal()
    }
  }, [lastAction, closeModal])

  //// RENDER VARS & HELPERS.

  //// RENDER.
  return !_.size(params) ? (
    ''
  ) : (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()

        dispatch(updateContractType(params, selected))
      }}
      className="form container"
      noValidate
    >
      <AdminForm
        params={params}
        setParams={setParams}
        selected={selected}
        setSelected={setSelected}
        errors={typeErrors}
      />
    </form>
  )
}

export default ContractTypeUpdate
