// import ContractForm from './forms/ContractForm'
import SrDocumentForm from './forms/SrDocumentForm'
import { createSrDocument } from 'actions/srDocuments/srDocumentActions'
import Section from 'components/common/Section'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findSelected } from 'utils/formUtils'

const SrDocumentCreate = () => {
  const { srDocumentErrors } = useSelector((state) => state.srDocuments)
  const dispatch = useDispatch()

  const handleSubmission = (e, params, options) => {
    e.preventDefault()

    const studySelected = findSelected(options.study)
    const typeSelected = findSelected(options.srDocumentType)
    const subtypeSelected = findSelected(options.srDocumentSubtype)
    const currencySelected = findSelected(options.currency)
    const contactSelected = findSelected(options.primaryInvestigator)
    const siteSelected = findSelected(options.primarySite)
    const srDocumentParams = _.pickBy(params, _.identity)

    if (studySelected.length > 0) {
      srDocumentParams.study_id = studySelected[0].id
    }
    if (typeSelected.length > 0) {
      srDocumentParams.sr_document_type_id = typeSelected[0].id
    }
    if (subtypeSelected.length > 0 && subtypeSelected[0].id !== 0) {
      srDocumentParams.sr_document_subtype_id = subtypeSelected[0].id
    }
    if (
      params.is_budget &&
      currencySelected.length > 0 &&
      currencySelected[0].id !== 0
    ) {
      srDocumentParams.currency_id = currencySelected[0].id
    }
    if (contactSelected.length > 0 && contactSelected[0].id !== 0) {
      srDocumentParams.primary_investigator_id = contactSelected[0].id
    }
    if (siteSelected.length > 0 && siteSelected[0].id !== 0) {
      srDocumentParams.primary_site_id = siteSelected[0].id
    }

    dispatch(createSrDocument(srDocumentParams))
  }

  return (
    <Section
      title="Create Site Readiness Document"
      description="Select options to create a new site readiness document"
      breadcrumbs
      footer={[
        {
          text: 'Submit',
          color: 'primary',
          additionalProps: {
            form: 'form_sr_document',
            type: 'submit',
          },
        },
      ]}
    >
      <SrDocumentForm submit={handleSubmission} errors={srDocumentErrors} />
    </Section>
  )
}

export default SrDocumentCreate
