import restApi from "apis/restApi";
import { SET_CUSTOM_PROPERTY_TYPES } from "actions/types";
import { fetchToken } from "utils/authUtils";

// Pass data to reducers
export const setCustomPropertyType = (obj, type) => {
  const action = { type: type, payload: obj };
  return action;
};

export const fetchCustomPropertyType = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get("/propertyType", {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setCustomPropertyType(res.data, SET_CUSTOM_PROPERTY_TYPES));
  }
};
