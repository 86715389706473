import _ from "lodash";
import moment from "moment";

import {
  SET_INVOICE_MILESTONES,
  UNSET_INVOICE_MILESTONES,
  ERROR_INVOICE_MILESTONE,
  UNSET_ERROR_INVOICE_MILESTONE,
  CREATE_INVOICE_MILESTONE,
  DELETE_INVOICE_MILESTONE,
  UPDATE_INVOICE_MILESTONE,
  UNSET_INVOICE_MILESTONE_ACTION
} from "actions/types";

const INITIAL_STATE = {
  lastAction: null,
  milestonesAvailable: {},
  milestoneOptions: [],
  milestoneErrors: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INVOICE_MILESTONES:
      return {
        ...state,
        lastAction: "fetch",
        milestonesAvailable: _.mapKeys(action.payload, "id")
      };
    case UNSET_INVOICE_MILESTONES:
      return {
        ...state,
        milestonesAvailable: []
      };
    case UNSET_INVOICE_MILESTONE_ACTION:
      return {
        ...state,
        lastAction: null
      };
    case UNSET_ERROR_INVOICE_MILESTONE:
      return {
        ...state,
        milestoneErrors: []
      };
    case CREATE_INVOICE_MILESTONE:
      const createdMilestone = action.payload.created_items[0];
      const { id } = createdMilestone;
      const newState = {
        ...state,
        lastAction: "create",
        milestonesAvailable: {
          ...state.milestonesAvailable,
          [id]: createdMilestone
        }
      };
      return newState;
    case UPDATE_INVOICE_MILESTONE:
      const updatedItem = (state.milestonesAvailable[
        action.currentInvoiceMilestone.id
      ] = action.currentInvoiceMilestone);
      updatedItem.created_at =
        state.milestonesAvailable[action.currentInvoiceMilestone.id].created_at;
      updatedItem.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      return {
        ...state,
        lastAction: "edit",
        milestonesAvailable: {
          ...state.milestonesAvailable,
          [action.currentInvoiceMilestone.id]: updatedItem
        }
      };
    case DELETE_INVOICE_MILESTONE:
      const updated = state.milestonesAvailable;
      _.unset(updated, `${action.currentInvoiceMilestone.id}`);
      return { ...state, lastAction: "delete", activitiesAvailable: updated };
    case ERROR_INVOICE_MILESTONE:
      return {
        ...state,
        milestoneErrors: action.payload,
        lastAction: "error"
      };
    default:
      return state;
  }
};
