import React, { useState } from "react";
import { MDBRow, MDBCol, MDBFileInput } from "mdbreact";
import { saveAs } from "file-saver";

import ErrorPanel from "components/common/panel/ErrorPanel";
import Select from "components/common/forms/Select";

import contactFile from "assets/files/contact_import_example.txt";
import siteFile from "assets/files/site_import_example.txt";

const AdminForm = ({ params, setParams, errors }) => {
  const typeOptions = useState([
    {
      text: "Contact",
      value: "contact"
    },
    {
      text: "Site",
      value: "org"
    }
  ]);

  const handleSelection = text => {
    if (text !== "Choose A Type") {
      const typeSelection = typeOptions[0].filter(type => type.text === text);
      setParams({ ...params, type: typeSelection[0].value });
    }
  };

  const handleFileAttachment = v => {
    setParams({ ...params, import_file: v[0] });
  };

  return (
    <MDBRow>
      <MDBCol md="12">
        <Select
          id="select__file-type"
          options={typeOptions[0]}
          getTextContent={handleSelection}
          label="Type"
          defaultOption="Choose A Type"
          required
          search
          errors={errors}
        />
      </MDBCol>
      <MDBCol md="12">
        <MDBFileInput
          textFieldTitle="Upload a file"
          btnColor="primary"
          getValue={handleFileAttachment}
          accept="text/plain"
        />
        <div className="text-muted small">
          <div className="pb-1">Files must be tab delimited text files.</div>
          <div>
            <strong>Example Files:</strong>
            <br />
            <a
              href="#!"
              onClick={() => saveAs(contactFile, "contact_import_example.txt")}
            >
              Contact Import File
            </a>
            <br />
            <a
              href="#!"
              onClick={() => saveAs(siteFile, "site_import_example.txt")}
            >
              Site Import File
            </a>
          </div>
        </div>
      </MDBCol>
      <ErrorPanel results={errors} />
    </MDBRow>
  );
};

export default AdminForm;
