import { AUTHORIZATION_SET, AUTHORIZATION_RESET } from "actions/types";

const INITIAL_STATE = {
  status: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHORIZATION_SET:
      return { status: true };
    case AUTHORIZATION_RESET:
      return { status: false };
    default:
      return state;
  }
};
