import {
  SET_SR_DOCUMENT_DOC_TYPES,
  SET_SR_DOCUMENT_DOC_TYPE_OPTIONS,
  UNSET_SR_DOCUMENT_DOC_TYPE_OPTIONS,
  CREATE_SR_DOCUMENT_DOC_TYPE,
  DELETE_SR_DOCUMENT_DOC_TYPE,
  UPDATE_SR_DOCUMENT_DOC_TYPE,
  TOGGLE_SR_DOCUMENT_DOC_TYPE,
  ERROR_SR_DOCUMENT_DOC_TYPE,
  UNSET_SR_DOCUMENT_DOC_TYPE_ACTION,
  UNSET_ERROR_SR_DOCUMENT_DOC_TYPE,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentDocTypeEndPoint = '/documents/sr-document/type'

// Passing data back to reducers
const setSrDocumentDocType = (obj, type, srDocumentDocType) => {
  const action = { type: type, payload: obj }
  if (srDocumentDocType) action.currentSrDocumentDocType = srDocumentDocType
  return action
}

// Non-API calls
export const clearSrDocumentDocTypeOptions = () => async (dispatch) => {
  dispatch(setSrDocumentDocType({}, UNSET_SR_DOCUMENT_DOC_TYPE_OPTIONS))
}
export const clearSrDocumentDocTypeAction = () => async (dispatch) => {
  dispatch(setSrDocumentDocType({}, UNSET_SR_DOCUMENT_DOC_TYPE_ACTION))
}
export const clearSrDocumentDocTypeErrors = () => async (dispatch) => {
  dispatch(setSrDocumentDocType({}, UNSET_ERROR_SR_DOCUMENT_DOC_TYPE))
}

// API calls
export const fetchSrDocumentDocTypes =
  (results, srDocumentDocType) => async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(srDocumentDocTypeEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setSrDocumentDocType(res.data, SET_SR_DOCUMENT_DOC_TYPES))
      } else if (results === 'form') {
        dispatch(
          setSrDocumentDocType(
            res.data,
            SET_SR_DOCUMENT_DOC_TYPE_OPTIONS,
            srDocumentDocType
          )
        )
      }
    }
  }

export const createSrDocumentDocType = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentDocTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentDocType(res.data, CREATE_SR_DOCUMENT_DOC_TYPE))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentDocType(error, ERROR_SR_DOCUMENT_DOC_TYPE))
    }
  }
}

export const deleteSrDocumentDocType = (docType) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentDocTypeEndPoint, {
      data: { id: docType.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentDocType(res.data, DELETE_SR_DOCUMENT_DOC_TYPE, docType)
    )
  }
}

export const updateSrDocumentDocType =
  (params, docType) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(srDocumentDocTypeEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setSrDocumentDocType(res.data, UPDATE_SR_DOCUMENT_DOC_TYPE, docType)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setSrDocumentDocType(error, ERROR_SR_DOCUMENT_DOC_TYPE))
      }
    }
  }

export const toggleSrDocumentDocType =
  (docType, toggle) => async (dispatch) => {
    const toggleEndPoint = `${srDocumentDocTypeEndPoint}/${
      toggle ? 'enable' : 'disable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: docType.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setSrDocumentDocType(
          { res, toggle: toggle },
          TOGGLE_SR_DOCUMENT_DOC_TYPE,
          docType
        )
      )
    }
  }
