import {
  CREATE_SR_DOCUMENT_PROPERTY,
  DELETE_SR_DOCUMENT_PROPERTY,
  ERROR_SR_DOCUMENT_PROPERTY,
  LOADING_SR_DOCUMENT_PROPERTIES,
  SET_SR_DOCUMENT_PROPERTIES,
  SET_SR_DOCUMENT_PROPERTY_OPTIONS,
  TOGGLE_SR_DOCUMENT_PROPERTY_GUEST_VISIBILITY,
  UNSET_ERROR_SR_DOCUMENT_PROPERTY,
  UNSET_SR_DOCUMENT_PROPERTY_ACTION,
  UNSET_SR_DOCUMENT_PROPERTY_OPTIONS,
  UPDATE_SR_DOCUMENT_PROPERTY,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  srDocumentPropertiesAvailable: {},
  srDocumentPropertyOptions: [],
  srDocumentPropertyErrors: {},
  loading: false,
}

const srDocumentPropertyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_PROPERTIES:
      return {
        ...state,
        lastAction: 'fetch',
        loading: false,
        srDocumentPropertiesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case LOADING_SR_DOCUMENT_PROPERTIES:
      return {
        ...state,
        loading: true,
        srDocumentPropertyErrors: {},
        lastAction: null,
      }
    case SET_SR_DOCUMENT_PROPERTY_OPTIONS:
      return {
        ...state,
        lastAction: 'fetchOptions',
        loading: false,
        srDocumentPropertyOptions: action.payload,
      }
    case UNSET_SR_DOCUMENT_PROPERTY_OPTIONS:
      if (state.srDocumentPropertyOptions) {
        return { ...state, srDocumentPropertyOptions: [] }
      }
      return state
    case UNSET_SR_DOCUMENT_PROPERTY_ACTION:
      return { ...state, lastAction: null }
    case CREATE_SR_DOCUMENT_PROPERTY:
      const createdItems = _.mapKeys(action.payload.created_items, 'id')
      const newProperties = _.merge(
        state.srDocumentPropertiesAvailable,
        createdItems
      )
      const newState = {
        ...state,
        lastAction: 'create',
        srDocumentPropertiesAvailable: newProperties,
      }
      return newState
    case UPDATE_SR_DOCUMENT_PROPERTY:
      const updatedItem = (state.srDocumentPropertiesAvailable[
        action.currentSrDocumentProperty.id
      ] = action.currentSrDocumentProperty)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_PROPERTY:
      return {
        ...state,
        srDocumentPropertyErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_PROPERTY:
      return {
        ...state,
        srDocumentPropertyErrors: {},
      }
    case TOGGLE_SR_DOCUMENT_PROPERTY_GUEST_VISIBILITY:
      const toggledItem = (state.srDocumentPropertiesAvailable[
        action.currentSrDocumentProperty.id
      ] = action.currentSrDocumentProperty)
      toggledItem.is_visible_to_guests = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_SR_DOCUMENT_PROPERTY:
      const updated = state.srDocumentPropertiesAvailable
      _.unset(updated, `${action.currentSrDocumentProperty.id}`)
      return {
        ...state,
        lastAction: 'delete',
        srDocumentPropertiesAvailable: updated,
      }
    default:
      return state
  }
}

export default srDocumentPropertyReducer
