import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateCustomProperty,
  clearCustomPropertyErrors,
  clearCustomPropertyAction
} from "actions/properties/customPropertyActions";

import CustomPropertyForm from "components/admin/properties/CustomPropertyForm";

const CustomPropertyUpdate = ({
  formId,
  params,
  setParams,
  closeModal,
  selected,
  setSelected
}) => {
  const { lastAction, propertyErrors } = useSelector(
    state => state.customProperties
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearCustomPropertyAction());
      dispatch(clearCustomPropertyErrors());
    };
  }, [dispatch]);

  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        id: selected.id,
        name: selected.name
      });
    }
  }, [params, setParams, selected]);

  // watch for success
  useEffect(() => {
    if (lastAction === "edit") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  const handleSubmit = (e, updateParams, updateItem) => {
    e.preventDefault();

    dispatch(updateCustomProperty(updateParams, updateItem));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params, selected)}
        className="form container"
        noValidate
      >
        <CustomPropertyForm
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          errors={propertyErrors}
        />
      </form>
    );
  }

  return "";
};

export default CustomPropertyUpdate;
