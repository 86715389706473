import {
  CHART_CONTRACT_ACTIVITY_BY_LOCATION,
  CHART_CONTRACT_ACTIVITY_CLEAR_ACTION,
  CHART_CONTRACT_ACTIVITY_CLEAR_BY_LOCATION,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractTimeByLocation = '/stats/contract/location'

// Passing data back to reducers
export const setContractActivityChart = (obj, type) => {
  const action = { type: type, payload: obj }
  return action
}

// clear state
export const clearContractActivityChartAction = () => async (dispatch) => {
  dispatch(setContractActivityChart({}, CHART_CONTRACT_ACTIVITY_CLEAR_ACTION))
}

export const clearContractActivityByLocation = () => async (dispatch) => {
  dispatch(
    setContractActivityChart({}, CHART_CONTRACT_ACTIVITY_CLEAR_BY_LOCATION)
  )
}

// API calls
export const displayContractActivityByLocation = (id) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${contractTimeByLocation}/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setContractActivityChart(res.data, CHART_CONTRACT_ACTIVITY_BY_LOCATION)
    )
  }
}
