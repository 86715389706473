import StudyDelete from './StudyDelete'
import { toggleStudy } from 'actions/studies/studyActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import Table from 'components/common/tables/Table'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBIcon } from 'mdbreact'
import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { isUserNotReadOnly } from 'utils/authUtils'
import { filterTableColumns } from 'utils/tableUtils'

const StudyList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()
  const auth = useAuth()
  const dispatch = useDispatch()

  // redux hooks
  const { studiesAvailable, lastAction } = useSelector((state) => state.studies)

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'desc',
      },
      {
        label: 'Date Created (UTC)',
        field: 'created_at',
        date: true,
        sort: 'desc',
      },
      {
        label: 'Sponsor Study Name',
        field: 'name',
        sort: 'asc',
      },
      {
        label: 'Protocol',
        field: 'protocol',
        sort: 'asc',
      },
      {
        label: 'Available',
        field: 'is_available',
        toggleControl: true,
        sort: 'disabled',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  // remove available column once we know the user context is loaded
  // and it is not an admin
  if (auth.hasUserInfo && !auth?.user?.is_admin) {
    filterTableColumns(tableData, setTableData, ['is_available'])
  }

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Study')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  const fetchTableLink = useCallback((type, item) => {
    switch (type) {
      case 'show':
        return `/studies/${item.id}`
      case 'edit':
        return `/studies/${item.id}/edit`
      default:
        return null
    }
  }, [])

  const handleAvailabilityChange = (item, toggle) => {
    dispatch(toggleStudy(item, toggle))
  }

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: (
          <>
            <MDBIcon icon="fas fa-plus" /> Add Study
          </>
        ),
        url: '/studies/new',
        additionalProps: {
          active: true,
        },
      },
    ],
  }

  const renderTable = () => {
    if (_.size(studiesAvailable)) {
      return (
        <Table
          section="Study"
          tableData={tableData}
          setTableData={setTableData}
          rowData={studiesAvailable}
          sort={['id', 'desc']}
          fetchTableLink={fetchTableLink}
          toggleAvailability={handleAvailabilityChange}
          toggleModal={toggleModal}
          updatedState={lastAction}
          clickableRow
          downloadableCSV
          linkToPage
          showButton
          editButton={isUserNotReadOnly(auth?.user?.user_level)}
          deleteButton={auth?.user?.is_admin ?? false}
        />
      )
    } else if (_.isEmpty(studiesAvailable)) {
      return (
        <EmptyList
          name="study"
          namePlural="studies"
          userRole={auth?.user?.user_level}
          createElement={<Link to="/studies/new">Create</Link>}
        />
      )
    }
    return ''
  }

  return (
    <Section
      header="Study List"
      menu={
        isUserNotReadOnly(auth?.user?.user_level) ? sectionHeaderMenu : null
      }
    >
      {renderTable()}
      {auth?.user?.is_admin ? (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: 'Delete',
              color: 'danger',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ]}
        >
          <StudyDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      ) : (
        ''
      )}
    </Section>
  )
}

export default StudyList
