import React from "react";
import { MDBCardHeader } from "mdbreact";

const SectionHeader = ({ description }) => {
  return (
    <MDBCardHeader className="d-flex justify-content-between align-items-center">
      <div className="text-muted">{description}</div>
    </MDBCardHeader>
  );
};

export default SectionHeader;
