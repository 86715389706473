import {
  SET_CONTRACT_LOCATIONS,
  SET_CONTRACT_LOCATION_OPTIONS,
  UNSET_CONTRACT_LOCATION_OPTIONS,
  CREATE_CONTRACT_LOCATION,
  DELETE_CONTRACT_LOCATION,
  UPDATE_CONTRACT_LOCATION,
  TOGGLE_CONTRACT_LOCATION,
  ERROR_CONTRACT_LOCATION,
  UNSET_CONTRACT_LOCATION_ACTION,
  UNSET_ERROR_CONTRACT_LOCATION,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractLocationEndPoint = '/contracts/location'

// Passing data back to reducers
const setContractLocation = (
  obj,
  type,
  contractLocation,
  noDefaultOptions = false
) => {
  const action = { type: type, payload: obj }
  if (contractLocation) action.currentContractLocation = contractLocation
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions
  return action
}

// Non-API calls
export const clearContractLocationOptions = () => async (dispatch) => {
  dispatch(setContractLocation({}, UNSET_CONTRACT_LOCATION_OPTIONS))
}
export const clearContractLocationAction = () => async (dispatch) => {
  dispatch(setContractLocation({}, UNSET_CONTRACT_LOCATION_ACTION))
}
export const clearContractLocationErrors = () => async (dispatch) => {
  dispatch(setContractLocation({}, UNSET_ERROR_CONTRACT_LOCATION))
}

// API calls
export const fetchContractLocations =
  (results, contractLocation, noDefaultOptions = false) =>
  async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(contractLocationEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setContractLocation(res.data, SET_CONTRACT_LOCATIONS))
      } else if (results === 'form') {
        dispatch(
          setContractLocation(
            res.data,
            SET_CONTRACT_LOCATION_OPTIONS,
            contractLocation,
            noDefaultOptions
          )
        )
      }
    }
  }

export const createContractLocation = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractLocationEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractLocation(res.data, CREATE_CONTRACT_LOCATION))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractLocation(error, ERROR_CONTRACT_LOCATION))
    }
  }
}

export const deleteContractLocation = (location) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractLocationEndPoint, {
      data: { id: location.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractLocation(res.data, DELETE_CONTRACT_LOCATION, location))
  }
}

export const updateContractLocation =
  (params, location) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractLocationEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setContractLocation(res.data, UPDATE_CONTRACT_LOCATION, location)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractLocation(error, ERROR_CONTRACT_LOCATION))
      }
    }
  }

export const toggleContractLocation =
  (location, toggle) => async (dispatch) => {
    const toggleEndPoint = `${contractLocationEndPoint}/${
      toggle ? 'enable' : 'disable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: location.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setContractLocation(
          { res, toggle: toggle },
          TOGGLE_CONTRACT_LOCATION,
          location
        )
      )
    }
  }
