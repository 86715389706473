import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MDBIcon, MDBCardBody } from "mdbreact";

import useModal from "hooks/useModal";
import { useAuth } from "hooks/useAuth";
import { fetchCustomPropertyListItems } from "actions/properties/customPropertyListItemActions";

import Modal from "components/common/Modal";
import Table from "components/common/tables/Table";
import SectionHeaderMenu from "components/nav/SectionHeaderMenu";
import EmptyList from "components/common/EmptyList";
import CustomPropertyListItemCreate from "./CustomPropertyListItemCreate";
import CustomPropertyListItemUpdate from "./CustomPropertyListItemUpdate";
import CustomPropertyListItemDelete from "./CustomPropertyListItemDelete";

const CustomPropertyListItemList = ({ match }) => {
  const { id } = match.params;
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState
  } = useModal();
  const auth = useAuth();
  const dispatch = useDispatch();

  // redux hooks
  const { listItemsAvailable, lastAction } = useSelector(
    state => state.customPropertyListItems
  );

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "#",
        field: "id"
      },
      {
        label: "Value",
        field: "value",
        minimal: "lg"
      },
      {
        label: "Date Created (UTC)",
        field: "created_at",
        date: true,
        sort: "desc"
      },
      {
        label: "",
        field: "buttons",
        sort: "disabled",
        minimal: "lg"
      }
    ]
  });

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault();

      if (type === "new") {
        setHeaderText("New List Item");
        setFooter(true);
        setActions([
          {
            text: "Submit",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
      } else if (type === "edit") {
        setHeaderText("Edit List Item");
        setFooter(true);
        setActions([
          {
            text: "Update",
            color: "primary",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
        setSelected(item);
      } else if (type === "delete") {
        setHeaderText("Delete List Item");
        setFooter(true);
        setActions([
          {
            text: "Delete",
            color: "danger",
            additionalProps: {
              form: formId,
              type: "submit"
            }
          }
        ]);
        setSelected(item);
      }
      if (type) {
        setModalState(true);
      } else {
        setModalState(false);
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  );

  useEffect(() => {
    dispatch(fetchCustomPropertyListItems(id));
    return () => {
      // any clearing or status changes on exit will go here
    };
  }, [dispatch, id]);

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    leftSide: (
      <Link to="/admin/properties">
        <MDBIcon icon="fas fa-chevron-left" /> Custom Properties
      </Link>
    ),
    linkItems: [
      {
        name: (
          <>
            <MDBIcon icon="fas fa-plus" /> Add List Item
          </>
        ),
        url: `/admin/properties/${id}/new/`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: e => toggleModal(e, "new"),
          className: "btn-sm"
        }
      }
    ]
  };

  const renderModalBody = () => {
    if (headerText === "New List Item") {
      return (
        <CustomPropertyListItemCreate
          propertyId={id}
          formId={formId}
          params={params}
          setParams={setParams}
          closeModal={() => setModalState(false)}
        />
      );
    } else if (headerText === "Edit List Item") {
      return (
        <CustomPropertyListItemUpdate
          propertyId={id}
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => setModalState(false)}
        />
      );
    } else if (headerText === "Delete List Item") {
      return (
        <CustomPropertyListItemDelete
          propertyId={id}
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      );
    }
    return "";
  };

  const renderTable = () => {
    if (_.size(listItemsAvailable)) {
      return (
        <Table
          section="Custom Property List Item"
          tableData={tableData}
          setTableData={setTableData}
          rowData={listItemsAvailable}
          toggleModal={toggleModal}
          updatedState={lastAction}
          editButton
          deleteButton
        />
      );
    } else if (_.isEmpty(listItemsAvailable)) {
      return (
        <EmptyList
          name="list item"
          namePlural="list items"
          userRole={auth?.user?.user_level}
          createElement={
            <span className="link" onClick={e => toggleModal(e, "new")}>
              Create
            </span>
          }
        />
      );
    }

    return "";
  };

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>{renderTable()}</MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  );
};

export default CustomPropertyListItemList;
