import {
  fetchInvoiceMilestones,
  clearInvoiceMilestoneErrors,
  clearInvoiceMilestoneAction,
  clearInvoiceMilestones,
} from 'actions/invoiceMilestones/invoiceMilestoneActions'
import { fetchStudies } from 'actions/studies/studyActions'
import InvoiceMilestoneCreate from 'components/admin/invoiceMilestones/InvoiceMilestoneCreate'
import InvoiceMilestoneDelete from 'components/admin/invoiceMilestones/InvoiceMilestoneDelete'
import InvoiceMilestoneUpdate from 'components/admin/invoiceMilestones/InvoiceMilestoneUpdate'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Select from 'components/common/forms/Select'
import Table from 'components/common/tables/Table'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBCol, MDBRow, MDBCardBody } from 'mdbreact'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

export const DEFAULT_STUDY_VAULE = 'Default'

const InvoiceMilestonesList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()
  const auth = useAuth()
  const dispatch = useDispatch()

  const [queryParams, setQueryParams] = useState()
  //fetch invoice milestones
  useEffect(() => {
    dispatch(fetchInvoiceMilestones(queryParams))
    dispatch(fetchStudies('form', null, false))
    return () => {
      dispatch(clearInvoiceMilestoneErrors())
      dispatch(clearInvoiceMilestoneAction())
      dispatch(clearInvoiceMilestones())
    }
  }, [dispatch])

  // redux hooks
  const { milestonesAvailable, lastAction } = useSelector(
    (state) => state.invoiceMilestones
  )

  const studyOptions = useSelector((state) => state.studies.studyOptions)

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'desc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'desc',
        minimal: 'lg',
      },
      {
        label: 'Contract Type',
        field: 'contract_type.name',
        sort: 'desc',
      },
      {
        label: 'Contract Location',
        field: 'contract_location.name',
        sort: 'desc',
      },
      {
        label: 'Contract Status',
        field: 'contract_status.name',
        sort: 'desc',
      },
      {
        label: 'Sponsor Study Name',
        field: 'study.name',
        sort: 'desc',
      },
      {
        label: 'Percent Complete',
        field: 'percent_complete',
        sort: 'desc',
      },
      {
        label: 'Date Created (UTC)',
        field: 'created_at',
        date: true,
        sort: 'desc',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'new') {
        setHeaderText('New Invoice Milestone')
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      } else if (type === 'edit') {
        setHeaderText('Edit Invoice Milestone')
        setFooter(true)
        setActions([
          {
            text: 'Update',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      } else if (type === 'delete') {
        setHeaderText('Delete Invoice Milestone')
        setFooter(true)
        setActions([
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      }
      if (type) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  useEffect(() => {
    typeof queryParams === 'number'
      ? dispatch(fetchInvoiceMilestones(queryParams.toString()))
      : dispatch(fetchInvoiceMilestones(queryParams))
  }, [queryParams])

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: 'Create',
        url: `/admin/invoice-milestones`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: (e) => toggleModal(e, 'new'),
          className: 'btn-sm',
        },
      },
    ],
  }

  const closeModal = () => {
    dispatch(clearInvoiceMilestoneAction())
    dispatch(clearInvoiceMilestoneErrors())
    setModalState(false)
  }

  const [selectStudyOption, setSelectStudyOption] = useState([
    { value: '0', text: DEFAULT_STUDY_VAULE },
    ...studyOptions,
  ])

  useEffect(() => {
    if (studyOptions.length) {
      const options = [
        { value: '0', text: DEFAULT_STUDY_VAULE },
        ...studyOptions,
      ]

      const optionsWithDefault = options.map((option) => {
        if (queryParams === option.value) {
          return { ...option, checked: true }
        }
        return option
      })
      setSelectStudyOption(optionsWithDefault)
    }
  }, [studyOptions, queryParams])

  const handleStudy = (e) => {
    if (
      (e && e === DEFAULT_STUDY_VAULE) ||
      e === 'Choose a Sponsor Study Name'
    ) {
      setQueryParams('0')
    } else {
      const currentStudy = studyOptions.filter((study) => study.value === e)
      setQueryParams(`${currentStudy[0].id}`)
    }
  }

  const renderModalBody = () => {
    if (headerText === 'New Invoice Milestone') {
      return (
        <InvoiceMilestoneCreate
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          setQueryParams={setQueryParams}
          studyOptions={selectStudyOption}
          closeModal={() => closeModal()}
        />
      )
    } else if (headerText === 'Edit Invoice Milestone') {
      return (
        <InvoiceMilestoneUpdate
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => setModalState(false)}
        />
      )
    } else if (headerText === 'Delete Invoice Milestone') {
      return (
        <InvoiceMilestoneDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      )
    }

    return ''
  }

  for (let key in milestonesAvailable) {
    if (milestonesAvailable[key]?.study?.id === null) {
      milestonesAvailable[key].study = { name: 'Default', id: '0' }
    }
  }
  const renderTable = () => {
    if (_.size(milestonesAvailable)) {
      return (
        <Table
          section="invoice milestone"
          tableData={tableData}
          setTableData={setTableData}
          rowData={milestonesAvailable}
          sort={['id', 'desc']}
          toggleModal={toggleModal}
          updatedState={lastAction}
          downloadableCSV
          editButton
          deleteButton
        />
      )
    } else if (_.isEmpty(milestonesAvailable)) {
      return (
        <EmptyList
          name="invoice milestone"
          namePlural="invoice milestones"
          userRole={auth?.user?.user_level}
          createElement={
            <span className="link" onClick={(e) => toggleModal(e, 'new')}>
              Create
            </span>
          }
        />
      )
    }

    return ''
  }

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBRow className="form">
        <MDBCol md="3">
          <Select
            id="filter__study_id"
            options={selectStudyOption}
            getTextContent={handleStudy}
            defaultOption="Choose a Sponsor Study Name"
            label="Filter by Sponsor Study Name"
            additionalClasses="mb-0"
            search
          />
        </MDBCol>
      </MDBRow>
      <MDBCardBody>{renderTable()}</MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  )
}

export default InvoiceMilestonesList
