import BadgeLabel from 'components/common/badges/BadgeLabel'
import Select from 'components/common/forms/Select'
import ErrorPanel from 'components/common/panel/ErrorPanel'
import { useAuth } from 'hooks/useAuth'
import _ from 'lodash'
import { MDBCol, MDBInput, MDBSwitch } from 'mdbreact'
import React, { useState, useEffect, useMemo } from 'react'
import { formatSnakeCase } from 'utils/commonUtils'
import { inputErrorClass, findSelected } from 'utils/formUtils'

const UserForm = ({ params, setParams, errors, availableToggle }) => {
  //// HOOKS.
  const auth = useAuth()
  const hasSiteReadiness = auth.user?.customer?.has_site_readiness

  //// GLOBAL STATE.

  //// LOCAL STATE.
  const [userLevelOptions, setUserLevelOptions] = useState([])

  //// LIFECYCLE HELPERS.

  //// MEMOS.
  const userLevels = useMemo(
    () => [
      'normal',
      ...(hasSiteReadiness ? ['site_readiness'] : []),
      'admin',
      'view_only',
      'guest',
    ],
    [hasSiteReadiness]
  )

  //// EFFECTS.
  useEffect(() => {
    if (!userLevelOptions.length) {
      const options = userLevels.map((level) => ({
        value: level,
        text: formatSnakeCase(level),
        checked: params?.user_level === level,
      }))
      setUserLevelOptions(options)
    }
  }, [userLevelOptions, params])

  //// RENDER VARS & HELPERS.
  const handleInputChange = (value, key) => {
    setParams({ ...params, [key]: value })
  }

  //// RENDER.
  return (
    <>
      <MDBCol md="12">
        <MDBInput
          value={params.first_name}
          autoFocus
          getValue={(value) => handleInputChange(value, 'first_name')}
          id="user_first-name-input"
          label="First Name"
          outline
          required
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !params.first_name
          )}`}
        />

        <MDBInput
          value={params.last_name}
          getValue={(value) => handleInputChange(value, 'last_name')}
          id="user_last-name-input"
          label="Last Name"
          outline
          required
          containerClass="required-label"
          className={`input-select${inputErrorClass(
            errors,
            !params.last_name
          )}`}
        />

        <MDBInput
          value={params.email}
          getValue={(value) => handleInputChange(value, 'email')}
          id="user_email-input"
          label="Email"
          outline
          required
          containerClass="required-label"
        />

        {!_.isEmpty(userLevelOptions) ? (
          <Select
            id="select__user-levels"
            options={userLevelOptions}
            getTextContent={(v) => {
              if (v !== 'Choose A User Role') {
                const userSelection = findSelected(userLevelOptions)
                setParams({ ...params, user_level: userSelection[0].value })
              }
            }}
            label="User Role"
            defaultOption="Choose A User Role"
            required
            errors={errors}
          />
        ) : (
          void 0
        )}

        {availableToggle ? (
          <>
            <BadgeLabel>Active</BadgeLabel>
            <MDBSwitch
              key="availability-switch"
              className="availability-switch"
              checked={params.is_available}
              getValue={(toggle) =>
                setParams({ ...params, is_available: toggle })
              }
            />
          </>
        ) : (
          void 0
        )}
      </MDBCol>
      <ErrorPanel results={errors} />
    </>
  )
}

export default UserForm
