import ContractFilter from './ContractFilter'
import {
  fetchContracts,
  useClearContracts,
} from 'actions/contracts/contractActions'
import { createContractColumns } from 'actions/contracts/contractColumnActions'
import EmptyList from 'components/common/EmptyList'
import Table from 'components/common/tables/Table'
import { useAuth } from 'hooks/useAuth.js'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET, atomWithReset } from 'jotai/utils'
import _ from 'lodash'
import { MDBSpinner } from 'mdbreact'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { isUserNotReadOnlyForContracts } from 'utils/authUtils'
import { defaultContractColumns } from 'utils/tableUtils'

const defaultButtons = [
  {
    label: '',
    field: 'buttons',
    sort: 'disabled',
    minimal: 'lg',
  },
]

export const contractsReloadAtom = atom(false)
export const isLoadingContractsAtom = atomWithReset(true)
export const prevContractsLoadQueryParamsAtom = atomWithReset(null)

export const contractsFilterParamsAtom = atomWithReset([])
export const contractsViewParamsAtom = atomWithReset([])
export const contractsQueryParamsAtom = atomWithReset('')

export const contractsColumnsAtom = atomWithReset([])
export const contractsTableDataAtom = atomWithReset({})

const ContractTable = ({
  contractsAvailable,
  isLoadingMore,
  lastAction,
  contractPropertyOptions,
  homePageView,
  fetchTableLink,
  toggleModal,
  section,
  willSaveColumns,
  shouldRequireQueryParams,
}) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}

  //// GLOBAL STATE.
  const [
    [reloadContracts, setReloadContracts],
    [isLoadingContracts, setIsLoadingContracts],
    [prevContractsLoadQueryParams, setPrevContractsLoadQueryParams],
    contractsViewParams,
    contractsFilterParams,
    [contractsQueryParams, setContractsQueryParams],
    contractsColumns,
    [contractsTableData, setContractsTableData],
  ] = [
    useAtom(contractsReloadAtom),
    useAtom(isLoadingContractsAtom),
    useAtom(prevContractsLoadQueryParamsAtom),
    useAtomValue(contractsViewParamsAtom),
    useAtomValue(contractsFilterParamsAtom),
    useAtom(contractsQueryParamsAtom),
    useAtomValue(contractsColumnsAtom),
    useAtom(contractsTableDataAtom),
  ]

  //// LOCAL STATE.

  //// EFFECT HELPERS.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUpdatedData = useCallback(
    _.debounce(async (qp, rc) => {
      setIsLoadingContracts(true)
      setPrevContractsLoadQueryParams(qp)
      await dispatch(fetchContracts(qp))
      setIsLoadingContracts(false)
      if (rc) setReloadContracts(false)
    }, 500),
    [
      dispatch,
      setReloadContracts,
      setIsLoadingContracts,
      setPrevContractsLoadQueryParams,
    ]
  )

  //// EFFECTS.
  // handle view param change
  useEffect(() => {
    if (!_.isEmpty(contractsViewParams)) {
      const queryParamsObjects = {}
      Object.keys(contractsViewParams).forEach((filterValue) => {
        if (contractsViewParams[filterValue].length === 0) {
          queryParamsObjects[filterValue] = []
        } else {
          const queryParam = `${filterValue}[]=${contractsViewParams[
            filterValue
          ].join(`&${filterValue}[]=`)}`
          queryParamsObjects[filterValue] = queryParam
        }
      })
      setContractsQueryParams(
        Object.values(queryParamsObjects).flat().join('&')
      )
    }
  }, [contractsViewParams, setContractsQueryParams])

  // handle filter param change
  useEffect(() => {
    if (!_.isEmpty(contractsFilterParams)) {
      const queryParamsObjects = {}
      Object.keys(contractsFilterParams).forEach((filterValue) => {
        if (contractsFilterParams[filterValue].length === 0) {
          queryParamsObjects[filterValue] = []
        } else {
          const queryParam = `${filterValue}[]=${contractsFilterParams[
            filterValue
          ].join(`&${filterValue}[]=`)}`
          queryParamsObjects[filterValue] = queryParam
        }
      })
      setContractsQueryParams(
        Object.values(queryParamsObjects).flat().join('&')
      )
    }
  }, [contractsFilterParams, setContractsQueryParams])

  // get new data as needed
  useEffect(() => {
    // only fetch data if we have already loaded the initial contract data
    if (
      (!shouldRequireQueryParams || contractsQueryParams?.length) &&
      (reloadContracts || contractsQueryParams !== prevContractsLoadQueryParams)
    ) {
      // update reload data in case of manual reload
      fetchUpdatedData(contractsQueryParams, reloadContracts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsQueryParams, reloadContracts, fetchUpdatedData])

  //// RENDER.
  return (
    <>
      <ContractFilter
        contractPropertyOptions={contractPropertyOptions}
        hasQueryParams={
          Object.values(contractsFilterParams).flat().length === 0
        }
        viewParams={contractsViewParams}
        homePageView={homePageView}
      />
      <div
        className="pr-4 d-flex align-items-center"
        style={{
          position: 'absolute',
          right: 0,
          opacity: isLoadingContracts || isLoadingMore ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <span
          className="pr-3"
          style={{
            opacity: isLoadingMore ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          Loading additional contracts ...
        </span>
        <MDBSpinner small className=" border-primary" />
      </div>

      {_.isEmpty(contractsAvailable) && !isLoadingContracts ? (
        <EmptyList
          name="contract"
          namePlural="contracts"
          userRole={user_level}
          createElement={<Link to="/contracts/new">Create</Link>}
        />
      ) : (
        <div
          style={{
            opacity: isLoadingContracts ? 0.5 : 1,
            transition: 'opacity 0.5s ease-in-out',
          }}
        >
          <Table
            section={section || 'Contract'}
            tableData={contractsTableData}
            setTableData={setContractsTableData}
            rowData={contractsAvailable}
            sort={['id', 'desc']}
            fetchTableLink={fetchTableLink}
            toggleModal={toggleModal}
            updatedState={lastAction}
            clickableRow
            downloadableCSV={!homePageView && !isLoadingMore}
            infoLabel={['', '-', 'of', isLoadingMore ? 'and counting ...' : '']}
            linkToPage
            showButton
            editButton={
              !homePageView && isUserNotReadOnlyForContracts(user_level)
            }
            deleteButton={is_admin && !homePageView}
            defaultColumns={defaultContractColumns}
            defaultButtons={defaultButtons}
            customProperties={
              contractPropertyOptions
                ? contractPropertyOptions?.custom_properties
                : undefined
            }
            willSaveColumns={willSaveColumns}
            columnsAvailable={contractsColumns}
            onSubmitColumns={
              willSaveColumns
                ? (values) => {
                    dispatch(
                      createContractColumns({
                        contract_columns: values.join(','),
                      })
                    )
                  }
                : undefined
            }
            toggleAvailability={undefined}
          />
        </div>
      )}
    </>
  )
}

export default ContractTable

export const useResetContracts = ({
  isLoadingContracts: isLoadingContractsAtomExt,
  prevContractsLoadQueryParams: prevContractsLoadQueryParamsAtomExt,
  contractsFilterParams: contractsFilterParamsAtomExt,
  contractsViewParams: contractsViewParamsAtomExt,
  contractsQueryParams: contractsQueryParamsAtomExt,
  contractsColumns: contractsColumnsAtomExt,
  contractsTableData: contractsTableDataAtomExt,
}) => {
  const dispatch = useDispatch()
  const clearContracts = useClearContracts(dispatch)
  const [
    setIsLoadingContracts,
    setPrevContractsLoadQueryParams,
    setContractsFilterParams,
    setContractsViewParams,
    setContractsQueryParams,
    setContractsColumns,
    setContractsTableData,
  ] = [
    useSetAtom(isLoadingContractsAtom),
    useSetAtom(prevContractsLoadQueryParamsAtom),
    useSetAtom(contractsFilterParamsAtom),
    useSetAtom(contractsViewParamsAtom),
    useSetAtom(contractsQueryParamsAtom),
    useSetAtom(contractsColumnsAtom),
    useSetAtom(contractsTableDataAtom),
  ]

  return () => {
    clearContracts()
    setIsLoadingContracts(isLoadingContractsAtomExt ?? RESET)
    setPrevContractsLoadQueryParams(
      prevContractsLoadQueryParamsAtomExt ?? RESET
    )
    setContractsFilterParams(contractsFilterParamsAtomExt ?? RESET)
    setContractsViewParams(contractsViewParamsAtomExt ?? RESET)
    setContractsQueryParams(contractsQueryParamsAtomExt ?? RESET)
    setContractsColumns(contractsColumnsAtomExt ?? RESET)
    setContractsTableData(contractsTableDataAtomExt ?? RESET)
  }
}
