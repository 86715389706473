import {
  CREATE_SR_DOCUMENT_STATUS,
  DELETE_SR_DOCUMENT_STATUS,
  ERROR_SR_DOCUMENT_STATUS,
  SET_SR_DOCUMENT_STATUSES,
  SET_SR_DOCUMENT_STATUS_OPTIONS,
  TOGGLE_SR_DOCUMENT_STATUS,
  UNSET_ERROR_SR_DOCUMENT_STATUS,
  UNSET_SR_DOCUMENT_STATUS_ACTION,
  UNSET_SR_DOCUMENT_STATUS_OPTIONS,
  UPDATE_SR_DOCUMENT_STATUS,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'
import { showAvailable } from 'utils/commonUtils'
import { selectObject } from 'utils/formUtils'

const INITIAL_STATE = {
  lastAction: null,
  statusesAvailable: {},
  statusOptions: [],
  statusErrors: {},
}

const srDocumentStatusesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SR_DOCUMENT_STATUSES:
      return {
        ...state,
        lastAction: 'fetch',
        statusesAvailable: _.mapKeys(action.payload, 'id'),
      }
    case SET_SR_DOCUMENT_STATUS_OPTIONS:
      const options = selectObject(
        showAvailable(action.payload),
        action.currentSrDocumentStatus ? action.currentSrDocumentStatus.id : 0,
        action.noDefaultOptions ? false : true,
        'All Statuses'
      )
      return { ...state, statusOptions: options }
    case UNSET_SR_DOCUMENT_STATUS_OPTIONS:
      if (state.statusOptions) {
        return { ...state, statusOptions: [] }
      }
      return state
    case UNSET_SR_DOCUMENT_STATUS_ACTION:
      return { ...state, lastAction: null }
    case CREATE_SR_DOCUMENT_STATUS:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        statusesAvailable: {
          ...state.statusesAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_SR_DOCUMENT_STATUS:
      const updatedItem = (state.statusesAvailable[
        action.currentSrDocumentStatus.id
      ] = action.currentSrDocumentStatus)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_SR_DOCUMENT_STATUS:
      return {
        ...state,
        statusErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_SR_DOCUMENT_STATUS:
      return {
        ...state,
        statusErrors: {},
      }
    case TOGGLE_SR_DOCUMENT_STATUS:
      const toggledItem = (state.statusesAvailable[
        action.currentSrDocumentStatus.id
      ] = action.currentSrDocumentStatus)
      toggledItem.is_available = action.payload.toggle
      return {
        ...state,
        lastAction: action.payload.toggle ? 'enable' : 'disable',
        toggledItem,
      }
    case DELETE_SR_DOCUMENT_STATUS:
      const updated = state.statusesAvailable
      _.unset(updated, `${action.currentSrDocumentStatus.id}`)
      return { ...state, lastAction: 'delete', statusesAvailable: updated }
    default:
      return state
  }
}

export default srDocumentStatusesReducer
