import restApi from "apis/restApi";
import {
  SET_CONTRACT_TYPES,
  SET_CONTRACT_TYPE_OPTIONS,
  UNSET_CONTRACT_TYPE_OPTIONS,
  CREATE_CONTRACT_TYPE,
  DELETE_CONTRACT_TYPE,
  UPDATE_CONTRACT_TYPE,
  TOGGLE_CONTRACT_TYPE,
  ERROR_CONTRACT_TYPE,
  UNSET_CONTRACT_TYPE_ACTION,
  UNSET_ERROR_CONTRACT_TYPE
} from "actions/types";
import { fetchToken } from "utils/authUtils";

const contractTypeEndPoint = "/contracts/type";

// Passing data back to reducers
const setContractType = (obj, type, contractType, noDefaultOptions = false) => {
  const action = { type: type, payload: obj };
  if (contractType) action.currentContractType = contractType;
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions;
  return action;
};

// Non-API calls
export const clearContractTypeOptions = () => async dispatch => {
  dispatch(setContractType({}, UNSET_CONTRACT_TYPE_OPTIONS));
};
export const clearContractTypeAction = () => async dispatch => {
  dispatch(setContractType({}, UNSET_CONTRACT_TYPE_ACTION));
};
export const clearContractTypeErrors = () => async dispatch => {
  dispatch(setContractType({}, UNSET_ERROR_CONTRACT_TYPE));
};

// API calls
export const fetchContractTypes = (
  results,
  contractType,
  noDefaultOptions = false
) => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(contractTypeEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    if (results === "list") {
      dispatch(setContractType(res.data, SET_CONTRACT_TYPES));
    } else if (results === "form") {
      dispatch(
        setContractType(
          res.data,
          SET_CONTRACT_TYPE_OPTIONS,
          contractType,
          noDefaultOptions
        )
      );
    }
  }
};

export const createContractType = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContractType(res.data, CREATE_CONTRACT_TYPE));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setContractType(error, ERROR_CONTRACT_TYPE));
    }
  }
};

export const deleteContractType = type => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(contractTypeEndPoint, {
      data: { id: type.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setContractType(res.data, DELETE_CONTRACT_TYPE, type));
  }
};

export const updateContractType = (params, type) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(contractTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setContractType(res.data, UPDATE_CONTRACT_TYPE, type));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setContractType(error, ERROR_CONTRACT_TYPE));
    }
  }
};

export const toggleContractType = (type, toggle) => async dispatch => {
  const toggleEndPoint = `${contractTypeEndPoint}/${
    toggle ? "enable" : "disable"
  }`;
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: type.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(
      setContractType({ res, toggle: toggle }, TOGGLE_CONTRACT_TYPE, type)
    );
  }
};
