import {
  SET_SR_DOCUMENT_TYPES,
  SET_SR_DOCUMENT_TYPE_OPTIONS,
  UNSET_SR_DOCUMENT_TYPE_OPTIONS,
  CREATE_SR_DOCUMENT_TYPE,
  DELETE_SR_DOCUMENT_TYPE,
  UPDATE_SR_DOCUMENT_TYPE,
  TOGGLE_SR_DOCUMENT_TYPE,
  ERROR_SR_DOCUMENT_TYPE,
  UNSET_SR_DOCUMENT_TYPE_ACTION,
  UNSET_ERROR_SR_DOCUMENT_TYPE,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentTypeEndPoint = '/sr-documents/type'

// Passing data back to reducers
const setSrDocumentType = (
  obj,
  type,
  srDocumentType,
  noDefaultOptions = false
) => {
  const action = { type: type, payload: obj }
  if (srDocumentType) action.currentSrDocumentType = srDocumentType
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions
  return action
}

// Non-API calls
export const clearSrDocumentTypeOptions = () => async (dispatch) => {
  dispatch(setSrDocumentType({}, UNSET_SR_DOCUMENT_TYPE_OPTIONS))
}
export const clearSrDocumentTypeAction = () => async (dispatch) => {
  dispatch(setSrDocumentType({}, UNSET_SR_DOCUMENT_TYPE_ACTION))
}
export const clearSrDocumentTypeErrors = () => async (dispatch) => {
  dispatch(setSrDocumentType({}, UNSET_ERROR_SR_DOCUMENT_TYPE))
}

// API calls
export const fetchSrDocumentTypes =
  (results, srDocumentType, noDefaultOptions = false) =>
  async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(srDocumentTypeEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setSrDocumentType(res.data, SET_SR_DOCUMENT_TYPES))
      } else if (results === 'form') {
        dispatch(
          setSrDocumentType(
            res.data,
            SET_SR_DOCUMENT_TYPE_OPTIONS,
            srDocumentType,
            noDefaultOptions
          )
        )
      }
    }
  }

export const createSrDocumentType = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentType(res.data, CREATE_SR_DOCUMENT_TYPE))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentType(error, ERROR_SR_DOCUMENT_TYPE))
    }
  }
}

export const deleteSrDocumentType = (type) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentTypeEndPoint, {
      data: { id: type.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setSrDocumentType(res.data, DELETE_SR_DOCUMENT_TYPE, type))
  }
}

export const updateSrDocumentType = (params, type) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(srDocumentTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentType(res.data, UPDATE_SR_DOCUMENT_TYPE, type))
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setSrDocumentType(error, ERROR_SR_DOCUMENT_TYPE))
    }
  }
}

export const toggleSrDocumentType = (type, toggle) => async (dispatch) => {
  const toggleEndPoint = `${srDocumentTypeEndPoint}/${
    toggle ? 'enable' : 'disable'
  }`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: type.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentType({ res, toggle: toggle }, TOGGLE_SR_DOCUMENT_TYPE, type)
    )
  }
}
