import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchContractTypes } from "actions/contracts/contractTypeActions";
import { fetchContractStatuses } from "actions/contracts/contractStatusActions";
import { fetchContractLocations } from "actions/contracts/contractLocationActions";
import { fetchStudies } from "actions/studies/studyActions";

import {
  clearInvoiceMilestoneErrors,
  createInvoiceMilestone,
  clearInvoiceMilestoneAction
} from "actions/invoiceMilestones/invoiceMilestoneActions";
import InvoiceMilestoneForm from "components/admin/invoiceMilestones/InvoiceMilestoneForm";

const InvoiceMilestoneCreate = ({
  formId,
  params,
  setParams,
  closeModal,
  setQueryParams,
  studyOptions
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch Contract Types, Status, Locations
    dispatch(fetchContractTypes("form", null, false));
    dispatch(fetchContractStatuses("form", null, false));
    dispatch(fetchContractLocations("form", null, false));
    return () => {
      // Cleanup error, actions, get updated milestone
      dispatch(clearInvoiceMilestoneErrors());
      dispatch(clearInvoiceMilestoneAction());
    };
  }, [dispatch]);

  const { lastAction, milestoneErrors } = useSelector(
    state => state.invoiceMilestones
  );

  const typeOptions = useSelector(state => state.contractTypes.typeOptions);

  const statusOptions = useSelector(
    state => state.contractStatuses.statusOptions
  );

  const locationOptions = useSelector(
    state => state.contractLocations.locationOptions
  );

  // watch for create success
  useEffect(() => {
    if (lastAction === "create") {
      closeModal();
    }
  }, [lastAction, closeModal]);

  // set initial form params
  useEffect(() => {
    if (_.isEmpty(params)) {
      setParams({
        contract_type_id: "",
        contract_status_id: "",
        contract_location_id: "",
        study_id: "",
        name: "",
        percent_complete: ""
      });
    }
  }, [params, setParams]);

  const handleSubmit = (e, createParams) => {
    e.preventDefault();

    const milestoneParams = new FormData();
    milestoneParams.append("contract_type_id", createParams.contract_type_id);
    milestoneParams.append(
      "contract_location_id",
      createParams.contract_location_id
    );
    milestoneParams.append(
      "contract_status_id",
      createParams.contract_status_id
    );
    milestoneParams.append("study_id", createParams.study_id);
    setQueryParams(createParams.study_id);

    milestoneParams.append("name", createParams.name);
    milestoneParams.append("percent_complete", createParams.percent_complete);

    dispatch(createInvoiceMilestone(milestoneParams));
  };

  if (_.size(params)) {
    return (
      <form
        id={formId}
        onSubmit={e => handleSubmit(e, params)}
        className="form container"
        noValidate
      >
        <InvoiceMilestoneForm
          params={params}
          setParams={setParams}
          typeOptions={typeOptions}
          statusOptions={statusOptions}
          locationOptions={locationOptions}
          studyOptions={studyOptions}
          setQueryParams={setQueryParams}
          errors={milestoneErrors}
        />
      </form>
    );
  }
  return "";
};

export default InvoiceMilestoneCreate;
