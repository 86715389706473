import SrDocumentDelete from './SrDocumentDelete'
import SrDocumentForm from './forms/SrDocumentForm'
import {
  fetchSrDocuments,
  updateSrDocument,
} from 'actions/srDocuments/srDocumentActions'
import { fetchSrDocumentStates } from 'actions/srDocuments/srDocumentStateActions'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { findSelected, selectValueSplit } from 'utils/formUtils'

const SrDocumentUpdate = ({
  match: {
    params: { id: srDocId },
  },
}) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { is_admin } = user ?? {}
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { srDocumentStates } = useSelector((state) => state)
  const { srDocumentsAvailable, srDocumentErrors } = useSelector(
    (state) => state.srDocuments
  )

  //// LOCAL STATE.
  const [currentSrDocument, setCurrentSrDocument] = useState({})
  const [customProperties, setCustomProperties] = useState({})

  //// LIFECYCLE HELPERS.

  //// MEMOS.
  const handleSubmission = useMemo(
    () => buildHandleSubmission(currentSrDocument, customProperties, dispatch),
    [currentSrDocument, customProperties, dispatch]
  )

  //// EFFECTS.
  useEffect(() => {
    if (_.isEmpty(srDocumentsAvailable)) {
      dispatch(fetchSrDocuments())
    }
    if (_.isEmpty(currentSrDocument)) {
      setCurrentSrDocument(srDocumentsAvailable[srDocId])
    }
    if (_.size(currentSrDocument)) {
      dispatch(fetchSrDocumentStates(currentSrDocument))
    }
  }, [
    dispatch,
    currentSrDocument,
    setCurrentSrDocument,
    srDocumentsAvailable,
    srDocId,
  ])

  //// RENDER VARS & HELPERS.
  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Site Readiness Document')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  //// RENDER.
  return !currentSrDocument || !user || _.isEmpty(currentSrDocument) ? (
    ''
  ) : (
    <Section
      title={`Site Readiness Document #${currentSrDocument.id}`}
      description="Change selections to edit the site readiness document."
      menu={{
        order: 2,
        show: true,
        linkItems: [
          {
            name: 'View',
            url: `/sr-documents/${currentSrDocument.id}`,
            exact: true,
          },
          {
            name: 'Edit',
            url: `/sr-documents/${currentSrDocument.id}/edit`,
            exact: true,
          },
          ...(!is_admin
            ? []
            : [
                {
                  name: 'Delete',
                  url: `/sr-documents/${currentSrDocument.id}/remove`,
                  exact: true,
                  additionalProps: {
                    onClick: (e) => toggleModal(e, 'delete', currentSrDocument),
                    className: 'text-danger',
                  },
                },
              ]),
        ],
      }}
      breadcrumbs
      footer={[
        {
          text: 'Update',
          color: 'primary',
          additionalProps: {
            form: 'form_sr_document',
            type: 'submit',
          },
        },
      ]}
    >
      <SrDocumentForm
        srDocument={currentSrDocument}
        states={srDocumentStates}
        submit={handleSubmission}
        errors={srDocumentErrors}
        customProperties={customProperties}
        setCustomProperties={setCustomProperties}
      />
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={true}
        actionButtons={[
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ]}
      >
        <SrDocumentDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      </Modal>
    </Section>
  )
}

export default SrDocumentUpdate

const buildHandleSubmission =
  (currentSrDocument, customProperties, dispatch) =>
  (e, params, options, states) => {
    e.preventDefault()

    const studySelected = findSelected(options.study)
    const typeSelected = findSelected(options.srDocumentType)
    const subtypeSelected = findSelected(options.srDocumentSubtype)
    const stateSelected = findSelected(states)
    const currencySelected = findSelected(options.currency)
    const contactSelected = findSelected(options.primaryInvestigator)
    const siteSelected = findSelected(options.primarySite)
    const updatedParams = _.mapValues(params, (prop, key) => {
      if (currentSrDocument[key] !== prop && prop !== undefined) return prop
    })
    const srDocumentParams = _.omitBy(updatedParams, _.isNil) // srDocumentParams is what gets sent to the API; had to fix by switching from _.pickBy
    srDocumentParams.id = currentSrDocument.id

    const updatedSrDocument = _.mergeWith(currentSrDocument, srDocumentParams)

    // study settings
    if (currentSrDocument.study.id !== studySelected[0].id) {
      srDocumentParams.study_id = studySelected[0].id
      updatedSrDocument.study = {
        id: studySelected[0].id,
        name: studySelected[0].value,
      }
    }

    const isSendingSubtype =
      currentSrDocument.sr_document_subtype_id !== subtypeSelected?.[0]?.id &&
      subtypeSelected?.[0]?.id

    const isSendingType =
      (currentSrDocument.sr_document_type.id !== typeSelected?.[0]?.id ||
        isSendingSubtype) &&
      typeSelected?.[0]?.id

    // type settings
    if (isSendingType) {
      if (typeSelected?.[0]?.id) {
        srDocumentParams.sr_document_type_id = typeSelected?.[0]?.id
        updatedSrDocument.sr_document_type = {
          id: typeSelected[0].id,
          name: typeSelected[0].value,
        }
      } else {
        srDocumentParams.sr_document_type_id = ''
      }
    }

    //subtype settings
    if (isSendingSubtype) {
      srDocumentParams.sr_document_subtype_id = subtypeSelected[0].id
    }
    if (subtypeSelected.length > 0) {
      updatedSrDocument.sr_document_subtype = {
        id: subtypeSelected[0].id,
        name:
          subtypeSelected[0].value !== 'None' ? subtypeSelected[0].value : '',
      }
    }

    // state settings
    if (Number(stateSelected[0].value) !== currentSrDocument.current_state) {
      srDocumentParams.current_state = Number(stateSelected[0].value)
    }
    if (stateSelected.length > 0) {
      updatedSrDocument.current_state = Number(stateSelected[0].value)
      updatedSrDocument.current_state_text = stateSelected[0].text
    }

    // currency settings
    const isBudget =
      srDocumentParams.is_budget === undefined
        ? currentSrDocument.is_budget
        : srDocumentParams.is_budget
    if (
      isBudget &&
      currencySelected.length &&
      currentSrDocument.currency_id !== currencySelected[0].id
    ) {
      srDocumentParams.currency_id = currencySelected[0].id
    }
    if (currencySelected.length > 0) {
      const splitCurrencyValue = selectValueSplit(currencySelected[0].value)
      updatedSrDocument.currency = {
        id: currencySelected[0].id,
        name: splitCurrencyValue[0],
        code: splitCurrencyValue[1],
      }
    }

    // contact settings
    if (
      currentSrDocument.primary_investigator_id !== contactSelected?.[0]?.id
    ) {
      srDocumentParams.primary_investigator_id = contactSelected[0].id
    }
    if (contactSelected.length > 0) {
      const contactName = contactSelected[0].value.split(', ')
      updatedSrDocument.primary_investigator = {
        id: contactSelected[0].id,
        name:
          contactName[0] !== 'None'
            ? `${contactName[1]} ${contactName[0]}`
            : '',
      }
    }

    // site settings
    if (currentSrDocument.primary_site_id !== siteSelected?.[0]?.id) {
      srDocumentParams.primary_site_id = siteSelected[0].id
    }
    if (siteSelected.length > 0) {
      updatedSrDocument.primary_site = {
        id: siteSelected[0].id,
        name: siteSelected[0].value !== 'None' ? siteSelected[0].value : '',
      }
    }

    Object.assign(updatedSrDocument.custom_properties, customProperties)

    dispatch(updateSrDocument(srDocumentParams, updatedSrDocument))
  }
