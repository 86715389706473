import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from "hooks/useAuth.js";
import useModal from "hooks/useModal";
import { updateStudy } from "actions/studies/studyActions";

import Section from "components/common/Section";
import Modal from "components/common/Modal";
import StudyForm from "./StudyForm";
import StudyDelete from "./StudyDelete";

const StudyUpdate = ({ match }) => {
  const studyId = match.params.id;
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState
  } = useModal();
  const { studiesAvailable, studyErrors } = useSelector(state => state.studies);
  const [currentStudy, setCurrentStudy] = useState(studiesAvailable[studyId]);
  const [customProperties, setCustomProperties] = useState({});
  const auth = useAuth();
  const dispatch = useDispatch();

  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault();

    if (type === "delete") {
      setHeaderText("Delete Study");
      setFooter(true);
      setActions([
        {
          text: "Delete",
          color: "danger",
          additionalProps: {
            form: formId,
            type: "submit"
          }
        }
      ]);
      setSelected(item);
    }

    if (type) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };

  useEffect(() => {
    if (Object.keys(studiesAvailable).length) {
      setCurrentStudy(studiesAvailable[studyId]);
      setCustomProperties(studiesAvailable[studyId].custom_properties);
    }
  }, [studiesAvailable, studyId, setCurrentStudy, setCustomProperties]);

  const handleSubmission = (e, params) => {
    e.preventDefault();
    const updatedParams = _.mapValues(params, (prop, key) => {
      if (currentStudy[key] !== prop && prop !== undefined) return prop;
    });
    const studyParams = _.omitBy(updatedParams, _.isNil);
    studyParams.id = currentStudy.id;

    const updatedStudy = _.mergeWith(currentStudy, studyParams);

    Object.assign(updatedStudy.custom_properties, customProperties);

    dispatch(updateStudy(studyParams, updatedStudy));
  };

  if (currentStudy) {
    const sectionHeaderMenu = {
      order: 2,
      show: true,
      linkItems: [
        {
          name: "View",
          url: `/studies/${currentStudy.id}`,
          exact: true
        },
        {
          name: "Edit",
          url: `/studies/${currentStudy.id}/edit`,
          exact: true
        }
      ]
    };

    if (auth.user.is_admin) {
      sectionHeaderMenu.linkItems.push({
        name: "Delete",
        url: `/#!`,
        exact: true,
        additionalProps: {
          onClick: e => toggleModal(e, "delete", currentStudy),
          className: "text-danger"
        }
      });
    }

    return (
      <Section
        header={`Study #${currentStudy.id}`}
        description="Change inputs to edit study"
        breadcrumbs
        menu={sectionHeaderMenu}
        footer={[
          {
            text: "Update",
            color: "primary",
            additionalProps: {
              form: "form_study",
              type: "submit"
            }
          }
        ]}
      >
        <StudyForm
          submit={handleSubmission}
          study={currentStudy}
          errors={studyErrors}
          customProperties={customProperties}
          setCustomProperties={setCustomProperties}
        />
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          headerText={headerText}
          includeFooter={true}
          actionButtons={[
            {
              text: "Delete",
              color: "danger",
              additionalProps: {
                form: formId,
                type: "submit"
              }
            }
          ]}
        >
          <StudyDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        </Modal>
      </Section>
    );
  }

  return "";
};

export default StudyUpdate;
