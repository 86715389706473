import { fetchUsers, manageGuestUserStudies } from 'actions/users/userActions'
import TableSimple from 'components/common/tables/TableSimple'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import _ from 'lodash'
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
} from 'mdbreact'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const UserGuestStudiesManagement = ({ match }) => {
  const dispatch = useDispatch()
  const { usersAvailable } = useSelector((state) => state.users)
  const { studiesAvailable } = useSelector((state) => state.studies)

  const [userLoading, setUserLoading] = useState(true)
  const [studyChecksLoading, setStudyChecksLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(true)
  const [studiesChecked, setStudiesChecked] = useState({})
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '',
        field: 'check',
        minimal: 'check',
      },
      {
        label: '#',
        field: 'id',
        minimal: 'id',
      },
      {
        label: 'Sponsor Study Name',
        field: 'name',
        minimal: 'lg',
      },
      {
        label: 'Protocol',
        field: 'protocol',
      },
    ],
  })
  const user = useRef(usersAvailable[match.params.id])

  const handleStudyAssigmentSubmission = () => {
    const filterStudiesToChecked = _.reduce(
      studiesChecked,
      function (result, item, key) {
        if (item.checked) {
          result[key] = item
        }
        return result
      },
      {}
    )

    const selectedStudies = Object.keys(filterStudiesToChecked)
    dispatch(
      manageGuestUserStudies({ id: user.current.id, study_id: selectedStudies })
    )
  }

  // functions needed as dependencies
  const buildUserLoading = useCallback(setUserLoading, [])
  const buildTableData = useCallback(setTableData, [])
  const buildStudiesChecked = useCallback(setStudiesChecked, [])
  const buildTableLoading = useCallback(setTableLoading, [])
  const buildStudyChecksLoading = useCallback(setStudyChecksLoading, [])

  // check against study checks list to keep track of checked studies
  const handleStudyCheck = useCallback(
    (id, currentCheckList) => {
      let currentChecked = { ...currentCheckList }

      _.forEach(currentChecked, (checkbox, index) => {
        if (Number(index) === id) {
          checkbox.checked = !checkbox.checked
        }

        return checkbox
      })
      buildStudiesChecked(currentChecked)
    },
    [buildStudiesChecked]
  )

  // maps out studies to object studiesChecked for table checkboxes to compare against
  const createStudiesCheckedObject = useCallback(
    (studies) => {
      let studyCheckList = {}
      _.forEach(studies, (study) => {
        studyCheckList = {
          ...studyCheckList,
          [study.id]: {
            checked: user.current.studies.includes(study.id),
          },
        }
      })
      buildStudiesChecked(studyCheckList)
      buildStudyChecksLoading(false)
    },
    [buildStudiesChecked, buildStudyChecksLoading]
  )

  // maps out studies to object to inject into table's rows
  const setupTableRowData = useCallback(
    (studies) => {
      buildTableData((prev) => ({
        ...prev,
        rows: _.map(studies, (study) => ({
          check: (
            <MDBInput
              type="checkbox"
              value={study.id}
              checked={studiesChecked[study.id].checked}
              onClick={() => handleStudyCheck(study.id, studiesChecked)}
              id={`study-checkbox-${study.id}`}
            />
          ),
          id: study.id,
          name: study.name,
          protocol: study.protocol,
        })),
      }))
      buildTableLoading(false)
    },
    [buildTableData, buildTableLoading, studiesChecked, handleStudyCheck]
  )

  // dispatch fetch for all users if no current user, otherwise mark user as loaded
  useEffect(() => {
    if (!user.current) {
      dispatch(fetchUsers())
    } else if (user.current) {
      buildUserLoading(false)
    }
    buildStudyChecksLoading(true)
    buildTableLoading(true)
  }, [dispatch, buildUserLoading, buildTableLoading, buildStudyChecksLoading])

  // create current user from state, set to loaded
  useEffect(() => {
    if (!user.current && usersAvailable[match.params.id]) {
      user.current = usersAvailable[match.params.id]
      buildUserLoading(false)
    }
  }, [usersAvailable, match.params.id, buildUserLoading])

  useEffect(() => {
    if (_.size(studiesAvailable) && !userLoading) {
      if (studyChecksLoading) {
        createStudiesCheckedObject(studiesAvailable)
      } else {
        setupTableRowData(studiesAvailable)
      }
    }
  }, [
    userLoading,
    tableLoading,
    studiesAvailable,
    setupTableRowData,
    createStudiesCheckedObject,
    studyChecksLoading,
  ])

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    leftSide: (
      <Link to="/admin/users">
        <MDBIcon icon="fas fa-chevron-left" /> Users
      </Link>
    ),
    linkItems: [
      {
        name: 'View User',
        url: `/admin/users/${match.params.id}`,
        additionalProps: {
          active: true,
        },
      },
    ],
  }

  if (userLoading && tableLoading) return ''

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>
        <MDBRow between className="align-items-center">
          <MDBCol size="9">
            <p className="mb-0 text-muted">
              Studies available for{' '}
              <Link to={`/admin/users/${user.current.id}`}>
                {user.current.first_name} {user.current.last_name}
              </Link>
              .
            </p>
          </MDBCol>
          <MDBCol
            size="3"
            className="pt-2 pb-3 text-right position-sticky sticky-top"
          >
            <MDBBtn
              color="primary"
              size="sm"
              onClick={handleStudyAssigmentSubmission}
            >
              Save
            </MDBBtn>
          </MDBCol>
          <MDBCol size="12">
            <TableSimple tableData={tableData} authWidth />
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </>
  )
}

export default UserGuestStudiesManagement
