import React, { useState } from "react";
import { getMFAQR, clearMFAQR } from "utils/authUtils";

import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from "mdbreact";

const MFAImage = ({ setMFA }) => {
  const handleLogin = () => {
    setMFA(null);
    clearMFAQR();
  };

  return (
    <MDBRow center className="sign-in-page">
      <MDBCol sm="9" md="6" lg="5" xl="4" middle>
        <MDBCard>
          <MDBCardBody>
            <h3>Please use the below QR Code to setup MFA.</h3>
            <img src={getMFAQR()} />
            <MDBBtn
              onClick={handleLogin}
              className="mx-auto d-block"
              color="primary"
              type="button"
            >
              Login With OTP Code
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default MFAImage;
