/* eslint-disable import/no-anonymous-default-export */
import {
  APPEND_CONTRACTS,
  SET_CONTRACTS,
  SET_CONTRACT,
  UNSET_CONTRACTS,
  CREATE_CONTRACT,
  DELETE_CONTRACT,
  UPDATE_CONTRACT,
  ERROR_CONTRACT,
  UNSET_CONTRACT_ACTION,
  UNSET_ERROR_CONTRACT,
} from 'actions/types'
import _ from 'lodash'
import moment from 'moment'

const INITIAL_STATE = {
  lastAction: null,
  contractsAvailable: {},
  contractErrors: {},
  fetchTime: 0,
}

const contractReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTRACTS:
      if ((action?.fetchTime || 0) > state.fetchTime) {
        return {
          ...state,
          lastAction: 'fetch',
          contractsAvailable: _.mapKeys(action.payload, 'id'),
          fetchTime: action.fetchTime,
          isLoadingMore: action.isLoadingMore,
        }
      } else {
        return state
      }
    case APPEND_CONTRACTS:
      if (action.fetchTime === state.fetchTime) {
        return {
          ...state,
          lastAction: 'fetch',
          contractsAvailable: {
            ...state.contractsAvailable,
            ..._.mapKeys(action.payload, 'id'),
          },
          isLoadingMore: action.isLoadingMore,
        }
      } else {
        return state
      }
    case SET_CONTRACT:
      const contractData = Array.isArray(action.payload) && action.payload[0]
      if (contractData) {
        return {
          ...state,
          contractsAvailable: {
            ...state.contractsAvailable,
            [contractData.id]: contractData,
          },
          lastAction: 'fetch',
        }
      } else {
        return state
      }
    case UNSET_CONTRACTS:
      return INITIAL_STATE
    case CREATE_CONTRACT:
      const createdItem = action.payload.created_items[0]
      const { id } = createdItem
      const newState = {
        ...state,
        lastAction: 'create',
        contractsAvailable: {
          ...state.contractsAvailable,
          [id]: createdItem,
        },
      }
      return newState
    case UPDATE_CONTRACT:
      const updatedItem = (state.contractsAvailable[action.currentContract.id] =
        action.currentContract)
      updatedItem.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      return {
        ...state,
        lastAction: 'edit',
        updatedItem,
      }
    case ERROR_CONTRACT:
      return {
        ...state,
        contractErrors: action.payload,
        lastAction: 'error',
      }
    case UNSET_ERROR_CONTRACT:
      return {
        ...state,
        contractErrors: {},
      }
    case UNSET_CONTRACT_ACTION:
      return { ...state, lastAction: null }
    case DELETE_CONTRACT:
      let updatedContracts = state.contractsAvailable
      _.unset(updatedContracts, `${action.currentContract.id}`)
      return {
        ...state,
        lastAction: 'delete',
        contractsAvailable: updatedContracts,
      }
    default:
      return state
  }
}

export default contractReducer
