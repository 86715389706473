import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  deleteStudyProperty,
  clearStudyPropertyAction
} from "actions/studies/studyPropertyActions";

const StudyPropertyDelete = ({ formId, closeModal, selected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearStudyPropertyAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteStudyProperty(selected));
    closeModal();
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this study property? This can not be
        undone.
      </div>
    </form>
  );
};

export default StudyPropertyDelete;
