import {
  SET_CONTRACT_ACTIVITIES,
  UNSET_CONTRACT_ACTIVITIES,
  CREATE_CONTRACT_ACTIVITY,
  DELETE_CONTRACT_ACTIVITY,
  UPDATE_CONTRACT_ACTIVITY,
  UNSET_CONTRACT_ACTIVITY_ACTION,
  ERROR_CONTRACT_ACTIVITY,
  UNSET_ERROR_CONTRACT_ACTIVITY,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractActivityEndPoint = '/contracts/activity'

// Passing data back to reducers
export const setContractActivity = (obj, type, contractActivity) => {
  const action = { type: type, payload: obj }
  if (contractActivity) action.currentContractActivity = contractActivity
  return action
}

// Non-API calls
export const clearContractActivities = () => async (dispatch) => {
  dispatch(setContractActivity({}, UNSET_CONTRACT_ACTIVITIES))
}
export const clearContractActivityAction = () => async (dispatch) => {
  dispatch(setContractActivity({}, UNSET_CONTRACT_ACTIVITY_ACTION))
}
export const clearContractActivityErrors = () => async (dispatch) => {
  dispatch(setContractActivity({}, UNSET_ERROR_CONTRACT_ACTIVITY))
}

// API calls
export const fetchContractActivities = (id) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(`${contractActivityEndPoint}/${id}`, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractActivity(res.data, SET_CONTRACT_ACTIVITIES))
  }
}

export const createContractActivity = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractActivityEndPoint, params, {
        headers: {
          Authorization: `Bearer ${fetchToken()}`,
          'Content-Type': `multipart/form-data; boundary=${params._boundary}`,
        },
      })
      dispatch(setContractActivity(res.data, CREATE_CONTRACT_ACTIVITY))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractActivity(error, ERROR_CONTRACT_ACTIVITY))
    }
  }
}

export const deleteContractActivity = (activity) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(contractActivityEndPoint, {
      data: { id: activity.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setContractActivity(res.data, DELETE_CONTRACT_ACTIVITY, activity))
  }
}

export const updateContractActivity =
  (params, activity) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractActivityEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setContractActivity(res.data, UPDATE_CONTRACT_ACTIVITY, activity)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractActivity(error, ERROR_CONTRACT_ACTIVITY))
      }
    }
  }
