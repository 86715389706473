import ContractDelete from './ContractDelete'
import ContractActivityList from './activity/ContractActivityList'
import ContractBudgetList from './budget/ContractBudgetList'
import ContractActivityChart from './charts/ContractActivityChart'
import ContractDocumentList from './documents/ContractDocumentList'
import { fetchContract } from 'actions/contracts/contractActions'
import { clearContractActivities } from 'actions/contracts/contractActivityActions'
import { clearContractDocuments } from 'actions/contracts/contractDocumentActions'
import { fetchContractPropertyOptions } from 'actions/contracts/contractPropertyActions'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import BadgeLabel from 'components/common/badges/BadgeLabel'
import BadgePill from 'components/common/badges/BadgePill'
import CustomPropertyDisplay from 'components/common/properties/CustomPropertyDisplay'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from 'mdbreact'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isUserNotReadOnlyForContracts } from 'utils/authUtils'
import { formatDateToLocal } from 'utils/commonUtils'

const ContractShow = ({
  match: {
    params: { id: contractId },
  },
}) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const contractBudgetRef = useRef()
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}

  //// GLOBAL STATE.
  const allContracts = useSelector(
    (state) => state.contracts.contractsAvailable
  )
  const { contractPropertyOptions } = useSelector(
    (state) => state.contractProperties
  )

  //// LOCAL STATE.
  const [currentContract, setCurrentContract] = useState(
    allContracts[contractId]
  )

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (Object.keys(allContracts).length) {
      setCurrentContract(allContracts[contractId])
    }
  }, [allContracts, contractId, setCurrentContract])

  useEffect(() => {
    dispatch(fetchContract(contractId))
    dispatch(fetchContractPropertyOptions())
  }, [dispatch, contractId])

  useEffect(() => {
    return () => {
      dispatch(clearContractActivities())
      dispatch(clearContractDocuments())
    }
  }, [dispatch])

  //// RENDER VARS & HELPERS.
  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Contract')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  return !currentContract ? (
    ''
  ) : (
    <>
      <Section
        title={`Contract #${currentContract.id}`}
        breadcrumbs
        menu={
          currentContract
            ? {
                order: 2,
                show: true,
                linkItems: [
                  ...(isUserNotReadOnlyForContracts(user_level)
                    ? [
                        {
                          name: 'View',
                          url: `/contracts/${currentContract.id}`,
                          exact: true,
                        },
                        {
                          name: 'Edit',
                          url: `/contracts/${currentContract.id}/edit`,
                          exact: true,
                        },
                      ]
                    : []),
                  ...(is_admin
                    ? [
                        {
                          name: 'Delete',
                          url: `/contracts/${currentContract.id}/remove`,
                          exact: true,
                          additionalProps: {
                            onClick: (e) =>
                              toggleModal(e, 'delete', currentContract),
                            className: 'text-danger',
                          },
                        },
                      ]
                    : []),
                ],
                leftSide: (
                  <div className="contract-badges d-flex">
                    <BadgePill themeColor="secondary" lightenColor={0.1}>
                      {currentContract.current_state_text}
                    </BadgePill>
                    <BadgePill
                      themeColor="secondary"
                      lightenColor={0.2}
                      additionalProps={
                        currentContract.is_budget
                          ? {
                              onClick: () => {
                                if (currentContract.is_budget) {
                                  window.scrollTo({
                                    behavior: 'smooth',
                                    top: contractBudgetRef.current.offsetTop,
                                  })
                                }
                              },
                            }
                          : null
                      }
                    >
                      {currentContract.is_budget ? 'Has Budget' : 'No Budget'}
                    </BadgePill>
                  </div>
                ),
              }
            : void 0
        }
      >
        <MDBRow>
          <MDBCol
            lg="6"
            className="d-flex flex-column align-items-start justify-content-start"
          >
            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Sponsor Study Name</BadgeLabel>
              {currentContract.study.name}
            </div>

            <hr className="mt-3 mb-3 w-100" />

            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Contract Type</BadgeLabel>
              {currentContract.contract_type.name}
            </div>

            {currentContract.contract_subtype &&
            currentContract.contract_subtype.name &&
            currentContract.contract_subtype.name !== 'None' ? (
              <>
                <div className="h5 d-flex flex-column align-items-start mt-3 font-weight-normal">
                  <BadgeLabel>Contract Subtype</BadgeLabel>
                  {currentContract.contract_subtype.name}
                </div>
              </>
            ) : (
              void 0
            )}

            <hr className="mt-3 mb-3 w-100" />

            {currentContract.primary_investigator.id ||
            currentContract.primary_site.id ? (
              <MDBCard className="card-panel w-100 mb-3">
                <MDBCardBody className="rgba-grey-slight dark-grey-text">
                  <MDBCardTitle>Primary Contacts</MDBCardTitle>
                  <MDBCardText tag="div">
                    {currentContract.primary_investigator.id ? (
                      <div className="d-flex flex-column align-items-start">
                        <BadgeLabel>Primary Investigator</BadgeLabel>
                        {currentContract.primary_investigator.name}
                      </div>
                    ) : (
                      ''
                    )}
                    {currentContract.primary_site.id ? (
                      <div
                        className={`d-flex flex-column align-items-start${
                          currentContract.primary_investigator.id ? ' mt-3' : ''
                        }`}
                      >
                        <BadgeLabel>Primary Site</BadgeLabel>
                        {currentContract.primary_site.name}
                      </div>
                    ) : (
                      ''
                    )}
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            ) : (
              void 0
            )}

            <div className="flex-grow-1 d-flex align-items-end">
              <strong className="mr-1">Created on</strong>
              {formatDateToLocal(currentContract.created_at, 'LLLL')}
            </div>
          </MDBCol>

          <MDBCol lg="6">
            <ContractActivityChart contractId={contractId} />
            {contractPropertyOptions?.custom_properties?.length ? (
              <CustomPropertyDisplay
                propertyOptions={contractPropertyOptions.custom_properties}
                customProperties={currentContract.custom_properties}
              />
            ) : (
              ''
            )}
          </MDBCol>
        </MDBRow>
      </Section>

      <ContractActivityList contract={currentContract} />

      <ContractDocumentList contract={currentContract} />

      {currentContract.is_budget ? (
        <div ref={contractBudgetRef}>
          <ContractBudgetList contract={currentContract} />
        </div>
      ) : (
        void 0
      )}

      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={true}
        actionButtons={[
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ]}
      >
        <ContractDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      </Modal>
    </>
  )
}

export default ContractShow
