/** @jsx jsx */
import { MDBCardHeader, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import { jsx, css } from "@emotion/core";
import { useTheme } from "emotion-theming";

const SectionHeaderMenu = ({ menuData, description }) => {
  const { linkItems, show, order, leftSide } = menuData;

  const theme = useTheme();
  const menuHeaderStyle = css`
    .md-form.md-outline {
      margin-top: 0;
      margin-bottom: 0;
      .select-dropdown {
        margin-bottom: 0;
      }
      label.active {
        background-color: ${theme.colors.lightGray};
      }
    }
  `;

  const renderSideInfo = () => {
    if (leftSide) {
      return leftSide;
    } else if (description) {
      return <div className="text-muted">{description}</div>;
    }
    return "";
  };

  const renderMenuItems = () => {
    if (show) {
      return linkItems.map((item, index) => (
        <MDBNavItem key={`section-header-menu-item-${index}`}>
          <MDBNavLink
            exact={item.exact}
            to={item.url ? item.url : false}
            {...item.additionalProps}
          >
            {item.name}
          </MDBNavLink>
        </MDBNavItem>
      ));
    }
    return "";
  };

  return (
    <MDBCardHeader
      css={menuHeaderStyle}
      className="d-flex justify-content-between align-items-center"
    >
      {renderSideInfo()}
      <MDBNav tag="div" header color="primary" className={`order-${order}`}>
        {renderMenuItems()}
      </MDBNav>
    </MDBCardHeader>
  );
};

export default SectionHeaderMenu;
