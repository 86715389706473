import ContractStatusCreate from './ContractStatusCreate'
import ContractStatusDelete from './ContractStatusDelete'
import ContractStatusUpdate from './ContractStatusUpdate'
import {
  fetchContractStatuses,
  toggleContractStatus,
} from 'actions/contracts/contractStatusActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Table from 'components/common/tables/Table'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBCardBody } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const NEW_HEADER = 'New Contract Status'
const EDIT_HEADER = 'Edit Contract Status'
const DELETE_HEADER = 'Delete Contract Status'

const ContractStatusList = () => {
  //// HOOKS.
  const auth = useAuth()
  const dispatch = useDispatch()
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { statusesAvailable, lastAction } = useSelector(
    (state) => state.contractStatuses
  )

  //// LOCAL STATE.
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'desc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'desc',
        minimal: 'lg',
      },
      {
        label: 'Date Created (UTC)',
        field: 'created_at',
        date: true,
        sort: 'desc',
      },
      {
        label: 'Available',
        field: 'is_available',
        toggleControl: true,
        sort: 'disabled',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractStatuses('list'))
  }, [dispatch])

  //// RENDER VARS & HELPERS.
  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      switch (type) {
        case 'new':
          setHeaderText(NEW_HEADER)
          setFooter(true)
          setActions([
            {
              text: 'Submit',
              color: 'primary',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ])
          setModalState(true)
          break
        case 'edit':
          setHeaderText(EDIT_HEADER)
          setFooter(true)
          setActions([
            {
              text: 'Update',
              color: 'primary',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ])
          setSelected(item)
          setModalState(true)
          break
        case 'delete':
          setHeaderText(DELETE_HEADER)
          setFooter(true)
          setActions([
            {
              text: 'Delete',
              color: 'danger',
              additionalProps: {
                form: formId,
                type: 'submit',
              },
            },
          ])
          setSelected(item)
          setModalState(true)
          break
        default:
          setModalState(false)
          break
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  //// RENDER.
  return (
    <>
      <SectionHeaderMenu
        menuData={{
          order: 2,
          show: true,
          linkItems: [
            {
              name: 'Create',
              url: `/admin/contracts/statuses`,
              exact: true,
              additionalProps: {
                active: true,
                onClick: (e) => toggleModal(e, 'new'),
                className: 'btn-sm',
              },
            },
          ],
        }}
      />
      <MDBCardBody>
        {_.size(statusesAvailable) ? (
          <Table
            section="Status"
            tableData={tableData}
            setTableData={setTableData}
            rowData={statusesAvailable}
            sort={['id', 'desc']}
            toggleAvailability={(item, toggle) => {
              dispatch(toggleContractStatus(item, toggle))
            }}
            toggleModal={toggleModal}
            updatedState={lastAction}
            downloadableCSV
            editButton
            deleteButton
          />
        ) : _.isEmpty(statusesAvailable) ? (
          <EmptyList
            name="contract status"
            namePlural="contract statuses"
            userRole={auth?.user?.user_level}
            createElement={
              <span className="link" onClick={(e) => toggleModal(e, 'new')}>
                Create
              </span>
            }
          />
        ) : (
          ''
        )}
      </MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {headerText === NEW_HEADER ? (
          <ContractStatusCreate
            formId={formId}
            params={params}
            setParams={setParams}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === EDIT_HEADER ? (
          <ContractStatusUpdate
            formId={formId}
            params={params}
            setParams={setParams}
            selected={selected}
            setSelected={setSelected}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === DELETE_HEADER ? (
          <ContractStatusDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  )
}

export default ContractStatusList
