import Panel from '../common/panel/Panel'
import LogoLight from 'assets/images/logo_combomark_white_orange.png'
import { useAuth } from 'hooks/useAuth'
import useWindowSize from 'hooks/useWindowSize'
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBContainer,
} from 'mdbreact'
import React, { useState } from 'react'
import { getMFAQR, fetchOTPReq, setOTPReq } from 'utils/authUtils'

const LoginForm = ({ setMFA }) => {
  //// HOOKS.
  const auth = useAuth()
  const size = useWindowSize()
  const requireOTP = fetchOTPReq()

  //// GLOBAL STATE.

  //// LOCAL STATE.
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [errors, setErrors] = useState([])

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.

  //// RENDER VARS & HELPERS.
  const isLandscapeMobile = size.width > size.height && size.height < 768
  const logoSize = size.height >= 950 ? '256px' : '160px'

  //// RENDER.
  return (
    <MDBContainer
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      fluid
    >
      <MDBRow style={{ height: '100%' }}>
        <MDBCol />
        <MDBCol
          size="auto"
          style={{
            minWidth: '280px',
            width: '100%',
            maxWidth: '480px',
            margin: 'auto',
            paddingBottom: '48px',
            ...(isLandscapeMobile
              ? {
                  display: 'flex',
                }
              : {}),
          }}
        >
          <div
            style={{
              backgroundColor: '#2d3848',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: logoSize,
              height: logoSize,
              padding: '30px',
              ...(isLandscapeMobile
                ? { margin: 'auto 32px auto -128px' }
                : { margin: '0px auto 32px auto' }),
            }}
          >
            <img
              src={LogoLight}
              alt="epicenter portal"
              style={{ width: '100%' }}
            />
          </div>
          <MDBCard>
            <MDBCardBody>
              <MDBCardBody>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault()
                    try {
                      await auth.signin(email, password, otp)
                      const MFA = getMFAQR()
                      if (MFA) {
                        setMFA(MFA)
                      }
                    } catch (err) {
                      // If this is a MFA error, we need to set OTP required to be true
                      const errMsg = err?.response?.data?.message
                      if (errMsg && errMsg.includes('MFA')) {
                        setOTPReq(true)
                      }
                      setErrors([
                        'Login Failed ' +
                          (err?.response?.statusText
                            ? `: ${err.response.statusText}`
                            : '') +
                          (err?.response?.data?.message
                            ? `: ${err.response.data.message}`
                            : ''),
                      ])
                    }
                  }}
                >
                  <p className="h3 text-center mb-4">Login</p>
                  <div className="grey-text">
                    <MDBInput
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      value={email}
                      label="Email"
                      icon="envelope"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                    />
                    <MDBInput
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      value={password}
                      label="Password"
                      icon="lock"
                      group
                      type="password"
                      validate
                    />
                    {requireOTP && (
                      <MDBInput
                        onChange={(e) => {
                          setOtp(e.target.value)
                        }}
                        value={otp}
                        label="OTP Code"
                        icon="clock"
                        group
                        type="text"
                        validate
                      />
                    )}
                  </div>
                  <div className="text-center">
                    <MDBBtn color="primary" type="submit">
                      Login
                    </MDBBtn>
                  </div>
                </form>
                <Panel
                  messages={errors}
                  backgroundColor="red"
                  textColor="white"
                />
              </MDBCardBody>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol />
      </MDBRow>
    </MDBContainer>
  )
}

export default LoginForm
