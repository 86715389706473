/** @jsx jsx */
import _ from "lodash";
import { MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import { css, jsx } from "@emotion/core";

const panel = css`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .modal-body & {
    margin-top: 1.5rem;
  }
`;

const inlineMessage = css`
  display: inline-block;
  margin-left: 0.25rem;
  &:first-of-type {
    margin-left: 0;
  }
`;

const Panel = ({ messages, backgroundColor, textColor }) => {
  const hasErrors = _.size(messages);
  if (hasErrors) {
    return (
      <MDBCol md="12">
        <MDBCard
          color={`${backgroundColor} lighten-3`}
          text={textColor}
          className="text-center"
          css={panel}
        >
          <MDBCardBody>
            {messages.map(message => {
              return (
                <span key={message} css={inlineMessage}>
                  {message}
                </span>
              );
            })}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    );
  }
  return "";
};

export default Panel;
