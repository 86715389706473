import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  deleteInvoiceMilestone,
  clearInvoiceMilestoneAction
} from "actions/invoiceMilestones/invoiceMilestoneActions";

const InvoiceMilestoneDelete = ({ formId, closeModal, selected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearInvoiceMilestoneAction());
    };
  }, [dispatch]);

  const handleDelete = e => {
    e.preventDefault();
    dispatch(deleteInvoiceMilestone(selected));
    closeModal();
  };

  return (
    <form
      id={formId}
      onSubmit={e => handleDelete(e)}
      className="form container"
    >
      <div>
        Are you sure you want to delete this invoice milestone? This can not be
        undone.
      </div>
    </form>
  );
};

export default InvoiceMilestoneDelete;
