import React, { useEffect, useState, useCallback } from "react";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";

import ErrorPanel from "components/common/panel/ErrorPanel";
import Select from "components/common/forms/Select";

import { inputErrorClass } from "utils/formUtils";

const AdminForm = ({
  params,
  setParams,
  propertyTypes,
  selected,
  setSelected,
  errors
}) => {
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const buildPropertyTypeOptions = useCallback(setPropertyTypeOptions, []);

  useEffect(() => {
    if (propertyTypes?.length) {
      const newOptions = propertyTypes.map(type => {
        return { value: type.name, id: type.id };
      });
      buildPropertyTypeOptions(newOptions);
    }
  }, [propertyTypes, buildPropertyTypeOptions]);

  const handleName = value => {
    setParams({ ...params, name: value });
    if (selected) setSelected({ ...selected, name: value });
  };

  const handlePropertyTypeSelection = v => {
    if (v !== "Choose A Property Type") {
      const typeSelection = propertyTypeOptions.filter(
        type => type.value === v
      );
      setParams({ ...params, property_type_id: typeSelection[0].id });
    }
  };

  const renderPropertyTypesSelect = () => {
    if (propertyTypeOptions?.length) {
      return (
        <MDBCol md="12">
          <Select
            id="select__property-types"
            options={propertyTypeOptions}
            getTextContent={handlePropertyTypeSelection}
            label="Custom Property Type"
            defaultOption="Choose A Property Type"
            required
            search
            errors={errors}
          />
        </MDBCol>
      );
    }
    return "";
  };

  const renderNameField = () => {
    if (params?.name?.length >= 0) {
      return (
        <MDBCol md="12">
          <MDBInput
            autoFocus
            value={params.name}
            getValue={handleName}
            id="name-input"
            label="Name"
            type="text"
            outline
            required
            containerClass="required-label"
            className={`input-select${inputErrorClass(errors, !params.name)}`}
          />
        </MDBCol>
      );
    }
    return "";
  };

  return (
    <MDBRow>
      {renderNameField()}
      {renderPropertyTypesSelect()}
      <ErrorPanel results={errors} />
    </MDBRow>
  );
};

export default AdminForm;
