import ContractDocumentCreate from './ContractDocumentCreate'
import ContractDocumentDelete from './ContractDocumentDelete'
import ContractDocumentUpdate from './ContractDocumentUpdate'
import { fetchContractDocuments } from 'actions/contracts/contractDocumentActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import Table from 'components/common/tables/Table'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBIcon } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isUserNotReadOnlyForContracts } from 'utils/authUtils'
import { requestContractDocument } from 'utils/downloadUtils'

const ContractDocumentList = ({ contract }) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { documentsAvailable, lastAction } = useSelector(
    (state) => state.contractDocuments
  )

  //// LOCAL STATE.
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: 'Date Stored (UTC)',
        field: 'created_at',
        date: true,
        sort: 'desc',
      },
      {
        label: 'Document',
        field: 'filename',
        sort: 'desc',
      },
      {
        label: 'Type',
        field: 'contract_document_type.name',
        sort: 'asc',
      },
      {
        label: 'Comment',
        field: 'comment',
        sort: 'asc',
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractDocuments({ contract_id: contract.id }))
  }, [dispatch, contract.id])

  //// RENDER VARS & HELPERS.
  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'new') {
        setHeaderText('New Document')
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      } else if (type === 'edit') {
        setHeaderText('Edit Document')
        setFooter(true)
        setActions([
          {
            text: 'Update',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      } else if (type === 'delete') {
        setHeaderText('Delete Document')
        setFooter(true)
        setActions([
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      }
      if (type) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  const fetchTableLink = useCallback(
    (type, item) => {
      switch (type) {
        case 'history':
          return `/contracts/${contract.id}/document-types/${item.contract_document_type.id}`
        default:
          return null
      }
    },
    [contract.id]
  )

  const handleDocumentDownload = useCallback((e, item) => {
    e.preventDefault()
    requestContractDocument(item.id, item.filename)
  }, [])

  const sectionHeaderMenu = {
    order: 2,
    show: contract.current_state === 0 ? true : false,
    linkItems: [
      {
        name: (
          <>
            <MDBIcon icon="fas fa-plus" /> Add Document
          </>
        ),
        url: `/contracts/${contract.id}/new/document`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: (e) => toggleModal(e, 'new'),
          className: 'btn-sm',
        },
      },
    ],
  }

  //// RENDER.
  return (
    <>
      <Section
        header="Contract Documents"
        description={
          contract.current_state !== 0
            ? `Contract document cannot be updated or added when the contract is ${contract.current_state_text.toLowerCase()}`
            : 'View, add, and edit contract document'
        }
        menu={
          isUserNotReadOnlyForContracts(user_level) ? sectionHeaderMenu : null
        }
      >
        {_.size(documentsAvailable) ? (
          <Table
            section="Contract Document"
            tableData={tableData}
            setTableData={setTableData}
            rowData={documentsAvailable}
            sort={['created_at', 'desc']}
            fetchTableLink={fetchTableLink}
            toggleModal={toggleModal}
            downloadFile={handleDocumentDownload}
            updatedState={lastAction}
            downloadableCSV
            downloadButton
            showHistoryButton
            editButton={
              contract.current_state === 0 &&
              isUserNotReadOnlyForContracts(user_level)
            }
            deleteButton={is_admin}
          />
        ) : _.isEmpty(documentsAvailable) && contract.current_state === 0 ? (
          <EmptyList
            name="document"
            namePlural="documents"
            userRole={user_level}
            createElement={
              <span className="link" onClick={(e) => toggleModal(e, 'new')}>
                Create
              </span>
            }
          />
        ) : _.isEmpty(documentsAvailable) ? (
          <p>No contract document were added to this contract while open.</p>
        ) : (
          void 0
        )}
      </Section>

      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {headerText === 'New Document' ? (
          <ContractDocumentCreate
            formId={formId}
            contractId={contract.id}
            params={params}
            setParams={setParams}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === 'Edit Document' ? (
          <ContractDocumentUpdate
            formId={formId}
            contractId={contract.id}
            params={params}
            setParams={setParams}
            selected={selected}
            setSelected={setSelected}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === 'Delete Document' ? (
          <ContractDocumentDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        ) : (
          void 0
        )}
      </Modal>
    </>
  )
}

export default ContractDocumentList
