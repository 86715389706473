import {
  SET_CONTRACT_DOCUMENT_TYPES,
  SET_CONTRACT_DOCUMENT_TYPE_OPTIONS,
  UNSET_CONTRACT_DOCUMENT_TYPE_OPTIONS,
  CREATE_CONTRACT_DOCUMENT_TYPE,
  DELETE_CONTRACT_DOCUMENT_TYPE,
  UPDATE_CONTRACT_DOCUMENT_TYPE,
  TOGGLE_CONTRACT_DOCUMENT_TYPE,
  ERROR_CONTRACT_DOCUMENT_TYPE,
  UNSET_CONTRACT_DOCUMENT_TYPE_ACTION,
  UNSET_ERROR_CONTRACT_DOCUMENT_TYPE,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractDocumentTypeEndPoint = '/documents/contract/type'

// Passing data back to reducers
const setContractDocumentType = (obj, type, contractDocumentType) => {
  const action = { type: type, payload: obj }
  if (contractDocumentType)
    action.currentContractDocumentType = contractDocumentType
  return action
}

// Non-API calls
export const clearContractDocumentTypeOptions = () => async (dispatch) => {
  dispatch(setContractDocumentType({}, UNSET_CONTRACT_DOCUMENT_TYPE_OPTIONS))
}
export const clearContractDocumentTypeAction = () => async (dispatch) => {
  dispatch(setContractDocumentType({}, UNSET_CONTRACT_DOCUMENT_TYPE_ACTION))
}
export const clearContractDocumentTypeErrors = () => async (dispatch) => {
  dispatch(setContractDocumentType({}, UNSET_ERROR_CONTRACT_DOCUMENT_TYPE))
}

// API calls
export const fetchContractDocumentTypes =
  (results, contractDocumentType) => async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(contractDocumentTypeEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setContractDocumentType(res.data, SET_CONTRACT_DOCUMENT_TYPES))
      } else if (results === 'form') {
        dispatch(
          setContractDocumentType(
            res.data,
            SET_CONTRACT_DOCUMENT_TYPE_OPTIONS,
            contractDocumentType
          )
        )
      }
    }
  }

export const createContractDocumentType = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(contractDocumentTypeEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractDocumentType(res.data, CREATE_CONTRACT_DOCUMENT_TYPE))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setContractDocumentType(error, ERROR_CONTRACT_DOCUMENT_TYPE))
    }
  }
}

export const deleteContractDocumentType =
  (documentType) => async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.delete(contractDocumentTypeEndPoint, {
        data: { id: documentType.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setContractDocumentType(
          res.data,
          DELETE_CONTRACT_DOCUMENT_TYPE,
          documentType
        )
      )
    }
  }

export const updateContractDocumentType =
  (params, documentType) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(contractDocumentTypeEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setContractDocumentType(
            res.data,
            UPDATE_CONTRACT_DOCUMENT_TYPE,
            documentType
          )
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setContractDocumentType(error, ERROR_CONTRACT_DOCUMENT_TYPE))
      }
    }
  }

export const toggleContractDocumentType =
  (documentType, toggle) => async (dispatch) => {
    const toggleEndPoint = `${contractDocumentTypeEndPoint}/${
      toggle ? 'enable' : 'disable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: documentType.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setContractDocumentType(
          { res, toggle: toggle },
          TOGGLE_CONTRACT_DOCUMENT_TYPE,
          documentType
        )
      )
    }
  }
