// import ContractBudgetList from './budget/ContractBudgetList' // may not be implementing budgets
import SrDocumentDelete from './SrDocumentDelete'
import SrDocumentActivityList from './activity/SrDocumentActivityList'
import SrDocumentActivityChart from './charts/SrDocumentActivityChart'
import SrDocumentDocList from './docs/SrDocumentDocList'
import { fetchSrDocument } from 'actions/srDocuments/srDocumentActions'
import { clearSrDocumentActivities } from 'actions/srDocuments/srDocumentActivityActions'
import { fetchSrDocumentPropertyOptions } from 'actions/srDocuments/srDocumentPropertyActions'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import BadgeLabel from 'components/common/badges/BadgeLabel'
import CustomPropertyDisplay from 'components/common/properties/CustomPropertyDisplay'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from 'mdbreact'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isUserNotReadOnlyForSiteReadiness } from 'utils/authUtils'
import { formatDateToLocal } from 'utils/commonUtils'

const SrDocumentShow = ({
  match: {
    params: { id: srdocId },
  },
}) => {
  //// HOOKS.
  const dispatch = useDispatch()
  // const contractBudgetRef = useRef() // may not be implementing budgets
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}

  //// GLOBAL STATE.
  const allSrDocs = useSelector(
    (state) => state.srDocuments.srDocumentsAvailable
  )
  const { srDocumentPropertyOptions } = useSelector(
    (state) => state.srDocumentProperties
  )

  //// LOCAL STATE.
  const [currentSrDocument, setCurrentSrDocument] = useState(allSrDocs[srdocId])

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    if (Object.keys(allSrDocs).length) {
      setCurrentSrDocument(allSrDocs[srdocId])
    }
  }, [allSrDocs, srdocId, setCurrentSrDocument])

  useEffect(() => {
    dispatch(fetchSrDocument(srdocId))
    dispatch(fetchSrDocumentPropertyOptions())
  }, [dispatch, srdocId])

  useEffect(() => {
    return () => {
      dispatch(clearSrDocumentActivities())
      // dispatch(clearContractDocuments()) // may not be implementing documents
    }
  }, [dispatch])

  //// RENDER VARS & HELPERS.
  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Site Readiness Document')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  return !currentSrDocument ? (
    ''
  ) : (
    <>
      <Section
        title={`Site Readiness Document #${currentSrDocument.id}`}
        breadcrumbs
        menu={
          currentSrDocument
            ? {
                order: 2,
                show: true,
                linkItems: [
                  ...(isUserNotReadOnlyForSiteReadiness(user_level)
                    ? [
                        {
                          name: 'View',
                          url: `/sr-documents/${currentSrDocument.id}`,
                          exact: true,
                        },
                        {
                          name: 'Edit',
                          url: `/sr-documents/${currentSrDocument.id}/edit`,
                          exact: true,
                        },
                      ]
                    : []),
                  ...(is_admin
                    ? [
                        {
                          name: 'Delete',
                          url: `/sr-documents/${currentSrDocument.id}/remove`,
                          exact: true,
                          additionalProps: {
                            onClick: (e) =>
                              toggleModal(e, 'delete', currentSrDocument),
                            className: 'text-danger',
                          },
                        },
                      ]
                    : []),
                ],
                /*
                budget is not being implemented for srdocs at this time.
                state is being hidden at the clients request.
                leftSide: (
                  <div className="sr-document-badges d-flex">
                    <BadgePill themeColor="secondary" lightenColor={0.1}>
                      {currentSrDocument.current_state_text}
                    </BadgePill>
                    <BadgePill
                      themeColor="secondary"
                      lightenColor={0.2}
                      additionalProps={
                        currentSrDoc.is_budget
                          ? {
                              onClick: () => {
                                if (currentSrDoc.is_budget) {
                                  window.scrollTo({
                                    behavior: 'smooth',
                                    top: contractBudgetRef.current.offsetTop,
                                  })
                                }
                              },
                            }
                          : null
                      }
                    >
                      {currentSrDocument.is_budget ? 'Has Budget' : 'No Budget'}
                    </BadgePill>
                  </div>
                ),
                */
              }
            : void 0
        }
      >
        <MDBRow>
          <MDBCol
            lg="6"
            className="d-flex flex-column align-items-start justify-content-start"
          >
            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Sponsor Study Name</BadgeLabel>
              {currentSrDocument.study.name}
            </div>

            <hr className="mt-3 mb-3 w-100" />

            <div className="h5 d-flex flex-column align-items-start mb-0 font-weight-normal">
              <BadgeLabel>Site Readiness Document Type</BadgeLabel>
              {currentSrDocument.sr_document_type.name}
            </div>

            {currentSrDocument.sr_document_subtype &&
            currentSrDocument.sr_document_subtype.name &&
            currentSrDocument.sr_document_subtype.name !== 'None' ? (
              <>
                <div className="h5 d-flex flex-column align-items-start mt-3 font-weight-normal">
                  <BadgeLabel>Site Readiness Document Subtype</BadgeLabel>
                  {currentSrDocument.sr_document_subtype.name}
                </div>
              </>
            ) : (
              void 0
            )}

            <hr className="mt-3 mb-3 w-100" />

            {currentSrDocument.primary_investigator.id ||
            currentSrDocument.primary_site.id ? (
              <MDBCard className="card-panel w-100 mb-3">
                <MDBCardBody className="rgba-grey-slight dark-grey-text">
                  <MDBCardTitle>Primary Contacts</MDBCardTitle>
                  <MDBCardText tag="div">
                    {currentSrDocument.primary_investigator.id ? (
                      <div className="d-flex flex-column align-items-start">
                        <BadgeLabel>Primary Investigator</BadgeLabel>
                        {currentSrDocument.primary_investigator.name}
                      </div>
                    ) : (
                      ''
                    )}
                    {currentSrDocument.primary_site.id ? (
                      <div
                        className={`d-flex flex-column align-items-start${
                          currentSrDocument.primary_investigator.id
                            ? ' mt-3'
                            : ''
                        }`}
                      >
                        <BadgeLabel>Primary Site</BadgeLabel>
                        {currentSrDocument.primary_site.name}
                      </div>
                    ) : (
                      ''
                    )}
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            ) : (
              void 0
            )}

            <div className="flex-grow-1 d-flex align-items-end">
              <strong className="mr-1">Created on</strong>
              {formatDateToLocal(currentSrDocument.created_at, 'LLLL')}
            </div>
          </MDBCol>

          <MDBCol lg="6">
            <SrDocumentActivityChart srDocumentId={srdocId} />
            {srDocumentPropertyOptions?.custom_properties?.length ? (
              <CustomPropertyDisplay
                propertyOptions={srDocumentPropertyOptions.custom_properties}
                customProperties={currentSrDocument.custom_properties}
              />
            ) : (
              ''
            )}
          </MDBCol>
        </MDBRow>
      </Section>

      <SrDocumentActivityList srDocument={currentSrDocument} />

      <SrDocumentDocList srDocument={currentSrDocument} />

      {/* may not be implementing budgets */}
      {/* {currentContract.is_budget ? (
        <div ref={contractBudgetRef}>
          <ContractBudgetList contract={currentContract} />
        </div>
      ) : (
        void 0
      )} */}

      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={true}
        actionButtons={[
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ]}
      >
        <SrDocumentDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      </Modal>
    </>
  )
}

export default SrDocumentShow
