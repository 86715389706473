import { MDBBreadcrumbItem } from 'mdbreact'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbSrDocumentItems = ({
  match: {
    path,
    params: { id, srDocumentId },
  },
}) => {
  const [isNew, isView, isEdit, isViewDocType] = [
    path === '/sr-documents/new',
    path === '/sr-documents/:id',
    path === '/sr-documents/:id/edit',
    path === '/sr-documents/:srDocumentId/doc-types/:docTypeId',
  ]

  return (
    <>
      <MDBBreadcrumbItem>
        <Link to="/sr-documents">Site Readiness Documents</Link>
      </MDBBreadcrumbItem>

      {!(isNew || isView || isEdit || isViewDocType) ? (
        ''
      ) : (
        <MDBBreadcrumbItem active={isNew || isView}>
          {isNew ? (
            'Create'
          ) : isView ? (
            `#${id}`
          ) : isEdit ? (
            <Link to={`/sr-documents/${id}`}>#{id}</Link>
          ) : isViewDocType ? (
            <Link to={`/sr-documents/${srDocumentId}`}>#{srDocumentId}</Link>
          ) : (
            ''
          )}
        </MDBBreadcrumbItem>
      )}

      {!(isEdit || isViewDocType) ? (
        ''
      ) : (
        <MDBBreadcrumbItem active>
          {isEdit ? 'Edit' : isViewDocType ? 'File Type' : ''}
        </MDBBreadcrumbItem>
      )}
    </>
  )
}

export default BreadcrumbSrDocumentItems
