import {
  SET_CONTRACT_COLUMNS,
  UNSET_CONTRACT_COLUMNS,
  CREATE_CONTRACT_COLUMNS,
  ERROR_CONTRACT_COLUMNS,
  UNSET_CONTRACT_COLUMN_ACTION,
  UNSET_ERROR_CONTRACT_COLUMN,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const contractColumnEndPoint = '/user/columns/contract'

// Passing data back to reducers
const setContractColumns = (obj, type) => {
  const action = { type: type, payload: obj }
  return action
}

// Non-API calls
export const clearContractColumns = () => async (dispatch) => {
  dispatch(setContractColumns({}, UNSET_CONTRACT_COLUMNS))
}
export const clearContractColumnsErrors = () => async (dispatch) => {
  dispatch(setContractColumns({}, UNSET_ERROR_CONTRACT_COLUMN))
}
export const clearContractColumnAction = () => async (dispatch) => {
  dispatch(setContractColumns({}, UNSET_CONTRACT_COLUMN_ACTION))
}

// user parameter is from the auth.user
export const fetchContractColumns = (user) => async (dispatch) => {
  if (fetchToken()) {
    const columns = user?.contract_columns?.split(',') ?? []
    dispatch(setContractColumns(columns, SET_CONTRACT_COLUMNS))
  }
}

// API calls
export const createContractColumns = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      await restApi.post(contractColumnEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setContractColumns(params, CREATE_CONTRACT_COLUMNS))
    } catch (err) {
      const errorMessage = err?.response?.data ?? { message: err.message }
      const error = { ...errorMessage, type: 'create' }
      dispatch(setContractColumns(error, ERROR_CONTRACT_COLUMNS))
    }
  }
}
