import {
  CREATE_SR_DOCUMENT_COLUMNS,
  ERROR_SR_DOCUMENT_COLUMNS,
  SET_SR_DOCUMENT_COLUMNS,
  UNSET_SR_DOCUMENT_COLUMNS,
  UNSET_SR_DOCUMENT_COLUMN_ACTION,
  UNSET_ERROR_SR_DOCUMENT_COLUMN,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentColumnEndPoint = '/user/columns/sr-document'

// Passing data back to reducers
const setSrDocumentColumns = (obj, type) => {
  const action = { type: type, payload: obj }
  return action
}

// Non-API calls
export const clearSrDocumentColumns = () => async (dispatch) => {
  dispatch(setSrDocumentColumns({}, UNSET_SR_DOCUMENT_COLUMNS))
}
export const clearSrDocumentColumnsErrors = () => async (dispatch) => {
  dispatch(setSrDocumentColumns({}, UNSET_ERROR_SR_DOCUMENT_COLUMN))
}
export const clearSrDocumentColumnAction = () => async (dispatch) => {
  dispatch(setSrDocumentColumns({}, UNSET_SR_DOCUMENT_COLUMN_ACTION))
}

// user parameter is from the auth.user
export const fetchSrDocumentColumns = (user) => async (dispatch) => {
  if (fetchToken()) {
    const columns = user?.sr_document_columns?.split(',') ?? []
    dispatch(setSrDocumentColumns(columns, SET_SR_DOCUMENT_COLUMNS))
  }
}

// API calls
export const createSrDocumentColumns = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      await restApi.post(srDocumentColumnEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentColumns(params, CREATE_SR_DOCUMENT_COLUMNS))
    } catch (err) {
      const errorMessage = err?.response?.data ?? { message: err.message }
      const error = { ...errorMessage, type: 'create' }
      dispatch(setSrDocumentColumns(error, ERROR_SR_DOCUMENT_COLUMNS))
    }
  }
}
