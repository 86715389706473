import React from "react";
import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";

const CardPanel = ({ title, className, children }) => {
  return (
    <>
      <MDBCard className={`card-panel ${className}`}>
        <MDBCardBody className="rgba-grey-slight dark-grey-text">
          <MDBCardTitle>{title}</MDBCardTitle>
          {children}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default CardPanel;
