import {
  CREATE_SR_DOCUMENT_LOCATION,
  DELETE_SR_DOCUMENT_LOCATION,
  ERROR_SR_DOCUMENT_LOCATION,
  SET_SR_DOCUMENT_LOCATIONS,
  SET_SR_DOCUMENT_LOCATION_OPTIONS,
  TOGGLE_SR_DOCUMENT_LOCATION,
  UNSET_SR_DOCUMENT_LOCATION_ACTION,
  UNSET_SR_DOCUMENT_LOCATION_OPTIONS,
  UNSET_ERROR_SR_DOCUMENT_LOCATION,
  UPDATE_SR_DOCUMENT_LOCATION,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const srDocumentLocationEndPoint = '/sr-documents/location'

// Passing data back to reducers
const setSrDocumentLocation = (
  obj,
  type,
  srDocumentLocation,
  noDefaultOptions = false
) => {
  const action = { type: type, payload: obj }
  if (srDocumentLocation) action.currentSrDocumentLocation = srDocumentLocation
  if (noDefaultOptions) action.noDefaultOptions = noDefaultOptions
  return action
}

// Non-API calls
export const clearSrDocumentLocationOptions = () => async (dispatch) => {
  dispatch(setSrDocumentLocation({}, UNSET_SR_DOCUMENT_LOCATION_OPTIONS))
}
export const clearSrDocumentLocationAction = () => async (dispatch) => {
  dispatch(setSrDocumentLocation({}, UNSET_SR_DOCUMENT_LOCATION_ACTION))
}
export const clearSrDocumentLocationErrors = () => async (dispatch) => {
  dispatch(setSrDocumentLocation({}, UNSET_ERROR_SR_DOCUMENT_LOCATION))
}

// API calls
export const fetchSrDocumentLocations =
  (results, srDocumentLocation, noDefaultOptions = false) =>
  async (dispatch) => {
    if (fetchToken()) {
      const res = await restApi.get(srDocumentLocationEndPoint, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      if (results === 'list') {
        dispatch(setSrDocumentLocation(res.data, SET_SR_DOCUMENT_LOCATIONS))
      } else if (results === 'form') {
        dispatch(
          setSrDocumentLocation(
            res.data,
            SET_SR_DOCUMENT_LOCATION_OPTIONS,
            srDocumentLocation,
            noDefaultOptions
          )
        )
      }
    }
  }

export const createSrDocumentLocation = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(srDocumentLocationEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setSrDocumentLocation(res.data, CREATE_SR_DOCUMENT_LOCATION))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setSrDocumentLocation(error, ERROR_SR_DOCUMENT_LOCATION))
    }
  }
}

export const deleteSrDocumentLocation = (location) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(srDocumentLocationEndPoint, {
      data: { id: location.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setSrDocumentLocation(res.data, DELETE_SR_DOCUMENT_LOCATION, location)
    )
  }
}

export const updateSrDocumentLocation =
  (params, location) => async (dispatch) => {
    if (fetchToken()) {
      try {
        const res = await restApi.patch(srDocumentLocationEndPoint, params, {
          headers: { Authorization: `Bearer ${fetchToken()}` },
        })
        dispatch(
          setSrDocumentLocation(res.data, UPDATE_SR_DOCUMENT_LOCATION, location)
        )
      } catch (err) {
        const error = { ...err.response.data, type: 'edit' }
        dispatch(setSrDocumentLocation(error, ERROR_SR_DOCUMENT_LOCATION))
      }
    }
  }

export const toggleSrDocumentLocation =
  (location, toggle) => async (dispatch) => {
    const toggleEndPoint = `${srDocumentLocationEndPoint}/${
      toggle ? 'enable' : 'disable'
    }`
    if (fetchToken()) {
      const res = await restApi.delete(toggleEndPoint, {
        data: { id: location.id },
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(
        setSrDocumentLocation(
          { res, toggle: toggle },
          TOGGLE_SR_DOCUMENT_LOCATION,
          location
        )
      )
    }
  }
