import restApi from "apis/restApi";
import {
  SET_USERS,
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
  TOGGLE_USER,
  ERROR_USER,
  UNSET_USER_ACTION,
  UNSET_ERROR_USER,
  MANAGE_STUDIES_GUEST_USER
} from "actions/types";
import history from "../../history";
import { fetchToken } from "utils/authUtils";

const userEndPoint = "/users";

// Passing data back to reducers
const setUser = (obj, type, user) => {
  const action = { type: type, payload: obj };
  if (user) action.currentUser = user;
  return action;
};

// Non-API calls
export const clearUserAction = () => async dispatch => {
  dispatch(setUser({}, UNSET_USER_ACTION));
};
export const clearUserErrors = () => async dispatch => {
  dispatch(setUser({}, UNSET_ERROR_USER));
};

// API calls
export const fetchUsers = () => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.get(userEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setUser(res.data, SET_USERS));
  }
};

export const createUser = params => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(userEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setUser(res.data, CREATE_USER));
    } catch (err) {
      const error = { ...err.response.data, type: "create" };
      dispatch(setUser(error, ERROR_USER));
    }
  }
};

export const deleteUser = user => async dispatch => {
  if (fetchToken()) {
    const res = await restApi.delete(userEndPoint, {
      data: { id: user.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setUser(res.data, DELETE_USER, user));
  }
};

export const updateUser = (params, user) => async dispatch => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(userEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` }
      });
      dispatch(setUser(res.data, UPDATE_USER, user));
    } catch (err) {
      const error = { ...err.response.data, type: "edit" };
      dispatch(setUser(error, ERROR_USER));
    }
  }
};

export const toggleUser = (user, toggle) => async dispatch => {
  const toggleEndPoint = `${userEndPoint}/${toggle ? "enable" : "disable"}`;
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: user.id },
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setUser({ res, toggle: toggle }, TOGGLE_USER, user));
  }
};

export const manageGuestUserStudies = params => async dispatch => {
  if (fetchToken()) {
    await restApi.post(`${userEndPoint}/studies`, params, {
      headers: { Authorization: `Bearer ${fetchToken()}` }
    });
    dispatch(setUser(params, MANAGE_STUDIES_GUEST_USER));
    history.push(`/admin/users/${params.id}`);
  }
};
